import classNames from "classnames";
import Icon from "../Icon";

import styles from "./Tabs.module.css";

export interface ITab {
  id: number;
  name: string;
  isSelected: boolean;
  icon?: string;
}

export interface TabsProps {
  items: ITab[];
  onSelect(arg: number): void;
  disabled?: boolean;
  className?: string;
}

interface TabProps {
  isSelected: boolean;
  isDisabled?: boolean;
  name: string;
  onSelect(): void;
  icon?: string;
}

function Tab({
  isSelected,
  isDisabled,
  name,
  onSelect,
  icon = undefined,
}: Readonly<TabProps>) {
  const tabClasses = classNames({
    [styles.tab]: true,
    [styles.selectedTab]: isSelected,
    [styles.disabledTab]: isDisabled,
  });
  return (
    <button
      className={tabClasses}
      onClick={onSelect}
      onKeyDown={(e) => {
        if (e.code === "Space" || e.code === "Enter") {
          e.preventDefault();
          onSelect();
        }
      }}
    >
      {icon && (
        <Icon
          className={styles.tabIcon}
          color={isSelected ? "Primary-700" : "grey"}
          name={icon}
          size={24}
        />
      )}
      {name}
    </button>
  );
}
export default function Tabs({
  items,
  onSelect,
  disabled,
  className,
}: Readonly<TabsProps>) {
  return (
    <div className={classNames(styles.container, className)}>
      {items.map((tab) => (
        <Tab
          key={tab.id}
          icon={tab.icon}
          isSelected={tab.isSelected}
          isDisabled={disabled}
          name={tab.name}
          onSelect={() => {
            if (disabled) {
              return;
            }
            onSelect(tab.id);
          }}
        />
      ))}
    </div>
  );
}
