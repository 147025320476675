import { useState } from "react";
import { Row, Spacer } from "../Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import Button from "../../ui-lib/components/Button/Button";
import { ManualSyncTable } from "./ManualSyncTable";
import { manualSyncObjects } from "../../core/api/objects/objects";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { ManualSyncInfoModal } from "./ManualSyncInfoModal";
import { ManualSyncTableProps } from "./ManualSyncWrapper";
import useSharedObject from "../../pages/Objects/SharedObjectContext/useSharedObject";

export const ManualSync = ({
  // temp flag, remove when DEV-6920 will be done
  buttonHidden,
  title,
  data,
  fetchFunc,
}: {
  buttonHidden: boolean;
  title: string;
  data?: ManualSyncTableProps;
  fetchFunc: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data: objectData } = useSharedObject();
  const [syncInfoModalOpen, setSyncInfoModalOpen] = useState(false);

  const syncObject = async () => {
    if (objectData) {
      setSyncInfoModalOpen(true);
      try {
        await manualSyncObjects(objectData.id, authenticatedRequest);
        notify({ message: t("Objects:sync_in_queue_send") });
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      }
    }
  };

  return (
    <>
      <ManualSyncInfoModal
        isOpen={syncInfoModalOpen}
        setIsOpen={setSyncInfoModalOpen}
        fetchObject={fetchFunc}
      />

      <Row className="w-100" type="space">
        <h2>{title}</h2>
        <Spacer size={20} />
        {!buttonHidden && (
          <Button
            className="croped-text"
            disabled={!objectData?.triggeringSyncAllowed}
            type="button"
            variant="secondary"
            image="refresh"
            text={t("Objects:synchronize_object")}
            onClick={syncObject}
          />
        )}
      </Row>

      <Spacer size={16} />

      <ManualSyncTable data={data} />
    </>
  );
};
