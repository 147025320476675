import { RefObject } from "react";
import classNames from "classnames";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { ResponseCenterDistributionResponse } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Table, { TableColumn } from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";

import tableStyles from "../../../../ui-lib/components/Tables/Table.module.css";

type Columns = { [key: string]: string };

const columns = (
  t: (key: string) => string,
  onStartEdit: (
    responseCenterContact: ResponseCenterDistributionResponse
  ) => void
): TableColumn<ResponseCenterDistributionResponse>[] => [
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: (rowData) => (
      <button
        className={classNames([tableStyles.tableLink, tableStyles.linkHover])}
        type="button"
        onClick={() => onStartEdit(rowData)}
      >
        {rowData.name}
      </button>
    ),
  },
  {
    key: "organisation",
    header: t("Common:email"),
    fieldTemplate: (rowData) => <TableCell value={rowData.email} />,
  },
  {
    key: "outgoing-calls",
    header: t("AlarmReceptions:distribution_table_column_get_auth_url"),
    fieldTemplate: (rowData) => (
      <Row type="left">
        <Checkbox checked={rowData.contactGetAuthorizedUrl} disabled />
      </Row>
    ),
  },
];

export const DistributionTable = ({
  data = [],
  selectionPopupRef,
  isLoading,
  onStartEdit,
  onStartDelete,
}: {
  data?: ResponseCenterDistributionResponse[];
  selectionPopupRef: RefObject<
    TableRowSelectionPopupActions<ResponseCenterDistributionResponse>
  >;
  isLoading: boolean;
  onStartEdit: (
    responseCenterContact: ResponseCenterDistributionResponse
  ) => void;
  onStartDelete: (
    responseCenterContacts: ResponseCenterDistributionResponse[]
  ) => void;
}) => {
  const t = useTranslations();

  const allColumns = columns(t, onStartEdit).reduce<Columns>(
    (prev, current) => {
      const key = current.key as string;
      const header = current.header as string;

      return {
        ...prev,
        [key]: header,
      };
    },
    {}
  );

  return (
    <>
      <Table<ResponseCenterDistributionResponse>
        // UI settings
        items={data}
        withShowMore
        isLoading={isLoading}
        hideEmptyMessage
        // Column settings
        columns={columns(t, onStartEdit).filter((c) =>
          Object.keys(allColumns).find((key) => c.key === key)
        )}
        // Row action settings
        rowActionsFixed
        rowActions={[
          {
            icon: "pencil-alt",
            text: t("Common:label_edit"),
            onClick: (rowData) => onStartEdit(rowData),
          },
          {
            icon: "trash",
            text: t("Common:delete"),
            iconVariant: "secondary",
            onClick: (rowData) => onStartDelete([rowData]),
          },
        ]}
        showRowHover
        // Selection settings
        withRowSelection
        onRowSelectionChange={(selectedItems) => {
          const filtered = selectedItems.filter(
            (i) => !!data.find((row) => row.id === i.id)
          );
          selectionPopupRef.current?.open(filtered);
        }}
      />

      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => onStartDelete(selectedItems)}
            />
          </Row>
        )}
      />
    </>
  );
};
