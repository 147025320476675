import {
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormSetValue } from "react-hook-form";
import { snakeCase } from "lodash";
import {
  AdministratorDetails,
  AvailableRoles,
} from "../../core/api/administrators/types";
import { Spacer } from "../Layout/Layout";
import { ICheckbox } from "../../ui-lib/components/Checkbox/Checkbox";
import useTranslations from "../../core/i18n/useTranslations";
import CheckboxListAccordion from "../../ui-lib/components/Accordion/CheckboxListAccordion";
import ToggleSwitch from "../../ui-lib/components/Checkbox/ToggleSwitch";

export const PermissionContext = createContext<{
  showAll: boolean;
  setShowAll: (value: SetStateAction<boolean>) => void;
  accordions?: {
    [key: string]: boolean;
  };
} | null>(null);

const PermissionGroup = ({
  title,
  description,
  icon,
  roles = {},
  selected,
  onChange,
  isEditable = true,
  accordionKey,
}: {
  title: string;
  description: string;
  icon: string;
  roles?: { [key: string]: string[] };
  selected: string[];
  onChange: (selected: string[]) => void;
  isEditable?: boolean;
  accordionKey?: string;
}) => {
  const t = useTranslations();

  if (Object.keys(roles).length === 0) {
    return null;
  }

  const items: ICheckbox[] = Object.keys(roles).map((role) => ({
    id: role,
    label: t(`Permissions:role_${snakeCase(role)}`),
    description: t(`Permissions:description_${snakeCase(role)}`),
    checked: selected.includes(role),
  }));

  const selectedItemsNum = items.filter((i) => i.checked).length;

  return (
    <CheckboxListAccordion
      title={title}
      description={description}
      icon={icon}
      selectedItemsNum={selectedItemsNum}
      items={items}
      containerStyle={{ marginBottom: "8px" }}
      onCheck={(toSelect, unselectAll) => {
        // logic for selecting all roles in the accordion at once
        if (toSelect.length > 1) {
          const rolesToSelect = toSelect.map((r) => r.id as string);
          if (unselectAll) {
            const newSelected = selected.filter(
              (item) => !rolesToSelect.includes(item)
            );
            onChange(newSelected);
          } else {
            onChange([...selected, ...rolesToSelect]);
          }
        } else {
          const role = toSelect[0].id as string;
          if (selected.includes(role)) {
            onChange(selected.filter((r) => r !== role));
          } else {
            onChange([...selected, role]);
          }
        }
      }}
      isEditable={isEditable}
      accordionKey={accordionKey}
    />
  );
};

const PermissionList = ({
  field,
  setValue,
  availableRoles,
  isEditable = true,
}: {
  field: any;
  setValue: UseFormSetValue<AdministratorDetails>;
  availableRoles?: AvailableRoles;
  isEditable?: boolean;
}) => {
  const t = useTranslations();
  const selected = field.value ?? [];

  const accordionData: {
    [key: string]: { title: string; description: string; icon: string };
  } = {
    "0MainRole": {
      title: t("Roles:main_role"),
      description: t("Roles:main_role_description"),
      icon: "key-role",
    },
    AlarmReceptionRelated: {
      title: t("Roles:alarm_reception"),
      description: t("Roles:alarm_reception_description"),
      icon: "alarm-reception",
    },
    CustomerRelated: {
      title: t("Roles:customer"),
      description: t("Roles:customer_description"),
      icon: "office-building",
    },
    ObjectRelated: {
      title: t("Roles:objects"),
      description: t("Roles:objects_description"),
      icon: "cube",
    },
    ProductRelated: {
      title: t("Roles:products"),
      description: t("Roles:products_description"),
      icon: "view-grid",
    },
    LicenseCategory: {
      title: t("Roles:license"),
      description: t("Roles:license_description"),
      icon: "ticket",
    },
    ResellerSpecific: {
      title: t("Roles:reseller"),
      description: t("Roles:reseller_description"),
      icon: "briefcase",
    },
    RoleTemplatesRelated: {
      title: t("Roles:role_template"),
      description: t("Roles:role_template_description"),
      icon: "identification",
    },
    AdministratorRelated: {
      title: t("Roles:administrators"),
      description: t("Roles:administrators_description"),
      icon: "user",
    },
    OnlySkyresponse: {
      title: t("Roles:only_skyresponse"),
      description: t("Roles:only_skyresponse_description"),
      icon: "skyresponse",
    },
    Advanced: {
      title: t("Roles:advanced"),
      description: t("Roles:advanced_description"),
      icon: "chip",
    },
    UnspecifiedGroup: {
      title: t("Roles:other"),
      description: t("Roles:other_description"),
      icon: "star",
    },
  };

  const [showAll, setShowAll] = useState(false);
  const [allAccordions, setAllAccordions] = useState<{
    [key: string]: boolean;
  }>();

  // Default open state for all accordions
  useEffect(() => {
    if (availableRoles) {
      const accordionsDefaultState: { [key: string]: boolean } = {};
      Object.keys(availableRoles).forEach((item) => {
        accordionsDefaultState[item] = false;
      });
      setAllAccordions(accordionsDefaultState);
    }
  }, [availableRoles]);

  const onChange = (roles: string[]) => setValue(field.name, roles);
  const contextValues = useMemo(
    () => ({ showAll, setShowAll, accordions: allAccordions }),
    [showAll, allAccordions]
  );

  return (
    availableRoles && (
      <PermissionContext.Provider value={contextValues}>
        <>
          <ToggleSwitch
            checked={showAll}
            onChange={() => setShowAll(!showAll)}
            name="Expand/Collapse"
            id="ToggleAllAccordions"
            label={t("Administrator:expand_switcher")}
          />
          <Spacer size={16} />
          {Object.keys(availableRoles).map((roleKey) => {
            return (
              accordionData[roleKey] && (
                <PermissionGroup
                  key={roleKey}
                  title={accordionData[roleKey]?.title}
                  description={accordionData[roleKey]?.description}
                  icon={accordionData[roleKey]?.icon}
                  roles={availableRoles[roleKey]}
                  selected={selected}
                  onChange={onChange}
                  isEditable={isEditable}
                  accordionKey={roleKey}
                />
              )
            );
          })}
        </>
      </PermissionContext.Provider>
    )
  );
};

export default PermissionList;
