import { useState } from "react";
import he from "he";
import { useForm, Controller } from "react-hook-form";
import { IPFilter } from "../../../../core/api/organizations/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Spacer } from "../../../../components/Layout/Layout";
import {
  updateIpAddress,
  useOrganizationsTree,
} from "../../../../core/api/organizations/organizations";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import { validateIPAddress } from "../../../../core/helpers/validation";
import Tree, { ITreeData } from "../../../../ui-lib/components/Hierarchy/Tree";
import TextArea from "../../../../ui-lib/components/Inputs/Textarea";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useUser from "../../../../core/user/useUser";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const CreateEditIPModal = ({
  defaultValues,
  parentId,
  onClose,
  isOpen,
}: {
  defaultValues?: IPFilter;
  parentId: string;
  onClose: (reload?: boolean) => void;
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { data: organisations } = useOrganizationsTree(+parentId);
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    trigger,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IPFilter>({ defaultValues: { ...defaultValues, inherit: true } });

  const onSubmit = async (values: IPFilter) => {
    try {
      setIsLoading(true);
      await updateIpAddress(
        values.belongsToId,
        values,
        authenticatedRequest,
        defaultValues?.id
      );
      onClose(true);
      notify({
        message: t(
          `AlarmReceptions:ip_${defaultValues ? "edit" : "create"}_success`
        ),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      header={t(`Organizations:${defaultValues ? "edit" : "create"}_ip_title`)}
      isOpen={isOpen}
      onClose={() => onClose()}
      contentStyle={{ width: "848px" }}
      submitBtn={{
        text: t(
          defaultValues
            ? "Common:save_changes"
            : "AlarmReceptions:create_ip_address"
        ),
        onClick: handleSubmit(onSubmit),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: () => onClose(false) }}
      loading={isLoading}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t(`Organizations:${defaultValues ? "edit" : "create"}_ip_description`)}
      </p>

      <Spacer size={32} />

      <form id="ip_address_from">
        <Controller
          name="startIP"
          control={control}
          rules={{
            required: true,
            validate: validateIPAddress,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("AlarmReceptions:edit_ipfilter_label_startip")}
              placeholder={t("AlarmReceptions:edit_ipfilter_placeholder_endip")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.startIP &&
                (field.value?.length === 0
                  ? t("Errors:input_field_required")
                  : t("AlarmReceptions:edit_ipfilter_ip_format"))
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          name="endIP"
          control={control}
          rules={{
            required: true,
            validate: validateIPAddress,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("AlarmReceptions:advanced_settings_ipfilters_endip")}
              placeholder={t("AlarmReceptions:edit_ipfilter_placeholder_endip")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.endIP &&
                (field.value?.length === 0
                  ? t("Errors:input_field_required")
                  : t("AlarmReceptions:edit_ipfilter_ip_format"))
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          name="belongsToId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Tree
              required
              treeRef={field.ref}
              label={t("Common:belongs_to")}
              placeholder={t("Common:select_organisation")}
              items={organisations}
              selectedTreeItem={field.value}
              onSelectItem={(selected?: ITreeData) => {
                setValue(field.name, selected?.key as number);
                trigger(field.name);
              }}
              showClearOption={false}
              validationError={
                errors?.belongsToId && t("Errors:input_field_required")
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          name="comment"
          control={control}
          rules={{
            validate: (input: string | undefined) =>
              !input || input.length < 2048,
          }}
          render={({ field }) => (
            <TextArea
              inputRef={field.ref}
              label={t(
                "AlarmReceptions:advanced_settings_addintegration_comment_label"
              )}
              placeholder={t(
                "AlarmReceptions:advanced_settings_addintegration_comment_placeholder"
              )}
              value={he.decode(field.value || "")}
              onChange={field.onChange}
              validationError={
                errors?.comment && t("Errors:ValidationHint_OrganizationInfo")
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          control={control}
          name="adminApi"
          render={({ field }) => (
            <Checkbox
              label={t("Common:admin")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
      </form>
    </PrimeModal>
  );
};
