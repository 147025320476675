import { useEffect, useState } from "react";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { MedicalTechnicalTable } from "../MedicalTechnicalNotes/MedicalTechnicalTable";
import { getMedicalTechnicalNotes } from "../../../core/api/objects/objects";
import { MedicalTechnicalNotes } from "../../../core/api/objects/types";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { MedicalTechnicalModal } from "../MedicalTechnicalNotes/MedicalTechnicalModal";
import useUser from "../../../core/user/useUser";
import useTranslations from "../../../core/i18n/useTranslations";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Button from "../../../ui-lib/components/Button/Button";

const MedicalNotes = ({ objectId }: { objectId: string }) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const [medicalData, setMedicalData] = useState<MedicalTechnicalNotes[]>();

  const isMedicalNotesAvailable = !!config?.show.find(
    (key) => key === "AllowMedicalNotes"
  );

  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      const result = await getMedicalTechnicalNotes(
        objectId,
        "medicalnotes",
        authenticatedRequest
      );
      setMedicalData(result.data);
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({ message: t(`Errors:${errorKey}`), variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMedicalNotesAvailable) {
      fetchNotes();
    }
  }, []);

  if (!isMedicalNotesAvailable && config?.show.includes("CareiumHideFields")) {
    return null;
  }

  return (
    <>
      <Spacer size={20} />

      <Column type="top" align="start">
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <>
            <Row type="space" className="w-100">
              <h2>{t("Objects:medical_notes")}</h2>
              <Spacer />
              <Button
                type="button"
                variant="secondary"
                image="document-text"
                text={t("Objects:add_note")}
                disabled={isLoading}
                onClick={() => setAddNoteModalOpen(true)}
              />
            </Row>

            <Spacer size={16} />
            <MedicalTechnicalTable notes={medicalData || []} />
          </>
        )}
      </Column>

      {addNoteModalOpen && (
        <MedicalTechnicalModal
          isOpen={addNoteModalOpen}
          onClose={() => setAddNoteModalOpen(false)}
          onAdd={fetchNotes}
          title={t("Objects:add_medical_notes")}
          type="medicalnotes"
          objectId={objectId}
        />
      )}
    </>
  );
};

export default MedicalNotes;
