import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Switch,
} from "react-router-dom";
import moment from "moment";
import ErrorAlert, {
  getErrorKey,
  notifyError,
} from "../../components/Errors/ErrorAlert";
import _adminApi from "../../core/api/admin";
import authApi, { GetTokenResponse } from "../../core/api/auth";
import configApi from "../../core/api/config";
import useBranding from "../../core/branding/useBranding";
import useUser from "../../core/user/useUser";
import { Step1, Step1FormData } from "./Step1";
import { Step2, Step2FormData } from "./Step2";
import { ResetPasswordContent } from "./ResetPasswordContent";
import { PasswordConstraints } from "../../core/api/administrators/types";
import useLanguage from "../../core/i18n/useLanguage";
import { getUserStoredLang } from "../../ui-lib/utils/localeHelper";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";
import Navigation from "../../components/Navigation/Navigation";
import { Spacer } from "../../components/Layout/Layout";
import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";
import useTranslations from "../../core/i18n/useTranslations";
import LogoLink from "./LogoLink/LogoLink";

import styles from "./Login.module.css";
import { notify } from "../../ui-lib/components/Alerts/Toast";

const adminApi = _adminApi(axios);

const Login = () => {
  const t = useTranslations();
  const user = useUser();
  const history = useHistory();
  const branding = useBranding();
  const languageStore = useLanguage();
  const { width } = useWindowDimensions();

  const imageSrc = `./assets/images/welcome${
    branding.currentHomeImage ? `_${branding.currentHomeImage}` : ""
  }.png`;

  const [logInError, setLogInError] = useState<string>();
  const [resetPasswordToken, setResetPasswordToken] = useState<
    string | undefined
  >();
  const [passwordComplexity, setPasswordComplexity] =
    useState<PasswordConstraints>();

  useEffect(() => {
    const resetToken = localStorage.getItem("userResetToken");
    if (resetToken && !resetPasswordToken) {
      setResetPasswordToken(resetToken);
      authApi
        .getPasswordComplexity(resetToken)
        .then((passComplexity) => {
          setPasswordComplexity(passComplexity.data);
        })
        .finally(() => {
          localStorage.removeItem("userResetToken");
        });
    }
  }, []);

  const [step1Data, setStep1Data] = useState<
    | {
        token: string;
        authType: string;
        username: string;
        password: string;
        phoneNumber?: string;
        resetPasswordToken?: string;
      }
    | undefined
  >();
  const [twoFactorReset, setTwoFactorReset] = useState(false);

  const clearTableSearches = () => {
    const localStoreKeys = Object.keys(localStorage);
    localStoreKeys.forEach((key) => {
      if (key.includes("table-state")) {
        localStorage.setItem(key, "");
      }
    });
  };

  const setMomentLocale = () => {
    const storedLanguage = languageStore.currentLanguage?.id;
    if (storedLanguage) {
      moment.locale(storedLanguage.split("-")[0]);
    }
  };

  const navigateToLink = () => {
    const storedLanguage = getUserStoredLang(user);
    if (storedLanguage)
      languageStore.setCurrentLanguage(storedLanguage.language);
    const { location } = window;
    const linkToNavigate = localStorage.getItem("linkToNavigate");
    if (linkToNavigate?.length) {
      location.href = linkToNavigate;
      localStorage.setItem("linkToNavigate", "");
    } else {
      history.replace("/adminportal");
    }
  };

  const handleStep1Sumbit = async (
    data: GetTokenResponse,
    formData: Step1FormData
  ) => {
    switch (data.twoFactorAuthType) {
      case "none":
      case undefined:
        // Skip step 2 and login user
        await user.logIn({
          token: data.access_token,
          refreshToken: data.refresh_token,
          expiresIn: data.expires_in,
        });
        clearTableSearches();
        navigateToLink();
        setMomentLocale();
        break;
      case "sms":
      case "sms_seperate": {
        // Fetch phone number
        const config = await configApi.getConfig({
          token: data.access_token,
        });
        const admin = await adminApi.getAdmin({
          id: config.account.id,
          token: data.access_token,
        });

        const phoneNumber =
          admin.TwoFactorAuthenticationType === "sms_seperate"
            ? admin.TwoFactorAuthenticationData
            : admin.Telephone;

        // Continue to step 2
        setStep1Data({
          ...formData,
          token: data.access_token,
          authType: "sms",
          phoneNumber,
          resetPasswordToken: data.resetPasswordToken,
        });
        break;
      }
      case "google":
      default:
        // Continue to step 2
        setStep1Data({
          ...formData,
          token: data.access_token,
          authType: data.twoFactorAuthType,
          resetPasswordToken: data.resetPasswordToken,
        });
        break;
    }
  };

  const onStep1Submit = async (formData: Step1FormData) => {
    setLogInError(undefined);

    try {
      const data = await authApi.getToken(formData);

      if (!data.access_token) {
        throw new Error();
      }

      if (data.resetPasswordToken && data.twoFactorAuthType === "none") {
        const passComplexity = await authApi.getPasswordComplexity(
          data.resetPasswordToken
        );
        setPasswordComplexity(passComplexity.data);
        setResetPasswordToken(data.resetPasswordToken);
      } else {
        handleStep1Sumbit(data, formData);
      }
    } catch (e) {
      setLogInError(getErrorKey(e));
    }
  };

  const onStep2Submit = async (formData: Step2FormData) => {
    setLogInError(undefined);

    try {
      const data = await authApi.getToken2FA({
        ...formData,
        token: formData.token,
      });

      if (!data.access_token) {
        throw new Error();
      }

      if (data.resetPasswordToken) {
        const passComplexity = await authApi.getPasswordComplexity(
          data.resetPasswordToken
        );
        setPasswordComplexity(passComplexity.data);
        setResetPasswordToken(data.resetPasswordToken);
      } else {
        await user.logIn({
          token: data.access_token,
          refreshToken: data.refresh_token,
          expiresIn: data.expires_in,
        });
        clearTableSearches();
        navigateToLink();
        setMomentLocale();
      }
    } catch (e) {
      setLogInError(getErrorKey(e));
    }
  };

  const onPasswordReset = async (formData: Step2FormData) => {
    try {
      await authApi.resetPassword({
        token: step1Data?.token!,
        password: step1Data?.password!,
        authCode: formData.code,
      });

      notify({
        message: t("Administrator:change_password_success"),
      });

      setStep1Data(undefined);
      setTwoFactorReset(false);
    } catch (error: any) {
      notifyError({ error, t });
    }
  };

  useEffect(() => {
    if (document.URL.includes("loginerror")) {
      setLogInError("BlockedIp");
    }
  });

  return (
    <>
      <div className={styles.loginWrapper}>
        {width < breakpoints.desktop && <LogoLink />}
        <div className={styles.loginContainer}>
          <div className={styles.logoWraper}>
            <img alt="logo" src={imageSrc} className={styles.brandImg} />
            {width >= breakpoints.desktop && (
              <p className="subTitle">
                {t(`Home:login_subtitle_${branding.customerKey}`)}
              </p>
            )}
          </div>
          <div className={styles.formContainer}>
            {width >= breakpoints.desktop && <LogoLink />}
            <Router>
              <Switch>
                <Route path="/adminportal/login">
                  {!step1Data ? (
                    <Step1 onSubmit={onStep1Submit} />
                  ) : (
                    <Step2
                      step1Data={step1Data}
                      onSubmit={
                        twoFactorReset ? onPasswordReset : onStep2Submit
                      }
                      twoFactorReset={twoFactorReset}
                    />
                  )}
                </Route>
                <Route path="/adminportal/forgotPassword">
                  <ForgotPassword />
                </Route>
              </Switch>
            </Router>
            {logInError && (
              <>
                <Spacer size={40} />
                <ErrorAlert errorKey={logInError} />
              </>
            )}
          </div>
        </div>
        <Navigation />
      </div>
      {!!resetPasswordToken && (
        <ResetPasswordContent
          isOpen={!!resetPasswordToken}
          onClose={() => {
            setResetPasswordToken(undefined);
          }}
          onPasswordReset={() => {
            setResetPasswordToken(undefined);
            setStep1Data(undefined);
          }}
          onTwofactor={(data) => {
            setStep1Data(data);
            setTwoFactorReset(true);
            setResetPasswordToken(undefined);
          }}
          data={passwordComplexity}
          resetPasswordToken={resetPasswordToken}
        />
      )}
    </>
  );
};

export default observer(Login);
