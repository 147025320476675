import { AxiosInstance } from "axios";
import { ISwitchQuestionnaire, UserAdminResponse } from "./types";

const getUserAdmins = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<UserAdminResponse[]>(
    `/api/v2/useradmins?userid=${objectId}`
  );

const addUserAdminRelation = async (
  objectId: string,
  adminId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<{}>(
    `/api/v2/useradmins`,
    JSON.stringify({ userid: objectId, adminid: adminId }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const removeUserAdminRelation = async (
  userAdminId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<{}>(`/api/v2/useradmins/${userAdminId}`, {
    headers: { "Content-Type": "application/json" },
  });

const sendSwitchQuestionnaire = async (
  data: ISwitchQuestionnaire,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(`/api/v2/feedback`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

export {
  getUserAdmins,
  addUserAdminRelation,
  removeUserAdminRelation,
  sendSwitchQuestionnaire,
};
