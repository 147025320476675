import { useEffect, useState } from "react";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { AssociatedObjectsTable } from "./AssociatedObjectsTable";
import { NewAssociationObjectModal } from "./NewAssociationObjectModal";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { fetchAssociatedObjects } from "../../../core/api/contacts/contacts";
import { usePageFilter } from "../../../core/hooks/filters/usePageFilter";
import { IAssociatedObjects } from "../../../core/api/contacts/types";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";

export const AssociatedObjects = ({ contactId }: { contactId: number }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const { pageFilter, setPageFilter } = usePageFilter();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [associatedObjects, setAssociatedObjects] =
    useState<IAssociatedObjects>();

  const getAssociatedObjects = async () => {
    setIsLoading(true);
    try {
      const res = await fetchAssociatedObjects(
        contactId,
        authenticatedRequest,
        pageFilter
      );
      setAssociatedObjects(res.data);
    } catch (error: any) {
      notifyApiErrors(error?.response?.data.errords);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAssociatedObjects();
  }, [pageFilter]);

  return (
    <>
      <h1>{t("Menu:associated_objects")}</h1>
      <Spacer size={8} />
      <p>{t("Contacts:associated_objects_title_description")}</p>
      <Spacer size={32} />
      <Row className="w-100 flex0" type="space">
        <h2>{t("Contacts:associated_objects_subtitle")}</h2>
        {/* hidden until back-end implementation */}
        {/* <Button
          variant="secondary"
          text={t("Contacts:associate_new_object")}
          onClick={() => setIsOpen(true)}
        /> */}
      </Row>
      <Spacer size={16} />
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <AssociatedObjectsTable
          tableData={associatedObjects?.users}
          contactId={contactId}
          totalAmount={associatedObjects?.total ?? 0}
          pageFilter={pageFilter}
          setPageFilter={setPageFilter}
          refetch={getAssociatedObjects}
        />
      )}
      {isOpen && (
        <NewAssociationObjectModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
