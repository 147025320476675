import { AxiosInstance } from "axios";
import querystring from "query-string";
import { ApiResponse, ApiSearch } from "../../interfaces/Api";
import { Camera, CameraStatus, EditCameraModel, ICameras } from "./types";

const getCamerasInStock = async (
  query: ApiSearch,
  authenticatedRequest: AxiosInstance
) => {
  const parsedQuery = querystring.stringify({ ...query });
  const result = await authenticatedRequest.get<ICameras>(
    `/api/v2/cameras/table?${parsedQuery}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result.data;
};
const addCameraToObject = async (
  objectId: string,
  cameraId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<ApiResponse<any>>(
    `/api/v2/cameras/${cameraId}/users/${objectId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
const getCamera = async (
  cameraId: string,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.get<EditCameraModel>(`/api/v2/cameras/${cameraId}`);
const getCameraStatus = async (
  cameraId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<CameraStatus>(`/api/v2/cameras/${cameraId}/status`);

const saveCamera = async (
  cameraData: Camera,
  authenticatedRequest: AxiosInstance,
  id?: number
) => {
  const method = id !== undefined ? "put" : "post";
  const path = id !== undefined ? `/${id}` : "";
  const result = await authenticatedRequest[method]<ApiResponse<number>>(
    `/api/v2/cameras${path}`,
    cameraData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return result.data;
};
const deleteCamera = async (
  cameraId: string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/cameras/${cameraId}`
  );
  return result;
};
const removeCameraFromObject = async (
  objectId: string,
  cameraId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/cameras/${cameraId}/users/${objectId}`
  );
export {
  addCameraToObject,
  removeCameraFromObject,
  getCamerasInStock,
  deleteCamera,
  saveCamera,
  getCamera,
  getCameraStatus,
};
