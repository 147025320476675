import { ReactNode } from "react";
import useUser from "../core/user/useUser";

export enum Role {
  UserAdminsAll = "UserAdminRelation",
  AlarmReceptionsCreate = "RcCreate",
  OrganizationsList = "Organizations",
  EditAlarmReceptionMenu = "ResponseCenterMenuEdit",
  EditIPWhiteList = "IPWhiteListEdit",
  EditAlarmReceptionAdvancedSettings = "RcAdvancedSettings",
  EmergencyCenterSettings = "EmergencyCenterSettings",
  AlarmReceptionCalendarEdit = "RcCalendarEdit",
  PeriodicInteractions = "User/Interactions",
  BetaAccess = "NewAdminBeta",
  AlarmReceptionsCreatePublic = "RcCreatePublic",
  ObjectTestAlarm = "UserTestAlarm",
  ObjectDelete = "DeleteUser",
  SuperAdmin = "SkygdSuperAdmin",
  DownloadAdminEvents = "DownloadAdminEvents",
  EditOrganizations = "EditOrganizations",
  DeleteOrganizations = "DeleteOrganizations",
  ActivateSubscriptions = "ActivateWithoutCode",
  ApplicationMenuAlternatives = "ApplicationMenuAlternatives",
  ViserStandart = "SensioHideFields",
  ViserSabo = "SensioHideFieldsSABO",
  ViserHTJ = "SensioHideFieldsOBO",
  CameraForceProvisioning = "CameraForceProvisioning",
}

export const RoleFeature = ({
  requires,
  children,
  type = "oneOf",
}: {
  requires: Role[];
  children: ReactNode;
  type?: "oneOf" | "every" | "oneOfNot" | "everyNot";
}) => {
  const { config } = useUser();

  const rolesArr: string[] = Object.values(requires);

  const getPermissionIsAllowed = () => {
    if (type === "every") {
      return rolesArr.every((p) => config?.show.includes(p));
    }
    if (type === "oneOf") {
      return rolesArr.some((p) => config?.show.includes(p));
    }
    if (type === "oneOfNot") {
      return !rolesArr.some((p) => config?.show.includes(p));
    }
    if (type === "everyNot") {
      return !rolesArr.every((p) => config?.show.includes(p));
    }
  };

  return getPermissionIsAllowed() ? <>{children}</> : null;
};
