import { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import BulkDeleteModal from "../../components/BulkDeleteModal";
import { Column, Spacer } from "../../components/Layout/Layout";
import LocationCameras from "../../components/location/LocationCameras";
import {
  EditLocationActions,
  LocationEditForm,
} from "../../components/location/LocationEditForm";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import {
  deleteLocation,
  getLocation,
} from "../../core/api/locations/locations";
import { EditLocationModel } from "../../core/api/locations/types";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { useStoredTableState } from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PopupContextMenu from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { PAGE_ID as parentId } from "./Locations";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { ManualSyncWrapper } from "../../components/ManualSync/ManualSyncWrapper";

import styles from "../PagesLayout.module.css";

const parentPath = "/adminportal/locations";

const LocationDetails = () => {
  const { data: organisationTree } = useOrganizationsTree();
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const history = useHistory();
  const user = useUser();
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const [location, setLocation] = useState<EditLocationModel>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const editLocationRef = useRef<EditLocationActions>(null);
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const { isDirty } = useSaveObject(); // We could use more stuff from save object, not just isDirty
  const { setSubNav } = useSubNav();

  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  const loadData = async () => {
    try {
      setIsLoading(true);
      const trans = await getLocation(id, authenticatedRequest);
      setLocation(trans.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    const nav = {
      menu: [
        {
          key: "Menu:Location_details",
          to: `${url}`,
        },
      ],
      backUrl,
      backText: t("Menu:locations"),
    };
    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  return (
    <>
      <PageHeader
        title={t(`Locations:BeaconType_${location?.klType}`)}
        icon="location-marker"
      >
        <Button
          text={t("Common:save")}
          onClick={async () => {
            setIsLoading(true);
            const result = await editLocationRef.current?.submitForm();
            if (result?.status) {
              await loadData();
            }
            notify({
              message: t("Locations:edited_success"),
            });
            setIsLoading(false);
          }}
          disabled={!isDirty}
        />
        {user.config?.show.includes("DeleteKnownLocations") && (
          <PopupContextMenu
            className="mt-8"
            key="location_details_actions"
            position="right bottom"
            nested
            trigger={() => (
              <div>
                <Button
                  className="ml-8"
                  type="button"
                  variant="secondary"
                  image="dots-horizontal"
                  text={t("Common:other")}
                />
              </div>
            )}
          >
            <PopupContextMenuLinkButton
              icon="trash"
              text={t("Common:delete")}
              onClick={() => {
                setDeletionModalOpen(true);
              }}
            />

            <BulkDeleteModal
              onClose={() => {
                setDeletionModalOpen(false);
              }}
              labels={{
                single: t("Locations:type"),
                multi: t("Locations:type_multi"),
              }}
              isOpen={deletionModalOpen}
              onDelete={(status: boolean) => {
                if (status) {
                  history.replace(backUrl);
                }
              }}
              ids={[id]}
              apiFunc={deleteLocation}
            />
          </PopupContextMenu>
        )}
      </PageHeader>
      <Column className={styles.content} align="fill">
        <h1>{t("Locations:details_page_title")}</h1>
        <Spacer size={8} />
        <p>{t("Locations:details_page_description")}</p>

        <Spacer size={32} />

        <h2>{t("Locations:basic_details_title")}</h2>
        <Spacer size={8} />
        <p>{t("Locations:basic_details_description")}</p>
        <Spacer size={16} />
        {(location === undefined || isLoading) && (
          <LoadingSpinner theme="primary" />
        )}
        {location && !isLoading && (
          <LocationEditForm
            ref={editLocationRef}
            location={location}
            organisationTree={organisationTree}
          />
        )}
        <Spacer size={16} />
        <Divider vertical={false} />
        <Spacer size={32} />
        {location?.id && (
          <LocationCameras
            locationId={location.id.toString()}
            organisationTree={organisationTree}
          />
        )}
        <ManualSyncWrapper
          clusterData={{
            lastSync: location?.lastClusterSync,
            latestSyncAttempt: location?.lastClusterSyncAttempt,
          }}
          refetchFunc={loadData}
        />
      </Column>
    </>
  );
};

const LocationDetailsContainer = () => {
  return (
    <SaveObjectProvider>
      <LocationDetails />
    </SaveObjectProvider>
  );
};
export default LocationDetailsContainer;
