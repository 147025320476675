import { useRef, useState } from "react";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import { AlarmCodeTable } from "./AlarmCodeTable";
import CreateAlarmCodeModal from "./CreateAlarmCodeModal";
import EditAlarmCodeModal from "./EditAlarmCodeModal";

export const AlarmCodes = ({
  responseCenterId,
}: {
  responseCenterId: string | number;
}) => {
  const t = useTranslations();
  const [reloadTableFlag, setReloadTableFlag] = useState(false);

  const editModalRef = useRef<ModalActions>(null);
  const [upForEdit, setUpForEdit] = useState<number | undefined>();

  const requestRefetch = () => {
    setReloadTableFlag(!reloadTableFlag);
  };

  return (
    <Column style={{ width: "100%" }} type="top" align="start">
      <h1>{t("AlarmReceptions:alarm_handling_alarm_codes")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:alarm_codes_description")}
      </p>
      <Spacer size={16} />
      <Row className="w-100 flex0" type="space">
        <h2>{t("AlarmReceptions:list_of_codes")}</h2>
        <Spacer />
        <Modal
          trigger={() => (
            <Button
              size="small"
              variant="secondary"
              text={t("AlarmReceptions:alarm_handling_alarm_codes_add")}
            />
          )}
        >
          {(close) => (
            <CreateAlarmCodeModal
              responseCenterId={responseCenterId}
              onClose={close}
              onAdd={() => {
                requestRefetch();
                close();
              }}
            />
          )}
        </Modal>
      </Row>
      <Spacer size={32} />
      <AlarmCodeTable
        responseCenterId={responseCenterId}
        onStartEdit={(rowData) => {
          setUpForEdit(rowData.id);
          editModalRef?.current?.open();
        }}
        fetchFlag={reloadTableFlag}
      />

      <Modal modalRef={editModalRef}>
        {(close) =>
          upForEdit ? (
            <EditAlarmCodeModal
              responseCenterId={responseCenterId}
              alarmCodeId={upForEdit}
              onClose={close}
              onEdit={() => {
                close();
                requestRefetch();
              }}
            />
          ) : null
        }
      </Modal>
    </Column>
  );
};
