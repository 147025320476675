import { useEffect, useState } from "react";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import { Spacer } from "../../../components/Layout/Layout";
import { NewRoleTemplate } from "../../../core/api/roleTemplates/types";
import { useObjectForm } from "../../../core/SaveObjectContext/hooks";
import {
  editRoleTemplate,
  useRoleTemplate,
} from "../../../core/api/roleTemplates/roleTemplates";
import { RoleTemplateGeneralForm } from "./RoleTemplateGeneralForm";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import {
  DateTimeFormat,
  Timezone,
} from "../../../core/api/administrators/types";
import { MenuAlternativesController } from "../../../components/MenuAlternatives/MenuAlternativesController";
import { Settings } from "../AdministratorDetails/Settings/Settings";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const EditRoleTemplate = ({
  roleTemplateId,
  onSubmit,
  onClose,
  resetId,
  organisations,
  isEditTemplateOpen,
  setIsEditTemplateOpen,
  timezones,
  dateTimeFormats,
  editable,
}: {
  roleTemplateId: number;
  onSubmit: () => void;
  onClose?: () => void;
  resetId?: () => void;
  organisations: ITreeData[];
  isEditTemplateOpen: boolean;
  setIsEditTemplateOpen: any;
  editable?: boolean;
  timezones: Timezone[];
  dateTimeFormats: DateTimeFormat[];
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const [loading, setLoading] = useState(false);

  const approveChanges = !!config?.show.includes("UpdateRoleTemplates");

  const {
    data: roleTemplate,
    isLoading,
    isError,
  } = useRoleTemplate(roleTemplateId);

  const objectForm = useObjectForm<NewRoleTemplate>({
    defaultValues: {
      ...roleTemplate,
      type: roleTemplate?.type === "customer" ? "admin" : roleTemplate?.type,
    },
  });

  useEffect(() => {
    if (roleTemplate) {
      objectForm.reset(roleTemplate);
    }
  }, [roleTemplate]);

  const closeModal = () => {
    setIsEditTemplateOpen(false);
    resetId?.();
    if (onClose !== undefined) {
      onClose();
    }
    objectForm.reset();
  };

  useEffect(() => {
    if (isError) {
      notify({
        variant: "error",
        message: "Error occurred during the template fetching",
      });
      onClose?.();
      closeModal();
    }
  }, [isError]);

  const onEditTemplateSubmit = async (formData: NewRoleTemplate) => {
    setLoading(true);
    try {
      if (formData.responseCenterId) {
        formData.organizationId = undefined;
      }
      await editRoleTemplate(roleTemplateId, formData, authenticatedRequest);
      notify({
        message: t("Administrator:role_template_edited_success"),
      });
      onSubmit();
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <LoadingSpinner theme="primary" />;

  return (
    <PrimeModal
      withHeader
      withFooter
      header={
        editable
          ? t("Administrator:edit_role_template")
          : t("Administrator:role_permissions")
      }
      isOpen={isEditTemplateOpen}
      onClose={closeModal}
      submitBtn={{
        text: t("Administrator:save_role_changes"),
        onClick: objectForm.handleSubmit(onEditTemplateSubmit),
        disabled: !objectForm.formState.isDirty || loading || !approveChanges,
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: closeModal,
      }}
      loading={isLoading || loading}
      contentStyle={{ width: "1000px" }}
    >
      <p>{t("Administrator:add_role_template_description")}</p>
      <Spacer size={16} />
      <RoleTemplateGeneralForm
        responceCenterId={roleTemplate?.responseCenterId}
        organisations={organisations}
        authenticatedRequest={authenticatedRequest}
        objectForm={objectForm}
        isEditPage
        isEditable={editable}
        timezones={timezones}
        dateTimeFormats={dateTimeFormats}
      />

      {roleTemplate && (
        <>
          <Spacer size={16} />

          <MenuAlternativesController
            alternativesType="adminId"
            ownerId={roleTemplateId}
            tableHeader="AlarmReceptions:input_menu_alternatives"
          />
        </>
      )}

      {(roleTemplate?.type === "respondent" ||
        roleTemplate?.type === "responscenter") && (
        <>
          <Spacer size={16} />

          <Settings id={roleTemplateId} onlyTable />
        </>
      )}
    </PrimeModal>
  );
};
