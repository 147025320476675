import { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Icon } from "../Icon";

import styles from "./CollapsibleBlock.module.css";

export interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  withScroll?: boolean;
  className?: string;
}
export default function CollapsibleBlock({
  title,
  children,
  withScroll,
  className,
}: Readonly<CollapsibleProps>) {
  const [show, setShow] = useState<boolean>(false);
  const collapseRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (show && withScroll)
      setTimeout(() => {
        collapseRef?.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 100);
  }, [show]);

  return (
    <>
      <button
        ref={collapseRef}
        className={classNames(styles.container, className)}
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "Space") {
            e.preventDefault();
            setShow(!show);
          }
        }}
      >
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          {show ? (
            <Icon name="chevron-up" color="Primary-700" size={18} />
          ) : (
            <Icon name="chevron-down" color="Primary-700" size={18} />
          )}
        </div>
      </button>
      {show && <div>{children}</div>}
    </>
  );
}
