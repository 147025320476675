import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { ISpecialSettings } from "../../../../core/api/administrators/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import {
  createAdminSetting,
  editAdminSetting,
} from "../../../../core/api/administrators/administrators";
import useUser from "../../../../core/user/useUser";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const EditSettingsModal = ({
  adminId,
  settingsData,
  isEditModalOpen,
  onClose,
  onSubmit,
}: {
  adminId: number;
  settingsData?: ISpecialSettings;
  isEditModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ISpecialSettings>({
    shouldUnregister: true,
    defaultValues: settingsData,
  });

  const updateSetting = async (values: ISpecialSettings) => {
    try {
      setIsLoading(true);
      if (settingsData) {
        await editAdminSetting(
          adminId,
          settingsData.id,
          values,
          authenticatedRequest
        );
      } else {
        await createAdminSetting(adminId, values, authenticatedRequest);
      }
      onClose();
      onSubmit();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      loading={isLoading}
      isOpen={isEditModalOpen}
      onClose={onClose}
      header={t(`Administrator:${settingsData ? "edit" : "add"}_new_setting`)}
      submitBtn={{
        text: t(`Common:${settingsData ? "save_changes" : "label_create"}`),
        onClick: handleSubmit(updateSetting),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t(
          `Administrator:${
            settingsData ? "edit" : "add"
          }_setting_modal_subtitle`
        )}
      </p>
      <Spacer size={16} />
      <form>
        <Column type="top" align="start">
          <Row align="start" type="fill">
            <Controller
              name="setting"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  inputRef={field.ref}
                  label={t("Menu:settings")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.setting && t("Errors:input_field_required")
                  }
                  width="376px"
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="value"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  inputRef={field.ref}
                  label={t("Common:custom_filters_column_value")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.value && t("Errors:input_field_required")
                  }
                  width="376px"
                />
              )}
            />
          </Row>
        </Column>
      </form>
    </PrimeModal>
  );
};
