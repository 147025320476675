import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import classNames from "classnames";
import {
  Column,
  ResponsiveRow,
  Row,
  Spacer,
  TwoColGrid,
} from "../../../components/Layout/Layout";
import { Role, RoleFeature } from "../../../components/RoleFeature";
import { ObjectDetailsModel } from "../../../core/api/objects/types";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import { AlarmHandling } from "../../../core/api/alarmhandlings";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";
import useTranslations from "../../../core/i18n/useTranslations";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import useUser from "../../../core/user/useUser";
import PhotoWrapperWithUpload from "../../../ui-lib/components/PhotoWrapperWithUpload/PhotoWrapperWithUpload";
import MultiStringInput from "../../../ui-lib/components/MultiInput/MultiStringInput";
import Divider from "../../../ui-lib/components/Divider/Divider";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import Button from "../../../ui-lib/components/Button/Button";
import TemporaryAlarmHandlingModal from "./TemporaryAlarmHandlingModal";
import useSharedObject from "../SharedObjectContext/useSharedObject";

import styles from "./ObjectGeneral.module.css";

const languageOptions = [
  { id: 9, key: "english", flag: "GB" },
  { id: 29, key: "swedish", flag: "SE" },
];

const ObjectGeneralBasic = ({
  defaultValues,
  objectId,
  objectPhoto,
  organizations,
  alarmHandlings,
}: {
  defaultValues: ObjectDetailsModel;
  objectId: string;
  objectPhoto: string;
  organizations: ITreeData[];
  alarmHandlings: AlarmHandling[];
}) => {
  const t = useTranslations();
  const { config } = useUser();
  const { width } = useWindowDimensions();
  const { isLoading: objectLoading } = useSharedObject();

  const [alarmHandlingsList, setAlarmHandlingsList] = useState<IItemProp[]>([]);
  const [alarmHandlingModalOpened, setAlarmHandlingModalOpened] =
    useState(false);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const isDesktop = width >= breakpoints.desktop;
  const isCoreDetailsEditable = !!config?.show.find(
    (key) => key === "User/CoreDetails"
  );
  const hasHideTemporaryAlarmHandlingPermission = config?.show.includes(
    "HideTemporaryAlarmHandling"
  );

  useEffect(() => {
    setAlarmHandlingsList(
      alarmHandlings.map(
        (alarm) =>
          ({
            isSelected: defaultValues.alarmHandlingId === alarm.id,
            id: alarm.id,
            name: alarm.name,
          } as IItemProp)
      )
    );
  }, [defaultValues.alarmHandlingId]);

  return (
    <div className={styles.ObjectBasicContainer}>
      <h2>{t("Objects:general_basic_details_title")}</h2>
      <Spacer size={8} />
      <p>{t("Objects:general_basic_details_description")}</p>

      <Spacer size={16} />
      <Row align="start" className={styles.namePhotoRow}>
        <Column align="start">
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
              minLength: 2,
              maxLength: 60,
            }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                dataCy="objectName"
                label={t("Objects:object_name")}
                value={field.value}
                onChange={field.onChange}
                disabled={!isCoreDetailsEditable}
                validationError={errors?.name && t("Errors:input_object_name")}
              />
            )}
          />
          <Spacer size={16} />
          {defaultValues.sellerName && (
            <p data-cy="objectCreationDate">
              <span className="strong">
                {t("Objects:labels_admins_seller")}
              </span>
              {": "}
              {defaultValues.sellerName}
            </p>
          )}
        </Column>
        <Spacer />
        <Column align="start" className={classNames({ ["flex0"]: !isDesktop })}>
          {!!config?.show.find((key) => key === "User/Photo") && (
            <PhotoWrapperWithUpload
              objectId={objectId}
              buttonName="objectThumbnail"
              propertyToContainData="Userimage"
              preLoadedImageData={objectPhoto}
              photoTitle={t("Common:label_image")}
              alternativeString="image thumbnail"
            />
          )}
        </Column>
      </Row>
      <ResponsiveRow align="end">
        {organizations.length > 0 ? (
          <Column align="fill">
            <Controller
              name="organizationId"
              control={control}
              render={({ field }) => (
                <div>
                  <label htmlFor="addobject_organisation">
                    {t("Objects:organisation")}
                  </label>
                  <Tree
                    placeholder={t("Common:select_organisation")}
                    validationError={
                      errors.organizationId &&
                      t("Objects:organisationvalidation")
                    }
                    items={organizations}
                    disabled={!isCoreDetailsEditable}
                    selectedTreeItem={field.value}
                    onSelectItem={(data: ITreeData) => {
                      if (data?.key !== undefined) {
                        setValue(field.name, data.key as number);
                      }
                    }}
                  />
                </div>
              )}
            />
          </Column>
        ) : (
          <Column>
            <TextInput
              dataCy="objectOrganisation"
              label={t("Objects:organisation")}
              value={defaultValues.organizationName}
              disabled
            />
          </Column>
        )}
        <Spacer />
        <Column align="start" type="bottom">
          <p data-cy="objectCreationDate">
            <span className="strong">{t("Common:label_registered_at")}</span>{" "}
            {moment(defaultValues.registrationTime).format("DD MMMM YYYY")}
          </p>
        </Column>
      </ResponsiveRow>

      <Spacer size={16} />

      <ResponsiveRow>
        <Controller
          name="responseCenterId"
          control={control}
          render={() => (
            <TextInput
              inputClassName="fw-600"
              dataCy="objectARC"
              label={t("Common:labels_alarm_reception")}
              value={defaultValues.responseCenterName}
              disabled
            />
          )}
        />
        <Spacer />
        <Controller
          name="alarmHandlingId"
          control={control}
          render={() => (
            <Dropdown
              dataCy="objectAlarmHandling"
              onSelectItem={(item) => {
                const updatedHandlingList = alarmHandlingsList.map((alarm) => ({
                  ...alarm,
                  isSelected: alarm.id === item.id,
                }));
                setAlarmHandlingsList(updatedHandlingList);
                setValue("alarmHandlingId", item.id as number);
                setValue("alarmHandling", item.name);
              }}
              items={alarmHandlingsList}
              title={t("AlarmHistory:alarm_query_filter_alarm_handling")}
              selectedItem={alarmHandlingsList.find(
                (alarm) => alarm.isSelected
              )}
              disabled={!alarmHandlingsList.length || !isCoreDetailsEditable}
            />
          )}
        />
      </ResponsiveRow>
      <Spacer size={16} />
      <Row type="left" align="end">
        {defaultValues.nextSubscriptionId &&
          !hasHideTemporaryAlarmHandlingPermission && (
            <Button
              className={styles.tmpAlarmHandlingBtn}
              variant="popup"
              text={
                defaultValues.temporaryAlarmHandlingEnd &&
                moment.utc() < moment(defaultValues.temporaryAlarmHandlingEnd)
                  ? t("Objects:edit_temporary_alarm_handling")
                  : t("Objects:set_temporary_alarm_handling")
              }
              onClick={() => setAlarmHandlingModalOpened(true)}
              disabled={!isCoreDetailsEditable || objectLoading}
            />
          )}
      </Row>

      <Spacer size={16} />

      {config?.show.includes("ShareAlarm") && (
        <Row type="fill">
          <Controller
            name="shareAlarmWith"
            control={control}
            render={({ field }) => {
              const length = field.value?.length ?? 0;
              const values = length > 0 ? field.value?.split(",") : [];

              return (
                <MultiStringInput
                  maxValues={6}
                  label={t("Common:labels_share_alarm_with")}
                  description={t("Common:share_alarm_description")}
                  values={values}
                  setValues={(updated) => {
                    const value = updated.join(",");
                    setValue(field.name, (value || "").toUpperCase(), {
                      shouldDirty: true,
                    });
                  }}
                />
              );
            }}
          />
        </Row>
      )}

      <Spacer size={16} />

      <RoleFeature requires={[Role.BetaAccess]}>
        <TwoColGrid>
          <Controller
            name="culture"
            control={control}
            render={({ field }) => {
              const items = languageOptions.map((l) => ({
                id: l.id,
                name: t(`Common:language_${l.key}`),
                flag: l.flag,
                isSelected: field.value === l.id,
              }));

              const selectedItem = items.find((i) => i.isSelected);

              return (
                <Dropdown
                  items={items}
                  selectedItem={selectedItem}
                  onSelectItem={(item) =>
                    setValue("culture", item.id as number)
                  }
                  title={t("Common:labels_language")}
                  placeholder={t("Common:labels_select_language")}
                  disabled={!isCoreDetailsEditable}
                />
              );
            }}
          />
        </TwoColGrid>
      </RoleFeature>
      {isDesktop && (
        <>
          <Spacer />
          <Divider />
          <Spacer />
        </>
      )}
      <TemporaryAlarmHandlingModal
        isOpen={alarmHandlingModalOpened}
        setIsOpen={setAlarmHandlingModalOpened}
        alarmHandlings={alarmHandlings}
        objectData={defaultValues}
        objectId={objectId}
      />
    </div>
  );
};

export default ObjectGeneralBasic;
