import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import he from "he";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import Button, {
  ButtonVariant,
} from "../../../ui-lib/components/Button/Button";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import { IOrganizationDetails } from "../../../core/api/organizations/types";
import {
  createOrganization,
  getOrganizationTrees,
} from "../../../core/api/organizations/organizations";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import Divider from "../../../ui-lib/components/Divider/Divider";
import styles from "../OrganizationGeneral/OrganizationsGeneral.module.css";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";
import {
  validateEmailIfExists,
  validateEmergencyPhoneNumberIfExists,
  validatePhoneNumberIfExists,
} from "../../../core/helpers/validation";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import { transformOrganizationsToTreeData } from "../../../core/utils/organizations";
import usePageState from "../../../core/pagestate/usePageState";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import CollapsibleBlock from "../../../ui-lib/components/CollapsibleBlock/CollapsibleBlock";

export const AddOrganization = ({
  onSubmit,
  onClose,
  variant,
  sub = false,
  parentId,
  sellers,
}: {
  onSubmit?: (organizationId: number) => void;
  onClose?: () => void;
  variant: ButtonVariant;
  sub?: boolean;
  parentId?: number;
  sellers?: IItemProp[];
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const pageState = usePageState();
  const user = useUser();
  const [isAddOrganizationOpen, setIsAddOrganizationOpen] =
    useState<boolean>(false);
  const [organizationsData, setOrganizationsData] = useState<ITreeData[]>([]);
  const [hasParent, setHasParent] = useState<number>(0);

  const superAdmin = config?.show.includes("SkygdSuperAdmin");
  const expandedAnchorRef = useRef<any>();

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
  } = useForm<IOrganizationDetails>();

  const radioItems = [
    { id: 0, value: "0", text: t("Organizations:first_level_organisation") },
    {
      id: 1,
      value: "1",
      text: t("Organizations:with_parent_level"),
      description: t("Organizations:below_parent_organisation"),
    },
  ];

  const closeModal = () => {
    reset();
    setIsAddOrganizationOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  const onCreateOrganizationSubmit = async (formData: IOrganizationDetails) => {
    try {
      const responseData = await createOrganization(
        formData,
        authenticatedRequest
      );
      onSubmit!(responseData.data);
      pageState.setInitialIds({
        name: organizationsData[0].data,
        id: Number(organizationsData[0].key),
      });
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    const params = { page: 1, pageSize: 2000 };
    getOrganizationTrees(
      sub && parentId
        ? { ...params, includeParent: true, parentId: +parentId }
        : params,
      authenticatedRequest
    ).then((data) => {
      const transformedData = transformOrganizationsToTreeData(data.data || []);
      setOrganizationsData(transformedData);

      if (sub && parentId && transformedData.length) {
        setValue("parentOrganizationId", +parentId);
        trigger("parentOrganizationId");
      }
    });
  }, [isAddOrganizationOpen]);

  return (
    <>
      <Button
        variant={variant}
        image={sub ? "office-building" : undefined}
        text={t(
          sub
            ? "Organizations:add_sub_organization"
            : "Organizations:add_organization"
        )}
        onClick={() => {
          setIsAddOrganizationOpen(true);
        }}
      />
      <StyledModal
        onClose={closeModal}
        nested
        isOpen={isAddOrganizationOpen}
        cancelBtnText={t("Common:cancel")}
        approveBtnText={t("Organizations:create_organization")}
        modalTitle={
          sub
            ? t("Organizations:create_new_sub_organization")
            : t("Organizations:create_new_organization")
        }
        onApproveBtnClick={() => handleSubmit(onCreateOrganizationSubmit)()}
        onCancelBtnClick={closeModal}
        closeOnDocumentClick={false}
        isLoading={isSubmitting}
      >
        <p>{t("Organizations:create_organization_description")}</p>
        <Spacer size={16} />
        <form>
          <Column type="top">
            {user.hasAccess("Sellers") && !sub && (
              <>
                <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
                  <RadioGroup
                    name="creation-type"
                    items={radioItems}
                    selectedValue={
                      radioItems.find((item) => item.id === hasParent)?.value
                    }
                    onChange={(value) => {
                      setHasParent(Number(value));
                    }}
                    fullWidth
                  />
                </Row>
                <Spacer size={16} />
              </>
            )}
            {(!user.hasAccess("Sellers") || hasParent === 1 || sub) && (
              <>
                <Controller
                  name="parentOrganizationId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Tree
                      required
                      treeRef={field.ref}
                      label={t("Organizations:parent_organization")}
                      placeholder={t(
                        "Organizations:select_parent_organization"
                      )}
                      selectedTreeItem={sub && parentId ? +parentId : undefined}
                      items={organizationsData}
                      disabled={!organizationsData.length}
                      onSelectItem={(selected?: ITreeData) => {
                        setValue(field.name, selected?.key as number);
                        trigger("parentOrganizationId");
                      }}
                      validationError={
                        errors?.parentOrganizationId &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            )}
            <Row
              type="fill"
              align="fill"
              style={{ alignSelf: "stretch" }}
              className={styles.rowWithValidation}
            >
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 60,
                }}
                render={({ field }) => (
                  <TextInput
                    required
                    inputRef={field.ref}
                    label={t("Common:name_label")}
                    placeholder={t("Organizations:org_col_name")}
                    value={field.value}
                    onChange={(e) => {
                      setValue("name", e.target.value);
                      trigger(field.name);
                    }}
                    validationError={
                      errors?.name && t("Errors:input_organization_name")
                    }
                  />
                )}
              />
              <Spacer size={32} />
              <Controller
                name="organizationNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    placeholder={t("Common:digit_number")}
                    label={t("Organizations:organization_number")}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
          </Column>

          <Spacer size={16} />

          <Row align="fill" type="fill">
            <Controller
              name="customerNumber"
              control={control}
              rules={{
                validate: (value) => {
                  if (value && value.length <= 5) {
                    return validateEmergencyPhoneNumberIfExists(value);
                  }
                  return validatePhoneNumberIfExists(value);
                },
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  width={!superAdmin && sub ? "48%" : undefined}
                  placeholder="+46123456789"
                  label={t("Common:labels_phone_number")}
                  description={
                    !errors.customerNumber
                      ? t("Common:telephone_placeholder")
                      : undefined
                  }
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.customerNumber &&
                    t("Errors:ValidationHint_TelephoneNumber")
                  }
                />
              )}
            />
            {superAdmin && (
              <>
                <Spacer size={32} />
                <Controller
                  name="sellerId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      title={t("Common:belongs_to")}
                      placeholder={t("Administrator:seller_placeholder")}
                      items={sellers || []}
                      disabled={!sellers}
                      selectedItem={sellers?.find(
                        (seller) => seller.id === field.value
                      )}
                      onSelectItem={(value?: IItemProp) => {
                        if (!value?.id) return;
                        setValue(field.name, value?.id as number);
                        trigger(field.name);
                      }}
                      validationError={
                        errors?.sellerId && t("Errors:input_field_required")
                      }
                    />
                  )}
                />
              </>
            )}
          </Row>

          <Spacer size={16} />

          <Row align="start" className={styles.rowWithValidation}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Common:labels_address")}
                  description={t("Organizations:address_example")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <TextInput
                  placeholder="000 00"
                  label={t("Common:labels_postal_code")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row className={styles.rowWithValidation}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextInput
                  placeholder={t("Common:enter_city")}
                  label={t("Common:labels_city")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextInput
                  placeholder={t("Common:enter_country")}
                  label={t("Common:labels_country")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Row>

          <Spacer size={32} />
          <Row>
            <Controller
              name="telefax"
              control={control}
              rules={{
                validate: (input: string | undefined) =>
                  !input || input.length < 2048,
              }}
              render={({ field }) => (
                <TextArea
                  inputRef={field.ref}
                  label={t("Organizations:organization_info")}
                  placeholder={t("Organizations:organization_info_placeholder")}
                  value={he.decode(field.value ?? "")}
                  onChange={field.onChange}
                  validationError={
                    errors?.telefax &&
                    t("Errors:ValidationHint_OrganizationInfo")
                  }
                />
              )}
            />
          </Row>

          <Spacer size={32} />

          <Divider />

          <Spacer size={32} />

          <h2>{t("Organizations:contact_person_detail")}</h2>

          <Spacer size={16} />

          <Row className={styles.rowWithValidation}>
            <Controller
              name="contactPerson"
              control={control}
              render={({ field }) => (
                <TextInput
                  placeholder={t("Common:contact_person_name")}
                  label={t("Common:name_label")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="emailAddress"
              control={control}
              rules={{
                maxLength: 60,
                validate: validateEmailIfExists,
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  placeholder={t("Common:placeholder_email")}
                  label={t("Common:email")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.emailAddress && t("Errors:input_email_format")
                  }
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row style={{ width: "48%" }} className={styles.rowWithValidation}>
            <Controller
              name="contactPersonAccessCode"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Organizations:contact_person_access_code")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row className={styles.rowWithValidation}>
            <Controller
              name="telephoneExchange"
              control={control}
              rules={{
                validate: (value) =>
                  validateEmergencyPhoneNumberIfExists(value) ||
                  validatePhoneNumberIfExists(value),
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  placeholder="+46123456789"
                  label={t("Organizations:telephone_exchange_number")}
                  description={
                    !errors.telephoneExchange
                      ? t("Common:telephone_placeholder")
                      : undefined
                  }
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.telephoneExchange &&
                    t("Errors:ValidationHint_TelephoneNumber")
                  }
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="telephoneDirect"
              control={control}
              rules={{
                validate: (value) =>
                  validateEmergencyPhoneNumberIfExists(value) ||
                  validatePhoneNumberIfExists(value),
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  placeholder="+46123456789"
                  description={
                    !errors.telephoneDirect
                      ? t("Common:telephone_placeholder")
                      : undefined
                  }
                  label={t("Organizations:direct_telephone_number")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.telephoneDirect &&
                    t("Errors:ValidationHint_TelephoneNumber")
                  }
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row style={{ width: "48%" }} className={styles.rowWithValidation}>
            <Controller
              name="telephoneMobile"
              control={control}
              rules={{
                validate: (value) =>
                  validateEmergencyPhoneNumberIfExists(value) ||
                  validatePhoneNumberIfExists(value),
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  placeholder="+46123456789"
                  description={
                    !errors.telephoneMobile
                      ? t("Common:telephone_placeholder")
                      : undefined
                  }
                  label={t("Organizations:mobile_telephone_number")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors?.telephoneMobile &&
                    t("Errors:ValidationHint_TelephoneNumber")
                  }
                />
              )}
            />
          </Row>

          <Spacer size={32} />

          <Divider />

          <Spacer size={32} />

          <CollapsibleBlock withScroll title={t("Common:advanced_options")}>
            <>
              <Spacer size={16} />
              <Row className={styles.rowWithValidation}>
                <div className={styles.organizationsCheckbox}>
                  <Controller
                    name="blockUserPages"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("Organizations:block_user_pages")}
                        checked={!!field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                      />
                    )}
                  />
                </div>
                <Spacer size={32} />
                <div
                  ref={expandedAnchorRef}
                  className={styles.organizationsCheckbox}
                >
                  <Controller
                    name="requireCostCenter"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label={t("Organizations:require_cost_center")}
                        checked={!!field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                      />
                    )}
                  />
                </div>
              </Row>
            </>
          </CollapsibleBlock>
        </form>
      </StyledModal>
    </>
  );
};
