import { ResponseCenterParams } from "../../core/api/responsecenters/types";
import useTranslations from "../../core/i18n/useTranslations";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";

type TypeSettings = Pick<ResponseCenterParams, "privateOnly" | "publicOnly">;

interface TypePickerProps {
  settings: TypeSettings;
  setSettings: (settings: TypeSettings) => void;
}

const TypePicker = ({ settings, setSettings }: TypePickerProps) => {
  const t = useTranslations();
  const placeholder = t("AlarmReceptions:type_placeholder");

  const items = [
    {
      id: "all",
      name: `--${placeholder}--`,
      isSelected:
        settings.privateOnly === "false" && settings.publicOnly === "false",
      onSelectItem: () =>
        setSettings({ privateOnly: "false", publicOnly: "false" }),
    },
    {
      id: "private",
      name: t("AlarmReceptions:type_private"),
      isSelected: settings.privateOnly === "true" || false,
      onSelectItem: () =>
        setSettings({ privateOnly: "true", publicOnly: "false" }),
    },
    {
      id: "public",
      name: t("AlarmReceptions:type_public"),
      isSelected: settings.publicOnly === "true" || false,
      onSelectItem: () =>
        setSettings({ privateOnly: "false", publicOnly: "true" }),
    },
  ];

  const selectedItem = items.find((i) => i.id !== "all" && i.isSelected);

  return (
    <Dropdown
      placeholder={placeholder}
      width={156}
      selectedItem={selectedItem}
      items={items}
      onSelectItem={(i) => {
        const current = items.find((item) => item.id === i.id);
        current?.onSelectItem();
      }}
    />
  );
};

export default TypePicker;
