import { Controller, useFormContext } from "react-hook-form";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { IOrgIntegration } from "../../../../core/api/organizations/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import MultiStringInput from "../../../../ui-lib/components/MultiInput/MultiStringInput";

export const CustomSettingsOptionalFields = ({
  settingType,
  settingIndex,
}: {
  settingType: string;
  settingIndex: number;
}) => {
  const t = useTranslations();

  const {
    formState: { errors },
    control,
    trigger,
  } = useFormContext<IOrgIntegration>();

  const renderFields = () => {
    switch (settingType) {
      case "Dynamic":
        return null;
      case "String":
        return (
          <Controller
            control={control}
            rules={{ required: true }}
            name={`expectedUserSettings.${settingIndex}.regex`}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                label={t("Common:regex_value_label")}
                placeholder={t("Organizations:regex_value_placeholder")}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  trigger(field.name);
                }}
                validationError={
                  errors.expectedUserSettings?.[settingIndex]?.regex &&
                  t("Errors:input_field_required")
                }
              />
            )}
          />
        );
      case "Enum":
        return (
          <Controller
            control={control}
            rules={{ required: true }}
            name={`expectedUserSettings.${settingIndex}.selectableEnums`}
            render={({ field }) => (
              <MultiStringInput
                required
                label={t("Common:enum_values_label")}
                placeholder={t("Organizations:enum_values_placeholder")}
                values={field.value}
                setValues={(val) => {
                  field.onChange(val);
                  trigger(field.name);
                }}
                validationError={
                  errors.expectedUserSettings?.[settingIndex]
                    ?.selectableEnums && t("Errors:input_field_required")
                }
              />
            )}
          />
        );
      case "Number":
      default:
        return (
          <Row align="start">
            <Controller
              control={control}
              rules={{ required: true }}
              name={`expectedUserSettings.${settingIndex}.minimum`}
              render={({ field }) => (
                <NumberInput
                  required
                  inputRef={field.ref}
                  label={t("Common:min_val")}
                  placeholder={t("Organizations:min_val_placeholder")}
                  value={field.value?.toString()}
                  onChange={(val) => {
                    field.onChange(val);
                    trigger(field.name);
                  }}
                  validationError={
                    errors.expectedUserSettings?.[settingIndex]?.minimum &&
                    t("Errors:input_field_required")
                  }
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              control={control}
              rules={{ required: true }}
              name={`expectedUserSettings.${settingIndex}.maximum`}
              render={({ field }) => (
                <NumberInput
                  required
                  inputRef={field.ref}
                  label={t("Common:max_val")}
                  placeholder={t("Organizations:max_val_placeholder")}
                  value={field.value?.toString()}
                  onChange={(val) => {
                    field.onChange(val);
                    trigger(field.name);
                  }}
                  validationError={
                    errors.expectedUserSettings?.[settingIndex]?.maximum &&
                    t("Errors:input_field_required")
                  }
                />
              )}
            />
          </Row>
        );
    }
  };
  return renderFields();
};
