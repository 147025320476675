import { Controller } from "react-hook-form";
import { RespondentGroup } from "../../core/api/responsecenters/types";
import useTranslations from "../../core/i18n/useTranslations";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItemsWithIcons } from "../../ui-lib/components/Dropdown/DropdownItem";
import Table from "../../ui-lib/components/Tables/Table";

const RespondentGroupList = ({
  respondentGroups,
  control,
  setValue,
  isLoading,
  disabled = false,
  watch,
}: {
  respondentGroups: RespondentGroup[];
  control: any;
  setValue: any;
  isLoading: boolean;
  disabled?: boolean;
  watch: any;
}) => {
  const t = useTranslations();

  const generateDropdown = (
    respondentGroup: RespondentGroup,
    id: number,
    _array: RespondentGroup[]
  ) => ({
    name: respondentGroup.description,
    dropdown: (
      <Controller
        name={`respondentGroups.${id}`}
        control={control}
        render={({ field }) => {
          if (!field.value) {
            setValue(field.name, respondentGroup);
          }

          const forced = watch(`${field.name}.forced`);
          const allowed = watch(`${field.name}.allowed`);

          const dropdownItems: IDropdownItemsWithIcons[] = [
            {
              id: 0,
              name: t("Common:forced"),
              isSelected: forced?.valueOf()!,
              icon: "check",
              color: "Primary-700",
              onlySelected: true,
            },
            {
              id: 1,
              name: t("Common:optional"),
              isSelected: !forced?.valueOf() && allowed?.valueOf()!,
              icon: "minus",
              color: "Grey-500",
              onlySelected: true,
            },
            {
              id: 2,
              name: t("Common:not_allowed"),
              isSelected: !forced?.valueOf() && !allowed?.valueOf(),
              icon: "x",
              color: "Error-500",
              onlySelected: true,
            },
          ];
          return (
            <Dropdown
              disabled={disabled}
              selectedItem={dropdownItems.find((i) => i.isSelected)}
              items={dropdownItems}
              onSelectItem={(item) => {
                if (item.id === 0) {
                  setValue(`${field.name}.forced`, true, {
                    shouldDirty: true,
                  });
                  setValue(`${field.name}.allowed`, true, {
                    shouldDirty: true,
                  });
                } else if (item.id === 1) {
                  setValue(`${field.name}.forced`, false, {
                    shouldDirty: true,
                  });
                  setValue(`${field.name}.allowed`, true, {
                    shouldDirty: true,
                  });
                } else if (item.id === 2) {
                  setValue(`${field.name}.forced`, false, {
                    shouldDirty: true,
                  });
                  setValue(`${field.name}.allowed`, false, {
                    shouldDirty: true,
                  });
                }
              }}
            />
          );
        }}
      />
    ),
  });

  const respondentGroupDropdowns = respondentGroups
    ? respondentGroups?.map(generateDropdown)
    : [];

  return (
    <Table<{ name: string; dropdown: any }>
      resizableColumns={false}
      withShowMore
      showRowHover
      hideEmptyMessage
      isLoading={isLoading}
      paginatedItems={{
        items: respondentGroupDropdowns,
        pagination: {
          offset: 0,
          limit: respondentGroupDropdowns.length,
          total: respondentGroupDropdowns.length,
        },
      }}
      columns={[
        {
          header: t("AlarmReceptions:respondent_group"),
          field: (rowData) => rowData.name,
        },
        {
          header: t("Common:access"),
          field: (rowData) => rowData.dropdown,
        },
      ]}
      items={respondentGroupDropdowns}
    />
  );
};

export default RespondentGroupList;
