import { useHistory } from "react-router-dom";
import useTranslations from "../../../core/i18n/useTranslations";
import { useSaveObject } from "../../../core/SaveObjectContext/hooks";
import Button from "../../../ui-lib/components/Button/Button";
import PopupContextMenu from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import Modal from "../../../ui-lib/components/Popup/Modal";
import { DeleteAlarmReceptionModal } from "../DeleteAlarmReceptionModal";
import useUser from "../../../core/user/useUser";

const AlarmReceptionActions = ({
  responseCenterId,
  parentPath,
}: {
  responseCenterId?: number;
  parentPath: string;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const { config } = useUser();

  return (
    <>
      {canSave && (
        <Button
          text={t("Common:save")}
          disabled={!isDirty}
          loading={isSaving}
          onClick={onSave}
        />
      )}
      {config?.show.includes("RcDelete") && (
        <PopupContextMenu
          className="autoWidth"
          key="alarm_reception_actions"
          position="right bottom"
          nested
          trigger={() => (
            <div>
              <Button
                className="ml-8"
                type="button"
                variant="secondary"
                image="dots-horizontal"
                text={t("Common:other")}
              />
            </div>
          )}
        >
          <Modal
            trigger={() => (
              <PopupContextMenuLinkButton
                icon="trash"
                text={t("AlarmReceptions:delete")}
              />
            )}
          >
            {(close) => (
              <DeleteAlarmReceptionModal
                responseCenterId={responseCenterId}
                onClose={close}
                onDelete={() => history.replace(parentPath)}
              />
            )}
          </Modal>
        </PopupContextMenu>
      )}
    </>
  );
};

export default AlarmReceptionActions;
