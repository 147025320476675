/* eslint-disable react/jsx-props-no-spreading */
import { Controller, FormProvider } from "react-hook-form";
import _ from "lodash";
import useTranslations from "../../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import useSharedAlarmReception from "../SharedAlarmReceptionContext/useSharedAlarmReception";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import {
  Column,
  Spacer,
  TwoColGrid,
} from "../../../../components/Layout/Layout";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";

export const CallResourcesSettings = () => {
  const t = useTranslations();
  const formRef = useObjectFormRef();
  const { data = {}, update } = useSharedAlarmReception();
  const defaultValues = { ...data };
  const methods = useObjectForm<ResponseCenterDetails>({
    defaultValues,
    shouldUnregister: false,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        style={{ width: "100%" }}
        onSubmit={handleSubmit(async (values) => {
          const parsed = _.omit(values, "CustomerId");
          try {
            await update(parsed);

            notify({
              message: t("AlarmReceptions:edited_success"),
            });
            reset(values);
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          }
        })}
      >
        <Column type="top" align="fill" style={{ maxWidth: "784px" }}>
          <h1>{t("AlarmReceptions:contacts_call_contact_settings_title")}</h1>
          <Spacer size={8} />
          <p style={{ color: "var(--Grey-600)" }}>
            {t("AlarmReceptions:contacts_call_contact_settings_description")}
          </p>
          <Spacer size={32} />
          <TwoColGrid style={{ alignItems: "start" }}>
            <Controller
              name="callResourceTimeout"
              control={control}
              rules={{ max: 899 }}
              render={({ field }) => (
                <NumberInput
                  label={t(
                    "AlarmReceptions:contacts_callresourcetimeout_label"
                  )}
                  placeholder="1...899"
                  pinnedText="sec"
                  pinnedTextPosition="right"
                  description={t(
                    "AlarmReceptions:contacts_callresourcetimeout_description"
                  )}
                  value={field.value ? field.value.toString() : "0"}
                  onChange={field.onChange}
                  validationError={
                    errors.callResourceTimeout &&
                    t("Errors:less_or_equal_digits").replace("{0}", "899")
                  }
                />
              )}
            />
            <Controller
              name="callResourceDeliveryTimeout"
              control={control}
              rules={{ max: 899 }}
              render={({ field }) => (
                <NumberInput
                  label={t(
                    "AlarmReceptions:contacts_callresourcedeliverytimeout_label"
                  )}
                  placeholder="1...3600"
                  pinnedText="sec"
                  pinnedTextPosition="right"
                  description={t(
                    "AlarmReceptions:contacts_callresourcedeliverytimeout_description"
                  )}
                  value={field.value ? field.value.toString() : "0"}
                  onChange={field.onChange}
                  validationError={
                    errors.callResourceDeliveryTimeout &&
                    t("Errors:less_or_equal_digits").replace("{0}", "899")
                  }
                />
              )}
            />
            <Controller
              name="callResourceTimeAlternatives"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t(
                    "AlarmReceptions:contacts_callresourcetimealternatives_label"
                  )}
                  placeholder={t(
                    "AlarmReceptions:contacts_callresourcetimealternatives_placeholder"
                  )}
                  description={t(
                    "AlarmReceptions:contacts_callresourcetimealternatives_description"
                  )}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              )}
            />
          </TwoColGrid>
          <Spacer size={36} />
          <Column align="start">
            <Controller
              name="callUnknownUsers"
              control={control}
              render={({ field }) => (
                <Checkbox
                  style={{ padding: 0 }}
                  id="edit_ipfilter_admin"
                  label={t("AlarmReceptions:contacts_callunknownobjects_label")}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Spacer size={16} />
            <Controller
              name="callResourceDeactivateAlarmWhenAllDone"
              control={control}
              render={({ field }) => (
                <Checkbox
                  style={{ padding: 0 }}
                  id="edit_ipfilter_admin"
                  label={t(
                    "AlarmReceptions:contacts_callresourcedeacivatewhendone_label"
                  )}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Spacer size={16} />
            <Controller
              name="callResourceNotifyWhenDone"
              control={control}
              render={({ field }) => (
                <Checkbox
                  style={{ padding: 0 }}
                  id="edit_ipfilter_admin"
                  label={t(
                    "AlarmReceptions:contacts_callresourcenotifywhendone_label"
                  )}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Column>
        </Column>
      </form>
    </FormProvider>
  );
};
