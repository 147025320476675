import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { Coords } from "../../../core/interfaces/Coords";
import {
  getDropdownCoords,
  getDropdownWidth,
} from "../../../core/helpers/dropdownHelper";

import styles from "./Dropdown.module.css";
import TextInput from "../Inputs/TextInput";
import useTranslations from "../../../core/i18n/useTranslations";
import Icon from "../Icon";

interface DropdownProps {
  children: ReactNode;
  elem: HTMLButtonElement | null;
  onClose: () => void;
  dropDownHeight: number;
  width?: number | string;
  withFilter?: boolean;
  style?: React.CSSProperties;
  withBtns?: boolean;
  onFilter?: (filter: string) => void;
  innerRef?: RefObject<HTMLDivElement> | null;
  dropdownInPopup?: boolean;
  searchPlaceholder?: string;
}

interface OutsideHookProps {
  children: ReactNode;
  parentElem: HTMLButtonElement | null;
  onEventOutside: () => void;
}

function useOutsideHook(ref: any, onClickOutside: () => void, parentElem: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !parentElem.contains(event.target)
      ) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function useScrollHook(ref: any, onScrollOutside: () => void, parentElem: any) {
  useEffect(() => {
    function handleScrollOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !parentElem.contains(event.target)
      ) {
        onScrollOutside();
      }
    }

    document.addEventListener("scroll", handleScrollOutside, true);
    return () => {
      document.removeEventListener("scroll", handleScrollOutside, true);
    };
  }, [ref]);
}

function EventOutside({
  children,
  onEventOutside,
  parentElem,
}: Readonly<OutsideHookProps>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideHook(wrapperRef, onEventOutside, parentElem);
  useScrollHook(wrapperRef, onEventOutside, parentElem);

  return <div ref={wrapperRef}>{children}</div>;
}

export default function DropdownBody({
  children,
  elem,
  onClose,
  dropDownHeight = 0,
  width,
  style,
  withBtns = false,
  withFilter = false,
  onFilter,
  innerRef,
  dropdownInPopup,
  searchPlaceholder,
}: Readonly<DropdownProps>) {
  const t = useTranslations();
  const coords: Coords = getDropdownCoords(elem, dropDownHeight);
  const bodyWidth: number | string = width ?? getDropdownWidth(elem);
  const [filterVal, setFilterVal] = useState<string>();
  const heightCalc = withBtns ? 40 : 56;
  useEffect(() => {
    onFilter?.(filterVal ?? "");
  }, [filterVal]);
  return (
    <EventOutside onEventOutside={() => onClose()} parentElem={elem}>
      <div
        className={classnames({
          [styles.dropdownContainer]: true,
          [styles.dropdownInPopup]: dropdownInPopup,
        })}
        style={{
          top: coords.top,
          left: coords.left,
          width: bodyWidth,
          height: dropDownHeight,
          ...style,
        }}
      >
        {withFilter && (
          <div className={styles.filterContainer}>
            <TextInput
              autoFocus
              hideLabel
              label={t("Common:filter_dropdown")}
              placeholder={searchPlaceholder}
              type="search"
              value={filterVal}
              autoComplete="off"
              onChange={(e) => {
                setFilterVal(e.target.value);
              }}
              renderIcon={() => <Icon name="search" color="Grey-300" />}
              css={{ backgroundColor: "var(--Grey-50)" }}
            />
          </div>
        )}
        <div
          style={{
            maxHeight: `${
              withBtns || withFilter
                ? dropDownHeight - heightCalc
                : dropDownHeight
            }px`,
          }}
          ref={innerRef}
          className={withBtns ? styles.innerWithButtons : styles.inner}
          data-cy="dropDownBody"
        >
          {children}
        </div>
      </div>
    </EventOutside>
  );
}
