import { locale, weekdaysShort } from "moment";
// languages need for correct translation from moment
import "moment/locale/sv";
import "moment/locale/fi";
import "moment/locale/de";
import "moment/locale/nb";
import "moment/locale/fr";
import "moment/locale/da";
import "moment/locale/pt";
import "moment/locale/it";
import "moment/locale/es";

export const userDateFormat = (date: string) => date.split("/").join("-");

export const normalizeDate = (dateStr: string): string => {
  const [year, month, day] = dateStr.split("-").map(Number);
  const normalizedMonth = month.toString().padStart(2, "0");
  const normalizedDay = day.toString().padStart(2, "0");
  return `${year}-${normalizedMonth}-${normalizedDay}`;
};

export const getWeekDays = (timeZone: string) => {
  locale(timeZone);
  const weekdays = weekdaysShort();

  return [...weekdays.slice(1), weekdays[0]];
};
