/* eslint-disable react/jsx-props-no-spreading */
import { toNumber } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../../components/Layout/Layout";
import { ResponseCenterAlarmCodeDetails } from "../../../../../core/api/responsecenters/types";
import useTranslations from "../../../../../core/i18n/useTranslations";
import Button from "../../../../../ui-lib/components/Button/Button";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";
import NumberInput from "../../../../../ui-lib/components/Inputs/NumberInput";
import TextInput from "../../../../../ui-lib/components/Inputs/TextInput";
import ModalContent from "../../../../../ui-lib/components/Popup/ModalContent";
import { TimeoutControl } from "../../AlarmHandling/Controls/TimeoutControl";
import { AdvancedSettings } from "./AdvancedSettings";
import { CreateNewSettings } from "./CreateNewSettings";

const alarmTypeOptions = [5, 7, 8, 9];
const notificationLevelOptions = [0, 1, 2];

export interface AlarmCodeFormProps {
  labels: {
    title: string;
    description: string;
    submit: string;
  };
  defaultValues?: ResponseCenterAlarmCodeDetails;
  onSubmit: (values: ResponseCenterAlarmCodeDetails) => void;
  onClose: () => void;
}

export const AlarmCodeForm = ({
  labels,
  defaultValues,
  onSubmit,
  onClose,
}: AlarmCodeFormProps) => {
  const t = useTranslations();

  const methods = useForm<ResponseCenterAlarmCodeDetails>({
    defaultValues: defaultValues ?? {
      alarmType: 7,
      useNotificationLevel: 1,
      createNewIfHigherPriorityAcceptedAlarm: true,
      createNewIfHigherPriorityAlarm: true,
      createNewIfHigherPriorityPresenceAlarm: true,
      createNewIfLowerPriorityAcceptedAlarm: true,
      createNewIfLowerPriorityAlarm: true,
      createNewIfLowerPriorityPresenceAlarm: true,
      createNewIfSamePriorityAcceptedAlarm: true,
      createNewIfSamePriorityAlarm: true,
      createNewIfSamePriorityPresenceAlarm: true,
      deactivateAlarmsOfCodes: "",
      forceAlarmText: "",
      useSound: "",
    },
  });

  const {
    control,
    setValue,
    trigger,
    getValues,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = methods;

  return (
    <ModalContent
      onClose={onClose}
      width="848px"
      fixedHeader
      headerContent={
        <>
          <h2 className="subTitle">{labels.title}</h2>
        </>
      }
      footerContent={
        <>
          <Spacer size={32} />

          <Row type="left">
            <Button
              type="submit"
              variant="primary"
              text={labels.submit}
              loading={isSubmitting}
              disabled={isSubmitting}
              form="add-alarm-code"
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={onClose}
            />
          </Row>
        </>
      }
    >
      <Spacer size={16} />
      <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>

      <Spacer size={32} />

      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            // To stop main form to submit
            e.preventDefault();
            e.stopPropagation();

            handleSubmit(onSubmit)(e);
          }}
          id="add-alarm-code"
        >
          <Row align="start">
            <Column>
              <Controller
                name="alarmCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <NumberInput
                    required
                    inputRef={field.ref}
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_codes_input_alarm_code"
                    )}
                    placeholder={t("Common:enter_integer_number")}
                    value={`${field.value}`}
                    onChange={(e) => {
                      const stringValue = e.currentTarget.value;
                      const updatedValue = Math.trunc(toNumber(stringValue));

                      setValue(field.name, updatedValue);
                      trigger(field.name);
                    }}
                    validationError={
                      errors.alarmCode && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column>
              <Controller
                name="alarmType"
                control={control}
                render={({ field }) => {
                  const dropdownItems = alarmTypeOptions.map((id) => ({
                    id,
                    name: t(
                      `AlarmReceptions:alarm_handling_alarm_codes_input_type_${id}`
                    ),
                    isSelected: id === field.value,
                  }));

                  return (
                    <Dropdown
                      title={t(
                        "AlarmReceptions:alarm_handling_alarm_codes_input_type"
                      )}
                      width="100%"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) =>
                        setValue(field.name, item.id as number)
                      }
                    />
                  );
                }}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Controller
              name="forceAlarmText"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t(
                    "AlarmReceptions:alarm_handling_alarm_codes_input_text_forced"
                  )}
                  placeholder={t(
                    "AlarmReceptions:alarm_handling_alarm_codes_input_text_forced_placeholder"
                  )}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column>
              <Controller
                name="usePriority"
                control={control}
                rules={{
                  min: 1,
                  max: 1100,
                  required: true,
                }}
                render={({ field }) => (
                  <NumberInput
                    required
                    inputRef={field.ref}
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_codes_input_priority"
                    )}
                    placeholder={t(
                      "AlarmReceptions:alarm_handling_alarm_code_prio_placeholder"
                    )}
                    value={`${field.value}`}
                    onChange={(e) => {
                      const stringValue = e.currentTarget.value;
                      const updatedValue = toNumber(stringValue);

                      setValue(field.name, updatedValue);
                      trigger(field.name);
                    }}
                    validationError={
                      errors.usePriority &&
                      `${t("Errors:input_format")}: 1, 1100`
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column>
              <Controller
                name="useNotificationLevel"
                control={control}
                render={({ field }) => {
                  const dropdownItems = notificationLevelOptions.map((id) => ({
                    id,
                    name: t(
                      `AlarmReceptions:alarm_handling_alarm_codes_input_notification_level_${id}`
                    ),
                    isSelected: id === field.value,
                  }));

                  return (
                    <Dropdown
                      title={t(
                        "AlarmReceptions:alarm_handling_alarm_codes_input_notification_level"
                      )}
                      width="100%"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) =>
                        setValue(field.name, item.id as number)
                      }
                    />
                  );
                }}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column>
              <Controller
                name="useSound"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_codes_input_use_sound"
                    )}
                    placeholder={t(
                      "AlarmReceptions:alarm_handling_alarm_codes_input_use_sound_placeholder"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }} />
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column>
              <Controller
                name="reminderTimeout"
                control={control}
                rules={{
                  validate: () =>
                    Number(getValues("reminderTimeout")) > 4 ||
                    !Number(getValues("reminderTimeout")),
                }}
                render={({ field }) => (
                  <TimeoutControl
                    min={5}
                    inputRef={field.ref}
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_codes_input_reminder_timeout"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                    hasError={!!errors.reminderTimeout}
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column>
              <Controller
                name="rejectAlarmTimeout"
                control={control}
                rules={{
                  validate: () =>
                    Number(getValues("rejectAlarmTimeout")) > 4 ||
                    !Number(getValues("rejectAlarmTimeout")),
                }}
                render={({ field }) => (
                  <TimeoutControl
                    min={5}
                    inputRef={field.ref}
                    label={t(
                      "AlarmReceptions:alarm_handling_alarm_codes_input_reject_timeout"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                    hasError={!!errors.rejectAlarmTimeout}
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={32} />

          <CreateNewSettings />

          <Spacer size={32} />

          <AdvancedSettings />
        </form>
      </FormProvider>
    </ModalContent>
  );
};
