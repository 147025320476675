import {
  breakpoints,
  useWindowDimensions,
} from "../core/hooks/dimensionProvider";

const ResponsiveLayout = ({
  breakPoint = breakpoints.tablet,
  renderMobile,
  renderDesktop,
}: {
  breakPoint: number;
  renderMobile: Function;
  renderDesktop: Function;
}) => {
  const { width } = useWindowDimensions();
  return width >= breakPoint ? renderDesktop() : renderMobile();
};

export default ResponsiveLayout;
