import { useState } from "react";
import { deleteProduct } from "../../../core/api/products/products";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const DeleteProductModal = ({
  onClose,
  productId,
  isOpen,
}: {
  onClose: (deleted?: boolean) => void;
  productId: number | string;
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Reseller:delete_product")}
      onClose={onClose}
      isOpen={isOpen}
      submitBtn={{
        text: t("Common:delete"),
        onClick: async () => {
          setIsLoading(true);
          try {
            await deleteProduct(authenticatedRequest, productId);
            notify({
              variant: "info",
              message: t("Messages:product_deleted"),
            });
            onClose(true);
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          }
          setIsLoading(false);
        },
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: () => onClose() }}
      loading={isLoading}
      contentStyle={{ width: "960px" }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Reseller:want_to_delete_product")}
      </p>
    </PrimeModal>
  );
};
