import { useState } from "react";
import classNames from "classnames";
import { Icon } from "../Icon";

import styles from "./Accordion.module.css";

export interface AccordionBlockProps {
  title: string;
  description: string;
  image?: string;
  children: React.ReactNode;
}

export default function Accordion({
  title,
  description,
  image,
  children,
}: Readonly<AccordionBlockProps>) {
  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(!isOpen);
  }

  const bodyClassNames = classNames({
    [styles.bodyNoTransition]: true,
  });

  return (
    <div className={styles.container}>
      <button
        className={styles.header}
        onClick={(e) => {
          e.preventDefault();
          onOpen();
        }}
        aria-label="Accordion toggler"
        style={{ borderRadius: isOpen ? "4px 4px 0 0" : "4px" }}
      >
        <div className={styles.headerLeftSide}>
          {image && (
            <div className={styles.iconWrapper}>
              <Icon name={image} color="fillPrimary700" />
            </div>
          )}
          <div className={styles.mainGroupInfo}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        <div className={styles.headerRightSide}>
          <div>
            {isOpen ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
          </div>
        </div>
      </button>
      {isOpen && (
        <div className={bodyClassNames}>
          <div
            className={classNames(styles.innerBody, {
              [styles.hidden]: !isOpen,
            })}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
