import { AxiosInstance } from "axios";
import querystring from "query-string";
import { ApiResponse, ApiSearch, ApiSearchResult } from "../../interfaces/Api";
import {
  PeriodicDisable,
  EditTransmitterData,
  TransmitterModel,
  Transmitter,
  TransmitterSetting,
  ObjectStatus,
  TransmitterSettingUpdate,
  MoveTransmitterData,
  TransmitterCredentials,
  ITransmitterLogsResponse,
} from "./types";

const getTransmittersInStock = async (
  query: ApiSearch,
  authenticatedRequest: AxiosInstance
) => {
  const clearQuery = Object.fromEntries(
    Object.entries(query).filter(([_, v]) => !!v || v === 0)
  );
  const parsedQuery = querystring.stringify({
    ...clearQuery,
  });
  const result = await authenticatedRequest.get<ApiSearchResult<Transmitter>>(
    `/api/v2/transmitters?${parsedQuery}`,
    { headers: { "Content-Type": "application/json" } }
  );
  return result.data;
};

const getPositioningTransmitters = async (
  query: ApiSearch,
  authenticatedRequest: AxiosInstance
) => {
  const clearQuery = Object.fromEntries(
    Object.entries(query).filter(([_, v]) => !!v)
  );
  const parsedQuery = querystring.stringify({
    ...clearQuery,
  });
  const result = await authenticatedRequest.get<ApiSearchResult<Transmitter>>(
    `/api/v2/transmitters?${parsedQuery}`,
    { headers: { "Content-Type": "application/json" } }
  );

  return result.data.result;
};

const registerTransmitter = async (
  authenticatedRequest: AxiosInstance,
  transmitterId: string | number,
  credentialsObject: {
    sipUsername?: string;
    sipPassword?: string;
  }
) => {
  const result = await authenticatedRequest.post(
    `/api/v2/transmitters/${transmitterId}/credentials`,
    JSON.stringify(credentialsObject),
    { headers: { "Content-Type": "application/json" } }
  );
  return result;
};

const saveTransmitter = async (
  objectData: EditTransmitterData,
  authenticatedRequest: AxiosInstance
) => {
  const transmitterData = JSON.stringify(objectData);
  const method = objectData.id !== undefined ? "put" : "post";
  const path = objectData.id !== undefined ? `/${objectData.id}` : "";
  const result = await authenticatedRequest[method]<number | undefined>(
    `/api/v2/transmitters${path}`,
    transmitterData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const getTransmitterPeriodicDisables = async (
  transmitterId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.get<PeriodicDisable[]>(
    `/api/v2/transmitters/${transmitterId}/periodicdisable`
  );
  return result;
};
const saveTransmitterPeriodicDisables = async (
  transmitterId: number,
  data: PeriodicDisable,
  authenticatedRequest: AxiosInstance
) => {
  const postData = JSON.stringify(data);
  const result = await authenticatedRequest.post<PeriodicDisable[]>(
    `/api/v2/transmitters/${transmitterId}/periodicdisable`,
    postData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const deleteTransmitterInStock = async (
  transmitterId: string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/transmitters/${transmitterId}`
  );
  return result;
};
const moveTransmitterToOrganisation = async (
  transmitterIds: number[],
  newOrganisation: number,
  authenticatedRequest: AxiosInstance
) => {
  const moveData: MoveTransmitterData = {
    moves: transmitterIds.map((t) => ({
      transmitterId: t,
      organizationId: newOrganisation,
    })),
  };
  const result = await authenticatedRequest.patch<ApiResponse<any>>(
    `/api/v2/transmitters`,
    moveData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const removeTransmitterPeriodicDisables = async (
  transmitterId: number,
  scheduleId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/transmitters/${transmitterId}/periodicdisable/${scheduleId}`
  );
  return result;
};
const addTransmitterToObject = async (
  objectId: string,
  transmitterId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.patch<ApiResponse<any>>(
    `/api/v2/transmitters/${transmitterId}/user/${objectId}`
  );
  return result;
};

const addTransmitterToAdmin = async (
  adminId: number,
  transmitterId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.patch<ApiResponse<any>>(
    `/api/v2/transmitters/${transmitterId}/admin/${adminId}`
  );
  return result;
};

const getTransmitter = async (
  transmitterId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<TransmitterModel>(
    `/api/v2/transmitters/${transmitterId}`
  );

const getTransmitterCredentials = async (
  transmitterId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<TransmitterCredentials[]>(
    `/api/v2/transmitters/${transmitterId}/credentials`
  );

const deleteTransmitterCredentials = async (
  transmitterId: number | string,
  credentialsId: number | string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `/api/v2/transmitters/${transmitterId}/credentials/${credentialsId}`,
    {
      headers: { "Content-Type": "application/json" },
      data: {},
    }
  );

const removeTransmitterFromObject = async (
  objectId: string,
  transmitterId: number | string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/transmitters/${transmitterId}/user/${objectId}`
  );
  return result;
};

const removeTransmitterFromAdmin = async (
  admindId: number,
  transmitterId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/transmitters/${transmitterId}/admin/${admindId}`
  );
  return result;
};

const getTransmitterSettings = async (
  transmitterId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.get<TransmitterSetting[]>(
    `/api/v2/transmitters/${transmitterId}/settings`
  );
  return result;
};
const saveTransmitterSetting = async (
  transmitterId: number,
  settings: TransmitterSettingUpdate,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.put<TransmitterSettingUpdate>(
    `/api/v2/transmitters/${transmitterId}/settings`,
    settings,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const getObjectStatus = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.get<ObjectStatus>(
    `/api/v2/users/${objectId}/status`
  );
  return result.data;
};

const getTransmitterLogs = async (
  transmitterId: string,
  searchData: any,
  authenticatedRequest: AxiosInstance
) => {
  const clearQuery = Object.fromEntries(
    Object.entries(searchData).filter(([_, v]) => !!v)
  );
  const parsedQuery = querystring.stringify({
    ...clearQuery,
  });
  const result = await authenticatedRequest.get<ITransmitterLogsResponse>(
    `/api/v2/transmitters/${transmitterId}/accesslog?${parsedQuery}`
  );
  return result.data;
};

export {
  getTransmittersInStock,
  saveTransmitter,
  addTransmitterToObject,
  addTransmitterToAdmin,
  removeTransmitterFromObject,
  removeTransmitterFromAdmin,
  getTransmitter,
  getTransmitterPeriodicDisables,
  saveTransmitterPeriodicDisables,
  removeTransmitterPeriodicDisables,
  getTransmitterSettings,
  getObjectStatus,
  saveTransmitterSetting,
  deleteTransmitterInStock,
  moveTransmitterToOrganisation,
  getPositioningTransmitters,
  registerTransmitter,
  getTransmitterCredentials,
  deleteTransmitterCredentials,
  getTransmitterLogs,
};
