import { SyntheticEvent } from "react";
import { Icon } from "../Icon";

import styles from "./Chips.module.css";

export interface IChips {
  id: string | number;
  label: string;
}

export interface ChipsProps {
  item: IChips;
  width?: number | string;
  onRemove(arg: IChips, arg1: SyntheticEvent): void;
}

export default function Chips({ item, width, onRemove }: Readonly<ChipsProps>) {
  return (
    <div className={styles.chipsContainer} style={{ width }}>
      <div className={styles.text}>{item.label}</div>
      <button
        className={styles.imageWrapper}
        onClick={(e) => onRemove(item, e)}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            onRemove(item, e);
          }
        }}
        aria-label="Close"
      >
        <Icon name="x" color="Grey-400" size={16} />
      </button>
    </div>
  );
}
