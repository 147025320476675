import { getAllEnumEntries } from "enum-for";
import { useState } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import {
  addLocationToObject,
  removeLocationFromObject,
} from "../../core/api/locations/locations";
import { NewLocationResponseData } from "../../core/api/locations/types";
import { updateLocation } from "../../core/api/objects/objects";
import { KnownLocation } from "../../core/api/objects/types";
import {
  PrecenseAlarmFlags,
  UserAlarmFlags,
} from "../../core/enumerations/enumerations";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import Icon from "../../ui-lib/components/Icon";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import { CreateLocationModal } from "../location/CreateLocationModal";

const ObjectLocations = ({
  objectId,
  locations,
  loadLocations,
}: {
  objectId: string;
  locations: KnownLocation[];
  loadLocations(): Promise<void>;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();

  const viserPermission = config?.show.includes("SensioHideFields");

  const precenseItems: IDropdownItem[] = [];
  precenseItems.push({
    id: "",
    name: t("Objects:accessories_knownlocation_noalarmevents"),
    isSelected: false,
  });
  getAllEnumEntries(PrecenseAlarmFlags).forEach((val) => {
    precenseItems.push({
      id: val[1].toString(),
      name: t(`CommonEnum:${val[0]}`),
      isSelected: false,
    });
  });
  const objectAlarmEvents: IDropdownItem[] = [];
  objectAlarmEvents.push({
    id: "",
    name: t("Objects:accessories_knownlocation_noalarmevents"),
    isSelected: false,
  });
  getAllEnumEntries(UserAlarmFlags).forEach((val) => {
    objectAlarmEvents.push({
      id: val[1].toString(),
      name: t(`CommonEnum:${val[0]}`),
      isSelected: false,
    });
  });
  const [locationsLoading, setLocationsLoading] = useState<boolean>(false);

  const updateLocationTable = async (rowData: KnownLocation) => {
    setLocationsLoading(true);

    try {
      await updateLocation(objectId, rowData, authenticatedRequest);
      await loadLocations();
    } catch (err: any) {
      notify({
        variant: "error",
        message: t("Messages:error_saving_data"),
      });
    }
    setLocationsLoading(false);
  };

  const loadInternalLocations = () => {
    setLocationsLoading(true);
    loadLocations();
    setLocationsLoading(false);
  };

  const connectLocation = async (locationId: number) => {
    try {
      await addLocationToObject(objectId, locationId, authenticatedRequest);
      loadInternalLocations();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };
  const removeLocation = async (locationId: number) => {
    setLocationsLoading(true);
    try {
      await removeLocationFromObject(
        objectId,
        locationId,
        authenticatedRequest
      );
      notify({
        message: t("Objects:removed_location_from_object"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLocationsLoading(false);
    }
    loadInternalLocations();
  };

  return (
    <>
      <Row type="space" align="center">
        <h2>{t("Objects:accessories_locations_title")}</h2>
        {config?.show.includes("KnownLocations") && (
          <CreateLocationModal
            onSubmit={(result?: NewLocationResponseData) => {
              if (result?.knownLocationId) {
                connectLocation(result?.knownLocationId);
              }
            }}
            modalButtonTitle={t("Objects:accessories_add_location")}
            modalTitle={t("Objects:accessories_add_location_modal_title")}
            saveButtonTitle={t("Common:save")}
            variant="secondary"
            modalButtonIcon="location-marker"
            linkedLocations={locations}
            objectId={objectId}
            onClose={loadInternalLocations}
          />
        )}
      </Row>
      <Spacer size={16} />
      <Table<KnownLocation>
        columns={[
          {
            header: t("Table:Addresses_Description"),
            fieldTemplate: (rowData) => (
              <TableCell value={rowData.description} />
            ),
          },
          ...(!viserPermission
            ? [
                {
                  header: t("Objects:accessories_knownlocation_homelocation"),
                  editable: true,
                  fieldTemplate: (rowData: KnownLocation) => (
                    <Checkbox
                      checked={rowData.userHomeLocation}
                      onChange={async () => {
                        rowData.userHomeLocation = !rowData.userHomeLocation;
                        await updateLocationTable(rowData);
                      }}
                    />
                  ),
                },
              ]
            : []),
          {
            header: t("Objects:respondent_alarm_event"),
            editable: true,
            fieldTemplate: (rowData) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Icon name="receipt-refund" />
                <Spacer size={8} />
                <div style={{ display: "flex", flex: 1 }}>
                  <Dropdown
                    placeholder={t(
                      "Objects:accessories_knownlocation_noalarmevents"
                    )}
                    selectedItem={precenseItems.find(
                      (item) =>
                        (item.id as string) === rowData.precenseAlarmFlags
                    )}
                    items={precenseItems.map((item) => ({
                      id: item.id,
                      name: item.name,
                      isSelected: item.id === rowData.precenseAlarmFlags,
                    }))}
                    onSelectItem={async (item) => {
                      rowData.precenseAlarmFlags = item.id as string;
                      await updateLocationTable(rowData);
                    }}
                    showTooltip
                  />
                </div>
              </div>
            ),
          },
          {
            header: t("Objects:object_alarm_event"),
            editable: true,
            fieldTemplate: (rowData) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Icon name="cube" />
                <Spacer size={8} />
                <div style={{ display: "flex", flex: 1 }}>
                  <Dropdown
                    placeholder={t(
                      "Objects:accessories_knownlocation_noalarmevents"
                    )}
                    selectedItem={objectAlarmEvents.find(
                      (item) => (item.id as string) === rowData.userAlarmFlags
                    )}
                    items={objectAlarmEvents.map((item) => ({
                      id: item.id,
                      name: item.name,
                      isSelected: item.id === rowData.userAlarmFlags,
                    }))}
                    onSelectItem={async (item) => {
                      rowData.userAlarmFlags = item.id as string;
                      await updateLocationTable(rowData);
                    }}
                    showTooltip
                  />
                </div>
              </div>
            ),
          },
        ]}
        items={locations}
        rowActions={[
          {
            icon: "x",
            iconVariant: "secondary",
            iconSize: 16,
            text: t("Objects:accessories_table_remove"),
            onClick: async (rowData) => await removeLocation(rowData.id),
          },
        ]}
        rowActionsFixed
        rowActionsColWidth={45}
        hideEmptyMessage
        noRowsMessage={t("Objects:accessories_no_locations")}
        isLoading={locationsLoading}
        showRowHover
        withShowMore
      />
    </>
  );
};
export default ObjectLocations;
