import { useState, ChangeEventHandler, RefObject } from "react";
import { RefCallBack } from "react-hook-form";
import { TextInput } from "./TextInput";
import { PasswordConstraints } from "../../../core/api/administrators/types";
import Icon from "../Icon";
import PasswordStrengthIndicator from "../PasswordStrengthIndicator/PasswordStrengthIndicator";
import PasswordHintsTooltip from "./PasswordHintsTooltip";

export interface PasswordInputProps {
  id?: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  value?: string;
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  withIndicator?: boolean;
  invalid?: boolean;
  withHint?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  passwordConstraints?: PasswordConstraints;
}

export const PasswordInput = ({
  id,
  label = "",
  placeholder = "",
  hint = "",
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  value = "",
  inputRef,
  withIndicator,
  invalid,
  withHint,
  passwordConstraints,
  onChange = () => {},
}: PasswordInputProps) => {
  const [type, setType] = useState<"password" | "text">("password");

  return (
    <div className="w-100">
      <TextInput
        id={id}
        dataPrAt="left-25 center"
        inputRef={inputRef}
        type={type}
        label={label}
        hint={hint}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        description={description}
        validationError={validationError}
        invalid={invalid}
        value={value}
        onChange={onChange}
        iconLabel={type === "password" ? "show password" : "hide password"}
        renderIcon={() =>
          type === "password" ? (
            <Icon name="eye" size={24} />
          ) : (
            <Icon name="eye-off" size={24} />
          )
        }
        iconPosition="right"
        onIconClick={
          disabled
            ? undefined
            : () => {
                type === "password" ? setType("text") : setType("password");
              }
        }
      />
      {withIndicator && <PasswordStrengthIndicator value={value} />}
      {withHint && passwordConstraints && (
        <PasswordHintsTooltip
          inputId={id}
          value={value}
          passwordConstraints={passwordConstraints}
        />
      )}
    </div>
  );
};
export default PasswordInput;
