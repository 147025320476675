import { MedicalTechnicalNotes } from "../../../core/api/objects/types";
import useTranslations from "../../../core/i18n/useTranslations";
import { Spacer } from "../../../components/Layout/Layout";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const MedicalTechnicalDetails = ({
  details,
  onClose,
  isMedical = true,
}: {
  details?: MedicalTechnicalNotes;
  onClose: () => void;
  isMedical?: boolean;
}) => {
  const t = useTranslations();

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={!!details}
      onClose={onClose}
      className="w-100"
      header={t(`Objects:${isMedical ? "medical" : "technical"}_note_details`)}
      submitBtn={{
        text: t("Common:close"),
        variant: "secondary",
        onClick: onClose,
      }}
      style={{ maxWidth: "576px" }}
    >
      {details ? (
        <>
          <p>{DateTimeHelper.formatDateTimeString(details.created)}</p>

          <Spacer size={16} />

          <p className="fw-600">{t("Objects:note_text")}</p>
          {details.note.split("\n").map((item) => {
            return <p key={self.crypto.randomUUID()}>{item}</p>;
          })}

          <Spacer size={16} />

          <p className="fw-600">{t("Objects:added_by")}</p>
          <p>{details.adminName || details.adminId}</p>
        </>
      ) : null}
    </PrimeModal>
  );
};
