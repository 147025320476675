import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

export type CustomersState = { customers?: string };
export type CustomersFilter = { customers?: string };

const toCustomersFilter = (state: CustomersState): CustomersFilter => ({
  customers: state.customers ?? undefined,
});

const toCustomersState = (filter: CustomersFilter): CustomersState => ({
  customers: filter.customers,
});

const DEFAULT: CustomersState = {
  customers: undefined,
};

export const useCustomersFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        customers: searchObj.customers,
      };

  const [state, setState] = useQueryState<CustomersState>(stateToUse);

  const customersFilter = useMemo(() => toCustomersFilter(state), [state]);

  const setCustomersFilter = useCallback(
    (args: CustomersFilter) => setState(toCustomersState(args)),
    [setState]
  );

  const resetCustomersFilter = useCallback(() => setState(DEFAULT), [setState]);

  return { customersFilter, setCustomersFilter, resetCustomersFilter };
};
