import {
  MouseEventHandler,
  ChangeEvent,
  KeyboardEventHandler,
  RefObject,
} from "react";
import { RefCallBack } from "react-hook-form";
import classNames from "classnames";
import InputMask from "react-input-mask";
import Tooltip from "../Tooltip/Tooltip";
import Icon from "../Icon";
import { getUniqueId } from "../../../core/hooks/getUniqueId";
import { isEmpty } from "../../../core/helpers/helpers";

import styles from "./Input.module.css";

type RenderFunction = () => JSX.Element;
type OnChangeFunction = (
  e: ChangeEvent<HTMLInputElement>,
  value: string
) => void;

export interface TextInputProps {
  type?: "text" | "password" | "number" | "email" | "search";
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  label?: string;
  iconLabel?: string;
  hideLabel?: boolean;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  invalid?: boolean;
  value?: string;
  id?: string;
  onChange?: OnChangeFunction;
  width?: string | number;

  /** Input mask. Check https://www.npmjs.com/package/react-input-mask */
  mask?: string;
  alwaysShowMask?: boolean;
  // icon
  renderIcon?: RenderFunction;
  iconPosition?: "left" | "right";
  onIconClick?: MouseEventHandler<HTMLSpanElement>;

  // text pinned to the input (left or right)
  pinnedText?: string;
  pinnedTextPosition?: "left" | "right";

  // number input specific props
  min?: number;
  max?: number;
  step?: number;
  enableNumberArrows?: boolean;
  autoComplete?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  dataCy?: string;
  dataCyBtn?: string;
  css?: any;
  autoFocus?: boolean;
  name?: string;
  dataPrTooltip?: string;
  dataPrAt?: string;
  className?: string;
  inputClassName?: string;
}

/** Represents <input /> tag */
export const TextInput = ({
  type = "text",
  inputRef = undefined,
  label = "",
  iconLabel = "text input icon",
  hideLabel = false,
  placeholder = "",
  hint = undefined,
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  invalid = false,
  value = "",
  onChange = () => {},
  width,
  mask = undefined,
  alwaysShowMask = false,
  renderIcon = undefined,
  iconPosition = "right",
  onIconClick = undefined,
  pinnedText = undefined,
  pinnedTextPosition = undefined,
  min = undefined,
  max = undefined,
  step = undefined,
  enableNumberArrows = false,
  autoComplete = "on",
  id = getUniqueId("tb_"),
  onKeyDown,
  dataCy,
  dataCyBtn,
  css,
  autoFocus,
  name,
  dataPrTooltip,
  dataPrAt,
  className,
  inputClassName,
}: TextInputProps) => {
  const internalValue = value ?? undefined;

  return (
    <div className={classNames(styles.inputContainer, className)}>
      {/* label row */}
      <div
        className={classNames({
          [styles.labelRow]: !hideLabel && !isEmpty(label),
          visuallyHidden: hideLabel || isEmpty(label),
        })}
      >
        {required && <span className={styles.requiredIcon}>*</span>}
        <label htmlFor={id}>{label}</label>
        {hint ? (
          <Tooltip
            trigger={() => (
              <span
                className={classNames({
                  [styles.labelHint]: true,
                })}
              >
                <Icon name="information-circle" size={16} />
              </span>
            )}
            position="right center"
            text={hint}
          />
        ) : null}
      </div>

      {/* input row */}
      <div
        className={classNames({
          [styles.inputRow]: true,
          [styles.filledBorder]: !!value,
          [styles.inputRowIconLeft]: renderIcon && iconPosition === "left",
          [styles.inputRowIconRight]: renderIcon && iconPosition === "right",
          [styles.inputRowPinnedTextLeft]:
            pinnedText && pinnedTextPosition === "left",
          [styles.inputRowPinnedTextRight]:
            pinnedText && pinnedTextPosition === "right",
        })}
        style={{ width }}
      >
        {pinnedText ? (
          <span
            className={classNames({
              [styles.inputPinnedText]: true,
              [styles.inputPinnedTextLeft]: pinnedTextPosition === "left",
              [styles.inputPinnedTextRight]: pinnedTextPosition === "right",
              [styles.inputPinnedTextDisabled]: disabled,
            })}
          >
            {pinnedText}
          </span>
        ) : null}

        {/* Render InputMask only when input mask passed */}
        {mask && type !== "number" ? (
          <InputMask
            mask={mask}
            maskPlaceholder="_"
            alwaysShowMask={alwaysShowMask}
            disabled={disabled}
            required={required}
            value={internalValue}
            onChange={(e) => {
              onChange(e, e.target.value);
            }}
            onKeyDown={(e) => {
              onKeyDown?.(e);
            }}
          >
            <input
              name={name}
              autoFocus={autoFocus}
              data-cy={dataCy}
              id={id}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              value={internalValue}
              // onChange={onChange}
              className={classNames(inputClassName, {
                [styles.inputValidationError]: invalid || !!validationError,
              })}
            />
          </InputMask>
        ) : (
          <input
            name={name}
            autoFocus={autoFocus}
            data-cy={dataCy}
            style={css}
            ref={inputRef}
            id={id}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            value={internalValue}
            autoComplete={autoComplete}
            onChange={(e) => {
              onChange(e, e.target.value);
            }}
            onKeyDown={(e) => {
              onKeyDown?.(e);
            }}
            className={classNames(inputClassName, {
              [styles.inputValidationError]: invalid || !!validationError,
              [styles.disableNumberArrows]: !enableNumberArrows,
            })}
            min={min}
            max={max}
            step={step}
            data-pr-tooltip={dataPrTooltip}
            data-pr-at={dataPrAt}
          />
        )}

        {/* icon */}
        {renderIcon && !pinnedText ? (
          <button
            type="button"
            tabIndex={internalValue?.length ? 0 : -1}
            data-cy={dataCyBtn}
            className={classNames({
              [styles.inputIcon]: true,
              [styles.inputIconLeft]: iconPosition === "left",
              [styles.inputIconRight]: iconPosition === "right",
              [styles.inputIconClickable]: !!onIconClick,
              [styles.inputIconDisabled]: disabled,
            })}
            onClick={onIconClick}
            aria-label={iconLabel}
          >
            {renderIcon()}
          </button>
        ) : null}
      </div>

      {/* Other */}
      {validationError ? (
        <div className={styles.validationError}>
          <Icon name="exclamation-circle" size={14} />
          {validationError}
        </div>
      ) : null}
      <p className={classNames("description", { ["ml-16"]: validationError })}>
        {description}
      </p>
    </div>
  );
};
export default TextInput;
