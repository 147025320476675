import { useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";
import { NestedOrganizationProps } from "../../../core/api/organizations/organizations";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import { Spacer } from "../../../components/Layout/Layout";
import Button from "../../../ui-lib/components/Button/Button";
import useTranslations from "../../../core/i18n/useTranslations";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import {
  IRolesTemplates,
  ITemplatesItemsProps,
} from "../../../core/api/adGroup/types";
import {
  IRoleTemplateDropdown,
  IOrganizationProps,
} from "../../../core/api/organizations/types";
import { HierarchyUsage } from "../../../core/api/roleTemplates/types";
import useUser from "../../../core/user/useUser";
import { getResponseCenter } from "../../../core/api/responsecenters/responsecenters";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { getFlatOrganizations } from "../../../ui-lib/utils/organizationsHelper";
import { notify } from "../../../ui-lib/components/Alerts/Toast";

export const RoleTemplateRow = ({
  index,
  role,
  generalTemplatesData,
  organizationsData,
  getValues,
  setValue,
  trigger,
  removeTemplateFromForm,
}: {
  index: number;
  role: IRolesTemplates;
  generalTemplatesData?: ITemplatesItemsProps[];
  organizationsData?: NestedOrganizationProps[];
  getValues: UseFormGetValues<any>;
  setValue: (name: string, value: any) => void;
  trigger: (name?: string) => Promise<boolean>;
  removeTemplateFromForm: (index: number) => void;
}) => {
  const [filteredData, setFilteredData] = useState<IItemProp[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [roleTemplatesData, setRoleTemplatesData] =
    useState(generalTemplatesData);
  const [isRespondentTemplate, setIsRespondentTemplate] = useState<boolean>();
  const { authenticatedRequest } = useUser();
  let flatOrganizationsArray: IOrganizationProps[] = [];
  const t = useTranslations();

  const templateQuantity = getValues("roleTemplates")?.length ?? 0;

  const showNoItemsToShow = () => {
    notify({
      variant: "error",
      message: t("Organizations:no_items_in_template"),
    });
  };

  const filterOrganizations = (
    idToCheck?: number,
    hierarchy?: HierarchyUsage,
    data = organizationsData,
    saveData = true
  ) => {
    data!.forEach((org) => {
      if (org.item.id === idToCheck) {
        let levelToCheck: number;
        switch (hierarchy) {
          case HierarchyUsage.AllBellow:
            levelToCheck = Infinity;
            break;
          case HierarchyUsage.OneBellow:
            levelToCheck = 1;
            break;
          case HierarchyUsage.ThisOrganization:
            levelToCheck = 0;
            break;
          default:
            levelToCheck = Infinity;
            break;
        }
        flatOrganizationsArray = getFlatOrganizations([org], [], levelToCheck);
        if (flatOrganizationsArray.length === 0) {
          showNoItemsToShow();
        }
        if (saveData) {
          setFilteredData(
            flatOrganizationsArray.map((flatOrg) => ({
              id: flatOrg.id,
              name: flatOrg.name,
              isSelected: role.organizationId === flatOrg.id,
              type: "customer",
            }))
          );
        }
      } else {
        filterOrganizations(idToCheck, hierarchy, org.children);
      }
    });
  };

  const uploadAlarmReceptions = async (idToCheck?: number) => {
    try {
      setIsLoading(true);
      const receptionData = await getResponseCenter(
        `${idToCheck!}`,
        authenticatedRequest
      );
      setFilteredData([
        {
          id: receptionData.data.id,
          name: receptionData.data.displayName,
          isSelected: true,
          type: "respondent",
        } as IItemProp,
      ]);
      const updatedTemplates = getValues("roleTemplates").length
        ? [...getValues("roleTemplates")]
        : [{}];
      updatedTemplates[index].responseCenterId = receptionData.data.id;
      setValue("roleTemplates", updatedTemplates);
      setIsRespondentTemplate(true);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const findTemplate = (id: number) =>
    roleTemplatesData!.find((template) => template.id === id);

  useEffect(() => {
    if (role.templateId) {
      const updatedTemplates = roleTemplatesData!.map((template) => ({
        ...template,
        isSelected: template.id === role.templateId,
      }));
      setRoleTemplatesData(updatedTemplates);

      const usedTemplate = findTemplate(role.templateId);
      if (role.organizationId) {
        filterOrganizations(usedTemplate!.orgId, usedTemplate!.hierarchy);
      } else if (role.responseCenterId) {
        uploadAlarmReceptions(usedTemplate!.arcId);
      }
    }
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Dropdown
        maxVisible={10}
        title={`${index === 0 ? t("Common:role_template") : ""}`}
        width="345px"
        selectedItem={roleTemplatesData?.find(
          (template) => template.isSelected
        )}
        items={roleTemplatesData!}
        onSelectItem={async (item: IRoleTemplateDropdown) => {
          const updatedTemplates = getValues("roleTemplates").length
            ? [...getValues("roleTemplates")]
            : [{}];
          updatedTemplates[index].templateId = item.id;
          setValue("roleTemplates", updatedTemplates);
          const updatedRoleTemplates = roleTemplatesData!.map((template) => ({
            ...template,
            isSelected: item.id === template.id,
          }));
          setRoleTemplatesData(updatedRoleTemplates);
          if (item.type === "customer") {
            filterOrganizations(item.orgId, item.hierarchy);
          } else {
            await uploadAlarmReceptions(item.arcId);
          }
          setIsRespondentTemplate(item.type === "respondent");
          trigger("roleTemplates");
        }}
        withFilter
      />

      <Spacer size={32} />

      <Dropdown
        maxVisible={10}
        title={`${index === 0 ? t("Organizations:applies_to") : ""}`}
        width="345px"
        searchPlaceholder={t("Common:search_placeholder")}
        disabled={
          isLoading ||
          !filteredData ||
          !filteredData.length ||
          isRespondentTemplate
        }
        selectedItem={filteredData?.find((org) => org.isSelected)}
        items={filteredData || []}
        onSelectItem={(item: IRoleTemplateDropdown) => {
          if (filteredData) {
            const updatedOrgs = [...filteredData].map((org) => ({
              ...org,
              isSelected: item.id === org.id,
            }));
            setFilteredData(updatedOrgs);
            const updatedTemplates = getValues("roleTemplates").length
              ? [...getValues("roleTemplates")]
              : [{}];
            if (item.type === "customer") {
              updatedTemplates[index].organizationId = item.id;
            } else {
              updatedTemplates[index].responseCenterId = item.id;
            }
            setValue("roleTemplates", updatedTemplates);
            trigger("roleTemplates");
          }
        }}
        withFilter
      />

      {isLoading && (
        <>
          <span
            style={{
              position: "relative",
              top: index === 0 ? "32px" : "8px",
              left: "-64px",
              width: 0,
            }}
          >
            <LoadingSpinner theme="primary" size={24} />
          </span>
        </>
      )}

      <Spacer size={32} />

      {templateQuantity > 1 && (
        <Button
          image="x"
          variant="secondary"
          onClick={() => removeTemplateFromForm(index)}
          customStyles={{
            maxHeight: "40px",
            marginTop: `${index === 0 ? "24px" : 0}`,
          }}
        />
      )}
    </div>
  );
};
