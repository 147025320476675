import styles from "./Table.module.css";

export const TableHeaderResults = ({
  itemsLength,
  totalResultsStyles,
  tableTexts,
  withShowMore,
  paginatedItems,
}: {
  itemsLength: number;
  totalResultsStyles?: React.CSSProperties;
  tableTexts: { showing: string; results: string };
  withShowMore?: boolean;
  paginatedItems?: boolean;
}) => {
  if (!withShowMore || !paginatedItems) {
    return null;
  }

  return (
    <span
      data-cy="total_result"
      className={styles.totalResult}
      style={totalResultsStyles}
    >
      {tableTexts.showing} {itemsLength} {tableTexts.results}
    </span>
  );
};
