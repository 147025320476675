import { useEffect, useRef, useState } from "react";
import { autorun, keys } from "mobx";
import { useHistory } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import { changeSettings } from "../../core/api/organizations/organizations";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { UnsavedChanges } from "../../components/UnsavedChanges";
import useTranslations from "../../core/i18n/useTranslations";
import Button from "../../ui-lib/components/Button/Button";
import Modal from "../../ui-lib/components/Popup/Modal";
import DeleteOrganizationModal from "./DeleteOrganizationModal";
import RequestLogModal from "./RequestOrganizationLogModal";
import usePageState from "../../core/pagestate/usePageState";
import useUser from "../../core/user/useUser";
import useSharedOrganization from "./SharedOrganizationContext/useSharedOrganization";
import { Role, RoleFeature } from "../../components/RoleFeature";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

const OrganizationActions = ({ id }: { id: string }) => {
  const t = useTranslations();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const { getSettingsData } = useSharedOrganization();
  const history = useHistory();
  const pageState = usePageState();
  const { authenticatedRequest } = useUser();
  const advancedSettingRoute =
    history.location.pathname.includes("advanced-settings");
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkSettings, setCheckSettings] = useState<boolean>(false);
  const [requestModalLogOpen, setRequestModalLogOpen] =
    useState<boolean>(false);
  const contextMenuRef = useRef<any>(null);

  const updateSettingData = async () => {
    if (pageState.advancedSettingError) return;
    setDisableButton(true);
    setLoading(true);
    try {
      if (keys(pageState.advancedSettingData).length) {
        const allFetches = Object.keys(pageState.advancedSettingData).map(
          (item) =>
            changeSettings(
              authenticatedRequest,
              pageState.advancedSettingData[item].customerId,
              {
                identifier: item,
                value: pageState.advancedSettingData[item].value ?? "",
              }
            )
        );
        await Promise.all(allFetches)
          .then(() => {
            notify({
              message: t("Organizations:edited_success"),
            });
            getSettingsData();
            pageState.setAdvancedSettingDataEmpty();
          })
          .catch((error) => notifyApiErrors(error.response?.data?.errors));
      }
    } catch (e: any) {
      notifyApiErrors(e.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    autorun(() => {
      if (Object.keys(pageState.advancedSettingData).length) {
        setDisableButton(false);
        setCheckSettings(true);
      } else {
        setDisableButton(true);
        setCheckSettings(false);
      }
    });
  }, [keys(pageState.advancedSettingData).length]);

  return (
    <>
      {canSave && (
        <RoleFeature requires={[Role.EditOrganizations]}>
          <Button
            text={t("Common:save")}
            disabled={!isDirty}
            loading={isSaving}
            onClick={onSave}
          />
        </RoleFeature>
      )}
      {advancedSettingRoute && (
        <Button
          text={t("Common:save")}
          disabled={disableButton}
          loading={loading}
          onClick={updateSettingData}
        />
      )}
      <Button
        className="ml-8"
        type="button"
        variant="secondary"
        image="dots-horizontal"
        text={t("Common:other")}
        onClick={(e) => contextMenuRef.current?.toggle(e)}
      />
      <OverlayPanel ref={contextMenuRef} className="actions-overlay">
        <>
          <RoleFeature requires={[Role.DownloadAdminEvents]}>
            <PopupContextMenuLinkButton
              icon="clipboard-list"
              text={t("Organizations:request_organization_log")}
              onClick={() => setRequestModalLogOpen(true)}
            />
          </RoleFeature>

          <PopupContextMenuLinkButton
            icon="external-link"
            text={t("Organizations:view_related_objects")}
            onClick={() => {
              history.push(`/adminportal/objects?customers=${id}`);
            }}
          />

          <RoleFeature requires={[Role.DeleteOrganizations]}>
            <Modal
              trigger={() => (
                <PopupContextMenuLinkButton
                  icon="trash"
                  text={t("Organizations:delete_organisation")}
                />
              )}
            >
              {(close) => <DeleteOrganizationModal onClose={close} />}
            </Modal>
          </RoleFeature>
        </>
      </OverlayPanel>
      <UnsavedChanges isDirty={checkSettings} />
      <PrimeModal
        withHeader
        isOpen={requestModalLogOpen}
        header={t("Organizations:request_organization_log")}
        onClose={() => setRequestModalLogOpen(false)}
      >
        <RequestLogModal onClose={() => setRequestModalLogOpen(false)} />
      </PrimeModal>
    </>
  );
};

export default OrganizationActions;
