import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Column } from "../../../../../components/Layout/Layout";
import { validateSipUrl } from "../../../../../core/helpers/validation";
import useTranslations from "../../../../../core/i18n/useTranslations";
import TextInput from "../../../../../ui-lib/components/Inputs/TextInput";
import RadioGroup from "../../../../../ui-lib/components/Radio/RadioGroup";
import useSharedAlarmReception from "../../SharedAlarmReceptionContext/useSharedAlarmReception";

enum CallType {
  Outgoing = "outgoing",
  Incoming = "incoming",
}

export const SIPControl = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useFormContext();
  const t = useTranslations();
  const { data } = useSharedAlarmReception();

  const [type, setType] = useState(
    !getValues("sipUrl") && !getValues("secureSipUrl")
      ? CallType.Outgoing
      : CallType.Incoming
  );

  const radioGroupItems = useMemo(
    () => [
      {
        idForText: "SIP_type_0",
        value: CallType.Outgoing,
        text: t("AlarmReceptions:alarm_handling_input_outgoing_calls"),
      },
      {
        idForText: "SIP_type_1",
        value: CallType.Incoming,
        text: t("AlarmReceptions:alarm_handling_input_incoming_calls"),
      },
    ],
    [t]
  );

  const getError = (field: string, errorType?: string) => {
    if (!errorType) return;
    if (errorType === "required") {
      return t("Errors:sip_or_sips");
    }
    if (errorType === "validate") {
      return field === "sipUrl"
        ? t("Errors:sip_url_format")
        : t("Errors:sips_url_format");
    }
    return t("Errors:unknown");
  };

  useEffect(() => {
    if (type === CallType.Outgoing) {
      setValue("sipUrl", "");
      setValue("secureSipUrl", "");
    }
    if (type === CallType.Incoming) {
      setValue("sipUrl", data?.sipUrl);
      setValue("secureSipUrl", data?.secureSipUrl);
    }
  }, [type]);

  return (
    <Column align="fill">
      <RadioGroup
        label={t("AlarmReceptions:alarm_handling_input_sip")}
        name="sipGroup"
        items={radioGroupItems}
        selectedValue={type}
        onChange={(value) => {
          setType(value as CallType);
          clearErrors();
        }}
      >
        {type === CallType.Incoming && (
          <>
            <Controller
              name="sipUrl"
              control={control}
              rules={{
                required: !getValues("secureSipUrl"),
                validate: (value) => validateSipUrl(value),
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  required={!getValues("secureSipUrl")}
                  value={field.value}
                  label={t("Common:label_sip")}
                  placeholder={t("Common:sip_address_placeholder")}
                  onChange={field.onChange}
                  validationError={getError(
                    field.name,
                    errors?.sipUrl?.type as string
                  )}
                />
              )}
            />
            <Controller
              name="secureSipUrl"
              control={control}
              rules={{
                required: !getValues("sipUrl"),
                validate: (value) => validateSipUrl(value),
              }}
              render={({ field }) => (
                <TextInput
                  inputRef={field.ref}
                  required={!getValues("sipUrl")}
                  value={field.value}
                  label={t("Common:label_sips")}
                  placeholder={t("Common:sips_address_placeholder")}
                  onChange={field.onChange}
                  validationError={getError(
                    field.name,
                    errors?.secureSipUrl?.type as string
                  )}
                />
              )}
            />
          </>
        )}
      </RadioGroup>
    </Column>
  );
};
