import { useState } from "react";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { endObjectAbsence } from "../../../../core/api/objects/objects";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import useUser from "../../../../core/user/useUser";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const CancelAbsenceModal = ({
  isOpen,
  isEnd = false,
  onClose,
  adminId,
  reloadAbsence,
  absenceId,
}: {
  isOpen: boolean;
  isEnd: boolean;
  onClose: () => void;
  adminId: number;
  reloadAbsence: () => Promise<void>;
  absenceId?: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const onRemove = async () => {
    setIsLoading(true);
    try {
      notify({
        message: t("Objects:absence_scheduled_deleted"),
      });
      await endObjectAbsence(adminId, absenceId!, authenticatedRequest);
      await reloadAbsence();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={isOpen}
      onClose={onClose}
      fixedWidth="576px"
      loading={isLoading}
      header={
        isEnd
          ? t("Objects:end_scheduled_absence")
          : t("Objects:cancel_scheduled_absence")
      }
      submitBtn={{
        text: isEnd
          ? t("Objects:end_the_absence")
          : t("Objects:cancel_the_absence"),
        variant: "primary",
        onClick: onRemove,
      }}
      cancelBtn={{ text: t("Objects:keep_the_absence"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {isEnd
          ? t("Objects:end_scheduled_absence_description")
          : t("Objects:cancel_scheduled_absence_description")}
      </p>
    </PrimeModal>
  );
};
