import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Column,
  FillTwoColRow,
  Spacer,
  TwoColGrid,
} from "../../../components/Layout/Layout";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { validateIPAddress } from "../../../core/helpers/validation";
import useTranslations from "../../../core/i18n/useTranslations";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import InputContainer from "../../../ui-lib/components/Inputs/InputContainer";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import Panel from "../../../ui-lib/components/Panel/Panel";
import { IPFilter, IpWhiteList } from "../../../core/api/organizations/types";

export declare type EditIPFilterDialogActions = {
  open: (rowIndex: number, whitelist?: IpWhiteList, isEdit?: boolean) => void;
};
export interface EditIPFilterDialogProps {
  onClose?: () => void;
  modalTitle: string;
  saveButtonTitle: string;
  editIPWhiteList: (whitelist: IpWhiteList) => Promise<void>;
}
export const EditIPFilter = forwardRef<
  EditIPFilterDialogActions,
  EditIPFilterDialogProps
>((props: EditIPFilterDialogProps, ref) => {
  const { onClose, modalTitle, saveButtonTitle, editIPWhiteList } = props;
  const t = useTranslations();
  const [isEditIPFilterOpen, setIsEditIPFilterOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(true);
  const whiteList = useRef<IpWhiteList | undefined>();
  const index = useRef<number | undefined>();
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useForm<IPFilter>({
    defaultValues: {},
  });

  const closeModal = () => {
    setIsEditIPFilterOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  };
  const submit = async (values: IPFilter) => {
    try {
      if (
        index.current !== undefined &&
        index.current > -1 &&
        whiteList.current &&
        whiteList.current.filters &&
        whiteList.current.filters.length > index.current
      ) {
        whiteList.current.filters[index.current] = values;
      } else {
        if (whiteList.current === undefined) {
          whiteList.current = { currentIP: "", filters: [] };
        }
        if (whiteList.current?.filters === undefined) {
          whiteList.current!.filters = [];
        }
        whiteList.current?.filters?.push(values);
      }
      await editIPWhiteList(whiteList.current!);
      notify({
        message: t(
          `AlarmReceptions:ip_${isModalEdit ? "edit" : "create"}_success`
        ),
      });
      closeModal();
    } catch (error: any) {
      if (error.response?.data?.errors) {
        if (whiteList.current?.filters?.length && index.current) {
          whiteList.current?.filters.splice(index.current, 1);
        } else {
          whiteList.current!.filters = [];
        }

        notifyApiErrors(error.response?.data?.errors);
      } else {
        notify({ message: "error occurred", variant: "error" });
      }
    }
  };
  useImperativeHandle<EditIPFilterDialogActions, EditIPFilterDialogActions>(
    ref,
    () => {
      const actions: EditIPFilterDialogActions = {
        open: function open(
          rowIndex: number,
          whitelist?: IpWhiteList,
          isEdit = true
        ) {
          if (
            rowIndex > -1 &&
            whitelist?.filters &&
            whitelist.filters?.length > rowIndex
          ) {
            reset(whitelist.filters[rowIndex]);
          } else {
            reset({});
          }
          whiteList.current = whitelist;
          index.current = rowIndex;
          setIsModalEdit(isEdit);
          setIsEditIPFilterOpen(true);
        },
      };
      return actions;
    }
  );

  return (
    <>
      <StyledModal
        onClose={closeModal}
        isOpen={isEditIPFilterOpen}
        cancelBtnText={t("Common:cancel")}
        approveBtnText={
          isModalEdit ? saveButtonTitle : t("AlarmReceptions:create_ip_address")
        }
        modalTitle={
          isModalEdit ? modalTitle : t("AlarmReceptions:add_new_ipfilter")
        }
        onApproveBtnClick={handleSubmit(submit)}
        onCancelBtnClick={closeModal}
        closeOnDocumentClick={false}
        isLoading={isSubmitting}
      >
        <p>{t("AlarmReceptions:edit_ipfilter_description")}</p>
        <Spacer size={16} />
        <form>
          <Column type="top">
            <TwoColGrid>
              <FillTwoColRow>
                <Controller
                  name="startIP"
                  control={control}
                  rules={{
                    validate: validateIPAddress,
                  }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      css={{ flex: 1 }}
                      placeholder={t(
                        "AlarmReceptions:edit_ipfilter_placeholder_startip"
                      )}
                      label={t("AlarmReceptions:edit_ipfilter_label_startip")}
                      value={field.value || ""}
                      onChange={field.onChange}
                      validationError={
                        errors.startIP &&
                        t("AlarmReceptions:edit_ipfilter_ip_format")
                      }
                    />
                  )}
                />
              </FillTwoColRow>
              <FillTwoColRow>
                <Controller
                  name="endIP"
                  control={control}
                  rules={{
                    validate: validateIPAddress,
                  }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      css={{ flex: 1 }}
                      placeholder={t(
                        "AlarmReceptions:edit_ipfilter_placeholder_endip"
                      )}
                      label={t("AlarmReceptions:edit_ipfilter_label_endip")}
                      value={field.value || ""}
                      onChange={field.onChange}
                      validationError={
                        errors.endIP &&
                        t("AlarmReceptions:edit_ipfilter_ip_format")
                      }
                    />
                  )}
                />
              </FillTwoColRow>
              <FillTwoColRow>
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      css={{ flex: 1 }}
                      placeholder={t(
                        "AlarmReceptions:edit_ipfilter_placeholder_comment"
                      )}
                      label={t("AlarmReceptions:edit_ipfilter_label_comment")}
                      value={field.value || ""}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FillTwoColRow>
              <FillTwoColRow>
                <InputContainer
                  label={
                    <span className="labelRowLabel">
                      {t("AlarmReceptions:edit_ipfilter_admin_central_label")}
                    </span>
                  }
                  input={
                    <Panel className="stretch">
                      <Controller
                        name="adminApi"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            id="edit_ipfilter_admin"
                            label={t(
                              "AlarmReceptions:edit_ipfilter_admin_label"
                            )}
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Controller
                        name="central"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            id="edit_ipfilter_central"
                            label={t(
                              "AlarmReceptions:edit_ipfilter_central_label"
                            )}
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Panel>
                  }
                />
              </FillTwoColRow>
            </TwoColGrid>
          </Column>
        </form>
      </StyledModal>
    </>
  );
});
