import React, { ReactNode, RefObject } from "react";
import { RefCallBack } from "react-hook-form";
import classnames from "classnames";
import Radio from "./Radio";
import styles from "./Radio.module.css";

export interface IRadioProps {
  label?: string;
  name: string;
  items: {
    value: string;
    text: string;
    description?: string;
    id?: number;
    idForText?: number | string;
    image?: string;
  }[];
  selectedValue?: string;
  onChange: (value: string) => void;
  children?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
  step?: number;
  wrapperStyle?: React.CSSProperties;
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
}

export const RadioGroup = ({
  label,
  name,
  items,
  selectedValue,
  onChange,
  children = null,
  fullWidth,
  disabled,
  noBorder,
  step = 0,
  wrapperStyle,
  inputRef,
}: IRadioProps) => (
  <>
    {label && <label>{label}</label>}
    <div
      className={classnames({
        [styles.radioGroupWrapper]: true,
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
        [styles.noBorder]: noBorder,
      })}
      style={wrapperStyle}
    >
      {items.map((item) => (
        <Radio
          inputRef={inputRef}
          inputId={item.id ? `${item.id + step}` : `undefined${step}`}
          key={item.value}
          name={name}
          value={item.value}
          text={item.text}
          description={item.description}
          checked={item.value === selectedValue}
          onChange={(e) => onChange(e.currentTarget.value)}
          idForText={item.idForText}
          image={item.image}
          disabled={disabled}
        />
      ))}
      {children}
    </div>
  </>
);

export default RadioGroup;
