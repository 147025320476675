import he from "he";
import { MedicalTechnicalNotes } from "../../../core/api/objects/types";
import { useState } from "react";
import { MedicalTechnicalDetails } from "./MedicalTechnicalDetails";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";
import useTranslations from "../../../core/i18n/useTranslations";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";

export const MedicalTechnicalTable = ({
  notes,
  isMedical = true,
}: {
  notes: MedicalTechnicalNotes[];
  isMedical?: boolean;
}) => {
  const t = useTranslations();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  const [noteDetails, setNoteDetails] = useState<MedicalTechnicalNotes>();

  return (
    <>
      <MedicalTechnicalDetails
        details={noteDetails}
        onClose={() => setNoteDetails(undefined)}
        isMedical={isMedical}
      />
      <Table<MedicalTechnicalNotes>
        columns={[
          {
            header: t("Common:note"),
            fieldTemplate: (rowData) => (
              <TableCell value={he.decode(rowData.note)} />
            ),
          },
          {
            header: t("Common:medical_technical_admin"),
            fieldTemplate: (rowData) => <TableCell value={rowData.adminName} />,
          },
          {
            header: t("Common:medical_technical_time"),
            fieldTemplate: (rowData) => (
              <TableCell
                value={DateTimeHelper.formatDateTimeString(rowData.created)}
              />
            ),
          },
        ]}
        rowActions={[
          {
            icon: "eye",
            text: t("Objects:view_details"),
            iconSize: isMobile ? 16 : 24,
            iconVariant: isMobile ? "secondary" : "primary",
            onClick: async (rowData: any) => {
              setNoteDetails(rowData);
            },
          },
        ]}
        rowActionsFixed
        rowActionsColWidth={45}
        items={notes}
        hideEmptyMessage
        noRowsMessage={t("Objects:no_note")}
        showRowHover={!isMobile}
        showRowsActionsOnHover={!isMobile}
        withShowMore
      />
    </>
  );
};
