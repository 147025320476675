import {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import Popup from "reactjs-popup";
import classnames from "classnames";
import styles from "./TableRowSelectionPopup.module.css";
import { BatchSMSModal } from "../../../components/Object/BatchSMSModal";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { IDropdownItem } from "../Dropdown/DropdownItem";
import {
  BaseTableDataParams,
  BatchAllTableDataParams,
} from "../../../core/api/common_table";
import { deleteAllSubscriptions } from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import { notify } from "../Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import Icon from "../Icon";

export declare type AllTableRowSelectionPopupActions<_TRowData> = {
  open: (selectedItems: number) => void;
  close: (clear?: boolean) => void;
  toggle: () => void;
};

export interface AllTableRowSelectionPopupProps<_TRowData> {
  position?: "top center" | "bottom center";
  onClose: (text?: string) => void;
  tableParams: BaseTableDataParams;
}

const AllTableRowSelectionPopupInternal = <TRowData,>(
  {
    position = "bottom center",
    onClose,
    tableParams,
  }: AllTableRowSelectionPopupProps<TRowData>,
  ref: Ref<AllTableRowSelectionPopupActions<TRowData>>
) => {
  const { authenticatedRequest } = useUser();
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number>(0);
  const [selectedRowAction, setSelectedRowAction] = useState<number>(1);
  const [userTable, setUserTable] = useState<
    BatchAllTableDataParams | undefined
  >();

  const rowSelectionActionItems: IDropdownItem[] = [
    {
      id: 1,
      name: t("Objects:dropdown_cancel_subscription"),
      isSelected: selectedRowAction === 1,
    },
    {
      id: 2,
      name: t("Objects:dropdown_batch_sms"),
      isSelected: selectedRowAction === 2,
    },
  ];

  useImperativeHandle<
    AllTableRowSelectionPopupActions<TRowData>,
    AllTableRowSelectionPopupActions<TRowData>
  >(ref, () => {
    const actions: AllTableRowSelectionPopupActions<TRowData> = {
      open: function open(selectedItemsT) {
        if (selectedItemsT === 0) {
          setIsOpen(false);
          setSelectedItems(0);
        } else {
          setIsOpen(true);
          setSelectedItems(selectedItemsT);
        }
      },
      close: function close(clear?: boolean) {
        if (clear) {
          setSelectedItems(0);
        }
        setIsOpen(false);
      },
      toggle: function toggle() {
        setIsOpen(!isOpen);
      },
    };
    return actions;
  });

  useEffect(() => {
    if (tableParams) {
      const newUserTable = {
        selected: true,
        exact: false,
        nameonly: false,
        customers: tableParams.customers,
        start: tableParams.start,
        end: tableParams.end,
      } as any;
      (tableParams.filter || []).forEach((filter, index) => {
        newUserTable[`filterType${index === 0 ? "" : index + 1}`] = filter.id;
        newUserTable[`filterValue${index === 0 ? "" : index + 1}`] =
          filter.value;
      });
      if (tableParams.searchFilter!.value.length) {
        const defaultFilterLength = (tableParams.filter || []).length;
        if (tableParams.searchFilter!.id === -1) {
          newUserTable.q = tableParams.searchFilter!.value;
        } else {
          newUserTable[
            `filterType${
              defaultFilterLength === 0 ? "" : defaultFilterLength + 1
            }`
          ] = tableParams.searchFilter!.id;
          newUserTable[
            `filterValue${
              defaultFilterLength === 0 ? "" : defaultFilterLength + 1
            }`
          ] = tableParams.searchFilter!.value;
        }
      }
      setUserTable(newUserTable);
    }
  }, [tableParams]);

  return (
    <Popup
      open={isOpen}
      position={position}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      repositionOnResize={false}
      keepTooltipInside
      arrow={false}
      className={classnames({
        popupTableRowSelection: true,
        popupTableRowSelectionTopCenter: position === "top center",
        popupTableRowSelectionBottomCenter: position === "bottom center",
      })}
    >
      <div className={styles.popupHeader}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon name="x" size={22} onClick={onClose} />
          <Spacer size={8} />
          {`${selectedItems} ${t("Common:items_selected")}`}
        </div>
      </div>
      <div className={styles.popupBody}>
        <Row
          type="space"
          align="center"
          style={{ width: "100%", maxWidth: "900px", flexGrow: 1 }}
        >
          <Dropdown
            items={rowSelectionActionItems}
            bodyWidth="fit-content"
            placeholder={t("Transmitters:select_action")}
            selectedItem={rowSelectionActionItems.find((r) => r.isSelected)}
            onSelectItem={(item) => {
              setSelectedRowAction(+item.id);
            }}
            width="230px"
            maxVisible={6}
          />
          {selectedRowAction === 1 && (
            <Button
              variant="primary"
              text={`${t("Objects:apply_to_selection")} (${selectedItems})`}
              loading={isLoading}
              disabled={isLoading}
              onClick={async () => {
                if (!userTable) return;
                setIsLoading(true);

                try {
                  await deleteAllSubscriptions(
                    selectedItems,
                    userTable,
                    authenticatedRequest
                  );

                  notify({
                    message: t("Objects:all_subscription_cancel_success"),
                  });
                  onClose(t("Objects:batch_subscription_deletion"));
                } catch (error: any) {
                  notifyApiErrors(
                    error.response?.data?.errors,
                    error.response?.status
                  );
                }
                setIsLoading(false);
              }}
            />
          )}
          {selectedRowAction === 2 && (
            <>
              <BatchSMSModal
                isOpen={isSmsModalOpen}
                onClose={(text?: string) => {
                  setIsSmsModalOpen(false);
                  onClose(text);
                }}
                ids={[]}
                tableData={[]}
                userTable={userTable}
                limit={selectedItems}
              />
              <Button
                variant="primary"
                text={`${t("Objects:apply_to_selection")} (${selectedItems})`}
                onClick={() => {
                  setIsSmsModalOpen(true);
                }}
              />
            </>
          )}
        </Row>
      </div>
    </Popup>
  );
};

export const AllTableRowSelectionPopup = forwardRef(
  AllTableRowSelectionPopupInternal
) as <TRowData>(
  p: AllTableRowSelectionPopupProps<TRowData> & {
    ref?: Ref<AllTableRowSelectionPopupActions<TRowData>>;
  }
) => ReactElement;

export default AllTableRowSelectionPopup;
