import { Link } from "react-router-dom";
import useBranding from "../../../core/branding/useBranding";

import styles from "./LogoLink.module.css";

export default function LogoLink() {
  const branding = useBranding();
  return (
    <div className={styles.headerLink}>
      <Link to="/" className={styles.headerLink_logo}>
        <img
          alt="Logo"
          src={branding.currentLogo}
          height={
            branding.currentLogoSize?.height
              ? branding.currentLogoSize?.height
              : 32
          }
          width={
            branding.currentLogoSize?.width
              ? branding.currentLogoSize?.width
              : "auto"
          }
        />
      </Link>
    </div>
  );
}
