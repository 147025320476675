import { useState } from "react";
import { capitalize } from "lodash";
import useTranslations from "../../../../core/i18n/useTranslations";
import { deleteRespondentsFromGroup } from "../../../../core/api/responsecenters/responsecenters";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useUser from "../../../../core/user/useUser";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const DeleteRespondentsFromGroupModal = ({
  arcId,
  groupId,
  isOpen,
  ids,
  onClose,
  onDelete,
}: {
  arcId: number;
  groupId: number;
  isOpen: boolean;
  ids: number[];
  onClose: () => void;
  onDelete: (success: boolean) => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const type =
    ids.length > 1
      ? t("AlarmReceptions:respondent_from_group_type_multi")
      : t("AlarmReceptions:respondent_from_group_type");
  const title = t("Common:delete_modal_title").replace("{type}", type);
  const confirmMessage =
    ids.length > 1
      ? t("Common:delete_modal_confirm_batch")
          .replace("{count}", `${ids.length}`)
          .replace("{type}", type)
      : t("Common:delete_modal_confirm").replace("{type}", type);
  const successMessage = capitalize(
    t("Common:delete_success").replace("{type}", type)
  );

  const onInternalDelete = async () => {
    setIsLoading(true);
    let success = true;
    await deleteRespondentsFromGroup(
      arcId,
      groupId,
      ids,
      authenticatedRequest
    ).catch((error) => {
      success = false;
      notifyApiErrors(error.response?.data?.errors, error.response?.status);
    });
    setIsLoading(false);

    if (success) {
      notify({
        message: successMessage,
      });
    }
    onDelete(success);
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={onClose}
      isOpen={isOpen}
      header={title}
      loading={isLoading}
      submitBtn={{
        text: t("Common:delete"),
        onClick: onInternalDelete,
        variant: "destructive",
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
      contentStyle={{ width: "848px" }}
    >
      <p style={{ color: "var(--Grey-600)" }}>{confirmMessage}</p>
    </PrimeModal>
  );
};
