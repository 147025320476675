import Flag from "react-country-flag";
import Icon from "../../Icon";
import styles from "../Menu.module.css";
import PrimeTooltip from "../../PrimeTooltip/PrimeTooltip";

export interface PopupContextMenuLinkButtonProps {
  id?: string;
  text: any;
  icon?: string;
  flag?: string;
  onClick?: (e?: Event) => void;
  disabled?: boolean;
  withTooltip?: boolean;
}

export const PopupContextMenuLinkButton = ({
  id,
  text,
  icon = undefined,
  flag = undefined,
  onClick = undefined,
  disabled = false,
  withTooltip,
}: PopupContextMenuLinkButtonProps) => (
  <>
    {withTooltip && <PrimeTooltip target={`#${id}`} />}
    <button
      id={id}
      data-pr-tooltip={text}
      type="button"
      className={styles.popupContextMenuLinkButton}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {icon ? <Icon name={icon} size={16} color="Grey-400" /> : null}
      {flag ? (
        <Flag
          svg
          countryCode={flag}
          className={styles.dropdownFlag}
          aria-label="language"
        />
      ) : null}
      <span className={styles.popupContextMenuLinkButtonText}>{text}</span>
    </button>
  </>
);
