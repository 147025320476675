import { Controller, useFormContext } from "react-hook-form";
import {
  Column,
  FillTwoColRow,
  Row,
  Spacer,
  TwoColGrid,
} from "../../../components/Layout/Layout";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import useTranslations from "../../../core/i18n/useTranslations";
import { IDropdownItem } from "../../../ui-lib/components/Dropdown/DropdownItem";
import InputContainer from "../../../ui-lib/components/Inputs/InputContainer";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import Divider from "../../../ui-lib/components/Divider/Divider";
import {
  alarmReceptionprotocols,
  alarmSorts,
  dtmpDigits,
  userSearchTypes,
} from "../../../core/constants/alarmReceptions";
import { isEmpty } from "../../../core/helpers/helpers";
import ToggleSwitch from "../../../ui-lib/components/Checkbox/ToggleSwitch";
import Icon from "../../../ui-lib/components/Icon";
import AlarmReceptionSettings from "./AlarmReceptionSettings";
import { validatePhoneNumber } from "../../../core/helpers/validation";
import { Role, RoleFeature } from "../../../components/RoleFeature";
import CollapsibleBlock from "../../../ui-lib/components/CollapsibleBlock/CollapsibleBlock";

export const AdvancedSettings = ({ id }: { id: number }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const t = useTranslations();

  if (isEmpty(getValues("defaultAlarmListSort"))) {
    setValue("defaultAlarmListSort", alarmSorts[0]);
  }
  const protocolItems: IDropdownItem[] = alarmReceptionprotocols.map(
    (index) => ({
      id: index,
      name: t(`AlarmReceptions:ProtocolValue_${index}`),
      isSelected: watch("protocol") === index,
    })
  );
  const defaultAlarmSortItems: IDropdownItem[] = alarmSorts.map((sort) => ({
    id: sort,
    name: t(`AlarmReceptions:DefaultAlarmListSort_${sort}`),
    isSelected: watch("defaultAlarmListSort") === sort,
  }));
  const dtmpDigitSelectItems: IDropdownItem[] = dtmpDigits.map((digit) => ({
    id: digit,
    name: digit,
    isSelected: watch("dtmfSendingDigit") === digit,
  }));

  const userSearchTypeItems: IDropdownItem[] = userSearchTypes.map((index) => ({
    id: index,
    name: t(`AlarmReceptions:UserSearchType_${index}`),
    isSelected: watch("userSearchType") === index,
  }));

  const hasAllTwilioSelected = () => {
    const hasAccountSid = !isEmpty(getValues("twilioAccountSid"));
    const hasAppSid = !isEmpty(getValues("twilioAppSid"));
    const hasAuthToken = !isEmpty(getValues("twilioAuthToken"));
    return (
      (hasAccountSid && hasAppSid && hasAuthToken) ||
      (!hasAccountSid && !hasAppSid && !hasAuthToken)
    );
  };
  const othersOrEmpty = () => {
    const hasPhone = !isEmpty(getValues("twilioTelephoneNumber"));
    const hasAccountSid = !isEmpty(getValues("twilioAccountSid"));
    const hasAppSid = !isEmpty(getValues("twilioAppSid"));
    const hasAuthToken = !isEmpty(getValues("twilioAuthToken"));
    return (
      hasAccountSid ||
      hasAppSid ||
      hasAuthToken ||
      (!hasPhone && !hasAccountSid && !hasAppSid && !hasAuthToken)
    );
  };
  const emptyOrPhoneNumber = (value?: any) =>
    isEmpty(value) || validatePhoneNumber(value);

  const isNumber = (value: number | string): boolean => {
    return (
      value !== null && value !== undefined && /^-?\d*$/.test(value as string)
    );
  };

  return (
    <Column type="top" align="start" className="w-75">
      <TwoColGrid style={{ alignItems: "end" }}>
        <RoleFeature requires={[Role.EditAlarmReceptionAdvancedSettings]}>
          <Controller
            name="alarmTransform"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_alarmtransform_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="alarmTransformCare"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_alarmtransformcare_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="alarmTransformProperty"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_alarmtransformproperty_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="alarmTransformMobile"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_alarmtransformmobile_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="workerQueue"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("AlarmReceptions:advanced_settings_workerqueue_label")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="protocol"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputContainer
                required
                label={
                  <label htmlFor="alarmreception_protocoltype">
                    {t("AlarmReceptions:advanced_settings_protocol_label")}
                  </label>
                }
                input={
                  <Dropdown
                    id="alarmreception_protocoltype"
                    items={protocolItems}
                    placeholder={t(
                      "AlarmReceptions:advanced_settings_protocol_placeholder"
                    )}
                    selectedItem={protocolItems.find((p) => p.isSelected)}
                    onSelectItem={(item) => {
                      setValue(field.name, +item.id);
                    }}
                    invalid={errors.protocol !== undefined}
                    validationError={
                      errors.protocol &&
                      t(
                        "AlarmReceptions:advanced_settings_protocol_validation_required"
                      )
                    }
                  />
                }
              />
            )}
          />
          <Controller
            name="useECSms1"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_smsalarmnumber_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Spacer size={0} />

          <Controller
            name="integrationSnsArn"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_intergrationsns_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="integrationAdditionalInfo"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:advanced_settings_integrationadditionalinfo_label"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="adminServerUrl"
            control={control}
            rules={{
              validate: (value) =>
                isEmpty(value) ||
                value.startsWith("https://") ||
                value.startsWith("http://"),
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t(
                  "AlarmReceptions:advanced_settings_adminserverurl_label"
                )}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.adminServerUrl && t("Errors:url_invalid")
                }
              />
            )}
          />
          <Controller
            name="dtmfSendingDigit"
            control={control}
            render={({ field }) => (
              <InputContainer
                label={
                  <label htmlFor="alarmreception_protocoltype">
                    {t("AlarmReceptions:advanced_settings_dtmfdigit_label")}
                  </label>
                }
                input={
                  <Dropdown
                    id="alarmreception_protocoltype"
                    items={dtmpDigitSelectItems}
                    placeholder={t(
                      "AlarmReceptions:advanced_settings_dtmfdigit_placeholder"
                    )}
                    selectedItem={dtmpDigitSelectItems.find(
                      (p) => p.isSelected
                    )}
                    onSelectItem={(item) => {
                      setValue(field.name, item.id.toString());
                    }}
                  />
                }
              />
            )}
          />
          <Controller
            name="minutesOfAlarmHistory"
            control={control}
            rules={{
              min: -10,
              max: 1440,
              pattern: {
                value: /^-?\d+$/,
                message: t("Errors:digits_only"),
              },
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t(
                  "AlarmReceptions:advanced_settings_minutesofalarmhistory_label"
                )}
                placeholder="-10...1440"
                pinnedText="min"
                pinnedTextPosition="right"
                value={isNumber(field.value) ? `${field.value}` : ""}
                onChange={field.onChange}
                validationError={
                  errors.minutesOfAlarmHistory && t("Errors:input_format")
                }
              />
            )}
          />
          <Controller
            name="minutesOfUserAlarmHistory"
            control={control}
            rules={{
              min: -10,
              max: 20160,
              pattern: {
                value: /^-?\d+$/,
                message: t("Errors:digits_only"),
              },
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t(
                  "AlarmReceptions:advanced_settings_minutesofobjectalarmhistory_label"
                )}
                placeholder="-10...20160"
                pinnedText="min"
                pinnedTextPosition="right"
                value={isNumber(field.value) ? `${field.value}` : ""}
                onChange={field.onChange}
                validationError={
                  errors.minutesOfUserAlarmHistory && t("Errors:input_format")
                }
              />
            )}
          />
          <Controller
            name="userSearchType"
            control={control}
            render={({ field }) => (
              <InputContainer
                label={
                  <label htmlFor="alarmreception_usersearchtype">
                    {t(
                      "AlarmReceptions:advanced_settings_objectsearchtype_label"
                    )}
                  </label>
                }
                input={
                  <Dropdown
                    id="alarmreception_usersearchtype"
                    items={userSearchTypeItems}
                    placeholder={t(
                      "AlarmReceptions:advanced_settings_objectsearchtype_placeholder"
                    )}
                    selectedItem={userSearchTypeItems.find((p) => p.isSelected)}
                    onSelectItem={(item) => {
                      setValue(field.name, +item.id);
                    }}
                  />
                }
              />
            )}
          />
          <Spacer size={0} />
          <Controller
            name="shareUsersWithRelatedResponseCenters"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_sharewithrelated_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <FillTwoColRow>
            <CollapsibleBlock
              title={t("AlarmReceptions:advanced_settings_twilio_panel_label")}
            >
              <TwoColGrid>
                <Controller
                  name="twilioAccountSid"
                  control={control}
                  rules={{ validate: hasAllTwilioSelected }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_twilioaccountsid_label"
                      )}
                      description={t(
                        "AlarmReceptions:advanced_settings_twilioallthree_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.twilioAccountSid &&
                        t("Errors:twilio_all_three_required")
                      }
                    />
                  )}
                />
                <Controller
                  name="twilioAppSid"
                  control={control}
                  rules={{ validate: hasAllTwilioSelected }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_twilioappsid_label"
                      )}
                      description={t(
                        "AlarmReceptions:advanced_settings_twilioallthree_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.twilioAppSid &&
                        t("Errors:twilio_all_three_required")
                      }
                    />
                  )}
                />
                <Controller
                  name="twilioAuthToken"
                  control={control}
                  rules={{ validate: hasAllTwilioSelected }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_twilioauthtoken_label"
                      )}
                      description={t(
                        "AlarmReceptions:advanced_settings_twilioallthree_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.twilioAuthToken &&
                        t("Errors:twilio_all_three_required")
                      }
                    />
                  )}
                />
                <Controller
                  name="twilioTelephoneNumber"
                  control={control}
                  rules={{
                    validate: {
                      others: othersOrEmpty,
                      phone: emptyOrPhoneNumber,
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_twilitelephone_label"
                      )}
                      description={t(
                        "AlarmReceptions:advanced_settings_twilioemptyifothersempty_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        (errors.twilioTelephoneNumber &&
                          errors.twilioTelephoneNumber.type === "phone" &&
                          t("Errors:ValidationHint_TelephoneNumber")) ||
                        (errors.twilioTelephoneNumber &&
                          errors.twilioTelephoneNumber.type === "others" &&
                          t("Errors:twilio_others_selected_or_empty")) ||
                        undefined
                      }
                    />
                  )}
                />
              </TwoColGrid>
            </CollapsibleBlock>
          </FillTwoColRow>
          <FillTwoColRow>
            <Divider />
          </FillTwoColRow>
          <Controller
            name="allowAlarmListSort"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_allowalarmlistsort_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Spacer size={0} />
          <Controller
            name="defaultAlarmListSortAscending"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_defaultalarmlistsortasc_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Spacer size={0} />
          <Controller
            name="defaultAlarmListSort"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputContainer
                required
                label={
                  <label htmlFor="alarmreception_defaultalarmlistsort">
                    {t(
                      "AlarmReceptions:advanced_settings_defaultalarmlistsort_label"
                    )}
                  </label>
                }
                input={
                  <Dropdown
                    id="alarmreception_defaultalarmlistsort"
                    items={defaultAlarmSortItems}
                    placeholder={t(
                      "AlarmReceptions:advanced_settings_defaultalarmlistsort_placeholder"
                    )}
                    selectedItem={defaultAlarmSortItems.find(
                      (p) => p.isSelected
                    )}
                    onSelectItem={(item) => {
                      setValue(field.name, item.id.toString());
                    }}
                    invalid={errors.DefaultAlarmListSort !== undefined}
                    validationError={
                      errors.defaultAlarmListSort &&
                      t(
                        "AlarmReceptions:advanced_settings_defaultalarmlistsort_validation_required"
                      )
                    }
                  />
                }
              />
            )}
          />
          <Spacer size={0} />

          <Controller
            name="deactivateAlarmsOnDelivery"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_deactivatealarmdelivery_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="handleServiceAlarms"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_handleservicealarms_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="allowLatestAlarmLink"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_allowlatestalarmlink_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="useSingleList"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_usesinglelist_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="allowShowSettings"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_allowshowsettings_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="requirePresenceForClear"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_requirepresenceclear_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="restrictOperatorLoginToAdmin"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_requirelogintoadmin_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="backToListOnAccept"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_backtolistaccept_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="backToListAllways"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_backtolistalways_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="operatorInactivityCheck"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_operatorinactivitycheck_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </RoleFeature>
        <RoleFeature requires={[Role.AlarmReceptionCalendarEdit]}>
          <Controller
            name="allowCalendars"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_allowcalendars_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </RoleFeature>
        <RoleFeature requires={[Role.EditAlarmReceptionAdvancedSettings]}>
          <Controller
            name="autoAnswerIncommingVoiceCalls"
            control={control}
            render={({ field }) => (
              <Checkbox
                style={{ paddingLeft: 0 }}
                label={t(
                  "AlarmReceptions:advanced_settings_autoanswervoicecalls_label"
                )}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Spacer size={0} />
          <FillTwoColRow>
            <Divider />
          </FillTwoColRow>
          <FillTwoColRow>
            <CollapsibleBlock
              title={t(
                "AlarmReceptions:advanced_settings_responseappsettings_panel_label"
              )}
            >
              <TwoColGrid>
                <FillTwoColRow style={{ marginTop: "16px" }}>
                  <Controller
                    name="mobileApplicationSettings.controlNotifications"
                    control={control}
                    render={({ field }) => (
                      <ToggleSwitch
                        checked={field.value}
                        onChange={field.onChange}
                        name={field.name}
                        id={field.name}
                        label={t(
                          "AlarmReceptions:advanced_settings_controlnotifications_label"
                        )}
                      />
                    )}
                  />
                  <Spacer size={16} />

                  {watch("mobileApplicationSettings.controlNotifications") && (
                    <TwoColGrid>
                      <Controller
                        name="mobileApplicationSettings.notificationSettings.onlyVibrateDuringCall"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            style={{ paddingLeft: 0 }}
                            label={t(
                              "AlarmReceptions:advanced_settings_onlyvibrateduringcall_label"
                            )}
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Spacer size={0} />
                      <Column align="start">
                        <Row>
                          <Icon name="trending-down" />
                          <Spacer size={8} />
                          <span className="labelRowLabel">
                            {t(
                              "AlarmReceptions:advanced_settings_lowpriorityalarms_label"
                            )}
                          </span>
                        </Row>
                        <Controller
                          name="mobileApplicationSettings.notificationSettings.lowPrioAlarmVibration"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              style={{ paddingLeft: 0, marginTop: "16px" }}
                              label={t(
                                "AlarmReceptions:advanced_settings_lowprioalarmvibration_label"
                              )}
                              checked={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        <Controller
                          name="mobileApplicationSettings.notificationSettings.lowPrioAlarmCameraFlash"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              style={{ paddingLeft: 0, marginTop: "16px" }}
                              label={t(
                                "AlarmReceptions:advanced_settings_lowprioalarmcameraflash_label"
                              )}
                              checked={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        <Controller
                          name="mobileApplicationSettings.notificationSettings.lowPrioAlarmTurnOffDNDInCaseOfAlarm"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              style={{ paddingLeft: 0, marginTop: "16px" }}
                              label={t(
                                "AlarmReceptions:advanced_settings_lowprioalarmturnofdndwhenalarm_label"
                              )}
                              checked={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </Column>
                      <Column align="start">
                        <Row>
                          <Icon name="trending-up" />
                          <Spacer size={8} />
                          <span className="labelRowLabel">
                            {t(
                              "AlarmReceptions:advanced_settings_highpriorityalarms_label"
                            )}
                          </span>
                        </Row>
                        <Controller
                          name="mobileApplicationSettings.notificationSettings.highPrioAlarmVibration"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              style={{ paddingLeft: 0, marginTop: "16px" }}
                              label={t(
                                "AlarmReceptions:advanced_settings_highprioalarmvibration_label"
                              )}
                              checked={field.value}
                              onChange={field.onChange}
                              htmlFor="mobileApplicationSettings.notificationSettings.highPrioAlarmVibration"
                            />
                          )}
                        />
                        <Controller
                          name="mobileApplicationSettings.notificationSettings.highPrioAlarmCameraFlash"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              style={{ paddingLeft: 0, marginTop: "16px" }}
                              label={t(
                                "AlarmReceptions:advanced_settings_highprioalarmcameraflash_label"
                              )}
                              checked={field.value}
                              onChange={field.onChange}
                              htmlFor="mobileApplicationSettings.notificationSettings.highPrioAlarmCameraFlash"
                            />
                          )}
                        />
                        <Controller
                          name="mobileApplicationSettings.notificationSettings.highPrioAlarmTurnOffDNDInCaseOfAlarm"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              style={{ paddingLeft: 0, marginTop: "16px" }}
                              label={t(
                                "AlarmReceptions:advanced_settings_highprioalarmturnofdndwhenalarm_label"
                              )}
                              checked={field.value}
                              onChange={field.onChange}
                              htmlFor="mobileApplicationSettings.notificationSettings.highPrioAlarmTurnOffDNDInCaseOfAlarm"
                            />
                          )}
                        />
                      </Column>
                    </TwoColGrid>
                  )}
                </FillTwoColRow>
              </TwoColGrid>
            </CollapsibleBlock>
          </FillTwoColRow>
          <FillTwoColRow>
            <Divider />
          </FillTwoColRow>
          <FillTwoColRow>
            <RoleFeature requires={[Role.EmergencyCenterSettings]}>
              <AlarmReceptionSettings id={id} />
            </RoleFeature>
          </FillTwoColRow>
        </RoleFeature>
      </TwoColGrid>
    </Column>
  );
};
