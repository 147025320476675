import { useEffect, useRef, useState } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import Button, { ButtonVariant } from "../../ui-lib/components/Button/Button";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import {
  KnownLocationListModel,
  NewLocationResponseData,
} from "../../core/api/locations/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import { EditLocationActions } from "./LocationEditForm";
import { KnownLocation } from "../../core/api/objects/types";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import Table from "../../ui-lib/components/Tables/Table";
import {
  addLocationToObject,
  getKnownLocations,
} from "../../core/api/locations/locations";
import useUser from "../../core/user/useUser";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { CreateNewLocationModal } from "./CreateNewLocationModal";

export const CreateLocationModal = ({
  onSubmit,
  onClose,
  modalTitle,
  variant = "primary",
  modalButtonIcon,
  saveButtonTitle,
  modalButtonTitle,
  linkedLocations = [],
  objectId,
}: {
  onSubmit?: (result?: NewLocationResponseData) => void;
  onClose?: () => void;
  modalTitle: string;
  variant: ButtonVariant;
  modalButtonIcon?: string;
  saveButtonTitle: string;
  modalButtonTitle: string;
  linkedLocations: KnownLocation[];
  objectId?: string;
}) => {
  const t = useTranslations();
  const editLocationRef = useRef<EditLocationActions>(null);
  const { authenticatedRequest, config } = useUser();
  const viserPermission = config?.show.includes("SensioHideFields");
  const [isNewLocationOpen, setIsNewLocationOpen] = useState(false);
  const [isLocationFromStockOpen, setIsLocationFromStockOpen] = useState(false);
  const [locations, setLocations] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLocationAdded, setIsLocationAdded] = useState(false);
  const [preselectedLocations, setPreselectedLocations] = useState<number[]>(
    linkedLocations.map((loc) => loc.knownLocationId)
  );
  const [selectedLocationsId, setSelectedLocationsId] = useState<
    number | undefined
  >();

  const closeModal = () => {
    setIsLocationAdded(false);
    setIsLocationFromStockOpen(false);
    setSearchQuery("");
    editLocationRef.current?.reset();
    if (isLocationAdded) {
      onClose?.();
    }
  };

  const fetchLocations = async () => {
    try {
      setIsLoading(true);
      const results = await getKnownLocations(
        { q: searchQuery, o: "ORGANIZATION_ASC", page: 1, pageSize: 200 },
        authenticatedRequest
      );
      setLocations(results.knownLocations);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const linkLocationFromStock = async () => {
    try {
      setIsLoading(true);
      await addLocationToObject(
        objectId!,
        selectedLocationsId!,
        authenticatedRequest
      );
      setPreselectedLocations([...preselectedLocations, selectedLocationsId!]);
      setSelectedLocationsId(undefined);
      if (!isLocationAdded) {
        setIsLocationAdded(true);
      }
      notify({
        message: t("Locations:location_added"),
      });
      setIsLocationFromStockOpen(false);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [searchQuery]);

  useEffect(() => {
    if (isLocationFromStockOpen) {
      setPreselectedLocations(
        linkedLocations.map((loc) => loc.knownLocationId)
      );
    }
  }, [isLocationFromStockOpen]);

  return (
    <>
      <Button
        variant={variant || "primary"}
        text={modalButtonTitle}
        image={modalButtonIcon}
        onClick={() => {
          setIsLocationFromStockOpen(true);
        }}
      />
      <StyledModal
        onClose={closeModal}
        isOpen={isLocationFromStockOpen}
        modalTitle={modalTitle}
        contentCss={{ width: "100%" }}
        onCancelBtnClick={closeModal}
        customFooter={() => (
          <Row type="left">
            <Button
              loading={isLoading}
              variant="primary"
              disabled={!selectedLocationsId || isLoading}
              text={saveButtonTitle}
              onClick={linkLocationFromStock}
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={closeModal}
            />
          </Row>
        )}
        closeOnDocumentClick={false}
      >
        <p>{t("Locations:add_modal_description")}</p>

        <Spacer size={16} />

        <SearchInput
          placeholder={t("Locations:search_stock_location_placeholder")}
          value={searchQuery ?? ""}
          limit={1}
          onChange={(value) => {
            setSearchQuery(value);
          }}
        />

        <Spacer size={36} />

        <Row type="space" align="center">
          <h2>{t("Locations:locations_in_stock")}</h2>
          {!viserPermission && (
            <Button
              image="location-marker"
              variant="secondary"
              text={t("Locations:add_location")}
              onClick={() => {
                setIsNewLocationOpen(true);
              }}
            />
          )}

          <CreateNewLocationModal
            onClose={() => {
              setIsNewLocationOpen(false);
            }}
            onSubmit={onSubmit}
            isOpened={isNewLocationOpen}
          />
        </Row>
        <Spacer size={16} />
        <Table<KnownLocationListModel>
          columns={[
            {
              header: t("Locations:table_columns_Description"),
              fieldTemplate: (rowData) => (
                <TableCell value={rowData.description} />
              ),
            },
            {
              header: t("Locations:table_columns_KLType"),
              fieldTemplate: (rowData) => (
                <TableCell
                  leftIcon="clipboard-list"
                  iconColor="Grey-300"
                  iconSize={16}
                  value={t(`Locations:BeaconType_${rowData.klType}`)}
                />
              ),
            },
            {
              header: t("Locations:table_columns_CustomerName"),
              fieldTemplate: (rowData) => (
                <TableCell
                  leftIcon="office-building"
                  iconColor="Grey-300"
                  iconSize={16}
                  value={rowData.organizationName}
                />
              ),
            },
            {
              header: t("Locations:table_columns_Marking"),
              fieldTemplate: (rowData) =>
                rowData.marking ? (
                  <TableCell
                    leftIcon="tag"
                    iconColor="Grey-300"
                    iconSize={16}
                    value={rowData.marking}
                  />
                ) : null,
            },
            {
              editable: true,
              fieldTemplate: (rowData) => (
                <Checkbox
                  disabled={preselectedLocations.indexOf(rowData.id) !== -1}
                  checked={
                    rowData.id === selectedLocationsId ||
                    preselectedLocations.indexOf(rowData.id) !== -1
                  }
                  label=""
                  innerStyle={{ justifyContent: "flex-end" }}
                  onChange={() => {
                    setSelectedLocationsId(
                      selectedLocationsId === rowData.id
                        ? undefined
                        : rowData.id
                    );
                  }}
                />
              ),
              style: {
                width: "32px",
              },
            },
          ]}
          items={locations}
          rowActionContextMenu
          hideEmptyMessage
          noRowsMessage={t("Locations:locations_table_not_instock")}
          showRowHover
          withShowMore
        />
      </StyledModal>
    </>
  );
};
