import { Controller, useFormContext } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../../components/Layout/Layout";
import { ResponseCenterAlarmCodeDetails } from "../../../../../core/api/responsecenters/types";
import useTranslations from "../../../../../core/i18n/useTranslations";
import CollapsibleBlock from "../../../../../ui-lib/components/CollapsibleBlock/CollapsibleBlock";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";
import TextArea from "../../../../../ui-lib/components/Inputs/Textarea";
import TextInput from "../../../../../ui-lib/components/Inputs/TextInput";
import MultiCodeInput from "../../../../../ui-lib/components/MultiInput/MultiCodeInput";
import { CheckboxControl } from "../../AlarmHandling/Controls/CheckboxControl";
import { TimeoutControl } from "../../AlarmHandling/Controls/TimeoutControl";

const forceCallHandlingOptions = [0, 1, 2];

export const AdvancedSettings = () => {
  const t = useTranslations();

  const { control, setValue } =
    useFormContext<ResponseCenterAlarmCodeDetails>();

  return (
    <CollapsibleBlock withScroll title={t("Common:advanced_options")}>
      <Spacer size={32} />

      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextInput
            label={t(
              "AlarmReceptions:alarm_handling_alarm_codes_input_comment"
            )}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Spacer size={16} />

      <Controller
        name="deactivateAlarmsOfCodes"
        control={control}
        render={({ field }) => {
          const length = field.value?.length ?? 0;
          const values =
            length > 0
              ? field.value?.split(",").map((stringValue) => stringValue)
              : [];

          return (
            <MultiCodeInput
              label={t(
                "AlarmReceptions:alarm_handling_alarm_codes_input_deactivate_alarm_of_codes"
              )}
              placeholder={t("Common:input_multi_code_placeholder")}
              values={values}
              setValues={(updated) => {
                const value = updated.join(",");
                setValue(field.name, value, { shouldDirty: true });
              }}
            />
          );
        }}
      />

      <Spacer size={16} />

      <Row align="start">
        <Column>
          <Controller
            name="deactivateAfterPresenceTimeout"
            control={control}
            render={({ field }) => (
              <TimeoutControl
                label={t(
                  "AlarmReceptions:alarm_handling_alarm_codes_input_deactivate_timeout"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column style={{ width: "100%" }} />
      </Row>

      <Spacer size={16} />

      <Row>
        <CheckboxControl
          name="forceDeactivated"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_force_deactivated"
          )}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="suppressSmsNotification"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_suppress_sms"
          )}
        />
      </Row>
      <Spacer size={8} />
      <Row>
        <CheckboxControl
          name="suppressEmailNotification"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_suppress_email"
          )}
        />
        <Spacer size={32} />
        <CheckboxControl
          name="suppressEcInteraction"
          label={t(
            "AlarmReceptions:alarm_handling_alarm_code_input_suprress_ec"
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Column>
          <Controller
            name="useTransform"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:alarm_handling_alarm_codes_input_use_transform"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column>
          <Controller
            name="useMRTransform"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t(
                  "AlarmReceptions:alarm_handling_alarm_codes_input_use_mr_transform"
                )}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Column>
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Column>
          <Controller
            name="forceCallHandling"
            control={control}
            render={({ field }) => {
              const dropdownItems = forceCallHandlingOptions.map((id) => ({
                id,
                name: t(
                  `AlarmReceptions:alarm_handling_alarm_codes_input_force_type_${id}`
                ),
                isSelected: id === field.value,
              }));

              return (
                <Dropdown
                  title={t(
                    "AlarmReceptions:alarm_handling_alarm_codes_input_force_type"
                  )}
                  width="100%"
                  selectedItem={dropdownItems.find((i) => i.isSelected)}
                  withClearItem
                  items={dropdownItems}
                  onSelectItem={(item) => {
                    const value = forceCallHandlingOptions.find(
                      (id) => id === item.id
                    );
                    setValue(field.name, value);
                  }}
                />
              );
            }}
          />
        </Column>
        <Spacer size={32} />
        <Column style={{ width: "100%" }} />
      </Row>

      <Spacer size={16} />

      <Controller
        name="additionalInfo"
        control={control}
        render={({ field }) => (
          <TextArea
            label={t(
              "AlarmReceptions:alarm_handling_alarm_codes_input_additional_info"
            )}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </CollapsibleBlock>
  );
};
