import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDebounce } from "../../../core/hooks/useDebounce";
import useTranslations from "../../../core/i18n/useTranslations";
import Icon from "../Icon";
import { TextInput } from "./TextInput";

const DEFAULT_LIMIT = 4;
const DEFAULT_DEBOUNCE = 600;

// Effect that only triggers when deps are updated, not on mount
function useDidUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
  }, deps);
}

type OnChangeFunction = (searchTerm: string) => void;

export interface SearchInputProps {
  label?: string;
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  value?: string;
  onChange: OnChangeFunction;

  limit?: number;
  /** When set, onChange will be fired only when debounceTimeout ms passed since last user input */
  debounceTimeout?: number;
}

/** Text input sharpened for search */
export const SearchInput = ({
  label = "",
  placeholder = "",
  hint = "",
  disabled = false,
  value,
  onChange,
  limit = DEFAULT_LIMIT,
  debounceTimeout = DEFAULT_DEBOUNCE,
}: SearchInputProps) => {
  const t = useTranslations();

  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useDidUpdateEffect(() => {
    if (
      debouncedSearchTerm?.length >= limit ||
      debouncedSearchTerm?.length === 0
    ) {
      onChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <TextInput
      inputClassName="truncateText"
      dataCy="search_input"
      dataCyBtn="search_input_button"
      type="text"
      label={label}
      iconLabel={t("Common:search")}
      placeholder={placeholder}
      hint={hint}
      required={false}
      disabled={disabled}
      value={searchTerm}
      onIconClick={value?.length ? () => setSearchTerm("") : undefined}
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      renderIcon={() => (
        <Icon name={value?.length ? "x" : "search"} size={24} />
      )}
      iconPosition="right"
    />
  );
};
export default SearchInput;
