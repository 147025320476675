import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { Spacer } from "../Layout/Layout";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import TextArea from "../../ui-lib/components/Inputs/Textarea";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { batchSms, sendSMSToAllObjects } from "../../core/api/objects/objects";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { RowData } from "../../pages/Objects/ObjectsTable";
import { BatchAllTableDataParams } from "../../core/api/common_table";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const BatchSMSModal = ({
  isOpen,
  onClose,
  ids,
  tableData,
  userTable = undefined,
  limit,
}: {
  isOpen: boolean;
  onClose: (text?: string) => void;
  ids: string[];
  tableData: RowData[];
  userTable?: BatchAllTableDataParams;
  limit?: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [smsLength, setSmsLength] = useState(0);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ senderId: string; longSms: string }>({
    defaultValues: {
      senderId: "Skyresponse",
      longSms: "",
    },
  });

  const getTableNameById = (id: string) => {
    const row = tableData.find((r) => r.id === id);
    return row ? row.Name : "";
  };

  const onSubmit = async (data: { senderId: string; longSms: string }) => {
    try {
      setIsLoading(true);
      if (!limit && !userTable) {
        const promisses = ids.map((id) =>
          batchSms(id, data, authenticatedRequest)
            .then(() => {
              notify({
                message: `${t("Objects:sms_send_successfully").replace(
                  "{0}",
                  getTableNameById(id)
                )}`,
              });
            })
            .catch(() => {
              notify({
                variant: "error",
                message: `${t("Objects:sms_send_error").replace(
                  "{0}",
                  getTableNameById(id)
                )}`,
              });
            })
        );
        await Promise.any(promisses);
      } else {
        await sendSMSToAllObjects(
          data,
          limit!,
          userTable!,
          authenticatedRequest
        );
        notify({
          message: t("Objects:sms_send_successfully_all"),
        });
      }
      reset();
      onClose(t("Objects:batch_sms_sending"));
      setSmsLength(0);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      loading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      fixedWidth="848"
      header={t("Objects:send_sms")}
      submitBtn={{
        text: `${t("Objects:send_sms_to_selection")} (${ids.length || limit})`,
        onClick: handleSubmit(onSubmit),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:send_sms_subtitle")}
      </p>

      <Spacer size={16} />

      <Controller
        name="senderId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextInput
            required
            inputRef={field.ref}
            label={t("Common:sender")}
            value={field.value}
            onChange={field.onChange}
            validationError={
              errors.senderId && t("Errors:input_field_required")
            }
          />
        )}
      />
      <Spacer size={16} />
      <Controller
        name="longSms"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextArea
            required
            inputRef={field.ref}
            label={t("Common:message")}
            onChange={(item) => {
              field.onChange(item);
              setSmsLength(item.target.value.length);
            }}
            validationError={errors.longSms && t("Errors:input_field_required")}
            value={field.value}
          />
        )}
      />
      <p
        style={{
          color: smsLength === 0 ? "var(--Grey-500)" : "var(--Primary-600)",
        }}
      >
        {`${smsLength} ${t("Common:characters_label")} / ${
          smsLength === 0 ? "0" : "1"
        } ${t("Common:labels_sms")}`}
      </p>
    </PrimeModal>
  );
};
