import configApi, { GetConfigResponse } from "../api/config";
import User from "./User";

const STORAGE_KEY = "auth-token";

interface TokenData {
  token: string;
  refreshToken: string;
  accountId: number;
  guiConfig: GetConfigResponse;
  issuedAt: number;
  expiresIn: number;
  unImpersonateName?: string;
  dateFormat?: string;
}

const getStoredToken = () => {
  const value = localStorage.getItem(STORAGE_KEY);

  return value ? (JSON.parse(value) as TokenData) : undefined;
};

const storeToken = ({
  token,
  refreshToken,
  accountId,
  issuedAt,
  expiresIn,
  guiConfig,
  unImpersonateName,
  dateFormat,
}: {
  token: string;
  refreshToken: string;
  accountId: number;
  issuedAt?: number;
  expiresIn: number;
  guiConfig: GetConfigResponse;
  unImpersonateName?: string;
  dateFormat?: string;
}) => {
  const data: TokenData = {
    token,
    refreshToken,
    accountId,
    issuedAt: issuedAt || Date.now(),
    expiresIn,
    guiConfig,
    unImpersonateName,
    dateFormat,
  };

  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));

  return data;
};

const updateGuiConfig = async (user: User) => {
  const storedData = getStoredToken();
  const guiConfig = await configApi.getConfig({
    token: storedData?.token!,
  });
  storeToken({
    token: storedData?.token!,
    refreshToken: storedData?.refreshToken!,
    accountId: storedData?.accountId!,
    expiresIn: storedData?.expiresIn!,
    guiConfig,
    unImpersonateName: storedData?.unImpersonateName!,
    dateFormat: storedData?.dateFormat,
  });
  user.config = guiConfig;
  window.location.reload();
};

const clearStoredToken = () => localStorage.removeItem(STORAGE_KEY);

const needsRefresh = ({
  issuedAt,
  expiresIn,
  now,
}: {
  issuedAt: number;
  expiresIn: number;
  now: number;
}) => now - issuedAt >= (expiresIn / 3) * 2;

export {
  STORAGE_KEY,
  getStoredToken,
  storeToken,
  updateGuiConfig,
  clearStoredToken,
  needsRefresh,
};
