import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { IDropdownItem } from "../DropdownItem";

import styles from "./PrimeDropdown.module.css";

export default function PrimeDropdown({
  options,
  placeholder,
  customOption,
  className,
  noBorder = false,
  panelClassName,
  onChange,
}: Readonly<{
  options: IDropdownItem[];
  placeholder?: string;
  className?: string;
  customOption?: React.ReactNode | ((option: IDropdownItem) => React.ReactNode);
  noBorder?: boolean;
  panelClassName?: string;
  onChange: (e: any) => void;
}>) {
  return (
    <Dropdown
      panelClassName={panelClassName}
      className={classNames(
        styles.primeDropdown,
        {
          [styles.noBorder]: noBorder,
        },
        className
      )}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      itemTemplate={customOption}
    />
  );
}
