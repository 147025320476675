import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toSafeInteger } from "lodash";
import classNames from "classnames";
import { useDateDropdownFilter } from "../../components/DateFilter/DateFilterDropdown";
import ExportObjectsSettings from "../../components/ExportModal/ExportObjectsSettings";
import { Column, Spacer } from "../../components/Layout/Layout";
import {
  BaseColumnsProps,
  BaseTableDataResponse,
  FilterData,
  getTableDataWithoutHook,
  postSelectedTableColumns,
  useBaseFilters,
  useTableColumns,
  useTableData,
} from "../../core/api/common_table";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { sortParamsToString } from "../../core/helpers/helpers";
import { useOrganisationFilter } from "../../core/hooks/filters/useOrganisationFilter";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useSearchFilter } from "../../core/hooks/filters/useSearchFilter";
import { useSortParams } from "../../core/hooks/filters/useSortParams";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { AddObject, ConfigurationAddObjectActions } from "./AddObject";
import { ObjectsTable } from "./ObjectsTable";
import { useMoreFilter } from "../../core/hooks/filters/useMoreFilter";
import { searchStringToFilterMap } from "../../ui-lib/utils/customFilters";
import { urlSearchParser } from "../../ui-lib/utils/urlSearchParser";
import useUser from "../../core/user/useUser";
import { useCustomersFilter } from "../../core/hooks/filters/useCustomersFilter";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import {
  useWindowDimensions,
  breakpoints,
} from "../../core/hooks/dimensionProvider";
import { ObjectFilters } from "./ObjectFilters";
export const PAGE_ID = "adminportal/objects";

import styles from "../PagesLayout.module.css";

const PAGE_URL = "api/v2/users";

// This component is massive and complex, we should probably split it up. Also it has many similarities with AlarmHistory.
const Objects = () => {
  const user = useUser();
  const t = useTranslations();
  const pageState = usePageState();
  const { data: treeData } = useOrganizationsTree();

  const history = useHistory();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);

  const addObjectModalRef = useRef<ConfigurationAddObjectActions>(null);

  const viserPermission = user.config?.show.includes("SensioHideFields");
  const viserSabo = user.config?.show.includes("SensioHideFieldsSABO");
  const viserHTJ = user.config?.show.includes("SensioHideFieldsOBO");
  const viserHTJReport = user.config?.show.includes(
    "SensioHideFieldsOBOReport"
  );

  // Table settings
  const { searchFilter, setSearchFilter, resetSearchFilter } =
    useSearchFilter(searchState);
  const {
    dateType,
    setDateType,
    dateFilter,
    setDateFilter,
    resetDateSettings,
  } = useDateDropdownFilter({ searchState });
  const { sortParams, setSortParams } = useSortParams(searchState);
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const { customersFilter, setCustomersFilter, resetCustomersFilter } =
    useCustomersFilter(searchState);
  const { resetMoreFilter } = useMoreFilter(searchState);
  const organisationFilter = useOrganisationFilter(customersFilter, treeData);

  const [exportModalOpen, setExportModalOpen] = useState(false);

  // Table columns
  const [selectedColumns, setSelectedColumns] = useState<BaseColumnsProps>();
  const { data: allObjectsColumns, isLoading: allColumnsLoading } =
    useTableColumns(PAGE_URL, false);
  const { data: selectedObjectsColumns, isLoading: selectedColumnsLoading } =
    useTableColumns(PAGE_URL, true);

  // Custom filters
  const [customFilters, setCustomFilters] = useState<FilterData[]>();
  const clearTableSelection = useRef<Function>();
  const { data: objectsFilters } = useBaseFilters(PAGE_URL);
  const { width } = useWindowDimensions();
  const isMobile = width <= breakpoints.desktop;

  const availableFilters = useCallback(() => {
    const otherFilters: FilterData[] = [];
    if (searchFilter?.id) otherFilters.push(searchFilter as FilterData);
    if (organisationFilter) otherFilters.push(organisationFilter);

    const allFilters: FilterData[] = [...otherFilters];
    if (customFilters) allFilters.push(...customFilters);

    clearTableSelection.current?.();

    return {
      // 4 - is max amount of filters we can pass to back-end
      amount: 4 - otherFilters.length,
      available:
        objectsFilters?.filter(
          (el) => !allFilters.find((item) => item.id === el.id)
        ) || [],
      notAvailable: allFilters,
    };
  }, [searchFilter, customFilters, objectsFilters, customersFilter])();

  // Data fetch
  const [tableData, setTableData] = useState<BaseTableDataResponse>();

  const fetchParams = {
    start: dateFilter.start
      ? moment(dateFilter.start).format("YYYY-MM-DDTHH:mm:ssZ")
      : "",
    end: dateFilter.end
      ? moment(dateFilter.end).format("YYYY-MM-DDTHH:mm:ssZ")
      : "",
    sort: sortParamsToString(sortParams),
    page: pageFilter?.page,
    pageSize: pageFilter?.pageSize,
    customer: organisationFilter,
    filter: customFilters,
    organizationIds: [],
    // uncomment when it will be available on back-end
    // exact: searchFilter.exact,
  };

  const { data, isLoading, isError } = useTableData(
    fetchParams,
    PAGE_URL,
    searchFilter
  );

  const tableParams = {
    filter: customFilters,
    customer: organisationFilter,
    sort: sortParamsToString(sortParams),
    customers: customersFilter.customers,
    start: dateFilter.start?.toISOString(),
    end: dateFilter.end?.toISOString(),
  };

  const resetTablePage = () => {
    setPageFilter({
      page: 1,
      pageSize: pageFilter.pageSize,
    });
  };

  useEffect(() => {
    setCustomFilters(searchStringToFilterMap(tableState ?? ""));
  }, []);

  // Need for the UI not rerender table after setState
  useLayoutEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const reFetchData = async () => {
    const { data: updated } = await getTableDataWithoutHook(
      fetchParams,
      PAGE_URL,
      searchFilter
    );

    if (updated) {
      setTableData(updated);
    }
  };

  // Effects
  const onClear = () => {
    resetSearchFilter();
    resetDateSettings();
    resetPageFilter();
    resetCustomersFilter();
    resetMoreFilter();
    setCustomFilters(undefined);
    history.push({ search: "page=1&pageSize=10" });
  };

  useEffect(() => {
    if (selectedObjectsColumns && !selectedColumns)
      setSelectedColumns(selectedObjectsColumns);
  }, [selectedObjectsColumns, selectedColumns]);

  useEffect(() => {
    if ([109, 110].includes(toSafeInteger(searchFilter.id))) {
      pageState.setIsSpecialSearch(true);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:objects"),
      })
    );
  }, []);

  const onSelectedColumnChange = async (columns: BaseColumnsProps) => {
    await postSelectedTableColumns(PAGE_URL, columns);
    setSelectedColumns(columns);

    await reFetchData();
  };

  storeTableState(PAGE_ID);

  const isFiltersApplied =
    customersFilter.customers ||
    searchFilter.value ||
    (searchFilter.id !== -1 && searchFilter.id !== 110) ||
    dateFilter.start ||
    customFilters?.length;

  return (
    <>
      <PrimeModal
        isOpen={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
      >
        <ExportObjectsSettings
          labels={{
            title: t("Objects:export"),
            description: t("Objects:all"),
          }}
          tableParams={tableParams}
          searchFilter={searchFilter}
          columnInfo={selectedColumns?.columns}
          onClose={() => setExportModalOpen(false)}
        />
      </PrimeModal>
      <PageHeader pageTitle title={t("Menu:objects")} icon={pageState.pageIcon}>
        {((user.config?.show.some((key) =>
          [
            "M:Users/Add",
            "ConfigureCareAlarms",
            "ConfigureOrdinaryHousingAlarms",
            "ConfigurePropertyAlarms",
            "ConfigureResponseAlarms",
            "ConfigureShelteredHousingAlarms",
            "ConfigurePeripheralAlarms",
          ].includes(key)
        ) &&
          !viserPermission) ||
          viserHTJ) && (
          <>
            <Button
              variant="primary"
              text={t("Objects:addobject")}
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                addObjectModalRef.current?.openWithAllOptions();
              }}
              dataCy="add_object_btn"
            />
            <AddObject
              ref={addObjectModalRef}
              onSubmit={(objectId: number) =>
                history.push(`/adminportal/objects/${objectId}`)
              }
              organisations={treeData}
            />
          </>
        )}
        {!isMobile &&
          user.config?.show.includes("Reports") &&
          (!viserPermission || viserSabo || viserHTJReport) && (
            <div>
              <Button
                variant="secondary"
                text={t("Common:export")}
                onClick={() => setExportModalOpen(true)}
                dataCy="export_objects_btn"
                className={classNames({ ["ml-8"]: !isMobile })}
              />
            </div>
          )}
      </PageHeader>
      <Column className={styles.content}>
        <ObjectFilters
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          objectsFilters={objectsFilters}
          availableFilters={availableFilters}
          resetTablePage={resetTablePage}
          pageState={pageState}
          customFilters={customFilters}
          setCustomersFilter={setCustomersFilter}
          setCustomFilters={setCustomFilters}
          resetCustomersFilter={resetCustomersFilter}
          customersFilter={customersFilter}
          treeData={treeData}
          organisationFilter={organisationFilter}
          dateType={dateType}
          setDateType={setDateType}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          searchState={searchState}
          onClear={onClear}
          allObjectsColumns={allObjectsColumns}
          selectedColumns={selectedColumns}
          onSelectedColumnChange={onSelectedColumnChange}
          showTableControlButton={
            !!(
              !isLoading &&
              !isError &&
              !allColumnsLoading &&
              !selectedColumnsLoading &&
              tableData
            )
          }
        />
        <Spacer size={16} />
        <Divider />
        <Column className={styles.tableContainer} type="top">
          {(isLoading && !isError) ||
          allColumnsLoading ||
          selectedColumnsLoading ||
          !tableData ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <ObjectsTable
              data={tableData}
              pageSettings={pageFilter}
              isFiltersApplied={!!isFiltersApplied}
              onPageSettingsChange={setPageFilter}
              sortParams={sortParams}
              onSortChange={setSortParams}
              allTableColumns={allObjectsColumns}
              selectedTableColumns={selectedColumns}
              onColumnChange={onSelectedColumnChange}
              onClear={onClear}
              onDataChanged={reFetchData}
              parentClearSelection={clearTableSelection}
              tableParams={{ ...tableParams, searchFilter: searchFilter }}
            />
          )}
        </Column>
      </Column>
    </>
  );
};

export default Objects;
