import Button from "../Button/Button";
import {
  PaginatorCurrentPageReportOptions,
  PaginatorPrevPageLinkOptions,
} from "primereact/paginator";
import Dropdown from "../Dropdown/Dropdown";

export const PrevPageButton = ({
  options,
  isMobile,
}: {
  options: PaginatorPrevPageLinkOptions;
  isMobile: boolean;
}) => (
  <Button
    ariaLabel="previous page"
    dataCy="previous page"
    variant="secondary"
    image="chevron-left"
    className={`previous-page-button ${isMobile ? "m-0" : "ml-32"}`}
    disabled={options.disabled}
    onClick={(e) => options.onClick(e)}
  />
);

export const NextPageButton = ({
  options,
  isMobile,
}: {
  options: PaginatorPrevPageLinkOptions;
  isMobile: boolean;
}) => (
  <Button
    ariaLabel="next page"
    dataCy="next page"
    variant="secondary"
    image="chevron-right"
    className={`next-page-button ${isMobile ? "m-0" : "ml-8"}`}
    disabled={options.disabled}
    onClick={(e) => options.onClick(e)}
  />
);

export const ItemsPerPageDropdown = ({
  rowsPerPageText,
  pageSizeOptions,
  onPageChange,
  selectedPageSize,
}: {
  rowsPerPageText: string;
  pageSizeOptions: {
    id: number;
    value: number;
    name: string;
    isSelected: boolean;
  }[];
  onPageChange:
    | ((nextPage: { offset: number; limit: number }) => void)
    | undefined;
  selectedPageSize: {
    id: number;
    value: number;
    name: string;
    isSelected: boolean;
  };
}) => (
  <span className="rowsPerPageDropdown">
    <span data-cy="rowsPerPageText" className="mr-8">
      {rowsPerPageText}
    </span>
    <Dropdown
      dataCy="rowsPerPageDropdown"
      width={70}
      items={pageSizeOptions}
      onSelectItem={(item) => {
        onPageChange?.({
          offset: 0,
          limit: item.id as number,
        });
      }}
      selectedItem={selectedPageSize}
      placeholder=""
    />
  </span>
);

export const PageReport = ({
  options,
  tableTexts,
}: {
  options: PaginatorCurrentPageReportOptions;
  tableTexts: {
    showing: string;
    of: string;
    results: string;
  };
}) => (
  <span className="table-results" data-cy={"table_results"}>
    {tableTexts.showing} {options.first}-{options.last} {tableTexts.of}{" "}
    {options.totalRecords} {tableTexts.results}
  </span>
);
