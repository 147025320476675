import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import useTranslations from "../../core/i18n/useTranslations";
import { Spacer } from "../Layout/Layout";
import ModalContent from "../../ui-lib/components/Popup/ModalContent";

export const ManualSyncInfoModal = ({
  isOpen,
  setIsOpen,
  fetchObject,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fetchObject: () => void;
}) => {
  const t = useTranslations();

  return (
    <PrimeModal
      withFooter
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      submitBtn={{
        text: t("Common:ok"),
        variant: "secondary",
        onClick: () => {
          setIsOpen(false);
          fetchObject();
        },
      }}
    >
      <ModalContent
        onClose={() => {
          setIsOpen(false);
          fetchObject();
        }}
        width="576px"
      >
        <h2>{t("Objects:sync_in_queue_header")}</h2>

        <Spacer size={16} />

        <p>{t("Objects:sync_in_queue_description")}</p>

        <Spacer size={16} />

        <p>{t("Objects:sync_in_queue_note")}</p>
      </ModalContent>
    </PrimeModal>
  );
};
