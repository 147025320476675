import { observer } from "mobx-react-lite";
import { useRef } from "react";
import AlarmChart from "../../components/AlarmChart/AlarmChart";
import HomeSearchbar from "../../components/HomeSearchbar/HomeSearchbar";
import { Column } from "../../components/Layout/Layout";
import usePageState from "../../core/pagestate/usePageState";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import Panel from "../../ui-lib/components/Panel/Panel";
import styles from "./Home.module.css";
import useUser from "../../core/user/useUser";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import useTranslations from "../../core/i18n/useTranslations";
import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";

const Home = () => {
  const pageState = usePageState();
  const chartPanel = useRef<HTMLDivElement>(null);
  const user = useUser();
  const t = useTranslations();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  return (
    <>
      <PageHeader title={t("Menu:home")} icon={pageState.pageIcon} />
      <Column className={styles.wrapper}>
        <Column className={styles.content}>
          {!user.initialized ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <>
              <HomeSearchbar />

              <div
                ref={chartPanel}
                style={{
                  flex: isMobile ? "0" : "1 1",
                  alignSelf: "stretch",
                  justifyContent: "stretch",
                  alignItems: "stretch",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                {!isMobile &&
                  user.config?.show.find((key) => key === "Alarms") && (
                    <Panel
                      className={styles.chartPanel}
                      padding={{ top: 12, right: 24, bottom: 12, left: 24 }}
                    >
                      <AlarmChart chartPanel={chartPanel} />
                    </Panel>
                  )}
              </div>
            </>
          )}
        </Column>
      </Column>
    </>
  );
};

export default observer(Home);
