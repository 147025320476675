import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import DateTimeHelper from "../../../../core/helpers/dateTimeHelper";
import useTranslations from "../../../../core/i18n/useTranslations";
import TimePicker from "../../../../ui-lib/components/DateAndTime/TimePicker";
import { PeriodicInteractionFormValues } from "./CreateInteractionModal";

export const InteractionTimeControl = ({
  watch,
  setValue,
}: {
  watch: UseFormWatch<PeriodicInteractionFormValues>;
  setValue: UseFormSetValue<PeriodicInteractionFormValues>;
}) => {
  const t = useTranslations();
  const [hour, minute] = watch(["hour", "minute"]);

  const value =
    hour || minute
      ? DateTimeHelper.getTimeStringFromTimeComponents(hour, minute)
      : undefined;

  const onChange = (updatedValue?: string) => {
    if (updatedValue) {
      const { hours, minutes } =
        DateTimeHelper.getTimeComponentsFromTimeString(updatedValue);

      setValue("hour", hours);
      setValue("minute", minutes);
    }
  };

  return (
    <TimePicker
      label={t("Objects:periodic_interactions_send_message_at")}
      value={value}
      onChange={onChange}
      placeholder="HH:MM"
    />
  );
};
