import { useState, useRef, useEffect } from "react";
import { Row, Spacer } from "../../components/Layout/Layout";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell, {
  MissingValueTableCell,
} from "../../ui-lib/components/Tables/TableCell";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { Transmitter } from "../../core/api/objects/objects";
import useUser from "../../core/user/useUser";
import { AddAlarmTransmitter } from "../Transmitters/AddAlarmTransmitter";
import {
  DialogActions,
  EditTransmitter,
} from "../Transmitters/EditTransmitter";
import {
  TransmitterEventsActions,
  TransmitterEventsModal,
} from "../Transmitters/TransmitterEventsModal";
import {
  TransmitterScheduleActions,
  TransmittersScheduleModal,
} from "../Transmitters/TransmittersScheduleModal";
import useSharedObject from "../../pages/Objects/SharedObjectContext/useSharedObject";
import {
  ConfigureTransmitterActions,
  ConfigureTransmitterModal,
} from "../Transmitters/ConfigureTransmitterModal";
import useTranslations from "../../core/i18n/useTranslations";
import Button from "../../ui-lib/components/Button/Button";
import {
  ObjectTransmitterInteractionsModal,
  TransmitterInteractionsEventsActions,
} from "../Transmitters/ObjectTransmitterInteractionsModal";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import { useHistory } from "react-router";
import { urlSearchParser } from "../../ui-lib/utils/urlSearchParser";
import { useModalIdHook } from "../../core/hooks/modalIdsHandlers/useIdModalHook";
import { DeleteTransmitterModal } from "./DeleteTransmitterModal";

const ObjectTransmitters = ({
  objectId,
  customerId,
  transmitters,
  loadTransmitters,
}: {
  objectId: string;
  customerId: number;
  transmitters: Transmitter[];
  loadTransmitters(): Promise<void>;
}) => {
  const t = useTranslations();
  const user = useUser();
  const viserPermission = user.config?.show.includes("SensioHideFields");
  const { data: objectDetails, fetchObject } = useSharedObject();

  const history = useHistory();
  const tableState = history.location.search;
  const searchState = urlSearchParser(tableState);

  const [transmitterId, setTransmitterId, resetTransmitterId] = useModalIdHook(
    searchState?.transmitterId || undefined,
    "transmitterId"
  );

  const [periodicDisables, setPeriodicDisables, resetPeriodicDisable] =
    useModalIdHook(
      searchState?.periodicDisables || undefined,
      "periodicDisables"
    );

  const { formatDateTime } = DateTimeHelper;

  const [transmittersLoading, setTransmittersLoading] =
    useState<boolean>(false);
  const [isAddTransmitterOpen, setIsAddTransmitterOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState<string>();

  const editTransmitterRef = useRef<DialogActions>(null);
  const editTransmitterLogsRef = useRef<TransmitterEventsActions>(null);
  const editTransmitterScheduleRef = useRef<TransmitterScheduleActions>(null);
  const configureTransmitterRef = useRef<ConfigureTransmitterActions>(null);
  const objectTransmitterInteractionsModalRef =
    useRef<TransmitterInteractionsEventsActions>(null);

  const rowActions: Array<{
    icon: string;
    text?: string;
    iconVariant?: "primary" | "secondary";
    iconSize?: number;
    hideForRow?: (rowData: Transmitter) => boolean;
    onClick: (rowData: Transmitter) => void;
  }> = [];

  const loadInternalTransmitters = async () => {
    setTransmittersLoading(true);
    await loadTransmitters();
    setTransmittersLoading(false);
    await fetchObject();
  };
  if (user.hasAccess("Transmitter/Edit") && !viserPermission) {
    rowActions.push({
      icon: "pencil-alt",
      text: t("Objects:accessories_table_edit"),
      onClick: (rowData) => {
        setTransmitterId(rowData.id);
      },
    });
  }
  rowActions.push({
    icon: "cog",
    text: t("Objects:accessories_table_configure"),
    onClick: (rowData) => {
      configureTransmitterRef.current?.open(rowData.id, objectId);
    },
    hideForRow: (rowData) =>
      !(rowData.configurable && objectDetails?.transmittersNumber! > 0),
  });
  if (!viserPermission) {
    rowActions.push({
      icon: "clock",
      text: t("Objects:accessories_table_events"),
      onClick: (rowData) => {
        editTransmitterLogsRef.current?.open(rowData.id);
      },
    });
  }

  if (user.hasAccess("PeriodicTransmitterDisable")) {
    rowActions.push({
      icon: "calendar",
      text: t("Objects:accessories_table_schedule"),
      onClick: (rowData) => {
        setPeriodicDisables(1);
        setTransmitterId(rowData.id);
      },
    });
  }
  rowActions.push({
    icon: "clipboard-list",
    text: t("Objects:accessories_table_interactions"),
    onClick: (rowData) => {
      objectTransmitterInteractionsModalRef.current?.open(rowData);
    },
  });
  rowActions.push({
    icon: "x",
    text: t("Objects:accessories_table_remove"),
    onClick: (rowData) => {
      setIdForDelete(rowData.id.toString());
    },
  });

  useEffect(() => {
    if (transmitterId.id) {
      if (periodicDisables.id) {
        editTransmitterScheduleRef.current?.open(+transmitterId.id);
      } else {
        editTransmitterRef.current
          ?.setTransmitter(+transmitterId.id)
          .then((success) => {
            if (success) {
              editTransmitterRef.current?.open();
            } else {
              notify({ message: "error", variant: "error" });
            }
          });
      }
    }
  }, [transmitterId]);

  return (
    <>
      {idForDelete && (
        <DeleteTransmitterModal
          transmitterId={idForDelete}
          userId={objectId}
          onClose={() => setIdForDelete(undefined)}
          onRemove={() => {
            setIdForDelete(undefined);
            loadInternalTransmitters();
          }}
        />
      )}
      <Row type="space" align="center">
        <h2>{t("Objects:accessories_transmitters_title")}</h2>
        {user.config?.show.some((key) =>
          [
            "AddUserFromStock",
            "CreateStock",
            "EditStock",
            "Transmitters",
            "UICreateStock",
            "ViewStock",
          ].includes(key)
        ) && (
          <Button
            type="button"
            variant="secondary"
            image="status-online"
            text={t("Transmitters:add_transmitter")}
            onClick={() => {
              setIsAddTransmitterOpen(true);
            }}
          />
        )}
        <AddAlarmTransmitter
          isAddTransmitterOpen={isAddTransmitterOpen}
          setIsAddTransmitterOpen={setIsAddTransmitterOpen}
          customerId={objectDetails?.organizationId}
          objectId={objectId}
          onSubmit={() => {
            loadInternalTransmitters();
          }}
        />
      </Row>
      <Spacer size={16} />
      <Table<Transmitter>
        columns={[
          {
            header: t("Objects:accessories_table_name"),
            fieldTemplate: (rowData) => (
              <TableCell value={rowData.productName} />
            ),
          },
          {
            header: t("AlarmReceptions:alarm_handling_alarm_codes_input_type"),
            fieldTemplate: (rowData) => (
              <TableCell
                value={
                  rowData.alarmType && t(`CommonEnum:${rowData.alarmType}`)
                }
              />
            ),
          },
          {
            header: t("Objects:accessories_table_model"),
            fieldTemplate: (rowData) => (
              <MissingValueTableCell
                iconColor="Grey-300"
                value={rowData.model}
                missingValueText={t("Transmitters:no_model")}
                leftIcon="clipboard-list"
              />
            ),
          },
          {
            header: t("Objects:Labels_identifier"),
            fieldTemplate: (rowData) => (
              <TableCell
                iconColor="Grey-300"
                value={rowData.identifier}
                leftIcon="phone"
              />
            ),
          },
          {
            header: t("Transmitters:table_columns_lastCommunicationTest"),
            fieldTemplate: (rowData) => {
              const valueToShow = rowData.lastCommunicationTest
                ? formatDateTime(
                    rowData.lastCommunicationTest,
                    "YYYY-MM-DD HH:mm"
                  )
                : undefined;

              return (
                <MissingValueTableCell
                  iconColor="Grey-300"
                  value={valueToShow}
                  missingValueText={t("Transmitters:no_last_communication")}
                  leftIcon="clock"
                />
              );
            },
          },
        ]}
        items={transmitters}
        rowActions={rowActions}
        rowActionsFixed
        rowActionContextMenu
        hideEmptyMessage
        noRowsMessage={t("Objects:accessories_no_transmitters")}
        isLoading={transmittersLoading}
        showRowHover
        withShowMore
      />
      <EditTransmitter
        ref={editTransmitterRef}
        modalTitle={t("Objects:accessories_edit_transmitter")}
        saveButtonTitle={t("Common:save")}
        customerId={customerId}
        onSubmit={() => {
          loadInternalTransmitters();
        }}
        onClose={() => {
          resetTransmitterId();
          resetPeriodicDisable();
        }}
        editTransmitter
      />
      <TransmitterEventsModal ref={editTransmitterLogsRef} />
      <TransmittersScheduleModal
        ref={editTransmitterScheduleRef}
        onClose={() => {
          resetTransmitterId();
          resetPeriodicDisable();
        }}
      />
      <ConfigureTransmitterModal ref={configureTransmitterRef} />
      <ObjectTransmitterInteractionsModal
        objectId={objectId}
        ref={objectTransmitterInteractionsModalRef}
      />
    </>
  );
};
export default ObjectTransmitters;
