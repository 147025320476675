import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ReactNode, useEffect } from "react";
import he from "he";
import { observer } from "mobx-react";
import useTranslations from "../../core/i18n/useTranslations";
import { Column } from "../../components/Layout/Layout";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import Button from "../../ui-lib/components/Button/Button";
import PopupContextMenu from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import Modal from "../../ui-lib/components/Popup/Modal";
import useSharedContact from "./SharedContactContext/useSharedContact";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import { SharedContactProvider } from "./SharedContactContext/SharedContactContext";
import { ContactDetails } from "../../core/api/contacts/types";
import { ContactDetailsGeneral } from "./General/ContactDetailsGeneral";
import DeleteContactModal from "../Objects/ObjectResponse/Contacts/DeleteContactModal";
import { Availability } from "./Accessibility/Availability";
import { getPrivateContactFromStore } from "../../ui-lib/utils/privateContactHelper";
import { AssociatedObjects } from "./AssociatedObjects/AssociatedObjects";

import styles from "../PagesLayout.module.css";

const parentPath = "/adminportal/common-contacts";

export const subMenuItems = (url: string, baseUrl?: string) => [
  {
    key: "Menu:general",
    to: baseUrl ?? url,
  },
  {
    key: "Menu:availability",
    to: `${baseUrl ?? url}/availability`,
  },
  {
    key: "Menu:associated_objects",
    to: `${baseUrl ?? url}/associated-objects`,
  },
];

const ContactDetailsWrapper = ({
  data,
  children,
}: {
  data: ContactDetails;
  children: ReactNode;
}) => {
  const history = useHistory();
  const t = useTranslations();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();

  return (
    <>
      <PageHeader title={he.decode(data.name || "")} icon="phone">
        {canSave && (
          <Button
            text={t("Common:save")}
            disabled={!isDirty || isSaving}
            loading={isSaving}
            onClick={onSave}
          />
        )}
        <PopupContextMenu
          className="mt-8"
          key="object_details_actions"
          position="right bottom"
          nested
          trigger={() => (
            <div>
              <Button
                className="ml-8"
                type="button"
                variant="secondary"
                image="dots-horizontal"
                text={t("Common:other")}
              />
            </div>
          )}
        >
          <Modal
            trigger={() => (
              <PopupContextMenuLinkButton
                icon="trash"
                text={t("Contacts:delete")}
              />
            )}
          >
            {(close) => (
              <DeleteContactModal
                contact={data}
                onClose={close}
                onDelete={() => {
                  close();
                  history.push(parentPath);
                }}
              />
            )}
          </Modal>
        </PopupContextMenu>
      </PageHeader>
      <Column className={styles.content} type="top" align="start">
        {children}
      </Column>
    </>
  );
};

const CommonContactsDetails = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { data } = useSharedContact();

  if (!data) {
    history.push(parentPath);
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}/associated-objects`}>
        <ContactDetailsWrapper data={data}>
          <AssociatedObjects contactId={data.id} />
        </ContactDetailsWrapper>
      </Route>
      <Route path={`${path}/availability`}>
        <ContactDetailsWrapper data={data}>
          <Availability />
        </ContactDetailsWrapper>
      </Route>
      <Route path={path}>
        <ContactDetailsWrapper data={data}>
          <ContactDetailsGeneral />
        </ContactDetailsWrapper>
      </Route>
    </Switch>
  );
};

const ContactDetailsLoader = () => {
  const history = useHistory();
  const { data, isError, errorMessage } = useSharedContact();

  useEffect(() => {
    if (isError) {
      notify({
        variant: "error",
        message: errorMessage,
      });
      history.replace(parentPath);
    }
  }, [isError]);

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return <CommonContactsDetails />;
};

const ContactDetailsContainer = () => {
  const t = useTranslations();
  const { setSubNav } = useSubNav();
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();

  const sessionPrevPath = sessionStorage.getItem("prevPath");
  const prevPath = sessionPrevPath ? JSON.parse(sessionPrevPath) : undefined;

  const pathToObject = getPrivateContactFromStore(+id);
  const backUrl = pathToObject || parentPath;
  const backUrlText = pathToObject
    ? t("Contacts:object_contacts")
    : t("Common:contacts_label");

  useEffect(() => {
    const nav = {
      menu: subMenuItems(url),
      backUrl: prevPath?.path || backUrl,
      backText: prevPath?.prevText || backUrlText,
    };
    setSubNav(nav);
    return () => {
      setSubNav(undefined);
    };
  }, []);

  return (
    <SharedContactProvider id={id}>
      <SaveObjectProvider>
        <ContactDetailsLoader />
      </SaveObjectProvider>
    </SharedContactProvider>
  );
};

export default observer(ContactDetailsContainer);
