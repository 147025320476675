import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Icon from "../Icon";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { breakpoints } from "../../../core/hooks/dimensionProvider";
import ResponsiveLayout from "../../../components/ResponsiveLayout";
import usePageState from "../../../core/pagestate/usePageState";
import useTranslations from "../../../core/i18n/useTranslations";

import styles from "./PageHeader.module.css";

export interface PageHeaderProps {
  title?: any;
  subTitle?: string;
  icon?: string;
  children?: React.ReactNode;
  pageTitle?: boolean;
  status?: string;
  isAbsent?: boolean;
}
const PageHeader = ({
  title,
  subTitle,
  icon,
  children,
  pageTitle = false,
  status,
  isAbsent,
}: PageHeaderProps) => {
  const isMounted = useRef(false);
  const t = useTranslations();
  const pageState = usePageState();
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <div className={styles.pageHeader}>
        <ResponsiveLayout
          breakPoint={breakpoints.desktop}
          renderMobile={() => (
            <div className={styles.headerSection}>
              <Icon
                name="bars"
                size={24}
                color="Grey-500"
                className="clickable"
                onClick={() => {
                  if (isMounted.current)
                    pageState.setShowNavigation(!pageState.showNavigation);
                }}
              />
              <Icon
                name="skyresponse_color"
                size={32}
                onClick={() => history.push("/")}
              />
            </div>
          )}
          renderDesktop={() => null}
        />
        <div
          className={classNames(styles.pageHeaderContent, {
            ["align-items-center"]: !!pageTitle,
          })}
        >
          <div className={styles.pageHeaderContent_left}>
            <div className={styles.pageHeaderContent_title}>
              {icon && (
                <Icon
                  name={icon}
                  size={24}
                  color="Primary-700"
                  className={styles.pageIcon}
                />
              )}
              {title && pageTitle && (
                <h1 className={classNames(styles.pageTitle, "w-100")}>
                  {title}
                </h1>
              )}
              {title && !pageTitle && (
                <div className="w-100">
                  <h2 className={styles.pageTitle}>{title}</h2>
                </div>
              )}
            </div>
            {(status || isAbsent) && (
              <div className={styles.pageHeaderContent_status}>
                {status && <h2 className={styles.statusText}>({t(status)})</h2>}
                {isAbsent && (
                  <h2 className={styles.absentText}>({t("Objects:absent")})</h2>
                )}
              </div>
            )}
          </div>

          <div className={styles.actionContainer}>{children}</div>
        </div>
      </div>
      {subTitle && (
        <>
          <Row
            type="fill"
            className={classNames({
              [styles.pageHeader]: true,
              [styles.pageSubtitle]: true,
              ["flex0"]: true,
            })}
          >
            <p>{subTitle}</p>
          </Row>
          <Spacer size={10} />
        </>
      )}
    </>
  );
};
export default PageHeader;
