import { NestedOrganizationProps } from "../../core/api/organizations/organizations";
import { ITreeData } from "../components/Hierarchy/Tree";
import User from "../../core/user/User";
import { IOrganizationProps } from "../../core/api/organizations/types";

export const getFlatOrganizations = (
  orgs: NestedOrganizationProps[],
  flatArray: IOrganizationProps[] = [],
  levelToCheck = Infinity
) => {
  orgs.forEach((org) => {
    flatArray.push(org.item);
    if (org.children.length > 0 && levelToCheck > 0) {
      getFlatOrganizations(org.children, flatArray, levelToCheck - 1);
    }
  });
  return flatArray;
};

export const findRootOrganization = (
  organizationsData: NestedOrganizationProps[],
  idToFind: number
): any =>
  organizationsData.find(
    (organization) =>
      organization.item.id === idToFind ||
      findRootOrganization(organization.children, idToFind)
  );

export const findRootTree = (
  organizationData: ITreeData[],
  idToFind: number
): any =>
  organizationData.find(
    (organization) =>
      organization.key === idToFind ||
      (organization.children && findRootTree(organization.children, idToFind))
  );

export const isOrganizationVisible = (user: User) =>
  (user.config?.show.includes("M:Configure/Customers") ||
    user.config?.show.includes("CustomerDetails") ||
    user.config?.show.includes("Organizations") ||
    user.config?.show.includes("SkygdSuperAdmin")) &&
  !user.config?.show.includes("SensioHideFields");
