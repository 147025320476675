import { useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Controller, useForm, useWatch } from "react-hook-form";
import { saveAs } from "file-saver";
import moment from "moment";
import { Row, Spacer } from "../../components/Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import DatePicker from "../../ui-lib/components/DateAndTime/DatePicker";
import Button from "../../ui-lib/components/Button/Button";
import useUser from "../../core/user/useUser";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Alert from "../../ui-lib/components/Alerts/Alert";
import { b64toBlob } from "../../core/helpers/helpers";
import styles from "./OrganizationGeneral/OrganizationsGeneral.module.css";

const RequestLogModal = ({
  onClose,
  logTarget = "Organization",
}: {
  onClose: () => void;
  logTarget?: string;
}) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest, dateFormat } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(undefined);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({});

  const isDateSet = () => !!(getValues("StartDate") || getValues("EndDate"));

  const buildAdminEventLogUrl = (
    entityId: string | number,
    start: string,
    end: string
  ) =>
    `/api/v2/adminevents?logtarget=${logTarget}&id=${entityId}&fromDate=${start}&toDate=${end}`;

  const submitRequestLog = () => {
    setLoading(true);
    authenticatedRequest
      .get(
        buildAdminEventLogUrl(
          id,
          moment(getValues("StartDate")).format("YYYY-MM-DD"),
          moment(getValues("EndDate")).format("YYYY-MM-DD")
        )
      )
      .then((r) => {
        if (!r.data) {
          setError({
            text: t(`Errors:no_logs`),
            variant: "error",
          });
          return;
        }
        const blob = b64toBlob(r.data.fileData, "application/octet-stream");
        saveAs(blob, r.data.fileName);
        notify({
          message: t("Organizations:log_downloaded"),
        });
        onClose();
      })
      .catch((e) => {
        const errorKey = getErrorKey(e);
        notify({
          message: t(`Errors:${errorKey}`),
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Organizations:download_logs_description")}
      </p>
      {error && (
        <>
          <Spacer size={16} />
          <Alert
            title={error.text}
            variant={error.variant}
            style={{ maxWidth: "390px" }}
            withDefaultIcon
          />
        </>
      )}
      <form>
        <Spacer size={16} />
        <Row className={styles.rowWithValidation}>
          <Controller
            name="StartDate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                required
                readonly
                yearNavigator
                label={t("Common:select_start_date")}
                yearRange={`2000:${new Date().getFullYear()}`}
                maxDate={useWatch({ control, name: "EndDate" }) || new Date()}
                dateFormat={dateFormat?.split("/").join("-")}
                placeholder={dateFormat?.split("/").join("-")}
                value={field.value}
                onChange={(date) => {
                  setValue(field.name, date);
                  setError(undefined);
                  trigger("StartDate");
                }}
                validationError={
                  errors.StartDate && t("Common:select_start_date_validation")
                }
              />
            )}
          />

          <Spacer size={32} />
          <Controller
            name="EndDate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                required
                readonly
                yearNavigator
                label={t("Objects:end_date")}
                value={field.value}
                minDate={useWatch({ control, name: "StartDate" })}
                maxDate={new Date()}
                dateFormat={dateFormat?.split("/").join("-")}
                placeholder={dateFormat?.split("/").join("-")}
                yearRange={`2000:${new Date().getFullYear()}`}
                onChange={(date) => {
                  setValue(field.name, date);
                  setError(undefined);
                  trigger("EndDate");
                }}
                validationError={
                  errors.EndDate && t("Objects:placeholder_subscription_ends")
                }
              />
            )}
          />
        </Row>
      </form>

      <Spacer size={32} />

      <Row type="left">
        <Button
          variant="primary"
          text={t("Transmitters:configuration_files_table_filename")}
          onClick={handleSubmit(submitRequestLog)}
          disabled={!isDateSet() || loading || error}
          loading={loading}
        />
        <Spacer size={8} />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={onClose}
        />
      </Row>
      <Spacer size={32} />
    </>
  );
};

export default observer(RequestLogModal);
