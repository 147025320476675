import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Flag from "react-country-flag";
import {
  IDropdownItem,
  IItemProp,
} from "../../ui-lib/components/Dropdown/DropdownItem";
import { Row, Spacer } from "../Layout/Layout";
import ContactInfo from "./ContactInfo/ContactInfo";
import useLanguage from "../../core/i18n/useLanguage";
import ExternalLink from "../../ui-lib/components/ExternalLink/ExternalLink";
import useTranslations from "../../core/i18n/useTranslations";
import useBranding from "../../core/branding/useBranding";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import PrimeDropdown from "../../ui-lib/components/Dropdown/PrimeDropdown/PrimeDropdown";

import styles from "./Navigation.module.css";

const Navigation = () => {
  const brandingStore = useBranding();
  const languageStore = useLanguage();
  const t = useTranslations();
  const usersPreferredLanguage = brandingStore.currentSecondaryLanguages;
  const [languageItems, setLanguageItems] = useState<Array<IItemProp>>([]);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    if (usersPreferredLanguage && !languageItems.length) {
      setLanguageItems(
        languageStore.loginLanguages
          .filter((language) =>
            usersPreferredLanguage.includes(language.nativeName)
          )
          .map((l) => ({
            id: l.id,
            name: t(`Languages:LANGUAGE-${l.nativeName.toUpperCase()}`),
            flag: l.flagIconKey,
            isSelected: l.id === languageStore.getStoredLanguage(),
          }))
      );
    }
  }, [usersPreferredLanguage]);

  const changeSelectedLanguage = (item: IDropdownItem) => {
    languageStore.setCurrentLoginLanguageById(item.id as string);
    setLanguageItems(
      languageItems.map((langItem) => ({
        ...langItem,
        isSelected: langItem.id === languageStore.getStoredLanguage(),
      }))
    );
  };

  const countryOptionTemplate = (option: IItemProp) => {
    return (
      <Row align="center">
        <Flag svg countryCode={option.flag ?? "EN"} />
        <Spacer size={8} />
        <span>{option.name}</span>
      </Row>
    );
  };

  return (
    <>
      <nav className={styles.navigation}>
        <button
          className={styles.navigationItem}
          onClick={() => setInfoModalOpen(true)}
        >
          {t("Menu:Support")}
        </button>
        {!!brandingStore.currentPartnerLink && (
          <>
            <Spacer size={32} />
            <ExternalLink
              text={t(`Menu:partner_${brandingStore.customerKey}`)}
              url={brandingStore.currentPartnerLink || ""}
              className={styles.navigationItem}
              iconColor="Grey-500"
              targetBlank
            />
          </>
        )}
        <Spacer size={32} />
        <PrimeDropdown
          noBorder
          className={styles.languageDropdown}
          panelClassName={styles.optionsPanel}
          options={languageItems}
          placeholder={t(
            `Languages:LANGUAGE-${languageStore
              .getStoredLanguage()!
              .toUpperCase()}`
          )}
          onChange={(e) => changeSelectedLanguage(e.value)}
          customOption={countryOptionTemplate}
        />
      </nav>
      <PrimeModal
        withHeader
        withFooter
        isOpen={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        header={t("Home:contact_info")}
        cancelBtn={{
          text: t("Common:close"),
          onClick: () => setInfoModalOpen(false),
        }}
      >
        <ContactInfo />
      </PrimeModal>
    </>
  );
};

export default observer(Navigation);
