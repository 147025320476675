import { useEffect, useState } from "react";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import Button from "../../../ui-lib/components/Button/Button";
import { ADManagementTable } from "./ADManagementTable";
import {
  IADGroup,
  ITemplatesItemsProps,
} from "../../../core/api/adGroup/types";
import { CreateADGroup } from "./CreateADGroup";
import { getRoleTemplates } from "../../../core/api/roleTemplates/roleTemplates";
import useUser from "../../../core/user/useUser";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import {
  getOrganizationTrees,
  NestedOrganizationProps,
} from "../../../core/api/organizations/organizations";
import {
  findRootOrganization,
  getFlatOrganizations,
} from "../../../ui-lib/utils/organizationsHelper";

export const ADManagement = ({ id }: { id: string }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState<IADGroup>();
  const [roleTemplates, setRoleTemplates] = useState<ITemplatesItemsProps[]>();
  const [organizationsData, setOrganizationsData] =
    useState<NestedOrganizationProps[]>();
  const [isRolesLoading, setIsRolesLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);

  const fetchRequiredData = async () => {
    try {
      setIsRolesLoading(true);
      const rolesResult = await getRoleTemplates(
        {
          page: 1,
          pageSize: 2000, // tmp fix fo fetch all data
          organization: id,
          publicArc: true,
        },
        authenticatedRequest
      );
      const organizationsResult = await getOrganizationTrees(
        { page: 1, pageSize: 2000, includeParent: true },
        authenticatedRequest
      );
      const relatedOrgs = getFlatOrganizations([
        findRootOrganization(organizationsResult.data, parseInt(id, 10)),
      ]);
      setRoleTemplates(
        rolesResult.data
          .filter((template) => template.hierarchyUsage !== 0)
          .filter(
            (template) =>
              template.responseCenterId ??
              relatedOrgs.find(
                (relatedOrg) => relatedOrg.id === template.organizationId
              )
          )
          .map(
            (template) =>
              ({
                id: template.id,
                name: template.name,
                type: template.type,
                orgId: template.organizationId,
                arcId: template.responseCenterId,
                hierarchy: template.hierarchyUsage,
                isSelected: false,
                icon:
                  template.type === "respondent" ? "phone-incoming" : "user",
              } as ITemplatesItemsProps)
          )
      );
      setOrganizationsData(organizationsResult.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsRolesLoading(false);
    }
  };

  useEffect(() => {
    fetchRequiredData();
  }, []);

  return (
    <Column style={{ width: "100%" }} type="top" align="start">
      <h1>{t("Organizations:ad_header")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Organizations:ad_description")}
      </p>

      <Spacer size={32} />

      <Row style={{ justifyContent: "space-between", width: "100%" }}>
        <h2>{t("Organizations:ad_groups")}</h2>
        <Button
          variant="secondary"
          text={t("Organizations:add_ad_group")}
          onClick={() => {
            setShowAddGroupModal(true);
          }}
          image="user-group"
          loading={isRolesLoading}
          disabled={isRolesLoading}
        />
      </Row>

      <CreateADGroup
        isOpen={showAddGroupModal}
        onClose={() => {
          setShowAddGroupModal(false);
          setGroupToEdit(undefined);
        }}
        adGroupData={groupToEdit}
        roleTemplatesData={roleTemplates}
        organizationsData={organizationsData}
        organizationId={id}
        initUpdateTable={updateTable}
        updateTableFunc={setUpdateTable}
      />

      <ADManagementTable
        organizationId={id}
        setShowAddGroupModal={setShowAddGroupModal}
        setGroupToEdit={setGroupToEdit}
        isRolesLoading={isRolesLoading}
        shouldUpdateTable={updateTable}
        organizations={organizationsData}
      />
    </Column>
  );
};
