import moment from "moment";
import classNames from "classnames";
import useTranslations from "../../../core/i18n/useTranslations";
import useLanguage from "../../../core/i18n/useLanguage";
import { IntervalCell } from "../../../pages/Objects/ObjectAdvanced/Interactions/CustomTableCell/CustomTableCell";
import { getWeekDays } from "../../utils/userDateFormat";

import styles from "./ScheduleItem.module.css";

export const CustomSelectedDays = ({
  repeatDays,
  isFormEditable,
  endDateValue,
  startFromSunday = false,
}: {
  repeatDays: number[];
  isFormEditable: boolean;
  endDateValue: string;
  startFromSunday?: boolean;
}) => {
  const t = useTranslations();
  const language = useLanguage();
  const locale = language.currentLanguage?.region || "sv-SE";
  let days = getWeekDays(locale);
  if (startFromSunday) {
    days = [days[6], ...days.slice(0, 6)];
  }
  const weekdays = Array.from(Array(7).keys()).map((i) => ({
    label: days[i],
    isSelected: repeatDays?.includes(startFromSunday ? i + 1 : i),
  }));

  return (
    <div
      className={classNames({
        [styles.intervalWrapper]: true,
        [styles.customView]: !isFormEditable,
      })}
      style={{ alignItems: "center" }}
    >
      <IntervalCell weekdays={weekdays} iconSize={isFormEditable ? 16 : 24} />
      {endDateValue && (
        <span style={{ fontWeight: 600, fontSize: "14px", lineHeight: "24px" }}>
          {`, ${t("Contacts:until")} `}
          {moment(new Date(endDateValue)).format("MMM Do YYYY")}
        </span>
      )}
    </div>
  );
};
