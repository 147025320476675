import { Chips } from "primereact/chips";
import { Icon } from "../Icon";

import styles from "./MultiInput.module.css";
import inputStyles from "../Inputs/Input.module.css";

export interface MultiCodeInputProps {
  label?: string;
  placeholder?: string;
  values?: string[];
  setValues: (values: string[]) => void;
  validationError?: string;
  className?: string;
  wrapperStyles?: React.CSSProperties;
  allowDuplicate?: boolean;
  maxValues?: number;
  required?: boolean;
  description?: string;
}

export default function MultiStringInput({
  label,
  placeholder,
  values = [],
  setValues,
  validationError,
  className,
  allowDuplicate = false,
  maxValues,
  required,
  description,
}: Readonly<MultiCodeInputProps>) {
  const getInput = () => {
    const elem = document.querySelector(".p-chips-input-token");
    const input = elem?.getElementsByTagName("input");

    return input ? input[0] : undefined;
  };

  function onDelete(item: string) {
    const newItems = [...values];
    const itemIndex = newItems.findIndex((i) => i === item);
    newItems.splice(itemIndex, 1);
    setValues(newItems);
  }

  function Item(item: string) {
    return (
      <div className={styles.item}>
        <span className={styles.name}>{item}</span>
        <button
          className={styles.deleteIcon}
          onClick={(e) => {
            e.preventDefault();
            onDelete(item);
          }}
          aria-label="Close"
        >
          <Icon name="x" color="Grey-400" size={18} hoverEffect />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.multiStringWrapper}>
      {required && <span className={inputStyles.requiredIcon}>*</span>}
      {label && <span className="strong">{label}</span>}
      <Chips
        placeholder={values.length === 0 ? placeholder : undefined}
        value={values.length === 0 ? undefined : values}
        onChange={(e) => {
          const valuesToSet =
            e.value?.filter((value) => value.length && !value.includes(",")) ||
            [];
          setValues(maxValues ? valuesToSet.splice(0, maxValues) : valuesToSet);
        }}
        onBlur={() => {
          // Clear input on blur by simulate enter press
          const input = getInput();

          if (input) {
            const event = new KeyboardEvent("keydown", {
              keyCode: 13,
              bubbles: true,
            });
            input.dispatchEvent(event);
          }
        }}
        itemTemplate={Item}
        allowDuplicate={allowDuplicate}
        separator=","
        className={className ?? ""}
        removable={false}
      />
      {description && <p className="description">{description}</p>}
      {validationError ? (
        <div className={styles.validationError}>{validationError}</div>
      ) : null}
    </div>
  );
}
