import { useTranslation } from "react-i18next";
import { SubRow } from "./SubRow";
import { Role } from "../../../core/api/administrators/types";
import useUser from "../../../core/user/useUser";
import { isOrganizationVisible } from "../../utils/organizationsHelper";

export const RolesCell = ({
  role,
  index,
  rolesLength,
  openHierarchyTree,
  hierarchyTooltip,
}: {
  role: Role;
  index: number;
  rolesLength: number;
  openHierarchyTree: Function;
  hierarchyTooltip?: boolean;
}) => {
  const respondent = role.type === "respondent";
  const organization = role.type === "admin";
  const user = useUser();
  const { t } = useTranslation();
  const organizationVisible = isOrganizationVisible(user);

  if (role.responseCenterId === 1) return null; // don't show the Skygd alarm reception

  let linkTo: string | undefined; // if it is a reseller don't link it to anywhere as we dont do any reseller stuff in new admin
  let icon = "";
  let iconTooltip = "";
  if (respondent) {
    linkTo = `/adminportal/alarm-receptions/${role.responseCenterId}`;
    icon = "alarm-reception";
    iconTooltip = t("Administrator:general_input_alarm_reception");
  } else if (organization) {
    if (organizationVisible) {
      linkTo = `/adminportal/organizations/${role.organizationId}`;
    }
    icon = "office-building";
    iconTooltip = t("Administrator:customer");
  }

  return (
    <SubRow
      key={role.id}
      displayDivider={index + 1 !== rolesLength}
      value={(respondent ? role.responseCenter : role.organization) ?? ""}
      linkTo={linkTo}
      icon={icon}
      hierarchyTooltip={hierarchyTooltip}
      leftIconTooltip={iconTooltip}
      hierarchyButton={
        organization && organizationVisible
          ? (event: any) => {
              if (event.type === "click") {
                event.stopPropagation();
                openHierarchyTree(role, event);
              }
            }
          : undefined
      }
    />
  );
};
