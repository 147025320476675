import { clamp, omit, orderBy, toNumber } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import {
  AlarmHandlingDetailsParams,
  Calendar,
  RespondentGroupResponse,
} from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import Table, { TableColumn } from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import { RepeatControl } from "./Controls/RepeatControl";
import { RespondentGroupControl } from "./Controls/RespondentGroupControl";
import { encodeTime, TimeControl } from "./Controls/TimeControl";
import { TimeoutControl } from "./Controls/TimeoutControl";
import { TypeControl } from "./Controls/TypeControl";
import NoItems from "../../../../ui-lib/components/Tables/NoItems";
import styles from "../../../../ui-lib/components/Tables/Table.module.css";
import PrimeTooltip from "../../../../ui-lib/components/PrimeTooltip/PrimeTooltip";

interface RowData {
  id: string;
  editing?: boolean;

  calendarId?: number;
  ruleType: number;
  order: number;
  alarmCode?: number | null;
  timeStart: string;
  timeEnd: string;
  primaryRespondentGroupId: number;
  secondaryRespondentGroupId?: number | null;
  secondaryRespondentGroupTimeout?: number | null;
  thirdRespondentGroupId?: number | null;
  thirdRespondentGroupTimeout?: number | null;
}

const columns = (
  t: (key: string) => string,
  respondentGroups: RespondentGroupResponse[],
  onItemChange: (rowData: RowData) => void,
  calendars?: Calendar[]
): TableColumn<RowData>[] => [
  {
    key: "type",
    style: { width: "240px" },
    header: t("AlarmReceptions:alarm_handling_table_column_rule_type"),
    fieldTemplate: (rowData) => {
      if (rowData.editing) {
        return (
          <TypeControl
            selected={rowData.ruleType}
            onSelect={(id) =>
              onItemChange({
                ...rowData,
                ruleType: id,
                alarmCode: id === 2 || id === 3 ? 0 : 1,
                timeStart: id === 1 ? "" : rowData.timeStart,
                timeEnd: id === 1 ? "" : rowData.timeEnd,
              })
            }
          />
        );
      }

      return (
        <TableCell
          value={t(
            `AlarmReceptions:alarm_handling_rule_type_${rowData.ruleType}`
          )}
        />
      );
    },
  },
  ...(calendars
    ? [
        {
          key: "calendar",
          style: { width: "240px" },
          header: t("AlarmReceptions:alarm_handling_rule_calendar"),
          fieldTemplate: (rowData: RowData) => {
            if (rowData.editing) {
              return (
                <RepeatControl
                  calendars={calendars}
                  selected={rowData.calendarId ?? 1}
                  onSelect={(id: number) =>
                    onItemChange({
                      ...rowData,
                      calendarId: id,
                    })
                  }
                />
              );
            }

            return (
              <TableCell
                value={t(
                  `AlarmReceptions:alarm_handling_rule_calendar_${
                    rowData.calendarId ?? 1
                  }`
                )}
              />
            );
          },
        },
      ]
    : []),
  {
    key: "code",
    style: { width: "100px" },
    editable: true,
    header: t("AlarmReceptions:alarm_handling_table_column_code"),
    fieldTemplate: (rowData) => {
      const min = 1;
      const max = Number.MAX_SAFE_INTEGER;
      const disabled = rowData.ruleType === 2 || rowData.ruleType === 3;

      if (rowData.editing) {
        return (
          <NumberInput
            value={disabled ? "" : `${rowData.alarmCode}`}
            disabled={disabled}
            onChange={(e) => {
              const stringValue = e.currentTarget.value;
              const updatedValue =
                stringValue.length > 0
                  ? clamp(toNumber(stringValue), min, max)
                  : null;

              onItemChange({
                ...rowData,
                alarmCode: updatedValue,
              });
            }}
          />
        );
      }

      return <TableCell value={rowData.alarmCode ? rowData.alarmCode : ""} />;
    },
  },
  {
    key: "time",
    header: t("AlarmReceptions:alarm_handling_table_column_time"),
    style: { width: "320px" },
    fieldTemplate: (rowData) => {
      const disabled = rowData.ruleType === 1;

      if (rowData.editing) {
        return (
          <Row>
            <TimeControl
              disabled={disabled}
              value={disabled ? "" : rowData.timeStart}
              onChange={(time) =>
                onItemChange({
                  ...rowData,
                  timeStart: time ?? "",
                })
              }
            />
            <Spacer size={8} />
            <TimeControl
              disabled={disabled}
              value={disabled ? "" : rowData.timeEnd}
              onChange={(time) =>
                onItemChange({
                  ...rowData,
                  timeEnd: time ?? "",
                })
              }
            />
          </Row>
        );
      }

      const startString = encodeTime(rowData.timeStart);
      const endString = encodeTime(rowData.timeEnd);
      const timeString =
        startString && endString ? `${startString} - ${endString}` : "";

      return <TableCell value={timeString} />;
    },
  },
  {
    key: "respondent-group-1",
    style: { width: "240px" },
    header: t("AlarmReceptions:alarm_handling_table_column_respondent_group"),
    fieldTemplate: (rowData) => {
      if (rowData.editing) {
        return (
          <RespondentGroupControl
            respondentGroups={respondentGroups}
            selected={rowData.primaryRespondentGroupId}
            onSelect={(id) =>
              onItemChange({
                ...rowData,
                primaryRespondentGroupId: id as number,
              })
            }
          />
        );
      }

      const value = respondentGroups.find(
        (rg) => rg.id === rowData.primaryRespondentGroupId
      )?.description;

      return <TableCell value={value ?? ""} />;
    },
  },
  {
    key: "respondent-group-2",
    header: t(
      "AlarmReceptions:alarm_handling_table_column_respondent_group_secondary"
    ),
    style: { width: "240px" },
    fieldTemplate: (rowData) => {
      if (rowData.editing) {
        return (
          <RespondentGroupControl
            respondentGroups={respondentGroups}
            selected={rowData.secondaryRespondentGroupId}
            onSelect={(id) =>
              onItemChange({
                ...rowData,
                secondaryRespondentGroupId: id,
              })
            }
            withClearItem
          />
        );
      }

      const value = respondentGroups.find(
        (rg) => rg.id === rowData.secondaryRespondentGroupId
      )?.description;

      return <TableCell value={value ?? ""} />;
    },
  },
  {
    key: "respondent-group-2-timeout",
    header: t(
      "AlarmReceptions:alarm_handling_table_column_respondent_group_secondary_timeout"
    ),
    style: { width: "240px" },
    fieldTemplate: (rowData) => {
      if (rowData.editing) {
        return (
          <TimeoutControl
            value={rowData.secondaryRespondentGroupTimeout}
            onChange={(value) =>
              onItemChange({
                ...rowData,
                secondaryRespondentGroupTimeout: value,
              })
            }
          />
        );
      }

      return (
        <TableCell value={rowData.secondaryRespondentGroupTimeout ?? ""} />
      );
    },
  },
  {
    key: "respondent-group-3",
    header: t(
      "AlarmReceptions:alarm_handling_table_column_respondent_group_third"
    ),
    style: { width: "240px" },
    fieldTemplate: (rowData) => {
      if (rowData.editing) {
        return (
          <RespondentGroupControl
            respondentGroups={respondentGroups}
            selected={rowData.thirdRespondentGroupId}
            onSelect={(id) =>
              onItemChange({
                ...rowData,
                thirdRespondentGroupId: id,
              })
            }
            withClearItem
          />
        );
      }

      const value = respondentGroups.find(
        (rg) => rg.id === rowData.thirdRespondentGroupId
      )?.description;

      return <TableCell value={value ?? ""} />;
    },
  },
  {
    key: "respondent-group-3-timeout",
    header: t(
      "AlarmReceptions:alarm_handling_table_column_respondent_group_third_timeout"
    ),
    style: { width: "240px" },
    fieldTemplate: (rowData) => {
      if (rowData.editing) {
        return (
          <TimeoutControl
            value={rowData.thirdRespondentGroupTimeout}
            onChange={(value) =>
              onItemChange({
                ...rowData,
                thirdRespondentGroupTimeout: value,
              })
            }
          />
        );
      }

      return <TableCell value={rowData.thirdRespondentGroupTimeout ?? ""} />;
    },
  },
];

export const EditAlarmHandlingRules = ({
  respondentGroups,
  calendars,
  setModalFullScreen,
  isModalFullScreen,
}: {
  respondentGroups: RespondentGroupResponse[];
  calendars?: Calendar[];
  setModalFullScreen: (value: boolean) => void;
  isModalFullScreen: boolean;
}) => {
  const t = useTranslations();
  const { control, setValue } = useFormContext<AlarmHandlingDetailsParams>();
  const {
    fields: formValues,
    prepend,
    remove,
  } = useFieldArray({
    control,
    name: "rules",
  });

  const [editing, setEditing] = useState<string>();
  const [editedItems, setEditedItems] = useState<RowData[]>(formValues);

  // Creating a ref for get previous state of items
  const prevItemsRef = useRef<RowData[]>(editedItems);

  useEffect(() => {
    // if edit in progress prevent setting items
    if (!editing) {
      setEditedItems(orderBy(formValues, "order"));
    }
  }, [formValues]);

  const items = useMemo(
    () =>
      orderBy(
        editedItems.map((item) => ({
          ...item,
          editing: item.id === editing,
        })),
        "Order"
      ),
    [editing, editedItems]
  );

  const addItem = () => {
    if (respondentGroups.length === 0) return;

    const newItem = {
      alarmCode: 1,
      ...(calendars && { calendarId: calendars[0].id }),
      primaryRespondentGroupId: respondentGroups[0].id,
      ruleType: 1,
      timeEnd: "",
      timeStart: "",
      order: 1,
      Order: 1,
    };

    prepend(newItem);
    // Set prev state of editedItems
    prevItemsRef.current = editedItems;
  };

  const removeItem = (id: string) => {
    const index = formValues.findIndex((value) => value.id === id);
    remove(index);
  };

  const submitChanges = (data: RowData[]) => {
    const newFormValues = data.map((i) => ({
      ...omit(i, "id", "edited"),
    }));

    setValue("rules", newFormValues);
  };

  const updateItem = (updatedItem: RowData) => {
    setEditedItems((prev) => {
      const updatedItems = prev.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      );
      submitChanges(updatedItems);
      return updatedItems;
    });
  };

  const resetChanges = () => setEditedItems(formValues);

  const reorder = (list: RowData[]) => {
    const reorderedItems = list.map((i, index) => ({ ...i, Order: index + 1 }));

    submitChanges(reorderedItems);
  };

  useEffect(() => {
    // Set edited item only if it's new one
    if (prevItemsRef.current.length < editedItems.length) {
      setEditing(editedItems?.[0]?.id);
    }
  }, [editedItems.length]);

  return (
    <>
      <h2>{t("AlarmReceptions:alarm_handling_rules")}</h2>
      <Spacer size={16} />
      <Row type="fill">
        <div style={{ display: "flex", width: "80%" }}>
          <p style={{ color: "var(--Grey-600)" }}>
            {t("AlarmReceptions:alarm_handling_rules_description")}
          </p>
        </div>
        <Row type="right" align="center">
          <Button
            size="small"
            variant="secondary"
            text={t("AlarmReceptions:add_new_rule")}
            onClick={addItem}
          />
          <Spacer size={16} />
          <PrimeTooltip target="#fullscreen" />
          <Button
            id="fullscreen"
            size="small"
            variant="secondary"
            image={isModalFullScreen ? "minimize" : "maximize"}
            onClick={() => setModalFullScreen(!isModalFullScreen)}
            dataPrTooltip={
              isModalFullScreen
                ? t("Common:exit_fullscreen")
                : t("Common:fullscreen")
            }
            dataPrMy="right bottom"
          />
        </Row>
      </Row>
      <Spacer size={32} />

      {items.length ? (
        <Table<RowData>
          items={items}
          columns={columns(t, respondentGroups, updateItem, calendars)}
          withShowMore
          rowActionsFixed
          showRowsActionsOnHover
          reorderableRows
          onRowReorder={(result) => reorder(result.value)}
          showRowHover
          hideEmptyMessage
          rowActions={[
            {
              text: t("Common:label_edit"),
              icon: "pencil-alt",
              onClick: (rowData) => {
                resetChanges();
                setEditing(rowData.id);
              },
              hideForRow: (rowData) => editing === rowData.id,
            },
            {
              text: t("Common:delete"),
              icon: "trash",
              iconVariant: "secondary",
              onClick: (rowData) => {
                removeItem(rowData.id);
                setEditing(undefined);
              },
              hideForRow: (rowData) => editing === rowData.id,
            },
            {
              text: t("Common:save"),
              icon: "check",
              onClick: () => {
                submitChanges(editedItems);
                setEditing(undefined);
              },
              hideForRow: (rowData) => editing !== rowData.id,
              alwaysShow: true,
            },
            {
              text: t("Common:cancel"),
              icon: "x",
              iconVariant: "secondary",
              onClick: () => {
                resetChanges();
                setEditing(undefined);
              },
              hideForRow: (rowData) => editing !== rowData.id,
              alwaysShow: true,
            },
          ]}
        />
      ) : (
        <NoItems
          title={t("Table:noresult_title")}
          subTitle={t("Table:noresult_subtitle")}
          className={styles.modalNoItems}
          icon="eye-off"
        />
      )}
    </>
  );
};
