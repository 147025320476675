import React, { useEffect, useState } from "react";
import classNames from "classnames";
import useTranslations from "../../../core/i18n/useTranslations";
import { Row } from "../../../components/Layout/Layout";

import styles from "./PasswordStrengthIndicator.module.css";

const weakPassword = /^[a-zA-Z\d\W_]*$/;
const mediumPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W_]{8,}$/;
const strongPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=(.*[\W_]))[a-zA-Z0-9\W_]{12,}$/;

function PasswordStrengthIndicator({
  value,
}: Readonly<{
  value: string;
}>) {
  const t = useTranslations();
  const [passData, setPassData] = useState({ text: "", variant: "blank" });

  const checkPasswordStrength = () => {
    if (!value) {
      return setPassData({ text: t("Common:blank_password"), variant: "" });
    }
    if (strongPassword.test(value)) {
      return setPassData({ text: t("Common:strong"), variant: "strong" });
    }
    if (mediumPassword.test(value)) {
      return setPassData({ text: t("Common:average"), variant: "medium" });
    }
    if (weakPassword.test(value)) {
      return setPassData({ text: t("Common:weak"), variant: "weak" });
    }
  };

  useEffect(() => {
    checkPasswordStrength();
  }, [value]);

  return (
    <>
      <Row className={styles.indicatorRow}>
        <div
          className={classNames(styles.indicator, styles[passData.variant])}
        />
        <div
          className={classNames(styles.indicator, {
            [styles.medium]: passData.variant === "medium",
            [styles.strong]: passData.variant === "strong",
          })}
        />
        <div
          className={classNames(styles.indicator, {
            [styles.strong]: passData.variant === "strong",
          })}
        />
      </Row>
      <p className={styles.indicatorText}>{passData.text}</p>
    </>
  );
}

export default React.memo(PasswordStrengthIndicator);
