import { useEffect, useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { getTransmitterLogs } from "../../../core/api/transmitters/transmitters";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { Spacer } from "../../../components/Layout/Layout";
import Table from "../../../ui-lib/components/Tables/Table";
import {
  AccessLogChanges,
  AccessLogItem,
} from "../../../core/api/objects/types";
import { SubRow } from "../../../ui-lib/components/Tables/SubRow";
import { defaultColumns } from "../../../components/Object/ObjectAccessLog";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import { usePageFilter } from "../../../core/hooks/filters/usePageFilter";

const dateFields = ["Warrenty", "LeasingExpires"];
const fieldsToInclude = ["TTransmitterLogV1", "TExternalCredentialV1"];

const parseValue = (value: string, type: string) => {
  if (DateTimeHelper.checkIsDateTimeString(value)) {
    if (dateFields.includes(type)) {
      return DateTimeHelper.formatDateString(value);
    }

    return DateTimeHelper.formatDateTimeString(value);
  }
  return value;
};

const changeValue = (
  change: AccessLogChanges,
  t: (key: string) => string,
  old = true
) => {
  const valueToShow = old ? change.oldValue : change.newValue;
  return (
    <div className="objectChangeLogRow">
      <div className="objectChangeLogField">{change.field}</div>
      <div
        className={old ? "objectChangeLogOldValue" : "objectChangeLogNewValue"}
      >
        {parseValue(valueToShow, change.field) || t("Language:WasRemoved")}
      </div>
    </div>
  );
};

const specialFieldsRender = (
  change: AccessLogChanges,
  i: number,
  t: (key: string) => string,
  rowData: AccessLogItem,
  visibility = true
) => {
  if (change.field === "TExternalCredentialV1") {
    return (
      <SubRow
        style={!visibility ? { visibility: "hidden" } : {}}
        key={change.modified + change.path}
        displayDivider={i + 1 !== rowData.changes.length}
        value={t(
          `Transmitters:credentials_${change.newValue ? "added" : "removed"}`
        )}
        hierarchyButton={undefined}
        changeLogButton={undefined}
      />
    );
  }

  return (
    <SubRow
      style={!visibility ? { visibility: "hidden" } : {}}
      key={change.modified + change.path}
      displayDivider={i + 1 !== rowData.changes.length}
      value={t(`Transmitters:transmitter_change_${change.field}`)}
      hierarchyButton={undefined}
      changeLogButton={undefined}
    />
  );
};

const logTableColumns = (t: (key: string) => string) => [
  ...defaultColumns(t),
  {
    key: "modified",
    header: t("Objects:accesslog_table_columns_modified"),
    style: { padding: "0" },
    fieldTemplate: (rowData: AccessLogItem) =>
      rowData.changes
        .filter(
          (change) =>
            change.path !== change.field ||
            fieldsToInclude.includes(change.field)
        )
        .map((change, i) => {
          if (fieldsToInclude.includes(change.field)) {
            return specialFieldsRender(change, i, t, rowData);
          }

          const value = changeValue(change, t);
          return (
            <SubRow
              key={change.modified + change.path}
              displayDivider={i + 1 !== rowData.changes.length}
              value={value}
              hierarchyButton={undefined}
              changeLogButton={undefined}
            />
          );
        }),
  },
  {
    key: "changedTo",
    style: { padding: "0" },
    header: "",
    fieldTemplate: (rowData: AccessLogItem) =>
      rowData.changes
        .filter(
          (change) =>
            change.path !== change.field ||
            fieldsToInclude.includes(change.field)
        )
        .map((change, i) => {
          if (fieldsToInclude.includes(change.field)) {
            return specialFieldsRender(change, i, t, rowData, false);
          }

          const value = changeValue(change, t, false);
          return (
            <SubRow
              key={change.modified + change.path}
              displayDivider={i + 1 !== rowData.changes.length}
              value={value}
              hierarchyButton={undefined}
            />
          );
        }),
  },
];

export const TransmitterLogs = ({
  transmitterId,
}: {
  transmitterId: string;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { pageFilter, setPageFilter } = usePageFilter();

  const [logsData, setLogsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLogs = async () => {
    setIsLoading(true);
    try {
      const result = await getTransmitterLogs(
        transmitterId,
        { ...pageFilter },
        authenticatedRequest
      );
      setLogsData(result);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [pageFilter]);

  return (
    <>
      <h1>{t("Menu:Transmitter_logs")}</h1>
      <Spacer size={32} />

      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <Table<AccessLogItem>
          items={logsData.rows}
          isLoading={isLoading}
          columns={logTableColumns(t)}
          hideEmptyMessage
          showRowHover
          withPagination
          withLazyLoading
          paginatedItems={{
            items: logsData.rows,
            pagination: {
              offset: pageFilter.pageSize * (pageFilter.page - 1),
              limit: pageFilter.pageSize,
              total: logsData.total,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              setPageFilter({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
        />
      )}
    </>
  );
};
