import React, { useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import Tabs, { ITab } from "../../../ui-lib/components/Tabs/Tabs";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Button from "../../../ui-lib/components/Button/Button";
import { NoScheduleItems } from "./NoScheduleItems";
import { IScheduleItem } from "../../../core/api/contacts/types";
import { ScheduleItemController } from "../../../ui-lib/components/Scheduler/ScheduleItemController";
import { Scheduler } from "./Scheduler";
import Divider from "../../../ui-lib/components/Divider/Divider";

import "react-big-calendar/lib/css/react-big-calendar.css";

export const NewSchedulerHandler = ({
  scheduleData = [],
}: {
  scheduleData: IScheduleItem[];
}) => {
  const t = useTranslations();

  const [tabItems, setTabItems] = useState([
    {
      id: 0,
      name: t("Contacts:available_time"),
      isSelected: true,
      icon: "calendar_available",
    },
    {
      id: 1,
      name: t("Contacts:exceptions"),
      isSelected: false,
      icon: "calendar_unavailable",
    },
  ] as ITab[]);
  const [availabilityData, setAvailabilityData] =
    useState<IScheduleItem[]>(scheduleData);
  const [showAddAvailability, setShowAddAvailability] = useState(false);

  const onNewItemCreated = () => {
    setShowAddAvailability(false);
  };

  const renderAvailabilityToAdd = () => (
    <ScheduleItemController
      key="new_item"
      availabilities={availabilityData}
      setAvailabilities={setAvailabilityData}
      onCreated={onNewItemCreated}
      isException={tabItems.find((item) => item.isSelected)?.id === 1}
      skipViewMode
    />
  );

  const renderAvailability = () => {
    const filteredData = availabilityData.filter((scheduleItem) =>
      tabItems.find((item) => item.isSelected)?.id === 1
        ? scheduleItem.exceptionTime
        : !scheduleItem.exceptionTime
    );
    return (
      <>
        {showAddAvailability && renderAvailabilityToAdd()}

        {filteredData.length || showAddAvailability ? (
          filteredData.map((data) => (
            <ScheduleItemController
              key={data.id}
              defaultValues={data}
              availabilities={availabilityData}
              setAvailabilities={setAvailabilityData}
              onCreated={onNewItemCreated}
            />
          ))
        ) : (
          <NoScheduleItems />
        )}
      </>
    );
  };

  const renderAvailabilityContent = () => {
    if (availabilityData.length || showAddAvailability) {
      return renderAvailability();
    }
    return <NoScheduleItems />;
  };

  return (
    <>
      <h1>{t("Menu:availability")}</h1>
      <Spacer size={8} />
      <p>{t("Contacts:availability_description")}</p>
      <Spacer size={32} />
      {!!availabilityData.length && (
        <Scheduler scheduleData={availabilityData} />
      )}
      <Spacer size={16} />
      <Row align="fill" type="space" className="flex0">
        <Tabs
          items={tabItems}
          onSelect={(id) => {
            if (showAddAvailability) setShowAddAvailability(false);
            setTabItems(
              tabItems.map((item) => ({ ...item, isSelected: item.id === id }))
            );
          }}
        />
        <Button
          variant="border-link"
          image="plus"
          text={
            tabItems.find((item) => item.isSelected)?.id === 0
              ? t("Contacts:set_availability")
              : t("Contacts:set_exception")
          }
          onClick={() => setShowAddAvailability(true)}
          disabled={showAddAvailability}
        />
      </Row>
      <Spacer size={8} />
      <Divider />
      <Spacer size={16} />
      {renderAvailabilityContent()}
    </>
  );
};
