/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  FormProvider,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { useRef, useState } from "react";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  IAlarmReceptionContact,
  ResponseCenterDetails,
} from "../../../../core/api/responsecenters/types";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useTranslations from "../../../../core/i18n/useTranslations";
import { useObjectForm } from "../../../../core/SaveObjectContext/hooks";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useSharedAlarmReception from "../../AlarmReceptionDetails/SharedAlarmReceptionContext/useSharedAlarmReception";
import useSharedContactsContext from "./SharedContactsContext";
import {
  EditAlarmReceptionContact,
  EditContactDialogActions,
} from "./EditAlarmReceptionContact";
import Button from "../../../../ui-lib/components/Button/Button";
import Table from "../../../../ui-lib/components/Tables/Table";
import BulkDeleteModal from "../../../../components/BulkDeleteModal";
import {
  deleteAlarmReceptionContact,
  reorderARCContacts,
} from "../../../../core/api/responsecenters/responsecenters";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import Icon from "../../../../ui-lib/components/Icon";
import useUser from "../../../../core/user/useUser";

import styles from "./Contacts.module.css";
import NoItems from "../../../../ui-lib/components/Tables/NoItems";

export interface FormMethods<T extends FieldValues> {
  control: Control<T>;
  reset: UseFormReset<T>;
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  errors: DeepMap<ResponseCenterDetails, FieldError>;
  isDirty?: boolean;
}
const Contacts = () => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data, update } = useSharedAlarmReception();
  const methods = useObjectForm<ResponseCenterDetails>({
    defaultValues: data,
    shouldUnregister: true,
  });
  const { handleSubmit, reset } = methods;
  const {
    id: alarmReceptionId,
    contacts,
    isLoading,
    reload,
  } = useSharedContactsContext();
  const editContactRef = useRef<EditContactDialogActions>(null);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [upForDelete, setUpForDelete] = useState<string[]>([]);

  const reorderLinks = async (dataToReorder: IAlarmReceptionContact[]) => {
    try {
      await reorderARCContacts(
        data!.id,
        dataToReorder.map((el, index) => ({
          contactId: el.id,
          priority: index,
        })),
        authenticatedRequest
      );
      await reload();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(async (values) => {
          const parsed = _.omit(values, "CustomerId");
          try {
            await update(parsed);

            notify({
              message: t("AlarmReceptions:edited_success"),
            });
            reset(values);
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          }
        })}
      >
        <Column type="top" align="start" style={{ maxWidth: "784px" }}>
          <h1>{t("AlarmReceptions:contacts_page_title")}</h1>
          <Spacer size={8} />
          <p>{t("AlarmReceptions:contacts_page_description")}</p>

          <Spacer size={32} />
          <Row type="space" align="fill">
            <Column align="start">
              <h2>{t("AlarmReceptions:contacts_table_label")}</h2>
              <p style={{ color: "var(--Grey-600)" }}>
                {t("AlarmReceptions:advanced_settings_menualternatives_label")}
              </p>
            </Column>
            <EditAlarmReceptionContact
              ref={editContactRef}
              onSubmit={() => {}}
            />
            <div>
              <Button
                variant="secondary"
                text={t("AlarmReceptions:add_new_contact")}
                onClick={async () => {
                  await editContactRef.current?.open();
                }}
              />
            </div>
          </Row>

          <Spacer size={16} />
          {isLoading && <LoadingSpinner theme="primary" />}

          {contacts?.length && !isLoading ? (
            <Table<IAlarmReceptionContact>
              columns={[
                {
                  fieldTemplate: (rowData) => (
                    <>
                      <div style={{ height: "24px" }}>{rowData.name}</div>
                      <Spacer size={18} />
                      <div style={{ height: "24px" }}>
                        <p style={{ color: "var(--Grey-600)" }}>
                          {rowData.description}
                        </p>
                      </div>
                    </>
                  ),
                },
                {
                  fieldTemplate: (rowData) => (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {rowData.telephone1 && (
                          <Row align="center" type="fill">
                            <Icon name="phone" color="Grey-300" />
                            <Spacer size={8} />
                            <span style={{ fontWeight: 600, fontSize: "14px" }}>
                              {rowData.telephone1}
                            </span>
                          </Row>
                        )}
                      </div>
                      <Spacer size={18} />
                      <Row type="space" align="fill" style={{ height: "24px" }}>
                        {rowData.telephone2 && (
                          <Row align="center" type="fill">
                            <span className={styles.ordinalNumber}>2</span>
                            <Icon name="phone" color="Grey-300" />
                            <Spacer size={8} />
                            <span style={{ fontWeight: 600, fontSize: "14px" }}>
                              {rowData.telephone2}
                            </span>
                          </Row>
                        )}
                        {rowData.telephone3 && (
                          <Row align="center" type="fill">
                            <span className={styles.ordinalNumber}>3</span>
                            <Icon name="phone" color="Grey-300" />
                            <Spacer size={8} />
                            <span style={{ fontWeight: 600, fontSize: "14px" }}>
                              {rowData.telephone3}
                            </span>
                          </Row>
                        )}
                      </Row>
                    </>
                  ),
                },
              ]}
              items={contacts}
              rowActions={[
                {
                  text: t("Objects:edit_details"),
                  icon: "pencil-alt",
                  iconVariant: "secondary",
                  onClick: (rowData) => {
                    editContactRef.current?.open(rowData.id);
                  },
                },
                {
                  icon: "x",
                  iconVariant: "secondary",
                  text: t("AlarmReceptions:remove_contact"),
                  onClick: (rowData) => {
                    if (rowData.id) {
                      setUpForDelete([rowData.id.toString()]);
                      setDeleteOpen(true);
                    }
                  },
                },
              ]}
              hideHeader
              hideEmptyMessage
              isLoading={isLoading}
              showRowHover
              withShowMore
              rowActionContextMenu
              reorderableRows
              onRowReorder={(e) => {
                reorderLinks(e.value);
              }}
            />
          ) : (
            !isLoading && (
              <Column align="center" style={{ width: "100%" }}>
                <NoItems
                  title={t("Table:no_results_found")}
                  icon="eye-off"
                  subTitle={t("Table:adjust_filter_description")}
                  clear={undefined}
                  style={{ padding: "55px 0" }}
                />
              </Column>
            )
          )}

          <BulkDeleteModal
            isOpen={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            onDelete={() => {
              setDeleteOpen(false);
              reload();
            }}
            labels={{
              single: t("AlarmReceptions:contacts_type"),
              multi: t("AlarmReceptions:contacts_type_multi"),
            }}
            ids={upForDelete}
            apiFunc={(id, authReq) =>
              deleteAlarmReceptionContact(alarmReceptionId, id, authReq)
            }
          />
        </Column>
      </form>
    </FormProvider>
  );
};

export default Contacts;
