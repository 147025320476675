import { Route, Switch, useRouteMatch } from "react-router-dom";
import LoggedInPage from "../../components/LoggedinPage/LoggedinPage";
import Administrators from "../Administrators/Administrators";
import RoleTemplates from "../Administrators/RoleTemplates/RoleTemplates";
import ProductsList from "../Resellers/Products/ProductsList";
import ProductDetails from "../Resellers/Products/ProductDetails/ProductDetails";
import AlarmHistory from "../AlarmHistory/AlarmHistory";
import AlarmReceptionDetails from "../AlarmReceptions/AlarmReceptionDetails/AlarmReceptionDetails";
import AlarmReceptions from "../AlarmReceptions/AlarmReceptions";
import CameraDetails from "../Cameras/CameraDetails";
import Cameras from "../Cameras/Cameras";
import CommonContacts from "../CommonContacts/CommonContacts";
import Home from "../Home/Home";
import Organizations from "../Organizations/Organizations";
import LocationDetails from "../Locations/LocationDetails";
import Locations from "../Locations/Locations";
import ObjectDetails from "../Objects/ObjectDetails";
import Objects from "../Objects/Objects";
import Reports from "../Reports/Reports";
import TransmitterDetails from "../Transmitters/TransmitterDetails";
import Transmitters from "../Transmitters/Transmitters";
import OrganizationDetails from "../Organizations/OrganizationDetails";
import AdministratorDetails from "../Administrators/AdministratorDetails/AdministratorDetails";
import IdentityDetails from "../Administrators/IdentityDetails/IdentityDetails";
import CommonContactsDetails from "../CommonContacts/CommonContactsDetails";
import ReportDownload from "../ReportDownload/ReportDowload";
import Page404 from "../Page404/Page404";
import Resellers from "../Resellers/Resellers";
import ResellerDetails from "../Resellers/ResellerDetails/ResellerDetails";

const Dashboard = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <LoggedInPage>
          <Home />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/alarms`}>
        <LoggedInPage>
          <AlarmHistory />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/objects/:id`}>
        <LoggedInPage>
          <ObjectDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/objects`}>
        <LoggedInPage>
          <Objects />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/reports`}>
        <LoggedInPage>
          <Reports />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/transmitters/:id`}>
        <LoggedInPage>
          <TransmitterDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/transmitters`}>
        <LoggedInPage>
          <Transmitters />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/locations/:id`}>
        <LoggedInPage>
          <LocationDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/locations`}>
        <LoggedInPage>
          <Locations />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/cameras/:id`}>
        <LoggedInPage>
          <CameraDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/cameras`}>
        <LoggedInPage>
          <Cameras />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/common-contacts/:id`}>
        <LoggedInPage>
          <CommonContactsDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/common-contacts`}>
        <LoggedInPage>
          <CommonContacts />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/organizations/:id`}>
        <LoggedInPage>
          <OrganizationDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/organizations`}>
        <LoggedInPage>
          <Organizations />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/identity/:id`}>
        <LoggedInPage>
          <IdentityDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/administrators/:id`}>
        <LoggedInPage>
          <AdministratorDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/administrators`}>
        <LoggedInPage>
          <Administrators />
        </LoggedInPage>
      </Route>

      <Route path={`${path}/alarm-receptions/:id`}>
        <LoggedInPage>
          <AlarmReceptionDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/alarm-receptions`}>
        <LoggedInPage>
          <AlarmReceptions />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/roletemplates`}>
        <LoggedInPage>
          <RoleTemplates />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/resellers/:id`}>
        <LoggedInPage>
          <ResellerDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/resellers`}>
        <LoggedInPage>
          <Resellers />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/products/:id`}>
        <LoggedInPage>
          <ProductDetails />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/products`}>
        <LoggedInPage>
          <ProductsList />
        </LoggedInPage>
      </Route>
      <Route path={`${path}/dlreport`}>
        <LoggedInPage>
          <ReportDownload />
        </LoggedInPage>
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default Dashboard;
