import { RefObject } from "react";
import classnames from "classnames";
import { Tooltip } from "primereact/tooltip";

import "./PrimeTooltip.module.css";

interface IPrimeTooltipProps {
  target?: string | HTMLElement | string[] | RefObject<HTMLElement>;
  theme?: "dark" | "light";
  position?: "top" | "bottom" | "left" | "right" | "mouse";
  event?: "both" | "focus" | "hover";
  autoHide?: boolean;
  showEvent?: string;
  hideEvent?: string;
}

const PrimeTooltip = ({
  target,
  theme = "light",
  position = "top",
  event,
  autoHide = true,
  showEvent = "mouseenter",
  hideEvent = "mouseleave",
}: IPrimeTooltipProps) => {
  return (
    <Tooltip
      event={event}
      showEvent={showEvent}
      hideEvent={hideEvent}
      autoHide={autoHide}
      target={target}
      className={classnames({
        dark: theme === "dark",
        customTooltip: true,
      })}
      position={position}
      pt={{
        text: { className: "customTooltipText" },
      }}
    />
  );
};

export default PrimeTooltip;
