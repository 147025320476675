import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { IOrgIntegration } from "../../../../core/api/organizations/types";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import Button from "../../../../ui-lib/components/Button/Button";
import useTranslations from "../../../../core/i18n/useTranslations";

import styles from "../CustomSettings/CustomSettingsWrapper.module.css";

export const IntegrationSettings = ({
  index,
  visible,
  removeSetting,
}: {
  index: number;
  visible: boolean;
  removeSetting: (idx: number) => void;
}) => {
  const t = useTranslations();
  const {
    formState: { errors },
    control,
    clearErrors,
  } = useFormContext<IOrgIntegration>();
  return (
    <div
      className={classNames({
        [styles.customSettingsContainer]: true,
        [styles.notVisible]: !visible,
      })}
    >
      <Row align="end">
        <Controller
          name={`integrationSettings.${index}.key`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Common:name")}
              placeholder={t(
                "Organizations:integration_settings_key_placeholder"
              )}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.integrationSettings?.[index]?.key &&
                t("Errors:input_field_required")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name={`integrationSettings.${index}.value`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Common:custom_filters_column_value")}
              placeholder={t(
                "Organizations:integration_settings_value_placeholder"
              )}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.integrationSettings?.[index]?.value &&
                t("Errors:input_field_required")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Button
          image="x"
          variant="secondary"
          onClick={() => {
            removeSetting(index);
            clearErrors("integrationSettings");
          }}
        />
      </Row>
    </div>
  );
};
