import { Namespace, TFunction } from "i18next";
import { ICategoryProduct } from "../../core/api/products/types";

export const getProductName = (
  product: ICategoryProduct,
  t: TFunction<Namespace<string>>
) => {
  const translatedName = t(`CommonEnum:Product_${product.id}`);
  return translatedName !== `Product_${product.id}`
    ? translatedName
    : product.name;
};
