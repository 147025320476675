import { AxiosInstance } from "axios";

export interface FormattedDateRange {
  start: Date;
  end: Date;
}

export interface Histogram {
  timestamp: Date;
  count: number;
}

export interface AlarmHistogram {
  histogram: Histogram[];
}

export interface TransmitterInteractionResult {
  id: number;
  serverAlarmTime: string;
  alarmCode: number;
}

export const getAlarmChart = async (
  authenticatedRequest: AxiosInstance,
  interval: number
): Promise<AlarmHistogram> =>
  authenticatedRequest
    .get<AlarmHistogram>(`/api/v2/alarms/histogram?interval=${interval}`)
    .then((response) => response.data);

export const getTransmitterAlarms = (
  authenticatedRequest: AxiosInstance,
  objectId: string,
  transmitterId: string,
  startDate: string,
  endTime: string
) =>
  authenticatedRequest.get<TransmitterInteractionResult[]>(
    `/api/v2/alarms?user=${objectId}&start=${startDate}&end=${endTime}&transmitter=${transmitterId}&filterType=6&filterValue=${transmitterId}&o=date_desc`
  );

export const forceDeactivateAlarmCall = async (
  alarmId: string,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.post(`/api/v2/alarms/${alarmId}/deactivatealarm`);

export const gatherAlarmDebugInfoCall = async (
  alarmId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(`/api/v2/alarms/${alarmId}/gatheralarmdebuginfo`);
