import { PasswordConstraints } from "../api/administrators/types";

const validPhoneRegExp = /^\+[1-9]\d{5,14}$/;
export const validatePhoneNumber = (inputvalue?: string | null): boolean => {
  if (!inputvalue) {
    return false;
  }
  if (RegExp(validPhoneRegExp).exec(inputvalue)) {
    return true;
  }
  return false;
};

export const validateForSpecialCharacters = (value?: string): boolean => {
  const specialCharsRegExp = /^[\p{L}_\\. ]*$/u;
  if (!value) {
    return false;
  }
  if (RegExp(specialCharsRegExp).exec(value)) {
    return true;
  }
  return false;
};

export const validatePhoneNumberIfExists = (
  inputvalue?: string | null
): boolean => {
  if (!inputvalue || inputvalue.length === 0) {
    return true;
  }

  return validatePhoneNumber(inputvalue);
};

export const validateIPAddress = (inputvalue: string | undefined): boolean => {
  if (!inputvalue) {
    return false;
  }
  const validIpExp = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
  if (RegExp(validIpExp).exec(inputvalue)) {
    return true;
  }
  return false;
};

const validIdentifierRegExp = /^\+?\d+$/;
export const validateIdentifier =
  (regexp: string): ((input?: string) => boolean) =>
  (inputvalue?: string): boolean => {
    if (inputvalue?.match(regexp ?? validIdentifierRegExp)) {
      return true;
    }
    return false;
  };

export const validateCommaSeparadedNumbers = (value?: string) => {
  const regExp = /^\d+(,\d+)*$/;
  if (value?.match(regExp)) {
    return true;
  }
  return false;
};

export const validateImei = (inputvalue?: string): boolean => {
  const s = inputvalue ?? "";
  const etal = /^\d{15}$/;
  if (!etal.test(s)) return false;
  let sum = 0;
  let mul = 2;
  const length = 14;
  let digit;
  let tp;
  for (let i = 0; i < length; i += 1) {
    digit = s.substring(length - i - 1, length - i);
    tp = parseInt(digit, 10) * mul;
    if (tp >= 10) sum += (tp % 10) + 1;
    else sum += tp;
    if (mul === 1) mul += 1;
    else mul -= 1;
  }
  const chk = (10 - (sum % 10)) % 10;
  if (chk !== parseInt(s.substring(14, 15), 10)) return false;
  return true;
};

const emailRegex = /\S+@\S+\.\S+/;
export const validateEmail = (inputValue?: string) =>
  !!inputValue?.match(emailRegex);

export const validateEmailIfExists = (email?: string) => {
  if (!email || email.length === 0) {
    return true;
  }

  return validateEmail(email);
};

export const validateSipUrl = (inputValue?: string): boolean => {
  const sipRegExp = /^sip(s)?:[\w.-]+@[\w.-]+\.[a-zA-Z]{2,5}$/;
  return !inputValue || sipRegExp.test(inputValue);
};

const validEmergencyPhoneRegExp = /^\d{3,5}$/;
export const validateEmergencyPhoneNumber = (
  inputvalue?: string | null
): boolean => {
  if (!inputvalue) {
    return false;
  }
  if (RegExp(validEmergencyPhoneRegExp).exec(inputvalue)) {
    return true;
  }
  return false;
};

export const validateEmergencyPhoneNumberIfExists = (
  inputvalue?: string | null
): boolean => {
  if (!inputvalue || inputvalue.length === 0) {
    return true;
  }

  return validateEmergencyPhoneNumber(inputvalue);
};

export const validateForbiddenChars = (
  inputValue: string,
  passwordConstraints: PasswordConstraints
) => {
  const forbiddenChars: string[] = passwordConstraints.forbiddenChars
    .toLowerCase()
    .split("");
  if (forbiddenChars.some((char) => inputValue?.toLowerCase().includes(char))) {
    return false;
  }
  return true;
};

export const validateNumberCount = (
  inputValue: string,
  passwordConstraints: PasswordConstraints
) => {
  const countNumbers = inputValue?.match(/\d+/g)
    ? inputValue.match(/\d+/g)?.join("").length
    : 0;
  if (countNumbers! < passwordConstraints.minNumbers) {
    return false;
  }
  return true;
};

export const validateLowerCase = (
  inputValue: string,
  passwordConstraints: PasswordConstraints
) => {
  const countLowercase = inputValue?.match(/[a-z]+/g)
    ? inputValue.match(/[a-z]+/g)?.join("").length
    : 0;
  if (countLowercase! < passwordConstraints.minLowerCase) {
    return false;
  }
  return true;
};

export const validateUpperCase = (
  inputValue: string,
  passwordConstraints: PasswordConstraints
) => {
  const countUppercase = inputValue?.match(/[A-Z]+/g)
    ? inputValue.match(/[A-Z]+/g)?.join("").length
    : 0;
  if (countUppercase! < passwordConstraints.minUpperCase) {
    return false;
  }
  return true;
};

export const validateAlarmIds = (inputValue?: string) => {
  if (!inputValue) {
    return true;
  }

  const specialChars = /[ `!@#$%^*()=[\]{}'"\\|,.<>/?~]/;
  const values = inputValue.split(",");
  for (const element of values) {
    if (element.length < 2 || specialChars.test(element)) {
      return false;
    }
  }

  return true;
};

const snsArnRegexp = /^(arn:aws:sns:.+:.+:.+)?$/;
export const validateSnsArn = (value?: string) => {
  if (!value || value.length === 0) {
    return true;
  }
  return !!RegExp(snsArnRegexp).exec(value);
};

const priceRegexp = /^(\d{1,2}([.]\d{1,2})?)$/;
export const validatePriceValue = (price?: string) => {
  if (!price || price.length === 0) {
    return true;
  }
  return !!RegExp(priceRegexp).exec(price);
};

export const validateHexadecimal = (
  from: number,
  to: number,
  value?: string
) => {
  const hexPattern = new RegExp(`^[0-9a-fA-F]{${from},${to}}$`);
  return !!value?.match(hexPattern);
};

export const validateAllHexadecimal = (
  from: number,
  to: number,
  value?: string
) => {
  const hexPattern = new RegExp(`^[0-9a-zA-Z]{${from},${to}}$`);
  return !!value?.match(hexPattern);
};
