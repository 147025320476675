import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  sendTestAlarm,
  useTestAlarms,
} from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Radio from "../../../ui-lib/components/Radio/Radio";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import useTranslations from "../../../core/i18n/useTranslations";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { Row, Spacer } from "../../../components/Layout/Layout";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";

import styles from "./ObjectActions.module.css";
import inputStyles from "../../../ui-lib/components/Inputs/Input.module.css";

export interface TestAlarmFormData {
  textMessage: string;
  flags: string;
}

const TestAlarmModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();
  const { data: testAlarms, isLoading } = useTestAlarms(id);

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useForm<TestAlarmFormData>();

  const internalOnClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: TestAlarmFormData) => {
    try {
      await sendTestAlarm(id, data, authenticatedRequest);
      notify({
        message: t("Messages:test_alarm_success"),
      });
      internalOnClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      fixedWidth="576px"
      header={t("Objects:test_alarm_create")}
      isOpen={isOpen}
      onClose={internalOnClose}
      loading={isSubmitting}
      submitBtn={{
        text: t("Objects:test_alarm_trigger"),
        onClick: handleSubmit(onSubmit),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: internalOnClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Messages:test_alarm_select")}
      </p>

      <Spacer size={16} />
      <Row type="left">
        <span className={inputStyles.requiredIcon}>*</span>
        <p className={styles.label}>{t("Objects:test_alarm_alarm_type")}</p>
      </Row>
      <Spacer size={4} />
      {isLoading || !testAlarms ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <form>
          <Controller
            name="flags"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <div className={styles.radioGroupWrapper}>
                  {testAlarms.map((testAlarm) => (
                    <Radio
                      inputRef={field.ref}
                      inputId={testAlarm.flags}
                      key={testAlarm.flags}
                      name="test_alarm"
                      text={t(`CommonEnum:AlarmFlagEnum${testAlarm.flags}`)}
                      value={testAlarm.flags}
                      checked={field.value === testAlarm.flags}
                      onChange={(e) => field.onChange(e.currentTarget.value)}
                    />
                  ))}
                </div>
                {errors.flags && (
                  <>
                    <Spacer size={8} />
                    <p className={styles.validationError}>
                      {t("Messages:test_alarm_type_required")}
                    </p>
                  </>
                )}
              </>
            )}
          />

          <Spacer size={16} />

          <Controller
            name="textMessage"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Objects:test_alarm_message_label")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.textMessage &&
                  t("Messages:test_alarm_message_required")
                }
              />
            )}
          />
        </form>
      )}
    </PrimeModal>
  );
};

export default TestAlarmModal;
