import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { AxiosInstance } from "axios";
import PermissionList from "../../../components/Administrator/PermissionList";
import RespondentGroupList from "../../../components/Administrator/RespondentGroupList";
import { LanguageDropdown } from "../../../components/LanguageDropdown/LanguageDropdown";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { useAvailableRoles } from "../../../core/api/administrators/administrators";
import {
  getRespondentGroups,
  getResponseCenters,
} from "../../../core/api/responsecenters/responsecenters";
import {
  RespondentGroup,
  ResponseCenterResponse,
} from "../../../core/api/responsecenters/types";
import useTranslations from "../../../core/i18n/useTranslations";
import { useObjectFormRef } from "../../../core/SaveObjectContext/hooks";
import useUser from "../../../core/user/useUser";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";
import { HierarcyDropdown } from "../../../components/RoleTemplates/HierarcyDropdown";
import { SelectOrganization } from "../../../components/RoleTemplates/SelectOrganization";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import { TimezoneDropdown } from "../../../components/Administrator/TimezoneDropdown";
import { DatetimeDropdown } from "../../../components/Administrator/DatetimeDropdown";
import Panel from "../../../ui-lib/components/Panel/Panel";
import styles from "../Administrator.module.css";
import {
  IDropdownItem,
  IItemProp,
} from "../../../ui-lib/components/Dropdown/DropdownItem";
import {
  DateTimeFormat,
  Timezone,
} from "../../../core/api/administrators/types";
import { OperatorAccessDropdown } from "../../../components/Administrator/OperatorAccess";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const RoleTemplateGeneralForm = ({
  organisations,
  authenticatedRequest,
  objectForm,
  isEditPage,
  isEditable = true,
  timezones,
  dateTimeFormats,
  responceCenterId,
}: {
  organisations: ITreeData[];
  authenticatedRequest: AxiosInstance;
  objectForm: any;
  isEditPage: boolean;
  isEditable?: boolean;
  timezones: Timezone[];
  dateTimeFormats: DateTimeFormat[];
  responceCenterId?: number;
}) => {
  const t = useTranslations();
  const { accountId } = useUser();
  const [loadingRespondentGroups, setLoadingRespondentGroups] = useState(false);
  const [loadingResponseCenters, setLoadingResponseCenters] = useState(false);
  const [isPublicSelectedArc, setIsPublicSelectedArc] = useState<boolean>();
  const [selectedOrganization, setSelectedOrganization] = useState<number>();
  const [responseCenters, setResponseCenters] = useState<
    ResponseCenterResponse[]
  >([]);
  const [belongsTo, setBelongsTo] = useState<IItemProp[]>();

  const { data: availableRoles, isLoading: loadingAvailableRoles } =
    useAvailableRoles(accountId as number);
  const [respondentGroups, setRespondentGroups] = useState(
    [] as RespondentGroup[]
  );

  const formRef = useObjectFormRef();

  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = objectForm;

  const selectedType = watch("type");

  // function for loading respondent groups from the api
  const loadRespondentGroups = async (item: IDropdownItem) => {
    setLoadingRespondentGroups(true);

    setValue("respondentGroups", []);
    setRespondentGroups([]);

    const rg = await getRespondentGroups(item.id, authenticatedRequest);
    setLoadingRespondentGroups(false);
    setRespondentGroups(rg);
  };

  const updateResponseCenters = async () => {
    setLoadingResponseCenters(true);
    try {
      const res = await getResponseCenters(authenticatedRequest, {
        order: "name",
        pageSize: 10000,
        includeSubCustomers: false,
      });
      setResponseCenters(res.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoadingResponseCenters(false);
    }
  };

  const preselectResponseCenter = () => {
    const selectedResponseCenter = responseCenters.find(
      (r) => r.id === responceCenterId
    );
    setIsPublicSelectedArc(selectedResponseCenter?.public);
    loadRespondentGroups({
      id: selectedResponseCenter?.id,
      name: selectedResponseCenter?.name,
    } as IDropdownItem);
    if (selectedResponseCenter) {
      setBelongsTo(
        responseCenters
          .filter(
            (r) => r.organizationId === selectedResponseCenter.organizationId
          )
          .map(
            (r) =>
              ({
                id: r.id,
                name: r.name,
                isSelected: responceCenterId === r.id,
              } as IDropdownItem)
          )
      );
    }
  };

  useEffect(() => {
    updateResponseCenters();
  }, []);

  useEffect(() => {
    if (responseCenters.length && responceCenterId) {
      preselectResponseCenter();
    }
    reset();
  }, [responseCenters]);

  useEffect(() => {
    if (!isEditPage) {
      if (selectedType === "respondent") {
        setBelongsTo(
          responseCenters
            .filter((r) => r.organizationId === selectedOrganization)
            .map(
              (r) =>
                ({
                  id: r.id,
                  name: r.name,
                  isSelected: getValues().responseCenterId === r.id,
                } as IDropdownItem)
            )
        );
        setValue("hierarchyUsage", 1);
      } else if (selectedType === "admin") {
        setValue("responseCenterId", null);
      }
    }
  }, [selectedOrganization, selectedType]);

  const radioItemsType = useMemo(
    () => [
      {
        idForText: "admin",
        value: "admin",
        text: t("Common:admin"),
        description: t("Administrator:admin_description"),
        image: "user",
        isSelected: isEditPage
          ? selectedType === "customer"
          : selectedType === "admin",
      },
      {
        idForText: "respondent",
        value: "respondent",
        text: t("Common:respondent"),
        description: t("Administrator:respondent_description"),
        image: "phone-incoming",
        isSelected: selectedType === "respondent",
      },
    ],
    [t, selectedType]
  );

  const responseCenterDropdown: any = responseCenters?.map((r) => ({
    id: r.id,
    name: r.name,
    isSelected: getValues().responseCenterId === r.id,
  }));

  if (loadingResponseCenters || loadingAvailableRoles) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <form ref={formRef}>
      <Row>
        <Column>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                label={t("Administrator:role_template_name")}
                placeholder={t("Administrator:role_template_name")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors?.name && t("Errors:input_field_required")
                }
              />
            )}
          />
        </Column>
        <Spacer size={16} />
        <Column />
      </Row>
      <Spacer size={32} />
      <Row type="fill" align="fill" style={{ alignSelf: "start" }}>
        <Column align="start">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioGroup
                label={t("Common:type")}
                name="template-type"
                items={radioItemsType}
                selectedValue={
                  field.value === "customer" ? "admin" : field.value
                }
                onChange={(value) => {
                  field.onChange(value);
                }}
                fullWidth
                disabled={isEditPage}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      {selectedType === "admin" || selectedType === "customer" ? (
        <Row align="start">
          <>
            <SelectOrganization
              organisations={organisations}
              trigger={trigger}
              errors={errors}
              control={control}
              t={t}
              setValue={setValue}
              setSelectedOrganization={setSelectedOrganization}
              disable={isEditPage}
              respondent={false}
              mandatory={!isPublicSelectedArc}
            />
            <Spacer size={16} />
            <HierarcyDropdown
              errors={errors}
              control={control}
              t={t}
              setValue={setValue}
              disable={isEditPage}
            />
          </>
        </Row>
      ) : (
        <Row align="start">
          <SelectOrganization
            organisations={organisations}
            trigger={trigger}
            errors={errors}
            control={control}
            t={t}
            setValue={setValue}
            setSelectedOrganization={setSelectedOrganization}
            disable={isEditPage}
            mandatory={!isPublicSelectedArc}
            respondent
          />
          <Spacer size={16} />
          <Column>
            <Controller
              name="responseCenterId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  required
                  title={t("Common:belongs_to")}
                  width="100%"
                  selectedItem={belongsTo?.find((i: any) => i.isSelected)}
                  placeholder={t("Administrator:choose_organisation_first")}
                  disabled={isEditPage || !belongsTo?.length}
                  items={belongsTo ?? []}
                  onSelectItem={async (item) => {
                    setValue(field.name, item.id as number);
                    setBelongsTo(
                      belongsTo?.map((i) => ({
                        ...i,
                        isSelected: i.id === item.id,
                      }))
                    );
                    loadRespondentGroups(item);
                  }}
                  validationError={
                    (!loadingResponseCenters &&
                      !!selectedOrganization &&
                      (!responseCenterDropdown ||
                        responseCenterDropdown.length === 0) &&
                      t("Errors:no_alarm_reception")) ||
                    (errors.responseCenterId &&
                      t("Errors:input_field_required")) ||
                    ""
                  }
                />
              )}
            />
          </Column>
        </Row>
      )}

      <Spacer size={16} />

      <Row align="start">
        <Column align="start" style={{ width: "100%" }}>
          <Controller
            name="primaryLanguage"
            control={control}
            render={({ field }) => (
              <LanguageDropdown
                label={t("Administrator:general_input_primary_language")}
                selected={field.value}
                onSelect={(value) => setValue(field.name, value)}
                disabled={!isEditable}
              />
            )}
          />
        </Column>
        <Spacer size={16} />
        <Column style={{ width: "100%" }}>
          {(selectedType === "admin" || selectedType === "customer") && (
            <Controller
              name="secondaryLanguage"
              control={control}
              render={({ field }) => (
                <LanguageDropdown
                  label={t("Administrator:general_input_secondary_language")}
                  selected={field.value}
                  onSelect={(value) => setValue(field.name, value)}
                  disabled={!isEditable}
                />
              )}
            />
          )}
        </Column>
      </Row>
      <Spacer size={16} />

      <Row align="start">
        <Column style={{ width: "100%" }}>
          <DatetimeDropdown
            dateTimeFormats={dateTimeFormats}
            control={control}
            setValue={setValue}
            t={t}
            disabled={!isEditable}
          />
        </Column>

        <Spacer size={16} />
        <Column style={{ width: "100%" }}>
          <TimezoneDropdown
            timezones={timezones}
            control={control}
            setValue={setValue}
            t={t}
            disabled={!isEditable}
          />
        </Column>
      </Row>
      <Spacer size={32} />
      <Controller
        control={control}
        name="roles"
        render={({ field }) =>
          loadingAvailableRoles ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <PermissionList
              availableRoles={availableRoles}
              field={field}
              setValue={setValue}
              isEditable={isEditable}
            />
          )
        }
      />
      {selectedType === "respondent" && (
        <>
          <Spacer size={32} />
          <Row align="start">
            <Column align="start" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="notifyIfNotLoggedIn"
                defaultValue={0}
                render={({ field }) => {
                  const dropdownItems = [
                    {
                      id: 0,
                      name: t("Administrator:no_notification"),
                      isSelected: field.value === 0,
                    },
                    {
                      id: 2,
                      name: t("Administrator:sms_app"),
                      isSelected: field.value === 2,
                    },
                  ];

                  return (
                    <Dropdown
                      title={t("Administrator:notify_if_not_logged_in")}
                      width="100%"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) => {
                        setValue(field.name, item.id as number);
                      }}
                      disabled={!isEditable}
                    />
                  );
                }}
              />
            </Column>
            <Spacer size={16} />
            <Column style={{ width: "100%" }}>
              <OperatorAccessDropdown
                control={control}
                setValue={setValue}
                t={t}
              />
            </Column>
          </Row>
          <Spacer size={16} />
          <Row align="start">
            <Column align="start" style={{ width: "100%" }}>
              <Controller
                control={control}
                name="readOnly"
                render={({ field }) => (
                  <Checkbox
                    label={t("Administrator:read_only")}
                    checked={field.value}
                    onChange={() => {
                      setValue(field.name, !field.value);
                    }}
                    disabled={!isEditable}
                  />
                )}
              />
            </Column>
          </Row>
          <Spacer size={16} />

          <h2>{t("Common:respondent_groups")}</h2>
          <p>{t("Administrator:permissions_respondent_group_description")}</p>
          <Spacer size={16} />
          {getValues().responseCenterId ? (
            <RespondentGroupList
              respondentGroups={respondentGroups}
              control={control}
              setValue={setValue}
              isLoading={loadingRespondentGroups}
              disabled={!isEditable}
              watch={watch}
            />
          ) : (
            <Panel className={styles.noRespondentGroups}>
              <p>{t("Administrator:choose_respondent_group_required")}</p>
            </Panel>
          )}
        </>
      )}
    </form>
  );
};
