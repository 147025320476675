import { AxiosInstance } from "axios";
import useAuthenticatedFetch from "./useAuthenticatedFetch";

interface AlarmLog {
  adminName: string;
  created: Date;
  id: number;
  subType: number;
  text: string;
  type: number;
}

export interface AlarmDetailsResponse {
  operator: string;
  responseTime: string;
  timeUntilVisible: string;
  alarmCode: number;
  alarmPageLink: string;
  canBeClassified: boolean;
  deactivationTime: Date;
  flags: Array<number>;
  globalAlarmId: string;
  id: number;
  log: Array<AlarmLog>;
  name: string;
  password: string;
  phone: string;
  productName: string;
  responseCenter: string;
  responseCenterId: number;
  serverAlarmTime: Date;
  textMessage: string;
  transmitterTelephoneNumber: string;
  triggerIdentifier: string;
  userId: number;
  alarmId?: number;
  alarmCodeDescription: string;
}

export interface AlarmAttachmentResponse {
  attachmentIdentifier: string;
  attachmentType: number;
  created: string;
  id: number;
  link: boolean;
  uri: string;
}

export interface AlarmClassifications {
  availableClassifications: any[];
  comment: string;
  currentClassification?: any[];
  classification?: string[];
  possibleToClassify: boolean;
  triggerIdentifier: string;
}

export const useAlarmDetails = (id: string) =>
  useAuthenticatedFetch<AlarmDetailsResponse>(`/api/v2/alarms/${id}`);

export const useAlarmAttachments = (id: string) =>
  useAuthenticatedFetch<AlarmAttachmentResponse[]>(
    `/api/v2/alarms/${id}/attachments`
  );

export const getClassifications = (
  id: string,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.get<any>(`/api/v2/alarms/${id}/classification`);

export const saveClassification = (
  id: string,
  data: AlarmClassifications,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put(
    `/api/alarms/${id}/classification`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const getAlarmAttachment = (
  authenticatedRequest: AxiosInstance,
  alarmId: string,
  attachmentId: number
) =>
  authenticatedRequest.get<any>(
    `/api/v2/alarms/${alarmId}/attachments/${attachmentId}`
  );
