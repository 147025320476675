import { RefObject } from "react";
import { Column } from "../../components/Layout/Layout";
import { TableRowSelectionPopupActions } from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import { Camera } from "../../core/api/cameras/types";
import Table, {
  RowAction,
  SortOrderParams,
  TableColumn,
} from "../../ui-lib/components/Tables/Table";
import useTranslations from "../../core/i18n/useTranslations";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import NoItems from "../../ui-lib/components/Tables/NoItems";

import styles from "../PagesLayout.module.css";

interface ICamerasTableProps {
  camerasData: Camera[];
  totalAmount: number;
  rowActions: RowAction<Object>[];
  pageSettings: {
    page: number;
    pageSize: number;
  };
  tableRowSelectionPopupRef: RefObject<
    TableRowSelectionPopupActions<Record<string, any>>
  >;
  sortParams?: SortOrderParams;
  loading: boolean;
  isFilterApplied: boolean;
  setClearItemsFunction: (func: Function) => void;
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  onSortChange?: (sortOrderParams?: SortOrderParams) => void;
  resetPageFilter: () => void;
}

const columns = (t: (key: string) => string): TableColumn<Camera>[] => [
  {
    key: "title",
    header: t("Cameras:table_columns_title"),
    sortByField: "title",
    fieldTemplate: (rowData: Camera) => (
      <TableCell
        value={rowData.title}
        linkTo={`/adminportal/cameras/${rowData.id}`}
      />
    ),
  },
  {
    key: "cameraType",
    header: t("Cameras:table_columns_cameraType"),
    sortByField: "cameraType",
    fieldTemplate: (rowData: Camera) => (
      <TableCell value={t(`Cameras:CameraType_${rowData.cameraType}`)} />
    ),
  },

  {
    key: "organizationName",
    header: t("Cameras:table_columns_organizationName"),
    sortByField: "organizationName",
    fieldTemplate: (rowData: Camera) => (
      <TableCell
        value={rowData.organizationName}
        linkTo={`/adminportal/organizations/${rowData.organizationId}`}
      />
    ),
  },
  {
    key: "description",
    header: t("Cameras:table_columns_description"),
    sortByField: "description",
    fieldTemplate: (rowData: Camera) => (
      <TableCell value={rowData.description} />
    ),
  },
];

const CamerasTable = ({
  camerasData,
  totalAmount,
  rowActions,
  sortParams,
  pageSettings,
  tableRowSelectionPopupRef,
  loading,
  isFilterApplied,
  setClearItemsFunction,
  onPageSettingsChange,
  onSortChange,
  resetPageFilter,
}: ICamerasTableProps) => {
  const t = useTranslations();

  if (loading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <Column className={styles.tableContainer} type="top">
      {camerasData.length ? (
        <Table<Camera>
          rowActionsFixed
          showRowHover
          hideEmptyMessage
          withRowSelection
          withPagination
          withLazyLoading
          showRowsActionsOnHover
          tableName="table-cameras"
          items={camerasData}
          columns={columns(t)}
          rowActions={rowActions}
          rowActionsColWidth={64}
          sortParams={sortParams}
          onSortChange={(params) => {
            resetPageFilter();
            onSortChange?.(params);
          }}
          setClearItemsFunction={setClearItemsFunction}
          onRowSelectionChange={(selectedItems) => {
            tableRowSelectionPopupRef.current?.open(selectedItems);
          }}
          paginatedItems={{
            items: camerasData,
            pagination: {
              offset: pageSettings.pageSize * (pageSettings.page - 1),
              limit: pageSettings.pageSize,
              total: totalAmount,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              onPageSettingsChange({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
        />
      ) : (
        <NoItems
          title={
            !isFilterApplied
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!isFilterApplied ? "eye-off" : undefined}
          subTitle={
            !isFilterApplied
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={undefined}
        />
      )}
    </Column>
  );
};

export default CamerasTable;
