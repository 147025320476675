import React, { useEffect } from "react";
import useBranding from "../core/branding/useBranding";
import { setTheme } from "../core/helpers/helpers";
import { useResponsiveStyle } from "../core/hooks/dimensionProvider";
import useLanguage from "../core/i18n/useLanguage";

interface Props {
  children: React.ReactNode;
}
const Theme = (props: Props) => {
  const brandingStore = useBranding();
  const languageStore = useLanguage();
  useEffect(() => {
    brandingStore.getBranding().then(() => {
      setTheme(
        brandingStore.currentCss,
        brandingStore.currentFavicon,
        brandingStore.currentTitle
      );
      languageStore.setCurrentLanguage(
        languageStore.getStoredLanguage() ?? brandingStore.currentLoginLanguage
      );
    });
  }, []);

  return (
    <div
      className={useResponsiveStyle<string>({
        mobile: () => "mobile",
        tablet: () => "mobile",
        desktop: () => "desktop",
      })}
    >
      {props.children}
    </div>
  );
};
export default Theme;
