import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Column } from "../../components/Layout/Layout";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import OrganizationActions from "./OrganizationActions";
import useSharedOrganization from "./SharedOrganizationContext/useSharedOrganization";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import useTranslations from "../../core/i18n/useTranslations";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import { SharedOrganizationProvider } from "./SharedOrganizationContext/SharedOrganizationContext";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import OrganizationGeneral from "./OrganizationGeneral/OrganizationGeneral";
import useUser from "../../core/user/useUser";
import { ADManagement } from "./ADManagement/ADManagement";
import {
  IOrganizationDetails,
  IRetentionDetails,
} from "../../core/api/organizations/types";
import { getOrganization } from "../../core/api/organizations/organizations";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { RetentionDetails } from "./RetentionDetails/RetentionDetails";
import { AdvancedSettings } from "./AdvancedSettings/AdvancedSettings";
import SubOrganizations from "./Sub-Organizations/SubOrganizations";
import usePageState from "../../core/pagestate/usePageState";
import AlarmReceptions from "../AlarmReceptions/AlarmReceptions";
import { SettingsContainer } from "./Settings/SettingsContainer";
import OrganizationAdmins from "./OrganisationAdmins";
import { Integrations } from "./Integrations/Integrations";

import styles from "../PagesLayout.module.css";

const parentPath = "/adminportal/organizations";

interface ISubNav {
  key: string;
  to: string;
  icon?: string;
}

export const SubMenuItems = (
  url: string,
  baseUrl?: string,
  organizationDetails?: IOrganizationDetails,
  retentionData?: IRetentionDetails
) => {
  const { config } = useUser();
  const access = {
    Integration: !!config?.show.includes("PlanningEngineIntegrationRead"),
    Retention: !!config?.show.includes("OrganizationRetentions"),
    Admins: !!config?.show.includes("M:Configure/Adminstrators"),
    AdGroupMappings: !!config?.show.includes("AdGroupMappings"),
    AdvancedSettings: !!config?.show.some((e) =>
      ["EditCustomers", "CustomerDetails"].includes(e)
    ),
    AlarmReception: !!config?.show.some((e) =>
      [
        "RcEditDetails",
        "ResponseCenterAllowViewOnlyEditable",
        "ResponseCenterRead",
        "ResponseCenter",
        "ResponseCenterViewList",
        "ResponseCenterViewDetails",
      ].includes(e)
    ),
    Settings:
      !!config?.show.includes("ApplicationMenuAlternatives") ||
      !!config?.show.includes("IPWhiteListEdit"),
  };

  const sub: ISubNav[] = [
    { key: "Menu:general", to: baseUrl ?? url },
    {
      key: "Menu:Organization_SubOrganizations",
      to: `${baseUrl ?? url}/sub-organizations`,
    },
    ...(access.Admins
      ? [
          {
            key: "Menu:administrators",
            to: `${baseUrl ?? url}/administrators`,
          },
        ]
      : []),
    ...(access.AlarmReception
      ? [
          {
            key: "Menu:alarmreceptions",
            to: `${baseUrl ?? url}/alarm-receptions`,
          },
        ]
      : []),
    ...(organizationDetails?.identityProviderConnection &&
    access.AdGroupMappings
      ? [
          {
            key: "Menu:Organization_ADManagement",
            to: `${baseUrl ?? url}/ad-management`,
          },
        ]
      : []),
    ...(access.Retention
      ? [
          {
            key: "Menu:Organization_RetentionDetails",
            to: `${baseUrl ?? url}/retention-details`,
            icon: `${
              retentionData?.alarmDataRetention === -1 ||
              retentionData?.alarmAfterObjectDeleteRetention === -1
                ? "exclamation-circle"
                : ""
            }`,
          },
        ]
      : []),
    ...(access.Integration
      ? [
          {
            key: "Menu:intergations_instances",
            to: `${baseUrl ?? url}/integrations`,
          },
        ]
      : []),

    ...(access.Settings
      ? [
          {
            key: "Menu:settings",
            to: `${baseUrl ?? url}/settings`,
          },
        ]
      : []),
    ...(access.AdvancedSettings
      ? [
          {
            key: "Menu:advanced_settings",
            to: `${baseUrl ?? url}/advanced-settings`,
          },
        ]
      : []),
  ];

  return { sub, access };
};

const OrganizationDetailsWrapper = ({
  data,
  children,
}: {
  data: any;
  children: ReactNode;
}) => {
  return (
    <>
      <PageHeader title={data.name} icon="office-building">
        <OrganizationActions id={data.id} />
      </PageHeader>
      <Column className={styles.content} type="top" align="start">
        {children}
      </Column>
    </>
  );
};

const OrganizationDetails = ({
  access,
}: {
  access: {
    Retention: boolean;
    AdvancedSettings: boolean;
    Settings: boolean;
    Integration: boolean;
  };
}) => {
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();
  const { data, retentionData } = useSharedOrganization();

  if (!data || (!retentionData && access.Retention)) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <Switch>
      <Route path={`${path}/sub-organizations`}>
        <OrganizationDetailsWrapper data={data}>
          <SubOrganizations id={+id} />
        </OrganizationDetailsWrapper>
      </Route>
      <Route path={`${path}/ad-management`}>
        <OrganizationDetailsWrapper data={data}>
          <ADManagement id={id} />
        </OrganizationDetailsWrapper>
      </Route>
      <Route path={`${path}/administrators`}>
        <OrganizationDetailsWrapper data={data}>
          <OrganizationAdmins organisationId={Number(id)} />
        </OrganizationDetailsWrapper>
      </Route>
      {access.Retention && (
        <Route path={`${path}/retention-details`}>
          <OrganizationDetailsWrapper data={data}>
            <RetentionDetails />
          </OrganizationDetailsWrapper>
        </Route>
      )}
      <Route path={`${path}/alarm-receptions`}>
        <OrganizationDetailsWrapper data={data}>
          <AlarmReceptions organizationId={Number(id)} />
        </OrganizationDetailsWrapper>
      </Route>
      {access.Integration && (
        <Route path={`${path}/integrations`}>
          <OrganizationDetailsWrapper data={data}>
            <Integrations id={id} />
          </OrganizationDetailsWrapper>
        </Route>
      )}
      {access.Settings && (
        <Route path={`${path}/settings`}>
          <OrganizationDetailsWrapper data={data}>
            <SettingsContainer id={id} />
          </OrganizationDetailsWrapper>
        </Route>
      )}
      {access.AdvancedSettings && (
        <Route path={`${path}/advanced-settings`}>
          <OrganizationDetailsWrapper data={data}>
            <AdvancedSettings id={Number(id)} />
          </OrganizationDetailsWrapper>
        </Route>
      )}
      <Route path={path}>
        <OrganizationDetailsWrapper data={data}>
          <OrganizationGeneral />
        </OrganizationDetailsWrapper>
      </Route>
    </Switch>
  );
};

const OrganizationDetailsLoader = ({
  organizationDetails,
  isError,
}: {
  organizationDetails?: IOrganizationDetails;
  isError: boolean;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const pageState = usePageState();
  const { url } = useRouteMatch();
  const { retentionData } = useSharedOrganization();
  const { sub, access } = SubMenuItems(
    url,
    undefined,
    organizationDetails,
    retentionData
  );
  const { setSubNav } = useSubNav();
  const sessionPrevPath = sessionStorage.getItem("prevPath");

  const getBackUrl = () => {
    if (sessionPrevPath) {
      const parsedPath = JSON.parse(sessionPrevPath);
      return {
        path: parsedPath.path,
        name: parsedPath.pathName,
      };
    }
    if (pageState.initialIds.length > 1) {
      return {
        path: `${parentPath}/${
          pageState.initialIds[pageState.initialIds.length - 1].id
        }`,
        name: pageState.initialIds[pageState.initialIds.length - 2].name,
      };
    }
    return {
      path: parentPath,
      name: t("Organizations:organizations"),
    };
  };

  useEffect(() => {
    const nav = {
      menu: sub,
      backUrl: getBackUrl().path,
      backText: getBackUrl().name,
    };
    setSubNav(nav);
    return () => {
      setSubNav(undefined);
    };
  }, [organizationDetails, retentionData]);

  useEffect(() => {
    if (isError) {
      history.replace(parentPath);
    }
  }, [isError]);

  return <OrganizationDetails access={access} />;
};

const OrganizationDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();
  const [isError, setIsError] = useState(false);
  const [organizationDetails, setOrganizationDetails] =
    useState<IOrganizationDetails>();

  const getOrganizationData = async () => {
    try {
      const result = await getOrganization(id, authenticatedRequest);
      setOrganizationDetails(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
      setOrganizationDetails({} as IOrganizationDetails);
      setIsError(true);
    }
  };

  useEffect(() => {
    getOrganizationData();
  }, [id]);

  return (
    <SharedOrganizationProvider
      organizationId={id}
      preOrganizationDetails={organizationDetails}
      fetchOrganizationData={false}
      getOrganizationData={getOrganizationData}
    >
      <SaveObjectProvider>
        <OrganizationDetailsLoader
          isError={isError}
          organizationDetails={organizationDetails}
        />
      </SaveObjectProvider>
    </SharedOrganizationProvider>
  );
};

export default observer(OrganizationDetailsContainer);
