import { useState } from "react";
import useUser from "../../core/user/useUser";
import { Spacer } from "../../components/Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import Alert from "../../ui-lib/components/Alerts/Alert";
import { patchUpdateObject } from "../../core/api/objects/objects";
import { ObjectPatch } from "../../core/api/objects/types";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { RowData } from "../../pages/Objects/ObjectsTable";
import { getErrorKey } from "../Errors/ErrorAlert";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

interface ObjectPair {
  ObjectId: string;
  ObjectName: string;
  Error: string;
}
const MoveObjectModal = ({
  onClose,
  onSuccess,
  onPartialSuccess,
  modalTitle,
  text,
  objects,
  isOpen,
}: {
  onClose?: () => void;
  onSuccess?: () => void;
  onPartialSuccess?: () => void;
  modalTitle: string;
  text: string;
  objects: RowData[];
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { data: organisations } = useOrganizationsTree();
  const [selectedOrg, setSelectedOrg] = useState<number>();
  const [errors, setErrors] = useState<ObjectPair[]>([]);
  const close = () => {
    setSelectedOrg(undefined);
    setErrors([]);
    onClose?.();
  };

  const onSubmit = async () => {
    let success = true;
    if (selectedOrg !== undefined) {
      setIsLoading(true);
      errors.length = 0; // reset error list in case the user does multipe attempts on the operation
      const pairs = objects.map((sr) => ({
        ObjectId: sr.id,
        ObjectName: sr.Name,
        Error: "",
      }));
      const object: ObjectPatch = {
        organizationId: selectedOrg,
      };
      for (const pair of pairs) {
        try {
          await patchUpdateObject(pair.ObjectId, object, authenticatedRequest);
        } catch (error: any) {
          success = false;
          if (
            error.response?.data?.errors &&
            error.response?.data?.errors.length > 0
          ) {
            pair.Error = error.response?.data?.errors[0].errorName;
            errors.push(pair);
          }
        }
      }

      if (errors.length > 0) {
        setErrors([...errors]);

        // some updates actually made it through, update table to display those changes
        if (errors.length < pairs.length) {
          onPartialSuccess?.();
        }
      } else {
        setErrors([]);
      }
    }
    setIsLoading(false);
    if (success) {
      notify({
        message: t("Objects:move_object_success"),
      });
      onSuccess?.();
      close();
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      isOpen={isOpen}
      onClose={close}
      header={modalTitle}
      loading={isLoading}
      submitBtn={{
        text: `${t("Objects:button_change_organisation")} (${objects.length})`,
        onClick: onSubmit,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: close }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {text.replace("{objects}", objects.length.toString())}
      </p>
      <Spacer size={16} />
      <InputContainer
        label={
          <label htmlFor="object_move_organisation">
            {t("Objects:new_organisation")}
          </label>
        }
        input={
          <Tree
            id="object_move_organisation"
            placeholder={t("Common:select_organisation")}
            items={organisations}
            selectedTreeItem={selectedOrg}
            onSelectItem={(data: ITreeData) => {
              if (data.key) {
                setSelectedOrg(+data.key);
              } else {
                setSelectedOrg(undefined);
              }
            }}
          />
        }
      />
      {errors.length > 0 && (
        <>
          <Spacer size={32} />
          <Alert
            items={errors.map((item) => ({
              title: t(`Errors:${getErrorKey(item.Error)}`).replace(
                "{admin}",
                item.ObjectName
              ),
              key: `${item.Error}-${item.ObjectId}`,
              onClosed: () => {},
            }))}
            variant="error"
            icon="exclamation-circle"
            title={t("Errors:move_failed")}
          />
        </>
      )}
    </PrimeModal>
  );
};

export default MoveObjectModal;
