import { IProduct } from "../../../core/api/products/types";
import useTranslations from "../../../core/i18n/useTranslations";
import { Column } from "../../../components/Layout/Layout";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Table from "../../../ui-lib/components/Tables/Table";
import { TableCell } from "../../../ui-lib/components/Tables/TableCell";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DeleteProductModal } from "./DeleteProductModal";

const columns = (t: (key: string) => string) => [
  {
    header: t("Reseller:product_id"),
    fieldTemplate: (rowData: IProduct) => (
      <TableCell
        value={rowData.productId}
        linkTo={`/adminportal/products/${rowData.id}`}
      />
    ),
  },
  {
    header: t("Reseller:product"),
    fieldTemplate: (rowData: IProduct) => <TableCell value={rowData.name} />,
  },
  {
    header: t("Organizations:organization"),
    fieldTemplate: (rowData: IProduct) => (
      <TableCell value={rowData.organization} />
    ),
  },
  {
    header: t("Reseller:action"),
    fieldTemplate: (rowData: IProduct) => (
      <TableCell value={rowData.requireAction} />
    ),
  },
  {
    header: t("Reseller:identifier_prefix"),
    fieldTemplate: (rowData: IProduct) => (
      <TableCell value={rowData.identifierPrefix} />
    ),
  },
];

export const ProductsTable = ({
  products,
  isLoading,
  searchParam,
  fetchProducts,
}: {
  products: IProduct[];
  isLoading: boolean;
  searchParam: string;
  fetchProducts: () => void;
}) => {
  const t = useTranslations();
  const history = useHistory();

  const [pageSettings, setPageSettings] = useState({
    page: 1,
    pageSize: 10,
    offset: 0,
  });
  const [itemsToShow, setItemsToShow] = useState<IProduct[]>(
    products.length ? products.slice(0, pageSettings.pageSize) : []
  );
  const [selectedProductId, setSelectedProductId] = useState<number>();

  useEffect(() => {
    setItemsToShow(products.slice(0, pageSettings.pageSize));
  }, [products]);

  useEffect(() => {
    if (searchParam) {
      setItemsToShow(products.filter((product) => product.id === +searchParam));
    } else {
      setItemsToShow(products.slice(0, pageSettings.pageSize));
    }
    setPageSettings({
      page: 1,
      pageSize: 10,
      offset: 0,
    });
  }, [products, searchParam]);

  return (
    <>
      <DeleteProductModal
        onClose={(deleted?: boolean) => {
          setSelectedProductId(undefined);
          console.log(deleted);
          if (deleted) {
            fetchProducts();
            setPageSettings({
              page: 1,
              pageSize: pageSettings.pageSize,
              offset: 0,
            });
          }
        }}
        productId={selectedProductId!}
        isOpen={!!selectedProductId}
      />
      {isLoading ? (
        <Column>
          <LoadingSpinner theme="primary" />
        </Column>
      ) : (
        <Table<IProduct>
          items={products}
          columns={columns(t)}
          hideEmptyMessage
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (product) =>
                history.push(`/adminportal/products/${product.productId}`),
            },
            {
              icon: "trash",
              text: t("Common:delete"),
              iconVariant: "secondary",
              onClick: (product) => setSelectedProductId(product.id),
            },
          ]}
          rowActionsColWidth={80}
          rowActionsFixed
          withRowSelection
          showRowHover
          withLazyLoading
          withPagination
          paginatedItems={{
            items: itemsToShow,
            pagination: {
              offset: pageSettings.offset,
              limit: pageSettings.pageSize,
              total: searchParam.length ? itemsToShow.length : products.length,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);
            if (!Number.isNaN(page) && nextPage.limit) {
              setItemsToShow(
                products.slice(
                  page * nextPage.limit,
                  page * nextPage.limit + nextPage.limit
                )
              );
              setPageSettings({
                page: page + 1,
                pageSize: nextPage.limit,
                offset: nextPage.offset,
              });
            }
          }}
        />
      )}
    </>
  );
};
