import { useEffect, useState } from "react";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Column, Spacer } from "../../../../components/Layout/Layout";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { getAdminSettings } from "../../../../core/api/administrators/administrators";
import useUser from "../../../../core/user/useUser";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { ISpecialSettings } from "../../../../core/api/administrators/types";
import Button from "../../../../ui-lib/components/Button/Button";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import { EditSettingsModal } from "./EditSettingsModal";
import { DeleteSettingsModal } from "./DeleteSettingsModal";

export const Settings = ({
  id,
  onlyTable = false,
}: {
  id: number;
  onlyTable?: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [settingsData, setSettingsData] = useState<ISpecialSettings[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState<
    ISpecialSettings | undefined
  >();

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const result = await getAdminSettings(id, authenticatedRequest);
      setSettingsData(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModals = () => {
    setIsEditModalOpen(false);
    setIsDeleteOpen(false);
    setSelectedSetting(undefined);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <>
      <Column style={{ width: "100%" }} type="top" align="start">
        {!onlyTable && (
          <>
            <h1>{t("Administrator:settings_title")}</h1>
            <Spacer size={8} />
            <p style={{ color: "var(--Grey-600)" }}>
              {t("Administrator:settings_subtitle")}
            </p>
            <Spacer size={32} />
          </>
        )}

        <div
          style={
            onlyTable
              ? {
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }
              : { width: "100%", textAlign: "end" }
          }
        >
          {onlyTable && <h2>{t("Administrator:settings_title")}</h2>}
          <Button
            variant="secondary"
            image="link"
            text={t("Administrator:add_setting")}
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          />
        </div>

        <Spacer size={16} />
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <Table<ISpecialSettings>
            columns={[
              {
                header: t("Menu:settings"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.setting} />
                ),
              },
              {
                header: t("Common:custom_filters_column_value"),
                fieldTemplate: (rowData) => <TableCell value={rowData.value} />,
              },
            ]}
            hideEmptyMessage
            items={settingsData}
            showRowHover
            withShowMore
            rowActions={
              [
                {
                  icon: "pencil-alt",
                  text: t("Common:label_edit"),
                  onClick: (rowData: ISpecialSettings) => {
                    setSelectedSetting(rowData);
                    setIsEditModalOpen(true);
                  },
                },
                {
                  icon: "trash",
                  text: t("Common:delete"),
                  iconVariant: "secondary",
                  onClick: (rowData: ISpecialSettings) => {
                    setSelectedSetting(rowData);
                    setIsDeleteOpen(true);
                  },
                },
              ] as any
            }
            rowActionsFixed
          />
        )}
      </Column>
      {isEditModalOpen && (
        <EditSettingsModal
          adminId={id}
          onClose={closeModals}
          settingsData={selectedSetting}
          onSubmit={fetchSettings}
          isEditModalOpen={isEditModalOpen}
        />
      )}
      {isDeleteOpen && (
        <DeleteSettingsModal
          adminId={id}
          idToDelete={selectedSetting?.id!}
          onClose={closeModals}
          onDelete={() => {
            closeModals();
            fetchSettings();
          }}
          isOpen={isDeleteOpen}
        />
      )}
    </>
  );
};
