import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { saveLocation } from "../../core/api/locations/locations";
import {
  EditLocationModel,
  NewLocationResponseData,
} from "../../core/api/locations/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../core/SaveObjectContext/hooks";
import useUser from "../../core/user/useUser";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import Panel from "../../ui-lib/components/Panel/Panel";
import Radio from "../../ui-lib/components/Radio/Radio";
import { GoogleMap } from "../GoogleMap/GoogleMap";
import {
  validateAllHexadecimal,
  validateHexadecimal,
} from "../../core/helpers/validation";
import { notify } from "../../ui-lib/components/Alerts/Toast";

const klTypes = [
  { id: 1, value: "IBEACON" },
  { id: 2, value: "GENERIC" },
  { id: 3, value: "SRT" },
  { id: 4, value: "NFC" },
  { id: 5, value: "POE" },
  { id: 6, value: "QR" },
  { id: 7, value: "PWSRIN" },
  { id: 8, value: "PWSROUT" },
  { id: 9, value: "TWB" },
  { id: 10, value: "ROOM" },
  { id: 11, value: "WARDEN_CALL" },
];

export declare type EditLocationActions = {
  submitForm: () => Promise<SubmitResult>;
  reset: () => void;
};
export interface SubmitResult {
  status: boolean;
  data: NewLocationResponseData;
}
export interface EditLocationProps {
  location?: EditLocationModel;
  organisationTree: ITreeData[];
}
export const LocationEditForm = forwardRef<
  EditLocationActions,
  EditLocationProps
>((props: EditLocationProps, ref) => {
  const { location, organisationTree } = props;
  const t = useTranslations();
  const formRef = useObjectFormRef();
  const [mapChoice, setMapChoice] = useState<string>(
    location?.longitude && location.longitude ? "mark" : "leave"
  );
  const [selectedType, setSelectedType] = useState<string | undefined>();
  const { authenticatedRequest, config } = useUser();
  const isNew = useRef<boolean>(true);
  const [belongsToId, setBelongsToId] = useState(
    location?.organizationId ?? undefined
  );
  const [beaconTypes, setBeaconTypes] = useState<IDropdownItem[]>([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    clearErrors,
    unregister,
  } = useObjectForm<EditLocationModel>({
    defaultValues: {
      organizationId: location ? location.organizationId : undefined,
      description: location ? location.description : undefined,
      id: location ? location.id : undefined,
      identifierString: location ? location.identifierString : undefined,
      klType: location ? location.klType : undefined,
      latitude: location ? location.latitude : null,
      longitude: location ? location.longitude : null,
      marking: location ? location.marking : undefined,
    },
  });

  const markingPlaceholder = t(`Locations:InputHelpMarking_${selectedType}`);

  const setPosition = () => {
    if (location?.latitude && location?.longitude) {
      location.position = {
        lat: location.latitude,
        lng: location.longitude,
      };
      trigger("position");
    }
  };

  const setLocation = () => {
    if (location) {
      isNew.current = false;
      setSelectedType(location.klType);
      setMapChoice(
        !location.latitude || !location.longitude ? "leave" : "mark"
      );
      trigger("klType");
      reset(location);
    }
  };

  const setBeacon = () => {
    const newBeacons = [] as IDropdownItem[];
    klTypes.forEach((val) => {
      if (
        (val.id === 2 &&
          config &&
          config.show.indexOf("KnownLocationsCustoms") > -1) ||
        (val.id === 5 && config && config.show.indexOf("POELocations") > -1) ||
        [1, 3, 4, 6, 7, 8, 9, 10, 11].indexOf(val.id) > -1
      ) {
        newBeacons.push({
          id: val.id,
          name: t(`Locations:BeaconType${val.id}`),
          isSelected: selectedType
            ? selectedType.toUpperCase() === val.value
            : location?.klType!.toUpperCase() === val.value,
        });
      }
    });
    if (!selectedType) {
      setSelectedType(location?.klType);
    }
    setBeaconTypes(newBeacons);
  };

  const validateMarking = (value?: string) => {
    switch (selectedType) {
      case "1":
        return validateAllHexadecimal(2, 12, value);
      case "3":
      case "9":
        return validateHexadecimal(8, 8, value);
      case "2":
      case "4":
        return validateHexadecimal(1, 100, value);
      case "6":
        return validateHexadecimal(10, 256, value);
      case "5":
      case "7":
      case "8":
        return validateHexadecimal(12, 12, value);
      default:
        return;
    }
  };

  const getMarkingErrorText = (type?: string) => {
    if (!type) return;
    if (type === "required") {
      return t("Locations:validation_marking");
    }
    if (type === "validate") {
      return `${t("Errors:marking_format")} ${markingPlaceholder}`;
    }
  };

  useImperativeHandle<EditLocationActions, EditLocationActions>(ref, () => {
    const actions: EditLocationActions = {
      submitForm: async (): Promise<any> => {
        let success = true;
        let data = {};
        try {
          await handleSubmit(
            async (formData: EditLocationModel): Promise<any> => {
              const updatedData = { ...formData };
              try {
                if (formData.position) {
                  updatedData.latitude = formData.position?.lat;
                  updatedData.longitude = formData.position?.lng;
                }
                if (selectedType === "10" || selectedType === "11") {
                  delete updatedData.marking;
                }
                const result = await saveLocation(
                  updatedData,
                  authenticatedRequest,
                  updatedData.id
                );
                data = result.data;
                success = true;
              } catch (error: any) {
                const allErrors = error.response?.data.errors;
                if (
                  allErrors[0]?.errorDetails.propertyName === "Marking" &&
                  allErrors[0]?.errorMessage === "NonUniqueData"
                ) {
                  success = false;
                  return notify({
                    message: t("Locations:marking_already_exist"),
                    variant: "error",
                  });
                }
                notifyApiErrors(allErrors);
                success = false;
              }
            },
            () => {
              success = false;
            }
          )().catch(() => {
            success = false;
          });
        } catch (error) {
          success = false;
        }
        return { status: success, data };
      },
      reset,
    };
    return actions;
  });

  useEffect(() => {
    if (mapChoice === "mark") {
      setPosition();
    }
    setLocation();
    setBeacon();
  }, [location]);

  return (
    <form ref={formRef}>
      <Column type="top">
        <Row
          type="fill"
          align="fill"
          style={{ marginTop: "16px", alignSelf: "stretch" }}
        >
          <Controller
            name="klType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputContainer
                required
                label={
                  <label htmlFor="addlocation_kltype">
                    {t("Locations:create_location_kltype")}
                  </label>
                }
                input={
                  <Dropdown
                    id="addlocation_kltype"
                    disabled={!isNew.current}
                    items={beaconTypes}
                    maxVisible={10}
                    placeholder={t("Locations:select_kltype")}
                    selectedItem={beaconTypes.find((r) => r.isSelected)}
                    onSelectItem={(item) => {
                      setBeaconTypes(
                        [...beaconTypes].map((type) => ({
                          ...type,
                          isSelected: item.id === type.id,
                        }))
                      );
                      setValue(field.name, `${item.id}`);
                      setSelectedType(`${item.id}`);
                      trigger(field.name);
                      clearErrors("marking");
                    }}
                    invalid={errors.klType !== undefined}
                    validationError={
                      errors.klType && t("Locations:validation_kltype")
                    }
                  />
                }
              />
            )}
          />
          <Spacer size={16} />
          <Controller
            name="organizationId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputContainer
                required
                label={
                  <label htmlFor="addlocation_organisation">
                    {t("Locations:organisation")}
                  </label>
                }
                input={
                  <Tree
                    treeRef={field.ref}
                    id="addlocation_organisation"
                    className="stretch"
                    placeholder={t("Common:select_organisation")}
                    validationError={
                      errors.organizationId &&
                      t("Locations:organisationvalidation")
                    }
                    items={organisationTree}
                    selectedTreeItem={belongsToId ? field.value : undefined}
                    onSelectItem={(data: ITreeData) => {
                      if (data?.key) {
                        setValue(field.name, +data.key);
                        setBelongsToId(+data.key);
                      } else {
                        setValue(field.name, undefined);
                        setBelongsToId(undefined);
                      }
                      trigger(field.name);
                    }}
                  />
                }
              />
            )}
          />
        </Row>
        <Row
          type="fill"
          align="fill"
          style={{ marginTop: "16px", alignSelf: "stretch" }}
        >
          {selectedType !== "10" &&
            selectedType !== "11" &&
            selectedType !== "WARDEN_CALL" &&
            selectedType !== "ROOM" && (
              <>
                <Controller
                  name="marking"
                  control={control}
                  rules={{
                    required: true,
                    validate: validateMarking,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      disabled={!isNew.current}
                      label={t("Locations:labels_marking")}
                      placeholder={
                        selectedType
                          ? markingPlaceholder
                          : t("Locations:placeholder_marking")
                      }
                      value={field.value}
                      onChange={field.onChange}
                      validationError={getMarkingErrorText(
                        errors.marking?.type
                      )}
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            )}
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Locations:labels_description")}
                placeholder={t("Locations:placeholder_description")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Row>
        <Row
          type="fill"
          align="fill"
          style={{
            marginTop: "16px",
            alignSelf: "stretch",
            display: isNew.current ? "none" : undefined,
          }}
        >
          <Controller
            name="identifierString"
            control={control}
            render={({ field }) => (
              <TextInput
                disabled={!isNew.current}
                label={t("Locations:labels_identifierstring")}
                placeholder={t("Locations:placeholder_identifierstring")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Row>
        <Row
          type="fill"
          align="fill"
          style={{ marginTop: "16px", alignSelf: "stretch" }}
        >
          <InputContainer
            label={
              <label htmlFor="addlocation_mapchoise">
                {t("Locations:mapchoise")}
              </label>
            }
            input={
              <Panel className="stretch">
                <Radio
                  inputId="addlocation_mapchoise1"
                  name="addlocation_mapchoise"
                  text={t("Locations:leave_undefined")}
                  value="leave"
                  checked={mapChoice === "leave"}
                  onChange={() => {
                    setMapChoice("leave");
                    if (!location?.position) unregister("position");
                    setValue("latitude", null);
                    setValue("longitude", null);
                  }}
                />
                <Radio
                  inputId="addlocation_mapchoise2"
                  name="addlocation_mapchoise"
                  text={t("Locations:mark_on_map")}
                  value="mark"
                  checked={mapChoice === "mark"}
                  onChange={() => {
                    setMapChoice("mark");
                    if (location?.position) {
                      setValue("latitude", location.latitude);
                      setValue("longitude", location.longitude);
                      setValue("position", location.position);
                    }
                  }}
                />
              </Panel>
            }
          />
        </Row>
        {mapChoice === "mark" && (
          <>
            <Spacer size={16} />
            <Row type="fill" align="fill">
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <GoogleMap
                    position={field.value}
                    onSetPosition={(pos: google.maps.LatLngLiteral | null) => {
                      setValue("position", pos);
                    }}
                  />
                )}
              />
            </Row>
          </>
        )}
      </Column>
      <Spacer size={16} />
    </form>
  );
});

LocationEditForm.defaultProps = {
  location: undefined,
};
