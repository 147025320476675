import { Spacer } from "../Layout/Layout";
import { ManualSync } from "./ManualSync";
import useUser from "../../core/user/useUser";
import Divider from "../../ui-lib/components/Divider/Divider";
import useTranslations from "../../core/i18n/useTranslations";
import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";

export interface ManualSyncTableProps {
  syncStatus?: string;
  latestSyncAttempt?: string;
  lastSync?: string;
}

export const ManualSyncWrapper = ({
  clusterData,
  externalSettingData,
  refetchFunc,
}: {
  clusterData: ManualSyncTableProps;
  externalSettingData?: ManualSyncTableProps;
  refetchFunc: () => Promise<void>;
}) => {
  const t = useTranslations();
  const { config } = useUser();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  return (
    config?.show.includes("LastClusterSync") && (
      <>
        {!isMobile && (
          <>
            <Spacer size={32} />
            <Divider />
          </>
        )}
        <Spacer />
        <h2>{t("Objects:manual_sync_header")}</h2>
        <p>{t("Objects:manual_sync_description")}</p>

        <Spacer size={20} />

        <ManualSync
          buttonHidden
          title={t("Objects:sync_with_cluster")}
          data={clusterData}
          fetchFunc={refetchFunc}
        />

        {externalSettingData?.syncStatus !== "NO_INTEGRATION_DEFINED" && (
          <ManualSync
            buttonHidden={false}
            title={t("Objects:sync_with_external_system")}
            data={externalSettingData}
            fetchFunc={refetchFunc}
          />
        )}
      </>
    )
  );
};
