import { useState } from "react";
import Button, { ButtonVariant } from "../../ui-lib/components/Button/Button";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import CreateAlarmReceptionModal from "./CreateAlarmReception/CreateAlarmReceptionModal";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";

export const AddAlarmReceptionButton = ({
  buttonColor,
  icon,
  organizationId,
  treeData,
  organizationById,
  isLoading,
  refetch,
}: {
  buttonColor: ButtonVariant;
  icon?: string;
  organizationId?: number;
  treeData: ITreeData[];
  organizationById?: ITreeData | null;
  isLoading: boolean;
  refetch: () => Promise<void>;
}) => {
  const t = useTranslations();
  const { config } = useUser();
  const [addArcModalOpen, setAddArcModalOpen] = useState(false);

  return config?.show.includes("RcCreatePublic") ||
    config?.show.includes("RcCreate") ? (
    <>
      <Button
        image={icon}
        variant={buttonColor}
        text={t("AlarmReceptions:add_alarm_reception")}
        onClick={() => setAddArcModalOpen(true)}
        loading={isLoading}
        disabled={isLoading}
      />
      <CreateAlarmReceptionModal
        isOpen={addArcModalOpen}
        onClose={() => setAddArcModalOpen(false)}
        onAdd={async () => {
          setAddArcModalOpen(false);
          await refetch();
        }}
        organizationId={organizationId}
        treeData={treeData}
        organizationsById={organizationById}
      />
    </>
  ) : null;
};
