import { observer } from "mobx-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { updateAdministratorPassword } from "../../../../core/api/administrators/administrators";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import PasswordInput from "../../../../ui-lib/components/Inputs/PasswordInput";
import useSharedAdministrator from "../SharedAdministratorContext/useSharedAdministrator";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";
import {
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validateUpperCase,
} from "../../../../core/helpers/validation";

export interface PasswordFormValues {
  currentPassword: string;
  password: string;
  passwordRepeat: string;
}

const ChangePasswordModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, accountId } = useUser();
  const { data } = useSharedAdministrator();
  const id = data?.id as number;

  const isCurrentUser = accountId === id;

  const [loading, setLoading] = useState(false);
  const [currentPasswordModalOpen, setCurrentPasswordModalOpen] =
    useState(false);
  const [validationError, setValidationError] = useState<{ type?: string }>({});

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    getValues,
    reset,
    formState: { errors },
  } = useForm<PasswordFormValues>();

  const closeCurrentPasswordModal = () => {
    clearErrors("currentPassword");
    setCurrentPasswordModalOpen(false);
    setValidationError({ type: undefined });
    reset({ ...getValues(), currentPassword: "" });
  };

  const onSubmit = async (values: PasswordFormValues) => {
    if (validationError.type) return;
    setLoading(true);
    try {
      await updateAdministratorPassword(
        id,
        values.currentPassword,
        values.password,
        authenticatedRequest
      );

      notify({
        message: t("Administrator:change_password_success"),
      });
      onClose();
    } catch (error: any) {
      if (error.response?.data?.errors[0].errorMessage === "InvalidPassword") {
        setValidationError({ type: "InvalidPassword" });
      }
    }
    setLoading(false);
  };

  const getError = (type?: string) => {
    if (type === "required") {
      return t("Errors:input_field_required");
    }
    if (type === "validate") {
      return t("Errors:password_mismatch");
    }
    if (type === "InvalidPassword") {
      return t("Errors:InvalidPassword");
    }
  };

  return (
    <>
      <PrimeModal
        withHeader
        withFooter
        header={t("Administrator:change_password")}
        loading={loading}
        isOpen={isOpen}
        onClose={onClose}
        submitBtn={{
          variant: "primary",
          text: t("Administrator:change_password"),
          onClick: () => {
            handleSubmit((e) => {
              if (
                !isCurrentUser &&
                !errors.password &&
                !errors.passwordRepeat
              ) {
                clearErrors("currentPassword");
                setCurrentPasswordModalOpen(true);
              } else {
                onSubmit(e);
              }
            })();
          },
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: onClose,
        }}
      >
        <form style={{ width: "100%", paddingBottom: "4px" }}>
          <p style={{ color: "var(--Grey-600)" }}>
            {t("Administrator:change_password_description")}
          </p>
          <Spacer size={16} />
          {isCurrentUser && (
            <Column style={{ width: "48%" }}>
              <Controller
                name="currentPassword"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <PasswordInput
                    required
                    inputRef={field.ref}
                    label={t("Administrator:current_password_lable")}
                    placeholder={t(
                      "Administrator:current_password_placeholder"
                    )}
                    value={field.value}
                    onChange={({ currentTarget }) => {
                      field.onChange(currentTarget.value);
                      setValidationError({});
                    }}
                    validationError={getError(
                      errors.currentPassword?.type ?? validationError.type
                    )}
                  />
                )}
              />
            </Column>
          )}

          <Spacer size={16} />

          <Row align="start">
            <Column>
              <Controller
                name="password"
                control={control}
                rules={
                  data?.passwordConstraints && {
                    required: true,
                    minLength: data.passwordConstraints.minLength,
                    validate: {
                      lowerCase: (v) =>
                        validateLowerCase(v, data.passwordConstraints!),
                      upperCase: (v) =>
                        validateUpperCase(v, data.passwordConstraints!),
                      countNumbers: (v) =>
                        validateNumberCount(v, data.passwordConstraints!),
                      forbiddenChars: (v) =>
                        validateForbiddenChars(v, data.passwordConstraints!),
                    },
                  }
                }
                render={({ field }) => (
                  <PasswordInput
                    required
                    withHint
                    withIndicator
                    passwordConstraints={data?.passwordConstraints}
                    id="password"
                    inputRef={field.ref}
                    label={t("Administrator:change_password_input_new")}
                    placeholder={t(
                      "Administrator:change_password_input_new_placeholder"
                    )}
                    value={field.value}
                    invalid={!!errors.password}
                    onChange={field.onChange}
                  />
                )}
              />
            </Column>
            <Spacer />
            <Column>
              <Controller
                name="passwordRepeat"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => watch("password") === value,
                }}
                render={({ field }) => (
                  <PasswordInput
                    required
                    inputRef={field.ref}
                    label={t("Administrator:change_password_input_new_repeat")}
                    placeholder={t(
                      "Administrator:change_password_input_new_placeholder"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={getError(errors.passwordRepeat?.type)}
                  />
                )}
              />
            </Column>
          </Row>
        </form>
      </PrimeModal>
      {!isCurrentUser && (
        <PrimeModal
          withHeader
          withFooter
          loading={loading}
          isOpen={currentPasswordModalOpen}
          onClose={closeCurrentPasswordModal}
          header={t("Administrator:confirm_password_title")}
          submitBtn={{
            text: t("Common:confirm_change"),
            variant: "primary",
            onClick: () => handleSubmit(onSubmit)(),
          }}
          cancelBtn={{
            text: t("Common:cancel"),
            onClick: closeCurrentPasswordModal,
          }}
        >
          <div style={{ width: "848px" }}>
            <p>{t("Administrator:current_password_modal")}</p>
            <Spacer size={16} />
            <Column style={{ width: "48%" }}>
              <Controller
                name="currentPassword"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <PasswordInput
                    required
                    inputRef={field.ref}
                    label={t("Administrator:current_password_lable")}
                    placeholder={t(
                      "Administrator:current_password_placeholder"
                    )}
                    value={field.value}
                    onChange={({ currentTarget }) => {
                      if (validationError.type) {
                        setValidationError({ type: undefined });
                      }
                      field.onChange(currentTarget.value);
                    }}
                    validationError={getError(
                      errors.currentPassword?.type ?? validationError.type
                    )}
                  />
                )}
              />
            </Column>
          </div>
        </PrimeModal>
      )}
    </>
  );
};

export default observer(ChangePasswordModal);
