import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Column } from "../../../../../components/Layout/Layout";
import { validatePhoneNumber } from "../../../../../core/helpers/validation";
import useTranslations from "../../../../../core/i18n/useTranslations";
import TextInput from "../../../../../ui-lib/components/Inputs/TextInput";
import RadioGroup from "../../../../../ui-lib/components/Radio/RadioGroup";

enum CallType {
  Outgoing = "outgoing",
  Incoming = "incoming",
}

const OUTGOING_NUMBER = "+999";

export const PSTNControl = () => {
  const t = useTranslations();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [type, setType] = useState(
    getValues("telephoneNumberPstn") === OUTGOING_NUMBER ||
      !getValues("telephoneNumberPstn")?.length
      ? CallType.Outgoing
      : CallType.Incoming
  );

  const radioGroupItems = useMemo(
    () => [
      {
        idForText: "PSTN_type_0",
        value: CallType.Outgoing,
        text: t("AlarmReceptions:alarm_handling_input_outgoing_calls"),
      },
      {
        idForText: "PSTN_type_1",
        value: CallType.Incoming,
        text: t("AlarmReceptions:alarm_handling_input_incoming_calls"),
      },
    ],
    [t]
  );

  const preSavedValue = useRef();

  useEffect(() => {
    const value = getValues("telephoneNumberPstn");
    if (value !== OUTGOING_NUMBER) {
      preSavedValue.current = value;
    }
  }, []);

  return (
    <Column align="fill">
      <Controller
        name="telephoneNumberPstn"
        control={control}
        rules={{
          validate: (value) => {
            if (type === CallType.Outgoing) {
              return true;
            }

            return validatePhoneNumber(value);
          },
        }}
        render={({ field }) => (
          <RadioGroup
            inputRef={field.ref}
            label={t("AlarmReceptions:alarm_handling_input_pstn")}
            name={field.name}
            items={radioGroupItems}
            selectedValue={type}
            onChange={(value) => {
              setType(value as CallType);

              switch (value) {
                case CallType.Outgoing:
                  setValue(field.name, OUTGOING_NUMBER, { shouldDirty: true });
                  break;
                case CallType.Incoming:
                  setValue(field.name, preSavedValue.current || "", {
                    shouldDirty: true,
                  });
                  break;
                default:
                  break;
              }
            }}
          >
            {type === CallType.Incoming && (
              <TextInput
                value={field.value}
                onChange={field.onChange}
                placeholder={t("Common:phone_number_placeholder")}
                validationError={
                  errors?.telephoneNumberPstn &&
                  t("Objects:Validation_identifier_format")
                }
              />
            )}
          </RadioGroup>
        )}
      />
    </Column>
  );
};
