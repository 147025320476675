import {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Flag from "react-country-flag";
import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  ENTER_KEY,
  ESCAPE_KEY,
} from "../../../core/constants/keyboard_keys";
import {
  getDropdownBodyHeight,
  getRootPopup,
} from "../../../core/helpers/dropdownHelper";
import { DropdownProps, useDropdownHooks } from "../../utils/dropdown-hook";
import DropdownBody from "./DropdownBody";
import DropdownItem, { IDropdownItem, IItemProp } from "./DropdownItem";
import { Icon } from "../Icon";
import Tooltip from "../Tooltip/Tooltip";
import LoadingSpinner from "../Loading/LoadingSpinner";

import inputStyles from "../Inputs/Input.module.css";
import styles from "./Dropdown.module.css";

/** Instance value that is exposed to parent components when using ref */
export declare type DropdownActions = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const Dropdown = forwardRef<DropdownActions, DropdownProps>(
  (props: DropdownProps, ref) => {
    const {
      selectedItem,
      items,
      withoutCheckIcon,
      withFilter,
      onSelectItem,
      onOpenStateChanged,
      title,
      placeholder,
      width,
      bodyWidth,
      className,
      buttonStyle,
      isOpen,
      setIsOpen,
      focusedIndex,
      setFocusedIndex,
      btnRef,
      container,
      openDropdown,
      dropdownInPopup,
      invalid,
      validationError,
      disabled,
      maxVisible,
      id,
      innerButtonStyles,
      showTooltip,
      dataCy,
      isLoading,
      searchPlaceholder,
      ariaLable,
      required,
    } = useDropdownHooks(props);

    const closeDropdown = () => {
      setIsOpen(false);
      setFocusedIndex(-1);
    };
    // declare limited interface exposed to parent components when using ref
    useImperativeHandle<DropdownActions, DropdownActions>(ref, () => {
      const actions: DropdownActions = {
        open: function open() {
          openDropdown();
        },
        close: function close() {
          closeDropdown();
        },
        toggle: function toggle() {
          setIsOpen(!isOpen);
        },
      };
      return actions;
    });

    useEffect(() => onOpenStateChanged?.(isOpen), [isOpen, onOpenStateChanged]);

    function selectItem(item: IDropdownItem, e: any) {
      setIsOpen(false);
      onSelectItem(item, e);
    }

    const handleInputKeyDown = useCallback(
      // eslint-disable-next-line consistent-return
      (e: any) => {
        if (disabled) {
          return false;
        }
        const { keyCode } = e;
        switch (keyCode) {
          case ARROW_UP_KEY:
            setFocusedIndex((prev) => {
              if (prev === 0) return items.length - 1;
              return prev - 1;
            });
            break;
          case ARROW_DOWN_KEY:
            setFocusedIndex((p) => (p + 1) % items.length);
            break;
          case ESCAPE_KEY:
            closeDropdown();
            break;
          case ENTER_KEY:
            if (isOpen) selectItem(items[focusedIndex], e);
            else openDropdown();
            break;
          default:
            break;
        }
      },
      [focusedIndex, isOpen, setFocusedIndex, items, setIsOpen, openDropdown]
    );

    const selectedItemClasses = classNames({
      [styles.selectedDropdownItemText]: selectedItem?.icon,
      [styles.selectOption]: !selectedItem?.name,
      [styles.text]: selectedItem?.name,
    });
    const [filteredItems, setFilteredItems] = useState<IItemProp[]>(items);

    const onFilter = (filter: string) => {
      const filtered = items.filter(
        (item) => item.name?.toLowerCase().indexOf(filter.toLowerCase()) > -1
      );
      setFilteredItems(filtered);
    };

    return (
      <div
        id={id}
        className={`${styles.dropdown} ${className}`}
        style={{ width }}
      >
        {title && (
          <div className={styles.dropdownTitle}>
            {required && <span className={inputStyles.requiredIcon}>*</span>}
            {title}
          </div>
        )}
        <button
          className={classNames({
            [styles.dropdownBtn]: true,
            [styles.inputValidationError]: invalid || validationError,
            [styles.disabled]: disabled,
          })}
          style={buttonStyle?.plate}
          aria-label={ariaLable}
          ref={btnRef}
          onClick={(e) => {
            e.preventDefault();
            openDropdown();
            setIsOpen(!isOpen);
          }}
          onKeyDown={handleInputKeyDown}
          data-cy={dataCy}
        >
          <div
            className={styles.selectedDropdownItemName}
            style={innerButtonStyles}
          >
            {selectedItem?.flag && (
              <Flag
                svg
                countryCode={selectedItem.flag}
                className={styles.dropdownFlag}
              />
            )}
            {selectedItem?.icon && (
              <Icon name={selectedItem.icon} color={selectedItem.color} />
            )}
            {showTooltip ? (
              <Tooltip
                variant="light"
                size="small"
                trigger={() => (
                  <span
                    className={selectedItemClasses}
                    style={buttonStyle?.text}
                  >
                    {selectedItem?.name ?? placeholder}
                  </span>
                )}
                text={selectedItem?.name ?? placeholder}
              />
            ) : (
              <span className={selectedItemClasses} style={buttonStyle?.text}>
                {selectedItem?.name ?? placeholder}
              </span>
            )}
            {isLoading && (
              <LoadingSpinner margin="0 0 0 auto" theme="primary" size={32} />
            )}
          </div>

          {isOpen ? (
            <Icon name="chevron-up" size={14} />
          ) : (
            <Icon name="chevron-down" size={14} />
          )}
        </button>
        {isOpen &&
          ReactDOM.createPortal(
            <DropdownBody
              dropdownInPopup={dropdownInPopup}
              innerRef={container}
              onFilter={onFilter}
              withFilter={withFilter}
              dropDownHeight={getDropdownBodyHeight(
                items.length,
                window.innerHeight <= 600 ? 6 : maxVisible,
                withFilter
              )}
              elem={btnRef.current}
              onClose={() => setIsOpen(false)}
              width={bodyWidth}
              searchPlaceholder={searchPlaceholder}
            >
              {filteredItems?.map((item: IItemProp, index) => {
                const focused = index === focusedIndex;
                return (
                  <DropdownItem
                    item={item}
                    onSelectItem={selectItem}
                    key={item.id}
                    withoutCheckIcon={withoutCheckIcon}
                    tabIndex={index}
                    focused={focused}
                    onFocusElement={() => {}}
                    showTooltip={showTooltip}
                  />
                );
              })}
            </DropdownBody>,
            getRootPopup()
          )}
        {validationError ? (
          <div className={styles.validationError}>
            <Icon name="exclamation-circle" size={14} />
            {validationError}
          </div>
        ) : null}
      </div>
    );
  }
);

export default Dropdown;
