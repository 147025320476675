import React from "react";
import { Tooltip } from "primereact/tooltip";
import classNames from "classnames";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import Icon from "../Icon";
import {
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validateUpperCase,
} from "../../../core/helpers/validation";
import { PasswordConstraints } from "../../../core/api/administrators/types";
import { format } from "../../../core/utils/strings";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";

interface IRules {
  title: string;
  key: string;
}

export default function PasswordHintsTooltip({
  inputId,
  value = "",
  passwordConstraints,
}: Readonly<{
  inputId?: string;
  value?: string;
  passwordConstraints: PasswordConstraints;
}>) {
  const t = useTranslations();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  const validate = () => ({
    "min-length": value?.length >= passwordConstraints.minLength,
    "min-lowercase": validateLowerCase(value, passwordConstraints),
    "min-uppercase": validateUpperCase(value, passwordConstraints),
    "min-number": validateNumberCount(value, passwordConstraints),
    "forbidden-chars": validateForbiddenChars(value, passwordConstraints),
  });

  const rules: IRules[] = [];
  if (passwordConstraints.minLength !== 0)
    rules.push({
      title: format(
        t("Administrator:password_constraints_minimum_length"),
        passwordConstraints.minLength
      ),
      key: "min-length",
    });
  if (passwordConstraints.minNumbers !== 0)
    rules.push({
      title: format(
        t("Administrator:password_constraints_minimum_digits"),
        passwordConstraints.minNumbers
      ),
      key: "min-number",
    });
  if (passwordConstraints.minUpperCase !== 0)
    rules.push({
      title: format(
        t("Administrator:password_constraints_minimum_uppercase"),
        passwordConstraints.minUpperCase
      ),
      key: "min-uppercase",
    });

  if (passwordConstraints.minLowerCase !== 0)
    rules.push({
      title: format(
        t("Administrator:password_constraints_minimum_lowercase"),
        passwordConstraints.minLowerCase
      ),
      key: "min-lowercase",
    });

  if (passwordConstraints.forbiddenChars !== "")
    rules.push({
      title: format(
        t("Administrator:password_constraints_forbidden_chars"),
        passwordConstraints.forbiddenChars
      ),
      key: "forbidden-chars",
    });

  const renderContent = () => (
    <>
      <span className="smallStrong">{`${t(
        "Administrator:password_hint_text"
      )}:`}</span>

      <Column align="start">
        {rules.map((pwdCtr: IRules) => {
          const valid = () => validate()[pwdCtr.key as keyof Object];
          const color = valid() ? "Success-600" : "Error-400";

          return (
            <React.Fragment key={pwdCtr.key}>
              <Row>
                <Icon
                  size={16}
                  name={valid() ? "check-circle" : "x-circle"}
                  color={color}
                />
                <Spacer size={4} />
                <span style={{ color: `var(--${color})` }} className="small">
                  {pwdCtr.title}
                </span>
              </Row>
              {rules.length > 1 && <Spacer size={8} />}
            </React.Fragment>
          );
        })}
      </Column>
    </>
  );

  if (isMobile) {
    return <div className="passwordHint">{renderContent()}</div>;
  }

  return (
    <Tooltip
      target={inputId ? `#${inputId}` : ""}
      event="focus"
      position="left"
      pt={{
        text: (e) => {
          return {
            className: classNames("passwordHintText", {
              left: e?.state.position === "right",
            }),
          };
        },
        arrow: {
          className: "passwordHintArrow",
        },
      }}
    >
      {renderContent()}
    </Tooltip>
  );
}
