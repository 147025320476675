import { Controller, useFormContext } from "react-hook-form";
import { useOrganizationsTree } from "../core/api/organizations/organizations";
import useTranslations from "../core/i18n/useTranslations";
import Tree, { ITreeData } from "../ui-lib/components/Hierarchy/Tree";
import LoadingSpinner from "../ui-lib/components/Loading/LoadingSpinner";

export const OrganisationPicker = ({
  name,
  label,
  required,
  disabled,
}: {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  const {
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const t = useTranslations();
  const { data: organisations = [], isLoading } = useOrganizationsTree(
    getValues("organizationId") || undefined
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field }) => (
        <>
          {isLoading ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <Tree
              treeRef={field.ref}
              label={label}
              disabled={disabled}
              placeholder={t("Common:organisation")}
              items={organisations}
              selectedTreeItem={field.value}
              onSelectItem={(data?: ITreeData) => {
                const customerId = data?.key as number | undefined;
                setValue(field.name, customerId);
                trigger(field.name);
              }}
              validationError={
                errors.CustomerId && t("Errors:input_field_required")
              }
            />
          )}
        </>
      )}
    />
  );
};
