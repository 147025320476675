import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AdministratorDetails,
  AdministratorMeta,
  IAdminCopy,
  Role,
} from "../../core/api/administrators/types";
import useTranslations from "../../core/i18n/useTranslations";
import Badge from "../../ui-lib/components/Badges/Badge";
import Table, { RowAction } from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";

import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { findNodeById, findRoot } from "../../core/utils/organizations";
import { SubRow } from "../../ui-lib/components/Tables/SubRow";
import { RolesCell } from "../../ui-lib/components/Tables/RolesCell";
import { RowData } from "../Organizations/Organizations";
import ChildrenTreeModal from "../../ui-lib/components/ChildrenTreePopup/childrenTreeModal";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { AddAdministrator } from "./AddAdministrator/AddAdministrator";
import { createCopyByAdmin } from "../../core/api/administrators/administrators";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import useUser from "../../core/user/useUser";
import NoItems from "../../ui-lib/components/Tables/NoItems";

type Columns = { [key: string]: string };

let openHierarchyTree: Function;

const userIcon: { [key: string]: string } = {
  respondent: "phone-incoming",
  admin: "user",
  seller: "super-user",
};

const columns = (t: (key: string) => string) => [
  {
    key: "username",
    header: t("Common:username"),
    fieldTemplate: (rowData: AdministratorMeta) => {
      const link = rowData.identityId
        ? `/adminportal/identity/${rowData.identityId}` // this page will point to Edit Administrator but as there are some work to do there before we can link to it we use this link for now
        : `/adminportal/administrators/${rowData.roles[0].id.toString()}`;
      return <TableCell value={rowData.userName} linkTo={link} />;
    },
  },
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: (rowData: AdministratorMeta) => (
      <TableCell value={rowData.name ?? ""} />
    ),
  },
  {
    key: "active",
    header: t("Administrator:table_column_active"),
    fieldTemplate: (rowData: AdministratorMeta) => {
      const badgeTitle = rowData.active
        ? t("Administrator:active")
        : t("Administrator:inactive");
      const badgeVariant = rowData.active ? "success" : "secondary";

      return <Badge title={badgeTitle} variant={badgeVariant} />;
    },
  },
  {
    key: "dateAdded",
    header: t("Administrator:table_column_date_added"),
    fieldTemplate: (rowData: AdministratorMeta) => (
      <TableCell
        value={
          rowData.created ? DateTimeHelper.formatDate(rowData.created) : ""
        }
      />
    ),
  },
  {
    key: "role",
    header: t("Common:role_template"),
    style: { padding: "0" },
    fieldTemplate: ({ roles }: AdministratorMeta) =>
      roles.map((role, i) => {
        const legacyAdmin = role.templateName === "LegacyAdmin";

        if (legacyAdmin) {
          return (
            <TableCell
              key={role.id}
              value={t("Administrator:LegacyAdmin")}
              leftIconTooltip={t(`Common:${role.type}`)}
              className="nodata"
              leftIcon={userIcon[role.type]}
              iconColor="Grey-300"
            />
          );
        }
        return (
          <SubRow
            key={role.id}
            displayDivider={i + 1 !== roles.length}
            value={role.templateName}
            // linkTo={`/adminportal/administrators/${role.roleId.toString()}`} // use this when the feature for opening up a role exists
            linkTo="/adminportal/administrators"
            leftIconTooltip={t(`Common:${role.type}`)}
            icon={userIcon[role.type]}
            hierarchyButton={undefined}
          />
        );
      }),
  },
  {
    key: "belongs",
    style: { padding: "0" },
    header: t("Common:belongs_to"),
    fieldTemplate: ({ roles }: AdministratorMeta) =>
      roles.map((role, i) => (
        <RolesCell
          key={role.id}
          role={role}
          index={i}
          rolesLength={roles.length}
          openHierarchyTree={openHierarchyTree}
          hierarchyTooltip
        />
      )),
  },
];

export const AdministratorsTable = ({
  data = [],
  pageSettings,
  onPageSettingsChange,
  organisations,
  onStartDelete,
  sellers,
  totalRoleAmount,
  subPage,
  adminFiltersApplied,
  onClear,
}: {
  data?: AdministratorMeta[];
  pageSettings: {
    page: number;
    pageSize: number;
  };
  onPageSettingsChange: (args: { page: number; pageSize: number }) => void;
  organisations: ITreeData[];
  sellers?: ITreeData[];
  onStartDelete: (administrator: AdministratorMeta) => void;
  totalRoleAmount?: number;
  subPage?: boolean;
  adminFiltersApplied?: boolean;
  onClear?: () => void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { authenticatedRequest, config } = useUser();
  const [copyModalOpened, setCopyModalOpened] = useState(false);
  const [selectedAdminData, setSelectedAdminData] =
    useState<AdministratorDetails>();
  const [isChildrenTreeOpen, setIsChildrenTreeOpen] = useState<boolean>(false);
  const allColumns = columns(t).reduce<Columns>(
    (prev, current) => ({
      ...prev,
      [current.key]: current.header,
    }),
    {}
  );

  const tableActions: RowAction<AdministratorMeta>[] = [
    {
      icon: "pencil-alt",
      text: t("Common:label_edit"),
      onClick: (rowData: AdministratorMeta) =>
        history.push(
          rowData.identityId
            ? `/adminportal/identity/${rowData.identityId}` // this page will point to Edit Administrator but as there are some work to do there before we can link to it we use this link for now
            : `/adminportal/administrators/${rowData.roles[0].id.toString()}`
        ),
    },
    {
      icon: "trash",
      text: t("Common:delete"),
      iconVariant: "secondary",
      onClick: (rowData: AdministratorMeta) => onStartDelete(rowData),
    },
  ];
  if (config?.show.includes("CreateAdminByCopy")) {
    tableActions.unshift({
      icon: "document-add",
      text: t("AlarmReceptions:input_copy"),
      hideForRow: (rowData: Record<string, any>) => rowData.identityId,
      onClick: (rowData: AdministratorMeta) => {
        if (
          rowData.roles[0].type === "responscenter" ||
          rowData.roles.length > 1
        ) {
          notify({
            variant: "error",
            message: t("Administrator:cant_create_copy"),
          });
        } else {
          const adminData = {
            ...rowData.roles[0],
            name: rowData.userName,
            mainSettings: true,
            menuAlternatives: true,
            forcePasswordReset: true,
          } as any;
          if (adminData.type === "seller") {
            adminData.sellerId = rowData.roles[0].sellerId;
          }
          if (adminData.type === "respondent") {
            adminData.adminRespondentGroups = true;
          }
          if (adminData.type === "admin" || adminData.type === "customer") {
            adminData.adminGUIConfigs = true;
            adminData.adminRoles = true;
            adminData.organizationId = rowData.roles[0].organizationId;
          }
          setSelectedAdminData(adminData);
          setCopyModalOpened(true);
        }
      },
    });
  }

  const childrenTreePopupRef = useRef<RowData>();

  openHierarchyTree = (role: Role) => {
    const organization = findNodeById(organisations, role.organizationId);
    childrenTreePopupRef.current = {
      id: organization?.key as string,
      value: organization?.label as string,
      children: organization?.children as ITreeData[],
      root: organization?.parentId
        ? findRoot(organization?.parentId, organisations)
        : null,
    };
    setIsChildrenTreeOpen(true);
  };

  const onCopyModalClose = () => {
    setCopyModalOpened(false);
    setSelectedAdminData(undefined);
  };

  const onSubmit = async (adminDataToSave: AdministratorDetails) => {
    try {
      const dataToUpload = {
        ...adminDataToSave,
      } as IAdminCopy;
      if (adminDataToSave.responseCenterId) {
        delete dataToUpload.organizationId;
      }
      const result = await createCopyByAdmin(
        selectedAdminData!.id,
        dataToUpload,
        authenticatedRequest
      );
      onCopyModalClose();
      history.push(`/adminportal/administrators/${result.data}`);
    } catch (error: any) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <>
      <ChildrenTreeModal
        organizations={childrenTreePopupRef.current as RowData}
        isOpen={isChildrenTreeOpen}
        onClose={() => {
          setIsChildrenTreeOpen(false);
        }}
      />
      {selectedAdminData && (
        <AddAdministrator
          publicArc={
            selectedAdminData.type === "respondent" &&
            !selectedAdminData.organizationId &&
            !!selectedAdminData.responseCenterId
          }
          organisations={organisations}
          creationType="copy"
          sellers={sellers}
          selectedType={selectedAdminData.type}
          defaultValues={selectedAdminData}
          isOpen={copyModalOpened}
          onClose={onCopyModalClose}
          onCopySubmit={onSubmit}
          extraHeaderText={`${t("Common:based_on")} ${selectedAdminData.name}`}
        />
      )}
      {data.length ? (
        <Table<AdministratorMeta>
          tableName={subPage ? undefined : "table-administrators"}
          items={data}
          columns={columns(t).filter((c) =>
            Object.keys(allColumns).find((key) => c.key === key)
          )}
          hideEmptyMessage
          rowActions={tableActions}
          rowActionsColWidth={110}
          rowActionsFixed
          showRowHover
          withLazyLoading
          withPagination
          paginatedItems={{
            items: data,
            pagination: {
              offset: pageSettings.pageSize * (pageSettings.page - 1),
              limit: pageSettings.pageSize,
              total: totalRoleAmount ?? 0,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              onPageSettingsChange({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
        />
      ) : (
        <NoItems
          title={
            !adminFiltersApplied
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!adminFiltersApplied ? "eye-off" : undefined}
          subTitle={
            !adminFiltersApplied
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={
            adminFiltersApplied && onClear
              ? {
                  text: t("Table:clear_filters"),
                  onClick: onClear,
                }
              : undefined
          }
        />
      )}
    </>
  );
};
