import { Spacer } from "../../../components/Layout/Layout";
import { useSearchFilter } from "../../../core/hooks/filters/useSearchFilter";
import { usePageFilter } from "../../../core/hooks/filters/usePageFilter";
import SearchInput from "../../../ui-lib/components/Inputs/SearchInput";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import useTranslations from "../../../core/i18n/useTranslations";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import Radio from "../../../ui-lib/components/Radio/Radio";

export interface INewObjectAssociationProps {
  isOpen: boolean;
  onClose: () => void;
}

const columns = (t: (key: string) => string) => [
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: () => (
      <TableCell value={""} linkTo={`/adminportal/objects/`} />
    ),
  },
  {
    key: "org",
    header: t("Organizations:organization"),
    fieldTemplate: () => <TableCell value={""} />,
  },
  {
    key: "idNo",
    header: t("Table:NationalIdNumber "),
    fieldTemplate: () => <TableCell value={""} />,
  },
  {
    key: "identifier",
    header: t("Table:TelephoneNumber "),
    fieldTemplate: () => <TableCell value={""} />,
  },
  {
    editable: true,
    style: { minWidth: "50px", width: "50px" },
    fieldTemplate: () => (
      <Radio name={""} value={""} checked={false} onChange={() => {}} />
    ),
  },
];

export const NewAssociationObjectModal = ({
  isOpen,
  onClose,
}: INewObjectAssociationProps) => {
  const t = useTranslations();
  const { searchFilter, setSearchFilter, resetSearchFilter } =
    useSearchFilter();
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter();

  const fetchParams = {
    page: pageFilter.page,
    pageSize: pageFilter.pageSize,
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      contentStyle={{ width: "848px" }}
      isOpen={isOpen}
      onClose={onClose}
      header={t("Contacts:associate_with_new_object")}
      submitBtn={{ disabled: true, text: t("Common:save"), onClick: () => {} }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p>{t("Contacts:associate_with_new_object_description")}</p>
      <Spacer size={16} />
      <SearchInput
        value=""
        onChange={() => {}}
        placeholder={t("Contacts:associated_objects_search_placeholder")}
      />
      <Spacer size={32} />
      <h2>{t("Menu:objects")}</h2>
      <Spacer size={16} />
      <Table hideEmptyMessage columns={columns(t)} items={[]} />
    </PrimeModal>
  );
};
