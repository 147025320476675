import { AxiosInstance } from "axios";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  ResponseCenterAlarmCodeRuleResponse,
  ResponseCenterAlarmCodeRuleDetails,
} from "./types";

const useResponseCenterAlarmCodeRules = (responseCenterId: string | number) =>
  useAuthenticatedFetch<ResponseCenterAlarmCodeRuleResponse[]>(
    `/api/v2/responsecenters/${responseCenterId}/alarmcoderules`
  );

const getResponseCenterAlarmCodeRules = async (
  responseCenterId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ResponseCenterAlarmCodeRuleResponse[]>(
    `/api/v2/responsecenters/${responseCenterId}/alarmcoderules`
  );

const useAlarmCodeRule = (
  responseCenterId: string | number,
  alarmCodeRuleId: string | number
) =>
  useAuthenticatedFetch<ResponseCenterAlarmCodeRuleDetails>(
    `/api/v2/responsecenters/${responseCenterId}/alarmcoderules/${alarmCodeRuleId}`
  );

const createAlarmCodeRule = async (
  id: string | number,
  params: ResponseCenterAlarmCodeRuleDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(params);

  return authenticatedRequest.post<{}>(
    `/api/v2/responsecenters/${id}/alarmcoderules`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const updateAlarmCodeRule = async (
  responseCenterId: string | number,
  alarmCodeRuleId: string | number,
  params: ResponseCenterAlarmCodeRuleDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify({
    ...params,
    ResponseCenterId: responseCenterId,
  });

  return authenticatedRequest.put<{}>(
    `/api/v2/responsecenters/${responseCenterId}/alarmcoderules/${alarmCodeRuleId}`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const deleteAlarmCodeRule = async (
  alarmCodeRuleId: string | number,
  responseCenterId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `/api/v2/responsecenters/${responseCenterId}/alarmcoderules/${alarmCodeRuleId}`
  );

export {
  useResponseCenterAlarmCodeRules,
  getResponseCenterAlarmCodeRules,
  useAlarmCodeRule,
  createAlarmCodeRule,
  updateAlarmCodeRule,
  deleteAlarmCodeRule,
};
