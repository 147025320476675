import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Column } from "../../components/Layout/Layout";
import { getSellers } from "../../core/api/sellers/sellers";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { IResellerType } from "../../core/api/sellers/types";
import { Role, RoleFeature } from "../../components/RoleFeature";

import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import useUser from "../../core/user/useUser";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import ResellersTable from "./ResellersTable";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import AddResellerModal from "./AddResellerModal";

import styles from "../PagesLayout.module.css";

const Resellers = () => {
  const { t } = useTranslation();
  const { authenticatedRequest } = useUser();
  const pageState = usePageState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [sellersData, setSellersData] = useState<IResellerType[]>([]);
  const [isAddSellerModalOpen, setIsAddSellerModalOpen] = useState(false);

  const getAllSellers = async () => {
    setIsLoading(true);
    try {
      const result = await getSellers(authenticatedRequest);
      setSellersData(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSellers();
  }, []);

  return (
    <>
      <PageHeader title={t("Common:sellers")} icon={pageState.pageIcon}>
        <RoleFeature requires={[Role.SuperAdmin]}>
          <Button
            variant="primary"
            text={t("Common:add_seller")}
            onClick={() => setIsAddSellerModalOpen(true)}
          />
          <Button
            className="ml-8"
            variant="secondary"
            text={t("Reseller:products_list")}
            onClick={() => history.push("/adminportal/products")}
          />
        </RoleFeature>
      </PageHeader>

      <Column className={styles.wrapper} style={{ padding: "24px" }} type="top">
        {isLoading ? (
          <LoadingSpinner theme="primary" margin="0 auto" />
        ) : (
          <ResellersTable sellers={sellersData} />
        )}
      </Column>

      <AddResellerModal
        isAddSellerModalOpen={isAddSellerModalOpen}
        setIsAddSellerModalOpen={setIsAddSellerModalOpen}
        submitHandler={(id: number) =>
          history.push(`/adminportal/resellers/${id}`)
        }
      />
    </>
  );
};

export default Resellers;
