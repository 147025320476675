import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import useTranslations from "../../../../core/i18n/useTranslations";
import { AlarmReceptionIntegration } from "../../../../core/api/responsecenters/types";
import Button from "../../../../ui-lib/components/Button/Button";
import {
  EditAlarmReceptionIntegration,
  EditIntegrationActions,
} from "./EditAlarmReceptionIntegration";
import useUser from "../../../../core/user/useUser";
import {
  editAlarmReceptionIntegration,
  getAlarmReceptionIntegrations,
} from "../../../../core/api/responsecenters/responsecenters";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteReceptionIntegrationModal } from "../../TechnicalSettings/DeleteReceptionIntegrationModal";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import TagList from "../../../../ui-lib/components/Tags/TagList";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { urlSearchParser } from "../../../../ui-lib/utils/urlSearchParser";
import { useModalIdHook } from "../../../../core/hooks/modalIdsHandlers/useIdModalHook";

import layoutStyles from "../../../PagesLayout.module.css";

const tableId = "arc-table-integrations";

const AlarmReceptionIntegrations = ({ id }: { id: number }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const history = useHistory();
  const tableState = history.location.search;
  const searchState = urlSearchParser(tableState);

  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(false);
  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<AlarmReceptionIntegration>>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [upForDelete, setUpForDelete] = useState<(number | undefined)[]>([]);
  const [pageFilter, setPageFilter] = useState({
    page: 1,
    pageSize: 2000,
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [, setIntegrationId, resetIntegrationId] = useModalIdHook(
    searchState,
    "integrationId"
  );
  const editIntegrationRef = useRef<EditIntegrationActions>(null);

  const fetchIntegrations = async () => {
    try {
      setIsLoadingIntegrations(true);
      const result = await getAlarmReceptionIntegrations(
        id,
        authenticatedRequest,
        pageFilter
      );
      setTableData(result.data.toReversed());
    } catch (error: any) {
      notifyError({
        error,
        t,
      });
    } finally {
      setIsLoadingIntegrations(false);
    }
  };

  const editIntegration = async (integration: AlarmReceptionIntegration) => {
    try {
      setIsLoadingIntegrations(true);
      await editAlarmReceptionIntegration(
        id,
        integration,
        authenticatedRequest
      );

      resetIntegrationId();

      await fetchIntegrations();
      notify({
        message: t(
          `AlarmReceptions:${
            integration.id ? "edit" : "create"
          }_integration_success`
        ),
      });
    } catch (error: any) {
      notifyError({
        error,
        t,
      });
    } finally {
      setIsLoadingIntegrations(false);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, [id]);

  useEffect(() => {
    if (tableData.length) {
      setItems(
        tableData.slice(
          (pageFilter.page - 1) * pageFilter.pageSize,
          (pageFilter.page - 1) * pageFilter.pageSize + pageFilter.pageSize
        )
      );
    } else {
      setItems([]);
    }
  }, [tableData, pageFilter]);

  useEffect(() => {
    if (!isLoadingIntegrations && searchState?.integrationId) {
      editIntegrationRef.current?.open(searchState.integrationId);
      setIntegrationId(searchState.integrationId);
    }
  }, [isLoadingIntegrations]);

  if (isLoadingIntegrations) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <Column style={{ width: "100%" }} type="top" align="start">
      <h1>{t("AlarmReceptions:technical_settings_integration_title")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("AlarmReceptions:technical_settings_integration_description")}
      </p>
      <Spacer size={16} />

      <Row className="w-100 flex0" type="space">
        <h2>{t("AlarmReceptions:advanced_settings_integrations__label")}</h2>
        <Spacer />
        <EditAlarmReceptionIntegration
          ref={editIntegrationRef}
          id={id}
          editIntegration={editIntegration}
          saveButtonTitle={t("AlarmReceptions:save_integration")}
          onClose={() => {
            resetIntegrationId();
          }}
        />
        <Button
          variant="secondary"
          text={t("AlarmReceptions:add_new_integration")}
          onClick={() => {
            editIntegrationRef.current?.open();
          }}
        />
      </Row>
      <Spacer size={16} />
      <Column className={layoutStyles.tableContainer} type="top">
        <Table<AlarmReceptionIntegration>
          columns={[
            {
              header: t(
                "AlarmReceptions:advanced_settings_integrations_alarmcodes"
              ),
              fieldTemplate: (rowData) => {
                if (rowData.alarmCodes?.length) {
                  return (
                    <TagList
                      tags={rowData.alarmCodes.split(",").map((code) => ({
                        title: code,
                        value: code,
                      }))}
                    />
                  );
                }
                return <TableCell value={t("AlarmReceptions:all_codes")} />;
              },
              style: { maxWidth: "200px", width: "200px" },
            },
            {
              header: t(
                "AlarmReceptions:advanced_settings_integrations_invertalarmcodes"
              ),
              fieldTemplate: (rowData) => (
                <Row type="center">
                  <Checkbox checked={!!rowData.invertAlarmCodes} disabled />
                </Row>
              ),
              style: { width: "90px" },
            },
            {
              header: t(
                "AlarmReceptions:advanced_settings_integrations_command"
              ),
              fieldTemplate: (rowData) => <TableCell value={rowData.command} />,
            },
            {
              header: t(
                "AlarmReceptions:advanced_settings_integrations_comment"
              ),
              fieldTemplate: (rowData) => <TableCell value={rowData.comment} />,
            },
            {
              header: t("AlarmReceptions:advanced_settings_integrations_delay"),
              fieldTemplate: (rowData) => (
                <TableCell
                  value={`${rowData.delay} ${t("Common:labels_seconds")}`}
                />
              ),
            },
          ]}
          items={items}
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (rowData) => {
                editIntegrationRef.current?.open(rowData.id);
                setIntegrationId(rowData.id);
              },
            },
            {
              iconVariant: "secondary",
              icon: "trash",
              text: t("Common:delete"),
              onClick: (rowData) => {
                setUpForDelete([rowData.id]);
                setDeleteOpen(true);
              },
            },
          ]}
          rowActionsColWidth={80}
          hideEmptyMessage
          withRowSelection
          showRowHover
          rowActionsFixed
          withPagination
          withLazyLoading
          paginatedItems={{
            items,
            pagination: {
              offset: pageFilter.pageSize * (pageFilter.page - 1),
              limit: pageFilter.pageSize,
              total: tableData.length,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              if (pageFilter.pageSize !== nextPage.limit) {
                localStorage.setItem(tableId, `${nextPage.limit}`);
              }
              setPageFilter({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
          onRowSelectionChange={(selectedItems) => {
            const filtered = selectedItems.filter(
              (i) => !!tableData.find((row) => row.id === i.id)
            );
            selectionPopupRef.current?.open(filtered);
          }}
        />
      </Column>

      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setUpForDelete(selectedItems.map((i) => i.id));
                setDeleteOpen(true);
              }}
            />
          </Row>
        )}
      />

      <DeleteReceptionIntegrationModal
        isOpen={deleteOpen}
        ids={upForDelete as number[]}
        onClose={() => setDeleteOpen(false)}
        onDelete={async () => {
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
          fetchIntegrations();
        }}
        objectId={id}
      />
    </Column>
  );
};
export default AlarmReceptionIntegrations;
