import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LocizeBackend, { LocizeBackendOptions } from "i18next-locize-backend";
import HttpBackend from "i18next-http-backend";
import { locizePlugin } from "locize";
import { initReactI18next } from "react-i18next";

export interface CachedLanguages {
  languages: Language[];
  expires: Date;
}
export interface Language {
  id: string;
  region: string;
  nativeName: string;
  flagIconKey?: string;
  momentKey: string;
}

let locizeVersion = "latest";
let expirationTime = 10 * 60000; // 10min

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  locizeVersion = "production";
  expirationTime = 120 * 60000; // 2h
}

if (process.env.REACT_APP_ENVIRONMENT === "stage") {
  locizeVersion = "staging";
  expirationTime = 60 * 60000; // 1h
}

const locizeOptions: LocizeBackendOptions = {
  projectId:
    process.env.REACT_APP_LOCIZE_PROJECTID ??
    "5cd9d088-03fd-4f5d-90eb-7e0ea0365831",
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: locizeVersion,
};

const locizeBackendOptions: LocizeBackendOptions = {
  projectId:
    process.env.REACT_APP_LOCIZE_BACKEND_PROJECTID ??
    "b7a9f067-87c6-4daa-8b14-7be80255bb2d",
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: locizeVersion,
};

i18n
  .use(ChainedBackend)
  .use(locizePlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      load: "currentOnly",
      defaultNS: "Common",
      ns: [
        "CommonEnum",
        "Common",
        "Objects",
        "Organizations",
        "Transmitters",
        "Locations",
        "Messages",
        "Filters",
        "AlarmHistory",
        "Errors",
        "Home",
        "Menu",
        "Table",
        "Language",
        "LogEvent",
        "Contacts",
        "Administrator",
        "Settings",
        "Cameras",
        "Reports",
        "Reseller",
        "AlarmReceptions",
        "Roles",
        "Languages",
        "Permissions",
      ],
      debug: false, // !isProduction,
      fallbackLng: (code: string) => {
        // load all possible fallback languages in decending order i.e.
        // nb-NO-x-care-doro falls back on nb-NO-x-care
        // nb-NO-x-care falls back on nb-NO-x
        // nb-NO-x falls back on nb-NO
        const fallbacks = [];
        const splitString = code.split("-");

        let i = splitString.length;
        while (i > 1) {
          if (splitString[i - 1] === "x") {
            i -= 1;
            continue;
          }

          fallbacks.push(splitString.slice(0, i).join("-"));
          i -= 1;
        }

        fallbacks.push("en-GB");
        return fallbacks;
      },
      saveMissing: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        backends: [
          LocalStorageBackend,
          HttpBackend,
          LocizeBackend,
          LocizeBackend,
        ],
        backendOptions: [
          {
            expirationTime,
          },
          {
            loadPath: "/adminportal/nls/{{lng}}/{{ns}}.json",
          },
          locizeOptions,
          locizeBackendOptions,
        ],
      },
      react: {
        useSuspense: true,
      },
      keySeparator: " ",
    },
    () => {}
  );

export default i18n;
