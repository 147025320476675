import { useState, useLayoutEffect } from "react";

export interface Breakpoints<T> {
  mobile?(): T;
  tablet?(): T;
  desktop(): T;
}
export const breakpoints = { tablet: 576, desktop: 1024 };

export const useWindowDimensions = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export function useResponsiveStyle<T>(
  breakpointStyles: Breakpoints<T>,
  customBreakpoints?: any
): T | undefined {
  const { width } = useWindowDimensions();
  const internalBreakpoints = { ...breakpoints, ...customBreakpoints };
  if (breakpointStyles.mobile && width < internalBreakpoints.tablet) {
    return breakpointStyles.mobile();
  }
  if (
    breakpointStyles.tablet &&
    width >= internalBreakpoints.tablet &&
    width < internalBreakpoints.desktop
  ) {
    return breakpointStyles.tablet();
  }
  if (breakpointStyles.desktop && width >= internalBreakpoints.desktop) {
    return breakpointStyles.desktop();
  }
  return undefined;
}
