import { decode } from "he";
import { useForm, Controller } from "react-hook-form";
import { IPFilter } from "../../../core/api/organizations/types";
import { Spacer } from "../../../components/Layout/Layout";
import { validateIPAddress } from "../../../core/helpers/validation";

import useTranslations from "../../../core/i18n/useTranslations";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";

export const ResellerIPForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: IPFilter;
  onSubmit: (values: IPFilter) => void;
}) => {
  const t = useTranslations();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IPFilter>({
    defaultValues: { ...defaultValues, inherit: true },
  });

  return (
    <>
      <p style={{ color: "var(--Grey-600)" }}>
        {t(`Organizations:${defaultValues ? "edit" : "create"}_ip_description`)}
      </p>

      <Spacer size={32} />

      <form id="ip_address_from" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="startIP"
          control={control}
          rules={{
            required: true,
            validate: validateIPAddress,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("AlarmReceptions:edit_ipfilter_label_startip")}
              placeholder={t("AlarmReceptions:edit_ipfilter_placeholder_endip")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.startIP &&
                (field.value?.length === 0
                  ? t("Errors:input_field_required")
                  : t("AlarmReceptions:edit_ipfilter_ip_format"))
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          name="endIP"
          control={control}
          rules={{
            required: true,
            validate: validateIPAddress,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("AlarmReceptions:advanced_settings_ipfilters_endip")}
              placeholder={t("AlarmReceptions:edit_ipfilter_placeholder_endip")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.endIP &&
                (field.value?.length === 0
                  ? t("Errors:input_field_required")
                  : t("AlarmReceptions:edit_ipfilter_ip_format"))
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          name="comment"
          control={control}
          rules={{
            validate: (input: string | undefined) =>
              !input || input.length < 2048,
          }}
          render={({ field }) => (
            <TextArea
              inputRef={field.ref}
              label={t(
                "AlarmReceptions:advanced_settings_addintegration_comment_label"
              )}
              placeholder={t(
                "AlarmReceptions:advanced_settings_addintegration_comment_placeholder"
              )}
              value={decode(field.value || "")}
              onChange={field.onChange}
              validationError={
                errors?.comment && t("Errors:ValidationHint_OrganizationInfo")
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          control={control}
          name="adminApi"
          render={({ field }) => (
            <Checkbox
              label={t("Common:admin")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0 }}
            />
          )}
        />
      </form>
    </>
  );
};
