import { AxiosInstance, AxiosResponse } from "axios";
import queryString from "query-string";
import { useMemo } from "react";
import { transformOrganizationsToTreeData } from "../../utils/organizations";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  IPFilter,
  IpWhiteList,
  IOrganizationDetails,
  IRetentionDetails,
  IOrganizationProps,
  IOrganizationTable,
  IOrgIntegration,
  IAllIntegrationTypes,
} from "./types";
import { ApiResponse } from "../../interfaces/Api";
import { IAdvancedData } from "../../../pages/Organizations/AdvancedSettings/AdvancedSettings";

export interface NestedOrganizationProps {
  item: IOrganizationProps;
  children: NestedOrganizationProps[];
}

interface IFetchParams {
  search?: string;
  page?: number;
  pageSize?: number;
  order?: string;
  parentId?: number;
}

const useOrganizationsTree = (parentId: number | undefined = undefined) => {
  let data: NestedOrganizationProps[];
  let isLoading: boolean;
  let isError: boolean;

  const tmp = useAuthenticatedFetch<NestedOrganizationProps[]>(
    parentId
      ? `/api/v2/organizations/tree?nested=true&parentId=${parentId}&includeParent=true`
      : `/api/v2/organizations/tree?nested=true&pageSize=2000`
  );
  data = tmp.data;
  isLoading = tmp.isLoading;
  isError = tmp.isError;

  const transformed = useMemo(
    () => transformOrganizationsToTreeData(data),
    [data]
  );

  return { data: transformed, isLoading, isError };
};

const getOrganizationTrees = (
  params: {
    search?: string;
    parentId?: number | string;
    includeParent?: boolean;
    page?: number;
    pageSize?: number;
    getRoot?: boolean;
  },
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify(params);

  return authenticatedRequest.get<any>(
    `/api/v2/organizations/tree?${settings}`
  );
};

const getOrganizationsTable = (
  params: IFetchParams,
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify({ ...params, order: "DFS" });

  return authenticatedRequest.get<IOrganizationTable>(
    `/api/v2/organizations/table?${settings}`
  );
};

const getOrganization = (
  organizationId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<any>(
    `/api/v2/organizations/${organizationId}/details`
  );

const createOrganization = (
  newOrganizationData: IOrganizationDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<number>(
    `/api/v2/organizations`,
    JSON.stringify(newOrganizationData),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteOrganization = (
  Id: string | number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete(`/api/v2/organizations/${Id}`);

const updateOrganization = async (
  objectId: string,
  organizationDetails: IOrganizationDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<{
    data: {
      changedAttributes: IOrganizationDetails;
    };
  }>(`/api/v2/organizations/${objectId}`, JSON.stringify(organizationDetails), {
    headers: { "Content-Type": "application/json" },
  });

const updateRetentionData = async (
  id: string | number,
  authenticatedRequest: AxiosInstance,
  retentionValues: IRetentionDetails
) => {
  await authenticatedRequest.put<ApiResponse<IRetentionDetails>>(
    `/api/v2/organizations/${id}/retention`,
    JSON.stringify(retentionValues),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const getRetentionData = (
  id: string | number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.get(`/api/v2/organizations/${id}/retention`);

const getRetentionOptions = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get(`/api/v2/organizations/retention`);

const getSettings = (
  id: string | number,
  authenticatedRequest: AxiosInstance
): Promise<AxiosResponse<IAdvancedData[]>> =>
  authenticatedRequest.get<IAdvancedData[]>(
    `/api/v2/organizations/${id}/settings`
  );

const changeSettings = async (
  authenticatedRequest: AxiosInstance,
  orgId: number,
  data: {
    identifier: string;
    value: string | number;
  }
) => {
  await authenticatedRequest.put(
    `/api/v2/organizations/${orgId}/settings`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const getOrganizationsIPList = async (
  orgId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IpWhiteList>(
    `/api/v2/organizations/${orgId}/ipfilter`
  );

const updateIpAddress = async (
  orgId: string | number,
  data: IPFilter,
  authenticatedRequest: AxiosInstance,
  idToEdit?: number
) =>
  authenticatedRequest[!idToEdit ? "post" : "put"]<IpWhiteList>(
    `/api/v2/organizations/${orgId}/ipfilter${idToEdit ? `/${idToEdit}` : ""}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteIpAddress = async (
  orgId: string,
  idToDelete: number | string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `/api/v2/organizations/${orgId}/ipfilter/${idToDelete}`
  );

const getIntegrationTypes = async (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<IAllIntegrationTypes>(`/api/planning/integrations`);

const getOrganizationIntegrations = async (
  orgId: string | number,
  authenticatedRequest: AxiosInstance
) =>
  await authenticatedRequest.get<IOrgIntegration[]>(
    `/api/planning/organizations/${orgId}/integration`
  );

const createIntegration = async (
  orgId: string,
  authenticatedRequest: AxiosInstance,
  data: IOrgIntegration
) => {
  await authenticatedRequest.post(
    `/api/planning/organizations/${orgId}/integration`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const updateIntegration = async (
  orgId: string,
  integrationId: string,
  authenticatedRequest: AxiosInstance,
  data: IOrgIntegration
) => {
  await authenticatedRequest.put(
    `/api/planning/organizations/${orgId}/integration/${integrationId}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const deleteIntegration = async (
  orgId: string,
  integrationId: string,
  authenticatedRequest: AxiosInstance
) =>
  await authenticatedRequest.delete(
    `/api/planning/organizations/${orgId}/integration/${integrationId}`
  );

export {
  useOrganizationsTree,
  deleteOrganization,
  getOrganizationTrees,
  createOrganization,
  getOrganization,
  updateOrganization,
  getRetentionData,
  getRetentionOptions,
  updateRetentionData,
  getSettings,
  changeSettings,
  getOrganizationsIPList,
  updateIpAddress,
  deleteIpAddress,
  getOrganizationsTable,
  getOrganizationIntegrations,
  updateIntegration,
  createIntegration,
  deleteIntegration,
  getIntegrationTypes,
};
