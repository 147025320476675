import { ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import useTranslations from "../../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import PageHeader from "../../../ui-lib/components/PageHeader/PageHeader";
import useUser from "../../../core/user/useUser";
import {
  storeTableState,
  useStoredTableState,
} from "../../../core/hooks/filters/useStoredTableState";
import {
  searchStringParser,
  urlSearchParser,
} from "../../../ui-lib/utils/urlSearchParser";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { IProduct } from "../../../core/api/products/types";
import { getProductsAll } from "../../../core/api/products/products";
import SearchInput from "../../../ui-lib/components/Inputs/SearchInput";
import Divider from "../../../ui-lib/components/Divider/Divider";
import { useSubNav } from "../../../components/LoggedinPage/SubNavContext";
import { PAGE_ID as parentId } from "../../Administrators/Administrators";
import { ProductsTable } from "./ProductsTable";
import Button from "../../../ui-lib/components/Button/Button";
import { AddProductModal } from "./ProductDetails/AddProductModal";
import { useQueryState } from "../../../core/hooks/filters/useQueryState";

import styles from "../../PagesLayout.module.css";

const parentPath = "/adminportal/resellers";
export const PAGE_ID = "adminportal/products";

const subMenuItems = (url: string) => [{ key: "Common:products", to: url }];

const ProductListWrapper = ({
  fetchProducts,
  children,
}: {
  fetchProducts: () => void;
  children: ReactNode;
}) => {
  const t = useTranslations();
  const [isAddProductModalVisible, setIsAddProductModalVisible] =
    useState(false);

  return (
    <>
      <AddProductModal
        isOpen={isAddProductModalVisible}
        setIsOpen={setIsAddProductModalVisible}
        fetchProducts={fetchProducts}
      />
      <PageHeader title={t("Common:products")}>
        <Button
          text={t("Reseller:add_product")}
          dataCy={"add-product-button"}
          onClick={() => setIsAddProductModalVisible(true)}
        />
      </PageHeader>
      <Column className={styles.content} type="top" align="start">
        {children}
      </Column>
    </>
  );
};

const ProductsList = () => {
  const { path } = useRouteMatch();
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  storeTableState(PAGE_ID);
  const savedState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(savedState);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  // tmp comment
  const [searchParam, setSearchParam] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) ?? "",
  });

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const result = await getProductsAll(authenticatedRequest);
      setProducts(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Switch>
      <Route path={path}>
        <ProductListWrapper fetchProducts={fetchProducts}>
          <Row className={styles.settingsRow}>
            <SearchInput
              placeholder={t("Reseller:search_by_product_id")}
              value={searchParam.q ?? ""}
              limit={1}
              onChange={(value) => {
                setSearchParam({ q: value });
              }}
            />
          </Row>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <Row className="w-100" align="start" type="fill">
            <ProductsTable
              products={products}
              isLoading={loading}
              searchParam={searchParam.q}
              fetchProducts={fetchProducts}
            />
          </Row>
        </ProductListWrapper>
      </Route>
    </Switch>
  );
};

const ProductListContainer = () => {
  const t = useTranslations();

  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  useEffect(() => {
    const nav = {
      menu: subMenuItems(url),
      backUrl,
      backText: t("Common:sellers"),
    };

    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  return <ProductsList />;
};

export default observer(ProductListContainer);
