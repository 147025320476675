import _ from "lodash";
import moment from "moment";
import he from "he";
import i18ninternal from "../../core/i18n/config";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { SortOrderParams } from "../../ui-lib/components/Tables/Table";
import { ApiError, ApiV2Error } from "../interfaces/Api";
import { getStoredToken } from "../user/token";
import { PasswordConstraints } from "../api/administrators/types";

export const getTimeZone = (replaceSymbols = true) => {
  // @ts-ignore
  let [timezone] = RegExp(/([-,+]\d+)\s/).exec(new Date().toString());
  timezone = `${timezone.slice(0, 3)}:${timezone.slice(
    3,
    timezone.length - 1
  )}`;
  if (replaceSymbols) {
    timezone = timezone.replace("+", "%2b");
    timezone = timezone.replace("-", "%2d");
  }
  return timezone;
};

export const isApiV2Error = (error: any): error is ApiV2Error =>
  "errorMessage" in error;

export const setTheme = (
  cssLink: string | undefined,
  icoLink: string | undefined,
  title: string | undefined
) => {
  const { origin } = window.location;
  if (cssLink !== undefined) {
    const css = document.getElementById("brandStylesheet") as HTMLLinkElement;
    css.href = `${origin}/${cssLink}`;
  }
  if (icoLink !== undefined) {
    const ico = document.getElementById("favicon") as HTMLLinkElement;
    ico.href = icoLink;
  }
  if (title !== undefined) {
    document.title = title;
  }
};

export const maskPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length <= 7) {
    return phoneNumber;
  }

  const first4 = phoneNumber.substring(0, 4);
  const last3 = phoneNumber.substring(phoneNumber.length - 3);

  const mask = phoneNumber
    .substring(4, phoneNumber.length - 3)
    .replace(/\d/g, "*");

  return first4 + mask + last3;
};

export const downloadAsXlsxFile = (data: string, fileName: string) => {
  const fileType =
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
  const link = document.createElement("a");
  link.href = fileType + data;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const hexToRgba = (hex: string, alpha?: number) => {
  hex = hex.replace("#", "");
  const r = parseInt(
    hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
    16
  );
  const g = parseInt(
    hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
    16
  );
  const b = parseInt(
    hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
    16
  );
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.startsWith(" ") ? hex.replace(" ", "") : hex
  );

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const sortParamsToString = (
  params?: SortOrderParams,
  withAsc?: boolean
) => {
  if (!params) {
    return undefined;
  }

  const { field, sortOrder } = params;

  switch (sortOrder) {
    case 1:
      // This need because back-end can not fix their code
      return withAsc ? `${field}_asc` : field;
    case -1:
      return `${field}_desc`;
    default:
      return undefined;
  }
};

export const getXAxis = (type: number) => {
  const xAxis: string[] = [];
  const timezone = getStoredToken()?.guiConfig.timezone.timezoneName ?? "";
  const currentDate = moment(Date.now()).tz(timezone);
  const stopDate = moment(Date.now()).tz(timezone);

  if (type === 0) {
    const startDate = currentDate.subtract(new Date().getHours() + 1, "hours");
    for (let hour = 0; hour < 24; hour += 1) {
      xAxis.push(startDate.add(1, "hours").format("HH"));
    }
  } else if (type === 1) {
    const startDate = currentDate.subtract(25 + new Date().getHours(), "hours");
    for (let hour = 0; hour < 24; hour += 1) {
      xAxis.push(startDate.add(1, "hours").format("HH"));
    }
  } else if (type === 2) {
    const startDate = currentDate.subtract(24, "hours");
    for (let hour = 0; hour < 24; hour += 1) {
      xAxis.push(startDate.add(1, "hours").format("HH"));
    }
  } else if (type === 3) {
    let startDate = currentDate.subtract(6, "days");
    while (startDate <= stopDate) {
      xAxis.push(moment(startDate).format("DD/MM"));
      startDate = moment(startDate).add(1, "days");
    }
  } else if (type === 4) {
    let startDate = currentDate.subtract(30, "days");
    while (startDate <= stopDate) {
      xAxis.push(moment(startDate).format("DD/MM"));
      startDate = moment(startDate).add(1, "days");
    }
  } else if (type === 6) {
    const startDate = currentDate.subtract(11, "months");
    for (let month = 0; month < 12; month += 1) {
      xAxis.push(startDate.format("MMM - YY"));
      startDate.add(1, "months");
    }
  }
  return xAxis;
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const stripArrayIdentifier = (val: string): string => {
  const regex = /.+(_\d)$/gm;
  const m = regex.exec(val);
  let newVal = val;
  if (m !== null && m.length > 1) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex += 1;
    }
    if (m.length) newVal = newVal.replace(m[1], "");
  }
  return newVal;
};

export const colKeyToLanguageKey = (val: string) => {
  const langKeysToModify = [
    "Addresses_PostalCode",
    "Addresses_Street",
    "Addresses_Telephone1",
    "Addresses_Telephone2",
  ];
  const stripped = val.split("_");
  const key = stripped[0];
  const parts = key.split(".");

  let keyToReturn = parts.length === 2 ? `${parts[0]}_${parts[1]}` : key;
  let indexToReturn = stripped.length === 2 ? stripped[1] : undefined;

  if (langKeysToModify.includes(keyToReturn)) {
    // eslint-disable-next-line prefer-destructuring
    keyToReturn = keyToReturn.split("_")[1];
    indexToReturn = undefined;
  }

  return {
    languageKey: keyToReturn,
    index: indexToReturn,
  };
};
const getNestedName = (object: any): string => {
  if (object?.name) {
    return object.name;
  }
  return `
  object`;
};
export const formattedDateString = (
  dateObject: Date,
  format: string = "YYYY-MM-DD HH:mm"
): string | undefined => {
  if (dateObject) {
    return moment(dateObject).format(format);
  }

  return undefined;
};
export const getInterpolatedError = (
  namespace: string,
  error: ApiError | ApiV2Error
): string => {
  let validationString: string = "";

  if (isApiV2Error(error)) {
    let name = error.errorMessage;

    if (error.errorMessage.endsWith("Exception")) {
      name = name.replace("Exception", "");
    }

    validationString = i18ninternal.t(`Errors:${name}`);
    if (name === "InvalidFieldValueHintParams") {
      const fieldName = i18ninternal.t(
        `Errors:${error.errorDetails.propertyName}`
      );
      validationString = validationString
        .replace("{FieldName}", fieldName)
        .replace("{FieldValue}", error.errorDetails.attemptedValues || "");

      return validationString;
    }
    if (name === "InvalidAttributeValueParams") {
      const errorMessage = i18ninternal.t(`Errors:InvalidAttributeValueParams`);
      validationString = errorMessage
        .replace("{FieldName}", error.errorDetails?.propertyName || "")
        .replace("{FieldValue}", error.errorDetails.attemptedValues || "");

      return validationString;
    }
    if (
      getNestedName(name) === "InvalidAttributeValue" &&
      error.errorDetails.propertyName
    ) {
      validationString = `${i18ninternal.t(`Errors:${getNestedName(name)}`)} (${
        error.errorDetails.propertyName
      })`;

      return validationString;
    }
    if (error.customDetails) {
      validationString = i18ninternal
        .t(`Errors:${error.customDetails.ValidationHint}`)
        .replace("{AcceptableValues}", error.customDetails.AcceptableValues);

      return validationString;
    }
    if (
      validationString.includes("{Value}") &&
      error.errorDetails.attemptedValue
    ) {
      validationString = validationString.replace(
        "{Value}",
        error.errorDetails.attemptedValue
      );

      return validationString;
    }

    return validationString;
  }

  const name = error.name || error.errorName;

  validationString = i18ninternal.t(`Errors:${name}`);
  if (name === "InvalidFieldValueHintParams") {
    const fieldName = i18ninternal.t(`Errors:${error.parameters?.FieldName}`);
    validationString = validationString
      .replace("{FieldName}", fieldName)
      .replace("{FieldValue}", error.parameters?.FieldValue || "");
    if (error.parameters.AcceptableValues) {
      validationString = `${validationString.replace("''", "")}. ${he.decode(
        error.parameters.AcceptableValues
      )}`;
    }
    return validationString;
  }
  if (name === "InvalidAttributeValueParams") {
    const errorMessage = i18ninternal.t(`Errors:InvalidAttributeValueParams`);
    validationString = errorMessage
      .replace("{FieldName}", error.parameters?.AttributeName || "")
      .replace("{FieldValue}", error.parameters?.AttributeValue || "");
    return validationString;
  }
  if (getNestedName(name) === "InvalidAttributeValue" && error.description) {
    validationString = `${i18ninternal.t(`Errors:${getNestedName(name)}`)} (${
      error.description
    })`;
    return validationString;
  }
  if (error.parameters) {
    _.each(_.keys(error.parameters), (x) => {
      validationString = validationString.replace(
        `{${x}}`,
        error.parameters[x]
      );
    });
  }

  return validationString;
};

export const notifyApiErrors = (errors?: any, status?: string | number) => {
  /**
   * If translation for status exist - show it;
   * Else show error message translation.
   */
  const translationExist = i18ninternal.exists(`Errors:${status}`);
  if (translationExist) {
    notify({
      message: i18ninternal.t(`Errors:${status}`),
      variant: "error",
    });
  } else if (errors) {
    errors?.forEach((err: any) => {
      notify({
        message: getInterpolatedError("Errors", err),
        variant: "error",
      });
    });
  }
};

export const b64toBlob = (
  b64Data: string,
  contentType: string = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const isEmpty = (val: any): boolean =>
  val === undefined || val === null || val?.toString() === "";

export const isEq = (defaultVals: any, currentVals: any) => {
  if (defaultVals === undefined || currentVals === undefined) {
    return defaultVals === currentVals;
  }
  const defProps = Object.getOwnPropertyNames(defaultVals);

  for (const element of defProps) {
    const prop: any = element;
    const defaultEmpty = isEmpty(defaultVals[prop]);
    const currentEmpty = isEmpty(currentVals[prop]);
    if (
      (!currentEmpty || !defaultEmpty) &&
      defaultVals[prop] !== currentVals[prop]
    ) {
      return false;
    }
  }
  return true;
};

export const getNonEmptyProps = (currentVals: any) => {
  const defProps = Object.getOwnPropertyNames(currentVals);
  const valueProps: string[] = [];
  for (const element of defProps) {
    const prop: any = element;
    if (!isEmpty(currentVals[prop])) {
      valueProps.push(prop);
    }
  }
  return valueProps;
};

export const getColonFormattedTimeString = (time?: string): string => {
  if (isEmpty(time)) {
    return "";
  }
  if (time?.length !== 4) {
    return time!;
  }
  return `${time.substring(0, 2)}:${time.substring(2, 4)}`;
};

export const safeGetFromArray = (idx: number, arr: string[]) => {
  if (arr.length >= idx) {
    return arr[idx];
  }

  return undefined;
};

export const isSipUsername = (numberOrUsername = "") =>
  // If numberOrUsername starts with either 'sip:' or 'sips:'
  RegExp(/^sips?:/).exec(numberOrUsername);

export const getIdentifierErrors = (type: string, identifierKey?: string) => {
  if (type === "required") {
    return i18ninternal.t("Objects:Validation_identifier_required");
  }
  if (type === "validate" && !!identifierKey) {
    return i18ninternal.t(`Common:validation_${identifierKey}`);
  }
  return i18ninternal.t("Errors:WrongFormat");
};

export const getPasswordErrorMessage = (
  type: string,
  rule: PasswordConstraints
) => {
  if (type === "required") {
    return i18ninternal.t("Errors:input_field_required");
  }
  if (type === "minLength") {
    return i18ninternal
      .t("Administrator:password_constraints_minimum_length")
      .replace("{0}", `${rule.minLength}`);
  }
  if (type === "lowerCase") {
    return i18ninternal
      .t("Administrator:password_constraints_minimum_lowercase")
      .replace("{0}", rule.minLowerCase.toString());
  }
  if (type === "upperCase") {
    return i18ninternal
      .t("Administrator:password_constraints_minimum_uppercase")
      .replace("{0}", rule.minUpperCase.toString());
  }
  if (type === "countNumbers") {
    return i18ninternal
      .t("Administrator:password_constraints_minimum_digits")
      .replace("{0}", rule.minNumbers.toString());
  }
  if (type === "forbiddenChars") {
    return i18ninternal
      .t("Administrator:password_constraints_forbidden_chars")
      .replace("{0}", rule.forbiddenChars);
  }
  return i18ninternal.t("Errors:password_wrong_format");
};
