import {
  Dispatch,
  RefObject,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "primereact/tooltip";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { PlayingRowType } from "../../pages/AlarmHistory/AlarmHistoryDetails/AttachmentsTable";
import Button from "../../ui-lib/components/Button/Button";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import Icon from "../../ui-lib/components/Icon";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

import "react-h5-audio-player/lib/styles.css";
import styles from "./Player.module.css";

type CurentlyPlaying = {
  name?: string;
  src: string;
};

interface PlayerProps {
  playerActionsRef: RefObject<AudioPlayer>;
  isLoading: boolean;
  allAttachmentsIDs: number[];
  currentTrack?: CurentlyPlaying;
  currentPlayingIndex: number;
  setRowIDToPlay: Dispatch<SetStateAction<PlayingRowType | undefined>>;
  nextPrevClick: (side: "next" | "prev") => void;
  handlePlayPauseState: (action: string) => void;
}
export interface PlayerActions {
  show: () => void;
  hide: () => void;
  isOpened: () => boolean;
}

const Player = forwardRef<PlayerActions, PlayerProps>((props, ref) => {
  const {
    currentTrack,
    playerActionsRef,
    isLoading,
    allAttachmentsIDs,
    currentPlayingIndex,
    setRowIDToPlay,
    nextPrevClick,
    handlePlayPauseState,
  } = props;
  const { t } = useTranslation();
  const [showPlayer, setShowPlayer] = useState(false);

  const endPlaylist = currentPlayingIndex === allAttachmentsIDs?.length - 1;
  const startPlaylist = currentPlayingIndex === 0;

  const customIcons = {
    play: (
      <>
        <Tooltip className="playerTooltip" target=".playBtn" />
        <span
          className="playBtn"
          data-pr-tooltip={t("Common:play")}
          data-pr-position="top"
        >
          <Icon name="play" color="Primary-700" size={24} />
        </span>
      </>
    ),
    pause: (
      <>
        <Tooltip className="playerTooltip" target=".pauseBtn" />
        <span
          className="pauseBtn"
          data-pr-tooltip={t("Common:pause")}
          data-pr-position="top"
        >
          <Icon name="pause" color="Primary-700" size={24} />
        </span>
      </>
    ),
    next: (
      <>
        <Tooltip className="playerTooltip" target=".nextBtn" />
        <span
          className="nextBtn"
          data-pr-tooltip={t("Common:next")}
          data-pr-position="top"
        >
          <Icon
            name="next"
            color={endPlaylist ? "Grey-400" : "Primary-700"}
            size={24}
          />
        </span>
      </>
    ),
    previous: (
      <>
        <Tooltip className="playerTooltip" target=".prevBtn" />
        <span
          className="prevBtn"
          data-pr-tooltip={t("Common:previous")}
          data-pr-position="top"
        >
          <Icon
            name="previous"
            color={startPlaylist ? "Grey-400" : "Primary-700"}
            size={24}
          />
        </span>
      </>
    ),
    volume: (
      <>
        <Tooltip className="playerTooltip" target=".muteBtn" />
        <span
          className="muteBtn"
          data-pr-tooltip={t("Common:mute")}
          data-pr-position="top"
        >
          <Icon name="volume-up" color="Grey-800" size={24} />
        </span>
      </>
    ),
    volumeMute: (
      <>
        <Tooltip className="playerTooltip" target=".unmuteBtn" />
        <span
          className="unmuteBtn"
          data-pr-tooltip={t("Common:unmute")}
          data-pr-position="top"
        >
          <Icon name="volume-off" color="Grey-800" size={24} />
        </span>
      </>
    ),
  };

  useImperativeHandle(ref, () => ({
    show() {
      setShowPlayer(true);
    },
    hide() {
      setShowPlayer(false);
    },
    isOpened() {
      return showPlayer;
    },
  }));

  return (
    showPlayer && (
      <PrimeModal
        isOpen={showPlayer}
        onClose={() => setShowPlayer(false)}
        modal={false}
        position="bottom"
        className={styles.playerWrapper}
      >
        <AudioPlayer
          autoPlay
          showSkipControls
          ref={playerActionsRef}
          customIcons={{ ...customIcons }}
          src={currentTrack?.src}
          onPause={() =>
            // Handle pause icon state
            handlePlayPauseState("pause")
          }
          onPlay={() =>
            // Handle play icon state
            handlePlayPauseState("play")
          }
          onClickNext={() => {
            if (endPlaylist) return;
            nextPrevClick("next");
          }}
          onClickPrevious={() => {
            if (startPlaylist) return;
            nextPrevClick("prev");
          }}
          showJumpControls={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          customControlsSection={[]}
          customProgressBarSection={[
            <div className={styles.trackNameWrapper} key={currentTrack?.name}>
              {isLoading ? (
                <LoadingSpinner theme="primary" size={32} />
              ) : (
                <p className={styles.trackName}>{currentTrack?.name ?? ""}</p>
              )}
            </div>,
            RHAP_UI.MAIN_CONTROLS,
            RHAP_UI.CURRENT_TIME,
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.DURATION,
            RHAP_UI.VOLUME,
            <>
              <Tooltip className="playerTooltip" target=".closeBtn" />
              <span
                className="closeBtn"
                data-pr-tooltip={t("Common:close")}
                data-pr-position="top"
              >
                <Button
                  key="Close_btn"
                  onClick={() => {
                    setShowPlayer(false);
                    setRowIDToPlay(undefined);
                  }}
                  className={styles.closeIcon}
                  image="x"
                  variant="secondary"
                  ariaLabel="Close player"
                />
              </span>
            </>,
          ]}
        />
      </PrimeModal>
    )
  );
});

export default Player;
