import { Coords } from "../interfaces/Coords";

const gap: number = 4;
const dropdownItemHeight: number = 40;
const maxVisibleDropDownItems: number = 5;

export const getDropdownCoords = (
  elem: HTMLButtonElement | null,
  bodyHeight: number
): Coords => {
  const { innerHeight, scrollY } = window;
  const elemData: DOMRect | undefined = elem?.getBoundingClientRect();

  const elemHeight: number = elemData?.height ?? 0;
  const elemTop: number = elemData?.top ?? 0;
  let top: number = elemTop + elemHeight + gap + scrollY;
  const left: number = elemData?.left ?? 0;
  if (top - scrollY + bodyHeight > innerHeight)
    top = top - bodyHeight - elemHeight - gap * 2; // if we don't fit on the screen we open dropdown up

  const coords: Coords = {
    left,
    top,
  };
  return coords;
};

export const getDropdownWidth = (elem: HTMLButtonElement | null): number =>
  elem?.getBoundingClientRect().width ?? 0;

export const getDropdownBodyHeight = (
  itemsLength: number,
  maxVisible?: number,
  withFilter = false
): number => {
  let height = (itemsLength + (withFilter ? 1.5 : 0)) * dropdownItemHeight;

  const effectiveMaxVisible = maxVisible ?? maxVisibleDropDownItems;
  if (itemsLength > effectiveMaxVisible)
    height = dropdownItemHeight * effectiveMaxVisible;

  return height;
};

export const getRootPopup = (): HTMLElement => {
  let PopupRoot = document.getElementById("popup-root");

  if (PopupRoot === null) {
    PopupRoot = document.createElement("div");
    PopupRoot.setAttribute("id", "popup-root");
    document.body.appendChild(PopupRoot);
  }

  return PopupRoot;
};
