import { useState } from "react";
import Icon from "../Icon";
import useUser from "../../../core/user/useUser";
import { notify } from "../Alerts/Toast";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import LoadingSpinner from "../Loading/LoadingSpinner";
import useTranslations from "../../../core/i18n/useTranslations";
import { patchUpdateObject } from "../../../core/api/objects/objects";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";

import styles from "./PhotoWrapperWithUpload.module.css";

interface PhotoWrapperProps {
  objectId: string;
  buttonName: string;
  propertyToContainData: string;
  photoTitle?: string;
  preLoadedImageData?: string;
  alternativeString?: string;
}

const PhotoWrapperWithUpload = ({
  objectId,
  buttonName,
  propertyToContainData,
  photoTitle,
  preLoadedImageData,
  alternativeString,
}: PhotoWrapperProps) => {
  const t = useTranslations();
  const { width } = useWindowDimensions();
  const regg = "data:image/jpeg;base64,";
  const photoURL = preLoadedImageData ? `${regg}${preLoadedImageData}` : "";

  const { authenticatedRequest } = useUser();
  const [loading, setLoading] = useState(false);
  const [photoData, setPhotoData] = useState({
    preview: photoURL,
    raw: "",
  });

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (e.target.files.length) {
      setLoading(true);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        if (reader.result) {
          // @ts-ignore
          const imgData = reader.result.replace(regg, "");

          try {
            await patchUpdateObject(
              objectId,
              { [propertyToContainData]: imgData },
              authenticatedRequest
            );
            setPhotoData({
              preview: imageUrl,
              raw: e.target.files[0],
            });
          } catch (error: any) {
            const errorMessage = error.response?.data.errors[0].errorMessage;
            if (errorMessage.includes("input is not a valid")) {
              return notify({
                message: t("Errors:wrong_image_format"),
                variant: "error",
              });
            }
            const errorKey = getErrorKey(error);
            notify({
              message: t(`Errors:${errorKey}`),
              variant: "error",
            });
          } finally {
            setLoading(false);
          }
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {width >= breakpoints.desktop && photoTitle && (
        <p className={styles.buttonTitle}>{photoTitle}</p>
      )}
      <label className={styles.buttonWrapper} htmlFor={buttonName}>
        {photoData.preview || photoURL ? (
          <img
            className={styles.buttonContent}
            src={photoData.preview || photoURL}
            alt={alternativeString ?? "image"}
          />
        ) : (
          <div className={styles.buttonContent}>
            <Icon name="photograph" color="Primary-700" />
            <p>{t("Common:upload")}</p>
          </div>
        )}
        <input
          type="file"
          id={buttonName}
          className={styles.fileInput}
          onChange={handleUpload}
          accept="image/jpeg"
        />
      </label>
    </>
  );
};

export default PhotoWrapperWithUpload;
