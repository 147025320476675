import moment from "moment/moment";
import momentTimezone from "moment-timezone";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { Calendar } from "primereact/calendar";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import Button from "../../../../ui-lib/components/Button/Button";
import Table from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import Icon from "../../../../ui-lib/components/Icon";
import Badge, { BadgeProps } from "../../../../ui-lib/components/Badges/Badge";
import useUser from "../../../../core/user/useUser";
import useTranslations from "../../../../core/i18n/useTranslations";
import { NewActivityModal } from "../NewActivityModal";
import { getPlannedActivities } from "../../../../core/api/objects/objects";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import {
  ICalendarActivities,
  ICalendarActivity,
} from "../../../../core/api/objects/types";
import { getOrganizationIntegrations } from "../../../../core/api/organizations/organizations";
import { IOrgIntegration } from "../../../../core/api/organizations/types";
import useSharedObject from "../../SharedObjectContext/useSharedObject";
import { normalizeDate } from "../../../../ui-lib/utils/userDateFormat";

import styles from "./PlanningOverview.module.css";
import { TFunction } from "i18next";
import { DeleteActivityModal } from "../DeleteActivityModal";
import { getStoredToken } from "../../../../core/user/token";

export const errorStatuses = [
  "ExecuteFailure",
  "ExecuteError",
  "Aborted",
  "FailedAbort",
];

export const completedStatuses = [
  "Complete",
  "Aborted",
  "FailedAbort",
  "ExecuteSuccess",
  "ExecuteFailure",
  "ExecuteError",
];

const columns = (t: TFunction<"translation", undefined>) => [
  {
    fieldTemplate(rowData: ICalendarActivity) {
      return rowData.timingType === "Reoccuring" ? (
        <TableCell value={<Icon name="refresh" size={16} />} />
      ) : null;
    },
    style: {
      minWidth: "unset",
      width: "48px",
      textAlign: "center",
    },
  },
  {
    fieldTemplate(rowData: ICalendarActivity) {
      const timezoneId = getStoredToken()?.guiConfig.timezone.timezoneName;
      let startTime = "";
      let cron;
      switch (rowData.timingType) {
        case "Reoccuring":
          cron = rowData.timingInformation.split(" ");
          startTime = moment()
            .set("hours", +cron[2])
            .set("minutes", +cron[1])
            .toISOString();
          startTime = momentTimezone(startTime).tz(timezoneId!).format("HH:mm");
          break;
        case "Date":
          startTime = momentTimezone(rowData.timingInformation)
            .tz(timezoneId!)
            .format("HH:mm");
          break;
        case "Now":
          startTime = momentTimezone
            .utc(rowData.executionTime)
            .tz(timezoneId!)
            .format("HH:mm");
          break;
      }
      return (
        <Column align="start">
          <span>{startTime}</span>
          <TableCell cellStyles={{ padding: 0 }} value={rowData?.description} />
        </Column>
      );
    },
  },
  {
    fieldTemplate(rowData: ICalendarActivity) {
      let statusVariant = "success";
      if (errorStatuses.includes(rowData.status)) statusVariant = "error";
      if (
        !errorStatuses.includes(rowData.status) &&
        rowData.status !== "Complete"
      )
        statusVariant = "info";
      return (
        <Badge
          withHighlightedText
          variant={statusVariant as BadgeProps["variant"]}
          title={t(`Objects:${rowData.status}`)}
        />
      );
    },
    style: { textAlign: "right" },
  },
];

export const PlanningOverview = ({ objectId }: { objectId: number }) => {
  const t = useTranslations();
  const { config, authenticatedRequest } = useUser();
  const { data: objectData } = useSharedObject();

  const [loading, setLoading] = useState(true);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [newActivityModalOpen, setNewActivityModalOpen] = useState(false);
  const [deleteActivityId, setDeleteActivityId] = useState<string>();
  const [editActivityId, setEditActivityId] = useState<string>();
  const [canEditActivity, setCanEditActivity] = useState(true);
  const [currentViewStartMonth, setCurrentViewStartMonth] = useState<string>();
  const [activitiesData, setActivitiesData] = useState<ICalendarActivities>();
  const [selectedDayActivities, setSelectedDayActivities] = useState<
    ICalendarActivity[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [integrationOptions, setIntegrationOptions] =
    useState<IOrgIntegration[]>();

  const fetchOrgIntegrations = async () => {
    setLoading(true);
    try {
      const integrationsData = await getOrganizationIntegrations(
        objectData?.organizationId!,
        authenticatedRequest
      );
      setIntegrationOptions(integrationsData.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const fetchActivities = async () => {
    setIsCalendarLoading(true);
    try {
      let startDate;
      if (!currentViewStartMonth) {
        startDate = moment().startOf("month").toISOString();
        setCurrentViewStartMonth(startDate);
      } else {
        startDate = currentViewStartMonth;
      }
      const endDate = moment(startDate)
        .add(1, "month")
        .endOf("month")
        .toISOString();
      const result = await getPlannedActivities(
        objectId,
        startDate,
        endDate,
        authenticatedRequest
      );
      setActivitiesData(result.data);
      setSelectedDayActivities(
        result.data[normalizeDate(moment().format("YYYY-MM-DD"))]
      );
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
      setIsCalendarLoading(false);
    }
  };

  useEffect(() => {
    fetchOrgIntegrations();
  }, []);

  useEffect(() => {
    fetchActivities();
  }, [currentViewStartMonth]);

  useEffect(() => {
    const nextMonthButton = document.querySelector(
      '[data-pc-section="nextbutton"]:not([style*="visibility: hidden"])'
    );
    const prevMonthButton = document.querySelector(
      '[data-pc-section="previousbutton"]:not([style*="visibility: hidden"])'
    );
    if (isCalendarLoading) {
      nextMonthButton?.setAttribute("disabled", "true");
      prevMonthButton?.setAttribute("disabled", "true");
    } else {
      nextMonthButton?.removeAttribute("disabled");
      prevMonthButton?.removeAttribute("disabled");
    }
  }, [isCalendarLoading]);

  const handleMonthChangeClick = (increase: boolean) => {
    const startDate = moment(currentViewStartMonth);
    startDate.add(increase ? 1 : -1, "month");
    setCurrentViewStartMonth(startDate.toISOString());
  };

  const checkMonthEvents = () => {
    const nextMonthButton = document.querySelector(
      '[data-pc-section="nextbutton"]:not([style*="visibility: hidden"])'
    );
    const prevMonthButton = document.querySelector(
      '[data-pc-section="previousbutton"]:not([style*="visibility: hidden"])'
    );

    if (!nextMonthButton?.getAttribute("data-click-listener-added")) {
      nextMonthButton?.addEventListener("click", () =>
        handleMonthChangeClick(true)
      );
      nextMonthButton?.setAttribute("data-click-listener-added", "true");
    }
    if (!prevMonthButton?.getAttribute("data-click-listener-added")) {
      prevMonthButton?.addEventListener("click", () =>
        handleMonthChangeClick(false)
      );
      prevMonthButton?.setAttribute("data-click-listener-added", "true");
    }
  };

  checkMonthEvents();

  return (
    <>
      <div style={{ width: "100%" }}>
        <Row type="space" style={{ width: "100%" }}>
          <Column align="start">
            <h1>{t("Objects:planning_overview_title")}</h1>
            <Spacer size={8} />
            <p>{t("Objects:planning_overview_description")}</p>
          </Column>
          <Column align="end">
            <Button
              text={t("Objects:plan_new_activity")}
              image="plus-circle"
              variant="border-link"
              onClick={() => setNewActivityModalOpen(true)}
            />
          </Column>
        </Row>
        <Spacer size={32} />
        {loading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <div className={classnames(styles.planningWrapper)}>
            <div
              className={classnames(styles.planningCalendar)}
              style={{
                borderStyle: "solid",
                borderWidth: "0 1px 0 0",
                borderColor: "var(--Grey-200)",
              }}
            >
              <Calendar
                inline
                disabled={isCalendarLoading}
                numberOfMonths={2}
                value={selectedDate}
                onChange={(val) => {
                  const date = val.target.value?.toISOString();
                  setSelectedDate(val.target.value);
                  setSelectedDayActivities(
                    activitiesData?.[
                      normalizeDate(moment(date).format("YYYY-MM-DD"))
                    ] ?? []
                  );
                }}
                dateTemplate={(el) => {
                  const dayString = `${el.year}-${el.month + 1}-${el.day}`;
                  const activitiesDays = Object.keys(activitiesData ?? {});
                  return (
                    <div
                      className={classnames({
                        [styles.withActions]: activitiesDays.some(
                          (date: string) => normalizeDate(dayString) === date
                        ),
                      })}
                    >
                      {el.day}
                    </div>
                  );
                }}
                panelStyle={{
                  borderWidth: "0",
                }}
              />
            </div>
            <div className={classnames(styles.planningList)}>
              <h2>{t("Objects:planned_activities")}</h2>
              <p>{`${t("Common:for")} ${
                moment(selectedDate?.toDateString()).format(
                  config?.dateFormat
                ) || moment().format(config?.dateFormat)
              }`}</p>
              <Spacer size={16} />
              <Table<ICalendarActivity>
                hideHeader
                hideEmptyMessage
                rowActionContextMenu
                items={selectedDayActivities}
                rowActions={[
                  {
                    text: t("Common:view"),
                    icon: "eye",
                    iconVariant: "secondary",
                    iconSize: 16,
                    onClick: (rowData) => {
                      setEditActivityId(rowData.activityId);
                      setNewActivityModalOpen(true);
                      setCanEditActivity(false);
                    },
                    hideForRow: (rowData) =>
                      !completedStatuses.includes(rowData.status),
                  },
                  {
                    text: t("Common:label_edit"),
                    icon: "pencil",
                    iconVariant: "secondary",
                    iconSize: 16,
                    onClick: (rowData) => {
                      setEditActivityId(rowData.activityId);
                      setNewActivityModalOpen(true);
                    },
                    hideForRow: (rowData) =>
                      completedStatuses.includes(rowData.status),
                  },
                  {
                    text: t("Common:delete"),
                    icon: "trash",
                    iconVariant: "secondary",
                    iconSize: 16,
                    onClick: (rowData) => {
                      setDeleteActivityId(rowData.activityId);
                    },
                    hideForRow: (rowData) => rowData.status === "Aborted",
                  },
                ]}
                columns={columns(t)}
              />
            </div>
          </div>
        )}
      </div>
      <NewActivityModal
        userId={objectData?.id!}
        activityId={editActivityId}
        canEdit={canEditActivity}
        isOpen={newActivityModalOpen}
        integrations={integrationOptions}
        onClose={(refetch = false) => {
          setEditActivityId(undefined);
          setCanEditActivity(true);
          setNewActivityModalOpen(false);
          if (refetch) fetchActivities();
        }}
      />
      <DeleteActivityModal
        activityId={deleteActivityId}
        userId={objectData?.id!}
        onClose={() => {
          setDeleteActivityId(undefined);
          fetchActivities();
        }}
      />
    </>
  );
};
