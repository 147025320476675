import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Row, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import { getOrganizationsTable } from "../../../core/api/organizations/organizations";
import useUser from "../../../core/user/useUser";
import { useQueryState } from "../../../core/hooks/filters/useQueryState";
import { OrganizationsTable } from "../OrganizationsTable";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { AddOrganization } from "../AddOrganization/AddOrganization";
import { PAGE_ID } from "../Organizations";
import SearchInput from "../../../ui-lib/components/Inputs/SearchInput";
import useSharedOrganization from "../SharedOrganizationContext/useSharedOrganization";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { usePageFilter } from "../../../core/hooks/filters/usePageFilter";
import { IOrganizationProps } from "../../../core/api/organizations/types";
import { TableRowSelectionPopupActions } from "../../../ui-lib/components/Tables/TableRowSelectionPopup";

import styles from "../../PagesLayout.module.css";

const SubOrganizations = ({ id }: { id?: number }) => {
  const { authenticatedRequest } = useUser();
  const t = useTranslations();
  const history = useHistory();
  const { sellers } = useSharedOrganization();
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter();
  const [searchParam, setSearchParam] = useQueryState<{ q: string }>({
    q: "",
  });
  const [subOrganizationsData, setSubOrganizationsData] = useState<
    IOrganizationProps[]
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const clearSelectedFunction = useRef<Function>();
  const tableRowSelectionPopupRef =
    useRef<TableRowSelectionPopupActions<Record<string, any>>>(null);

  const fetchParams = {
    search: searchParam.q,
    page: pageFilter.page,
    pageSize: pageFilter.pageSize,
    parentId: id,
    includeParent: false,
  };

  const fetchSubOrgs = async () => {
    setIsLoading(true);
    try {
      const { data } = await getOrganizationsTable(
        fetchParams,
        authenticatedRequest
      );
      setSubOrganizationsData(data.organizations);
      setTotalItems(data.total);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubOrgs();
  }, [id, pageFilter.page, pageFilter.pageSize, searchParam.q]);

  return (
    <>
      <Row type="space" className="w-100 flex0">
        <div>
          <h1>{t("Organizations:sub_organization_page_title")}</h1>
          <Spacer size={8} />
          <p>{t("Organizations:sub_organization_page_description")}</p>
        </div>
        <AddOrganization
          sub
          sellers={sellers}
          parentId={id}
          variant="secondary"
          onSubmit={(organizationId: number) =>
            history.push(`/${PAGE_ID}/${organizationId}`)
          }
        />
      </Row>
      <Spacer size={32} />
      <Row className={styles.settingsRow}>
        <SearchInput
          placeholder={t("Common:search_placeholder")}
          onChange={(val) => {
            if (searchParam.q !== val) {
              setSearchParam({ q: val });
            }
            resetPageFilter();
          }}
          limit={1}
          value={searchParam.q}
        />
      </Row>
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <OrganizationsTable
          subOrganizations
          organizationsData={subOrganizationsData}
          uploadNewOrganizations={fetchSubOrgs}
          searchParam={searchParam.q}
          items={subOrganizationsData}
          pageFilter={pageFilter}
          setPageFilter={setPageFilter}
          totalItems={totalItems}
          clearSelectedFunction={clearSelectedFunction}
          tableRowSelectionPopupRef={tableRowSelectionPopupRef}
        />
      )}
    </>
  );
};

export default observer(SubOrganizations);
