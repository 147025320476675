import { AxiosInstance } from "axios";
import queryString from "query-string";
import { IAlternatives } from "./types";

const getAlternatives = (
  params: {
    adminId?: number;
    organizationId?: number;
    responseCenterId?: number;
    sellerId?: number;
  },
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify(params);

  return authenticatedRequest.get<IAlternatives[]>(
    `/api/v2/menualternatives?${settings}`
  );
};

const getAlternative = (id: number, authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<IAlternatives>(`/api/v2/menualternatives/${id}`);

const updateAlternative = (
  data: IAlternatives,
  authenticatedRequest: AxiosInstance
) => {
  const method = data.id ? "put" : "post";
  return authenticatedRequest[method](
    `/api/v2/menualternatives/${method === "put" ? data.id : ""}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const reorderAlternatives = (
  reorderFor: "adminId" | "organizationId" | "responseCenterId" | "sellerId",
  reorderForId: number,
  alternativesData: IAlternatives[],
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(
    `/api/v2/menualternatives/batch`,
    JSON.stringify({
      [reorderFor]: reorderForId,
      menuAlternatives: alternativesData,
    }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteAlternatives = (
  id: number | string,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete(`/api/v2/menualternatives/${id}`);

export {
  getAlternatives,
  getAlternative,
  updateAlternative,
  reorderAlternatives,
  deleteAlternatives,
};
