import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { NewObjectData } from "../../core/api/objects/types";
import { useProductCategories } from "../../core/api/products/products";
import {
  ICategoryProduct,
  IProductCategory,
} from "../../core/api/products/types";
import {
  getOrganisationSubscriptions,
  OrganisationSubscription,
} from "../../core/api/subscriptions";
import {
  validateIdentifier,
  validateImei,
} from "../../core/helpers/validation";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import { getProductName } from "../../ui-lib/utils/getProductName";
import RadioGroup from "../../ui-lib/components/Radio/RadioGroup";
import {
  ExternalIntegration,
  useExternalIntegrations,
} from "../../core/api/externalIntergations";
import { AddTransmitterToObjectCreation } from "./AddTransmitterToObjectCreation";
import { Transmitter } from "../../core/api/transmitters/types";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import {
  activateSubscription,
  saveNewObject,
} from "../../core/api/objects/objects";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import {
  getIdentifierErrors,
  notifyApiErrors,
} from "../../core/helpers/helpers";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { ResponsiveWrapper } from "../../components/ResponsiveWrapper/ResponsiveWrapper";
import {
  useWindowDimensions,
  breakpoints,
} from "../../core/hooks/dimensionProvider";

import styles from "../PagesLayout.module.css";

export declare type ConfigurationAddObjectActions = {
  open: (transmitterData?: Transmitter, defaultValues?: NewObjectData) => void;
  openWithAllOptions: () => void;
};

export interface ConfigurationAddObjectProps {
  onClose?: () => void;
  onSubmit?: (objectId: number) => void;
  relatedObject?: boolean;
  organisations: ITreeData[];
}

export const AddObject = forwardRef<
  ConfigurationAddObjectActions,
  ConfigurationAddObjectProps
>((props: ConfigurationAddObjectProps, ref) => {
  const { onSubmit, onClose, organisations, relatedObject = false } = props;

  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const { data: productCategories } = useProductCategories();
  const { data: externalIntegrations } = useExternalIntegrations();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  const stockPermission = config?.show.some((key) =>
    [
      "AddUserFromStock",
      "CreateStock",
      "EditStock",
      "Transmitters",
      "UICreateStock",
      "ViewStock",
    ].includes(key)
  );

  const defaultRadioButtons = [
    {
      id: 0,
      value: "0",
      text: t("Objects:labels_create_0"),
    },
    ...(stockPermission
      ? [
          {
            id: 1,
            value: "1",
            text: t("Objects:labels_create_1"),
          },
        ]
      : []),
  ];

  const [isAddObjectOpen, setIsAddObjectOpen] = useState(false);
  const [selectedTransmitter, setSelectedTransmitter] = useState<
    Transmitter | undefined
  >();
  const [selectedCreationType, setSelectedCreationType] = useState<number>();
  const [valuesData, setValuesData] = useState<NewObjectData>();
  const [selectedProduct, setSelectedProduct] = useState<
    ICategoryProduct | undefined
  >();
  const [categories, setCategories] = useState<IDropdownItem[]>([]);
  const [receptions, setReceptions] = useState<IDropdownItem[]>([]);
  const [subscriptions, setSubscriptions] = useState<IDropdownItem[]>([]);
  const [externalIntegrationItems, setExternalIntegrationItems] = useState<
    IDropdownItem[]
  >([]);
  const [selectedExternalIntegration, setSelectedExternalIntegration] =
    useState<ExternalIntegration>();
  const [radioItems, setRadioItems] =
    useState<{ id: number; value: string; text: string }[]>(
      defaultRadioButtons
    );
  const [isCostCenterEnabled, setIsCostCenterEnabled] = useState(false);
  const [isTransmittersModal, setIsTransmittersModal] = useState(false);
  const [showAllOptions, setShowAllOptions] = useState(false);

  const orgSubs = useRef<OrganisationSubscription[]>([]);
  const products = useRef<IDropdownItem[]>([]);

  const {
    control,
    trigger,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm<NewObjectData>({ defaultValues: valuesData });

  useImperativeHandle<
    ConfigurationAddObjectActions,
    ConfigurationAddObjectActions
  >(ref, () => {
    const actions: ConfigurationAddObjectActions = {
      open: function open(
        transmitterData?: Transmitter,
        defaultValues?: NewObjectData
      ) {
        setIsAddObjectOpen(true);
        setSelectedTransmitter(transmitterData);
        setValuesData(defaultValues);
      },
      openWithAllOptions: function openWithAllOptions() {
        setShowAllOptions(true);
        setIsAddObjectOpen(true);
      },
    };
    return actions;
  });

  const getProduct = (id: number): ICategoryProduct | undefined => {
    if (productCategories) {
      const category = productCategories.find(
        (cat) => cat.productCategoryId === getValues("productCategoryId")
      );
      if (category) {
        return category.products.find((prod) => prod.id === id);
      }
    }
    return undefined;
  };

  const closeModal = () => {
    setIsAddObjectOpen(false);
    setSelectedCreationType(0);
    setSelectedTransmitter(undefined);
    reset();
    products.current = [];
    setSubscriptions([]);
    setSelectedProduct(undefined);
    setShowAllOptions(false);
    categories.forEach((cat) => {
      cat.isSelected = false;
    });
    if (onClose !== undefined) {
      onClose();
    }
  };

  const loadOrganisationAlarmReceptions = async (orgId?: number) => {
    const receptionItems: IDropdownItem[] = [];
    if (orgId) {
      try {
        const subs = await getOrganisationSubscriptions(
          orgId,
          authenticatedRequest
        );
        orgSubs.current = subs;
        subs.forEach((sub) => {
          if (
            receptionItems.find(
              (thesub) => thesub.id === sub.subscriptionGroupId
            ) === undefined
          ) {
            const isSelected =
              sub.responseCenterId === valuesData?.responseCenterId;
            receptionItems.push({
              id: sub.subscriptionGroupId,
              name: sub.subscriptionGroupDescription,
              isSelected,
              extraData: {
                nextSubscriptionId: sub.subscriptionId,
              },
            });
            if (isSelected) {
              setValue("nextSubscriptionId", sub.subscriptionId);
            }
          }
        });
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      }
    }
    setReceptions(receptionItems);
  };

  const updateProducts = (id: string | number) => {
    categories.forEach((r) => {
      r.isSelected = r.id === id;
    });
    const prods = productCategories
      ?.find((p) => p.productCategoryId === +id)
      ?.products.map((pr) => ({
        id: pr.id,
        name: getProductName(pr, t),
        isSelected: false,
      }));
    products.current = prods || [];
    setValue("productId", 999);
    products.current.forEach((r) => {
      r.isSelected = r.id === 999;
    });
    const prod = getProduct(999);
    setSelectedProduct(prod);
    trigger("productCategoryId");
  };

  const prefillForm = async () => {
    reset({ ...valuesData });
    await loadOrganisationAlarmReceptions(valuesData?.organizationId);
  };

  const onInternalSubmit = async (formData: NewObjectData) => {
    if (selectedTransmitter?.id) {
      formData.transmitterId = selectedTransmitter.id;
      formData.identifier =
        selectedTransmitter.identifier ?? formData.telephoneNumber;
      formData.telephoneNumber = selectedTransmitter.identifier;
    }

    try {
      const saveResult = await saveNewObject(formData, authenticatedRequest);
      if (formData.active) {
        try {
          await activateSubscription(saveResult.data, authenticatedRequest);
        } catch (error: any) {
          notify({
            message: t("Objects:createobject_subscriptioncreation_failed"),
            variant: "error",
          });
        }
      }
      onSubmit?.(saveResult.data);
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const setSingleOrganisation = async () => {
    if (isAddObjectOpen && organisations && organisations.length === 1) {
      const key = organisations[0].key;
      setValue("organizationId", +key!);
      await loadOrganisationAlarmReceptions(+key!);
    }
  };

  // ------useEffect block start---------

  useEffect(() => {
    if (
      externalIntegrations?.length > 0 &&
      !radioItems.find((r) => r.id === 2)
    ) {
      const newExternalItems = externalIntegrations.map(
        (integration) =>
          ({
            id: integration.externalIntegrationId,
            name: integration.name,
            isSelected: false,
          } as IDropdownItem)
      );
      setExternalIntegrationItems(newExternalItems);
      const newRadioItems = [...radioItems];
      newRadioItems.push({
        id: 2,
        value: "2",
        text: t("Objects:labels_create_2"),
      });
      setRadioItems(newRadioItems);
    }
  }, [externalIntegrations, isAddObjectOpen]);

  useEffect(() => {
    if (valuesData?.organizationId && isAddObjectOpen) {
      prefillForm();
    }
    if (valuesData?.productCategoryId) {
      updateProducts(valuesData.productCategoryId);
    }
    setSingleOrganisation();
  }, [isAddObjectOpen, valuesData?.organizationId]);

  useEffect(() => {
    if (productCategories !== null) {
      const newCategories = productCategories.map((r: IProductCategory) => ({
        id: r.productCategoryId,
        name: t(`Common:product_categories_${r.name}`),
        isSelected: valuesData?.productCategoryId === r.productCategoryId,
      }));
      setCategories(newCategories);
    }
  }, [productCategories]);

  useEffect(() => {
    const updatedSubscriptions = subscriptions;
    if (subscriptions.length) {
      if (
        selectedCreationType === 2 &&
        subscriptions.find((sub) => sub.id === 0)
      ) {
        updatedSubscriptions.shift();
        setSubscriptions(updatedSubscriptions);
      }
      if (
        selectedCreationType !== 2 &&
        !subscriptions.find((sub) => sub.id === 0)
      ) {
        updatedSubscriptions.unshift({
          id: 0,
          name: `${t("Objects:subscription_noactivate")}`,
          isSelected: !config?.show.includes("ActivateWithoutCode"),
        });
        setSubscriptions(updatedSubscriptions);
      }
    }
  }, [selectedCreationType]);

  useEffect(() => {
    if (!showAllOptions) {
      if (selectedTransmitter) {
        setValue("transmitterId", selectedTransmitter?.id);
        setRadioItems([defaultRadioButtons[1]]);
        setSelectedCreationType(1);
      } else if (relatedObject) {
        setRadioItems([defaultRadioButtons[0]]);
        setSelectedCreationType(0);
      } else {
        setRadioItems(defaultRadioButtons);
        setSelectedCreationType(0);
      }
    }
  }, [selectedTransmitter]);

  // ------useEffect block end---------

  return (
    <PrimeModal
      withHeader
      withFooter
      isOpen={isAddObjectOpen}
      onClose={closeModal}
      header={t("Objects:createnewobject")}
      dataCy="add_object_modal"
      loading={isSubmitting}
      submitBtn={{
        text: t("Objects:createobject"),
        onClick: handleSubmit(onInternalSubmit),
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: closeModal,
      }}
      contentStyle={{ width: isMobile ? "auto" : "1000px" }}
    >
      <p>{t("Objects:addobjectdescription")}</p>
      <Spacer size={16} />
      <form>
        <Column align="start" className="w-100">
          <Row className="stretch">
            <RadioGroup
              name="creation-type"
              items={radioItems}
              selectedValue={
                radioItems.find((item) => item.id === selectedCreationType)
                  ?.value
              }
              onChange={(value) => {
                setSelectedCreationType(Number(value));
                setSelectedTransmitter(undefined);
              }}
              fullWidth
            />
          </Row>

          {selectedCreationType === 2 ? (
            <>
              <Spacer size={16} />
              <Column className="stretch">
                <Controller
                  name="externalIntegrationId"
                  control={control}
                  rules={{ required: true }}
                  render={() => (
                    <Dropdown
                      required
                      title={t("Objects:labels_external_integrations")}
                      id="addobject_reception"
                      items={externalIntegrationItems}
                      disabled={externalIntegrations.length === 0}
                      selectedItem={externalIntegrationItems.find(
                        (r) => r.isSelected
                      )}
                      onSelectItem={async (selectedItem) => {
                        const newExternalItems = [...externalIntegrationItems];
                        newExternalItems.forEach((item) => {
                          item.isSelected = item.id === selectedItem.id;
                        });
                        setExternalIntegrationItems(newExternalItems);
                        setSelectedExternalIntegration(
                          externalIntegrations.find(
                            (item) =>
                              item.externalIntegrationId === selectedItem.id
                          )
                        );
                        setValue(
                          "externalIntegrationId",
                          Number(selectedItem.id)
                        );
                        trigger("externalIntegrationId");
                      }}
                      validationError={
                        errors.externalIntegrationId &&
                        t("Objects:error_external_integrations")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
                <Controller
                  name="externalIdentifier"
                  control={control}
                  rules={{
                    required: true,
                    pattern: RegExp(
                      selectedExternalIntegration?.identifierRegexp ?? ""
                    ),
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      css={{ flex: 1 }}
                      label={
                        selectedExternalIntegration?.identifierLabel ??
                        t("Objects:Id")
                      }
                      value={field.value ? `${field.value}` : ""}
                      onChange={(event) => {
                        setValue(
                          "externalIdentifier",
                          Number(event.target.value)
                        );
                        trigger("externalIdentifier");
                      }}
                      disabled={
                        !externalIntegrationItems.find(
                          (item) => item.isSelected
                        )
                      }
                      validationError={
                        errors.externalIdentifier &&
                        t("Objects:error_external_identifier")
                      }
                    />
                  )}
                />
              </Column>
            </>
          ) : null}

          <Spacer size={16} />

          <ResponsiveWrapper className="stretch">
            {selectedCreationType !== 2 ? (
              <>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    minLength: 2,
                    maxLength: 60,
                    required: true,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={t("Common:name")}
                      placeholder={t("Objects:nameplaceholder")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.name && t("Errors:input_object_name")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            ) : null}
            {selectedCreationType !== 2 ||
            (selectedCreationType === 2 &&
              selectedExternalIntegration?.requireCustomerId) ? (
              <Controller
                name="organizationId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputContainer
                    required
                    label={
                      <label htmlFor="addobject_organisation">
                        {t("Objects:organisation")}
                      </label>
                    }
                    input={
                      <Tree
                        treeRef={field.ref}
                        id="addobject_organisation"
                        className={styles.nomargin}
                        placeholder={t("Common:select_organisation")}
                        validationError={
                          errors.organizationId &&
                          t("Objects:organisationvalidation")
                        }
                        disabled={
                          organisations === undefined ||
                          (organisations.length < 2 &&
                            !organisations[0]?.children?.length)
                        }
                        items={organisations}
                        selectedTreeItem={field.value}
                        onSelectItem={async (data?: ITreeData) => {
                          if (data?.requireCostCenter !== isCostCenterEnabled) {
                            setIsCostCenterEnabled(!!data?.requireCostCenter);
                          }
                          if (data?.key) {
                            setValue(field.name, +data.key);
                          } else {
                            setValue(field.name, undefined);
                          }
                          trigger(field.name);
                          await loadOrganisationAlarmReceptions(+data?.key!);
                        }}
                      />
                    }
                  />
                )}
              />
            ) : null}
          </ResponsiveWrapper>

          {isCostCenterEnabled && (
            <>
              <Spacer size={16} />
              <ResponsiveWrapper className="stretch">
                <Controller
                  name="costCenter"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={t("Common:labels_cost_centre")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.costCenter &&
                        t("Organizations:require_cost_center")
                      }
                    />
                  )}
                />
              </ResponsiveWrapper>
            </>
          )}

          {selectedCreationType === 0 ? (
            <>
              <Spacer size={16} />
              <ResponsiveWrapper className="stretch">
                <Controller
                  name="productCategoryId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputContainer
                      required
                      label={
                        <label htmlFor="addobject_category">
                          {t("Objects:category")}
                        </label>
                      }
                      input={
                        <Dropdown
                          id="addobject_category"
                          items={categories}
                          disabled={
                            categories === undefined || categories.length === 0
                          }
                          placeholder={t("Objects:selectcategory")}
                          selectedItem={categories.find(
                            (r) => getValues(field.name) === r.id
                          )}
                          onSelectItem={(item) => {
                            setValue(field.name, +item.id);
                            updateProducts(item.id);
                          }}
                          invalid={errors.productCategoryId !== undefined}
                          validationError={
                            errors.productCategoryId &&
                            t("Objects:Validation_productcategory")
                          }
                        />
                      }
                    />
                  )}
                />
                <Spacer size={16} />
                <Controller
                  name="productId"
                  control={control}
                  render={({ field }) => (
                    <InputContainer
                      label={
                        <label htmlFor="addobject_product">
                          {t("Objects:product")}
                        </label>
                      }
                      input={
                        <Dropdown
                          id="addobject_product"
                          withFilter
                          items={products.current}
                          maxVisible={8}
                          disabled={
                            products.current === undefined ||
                            products.current.length === 0
                          }
                          placeholder={t("Objects:selectproduct")}
                          selectedItem={products.current.find(
                            (r) => getValues(field.name) === r.id
                          )}
                          onSelectItem={(item) => {
                            setValue(field.name, +item.id);
                            products.current.forEach((r) => {
                              r.isSelected = false;
                            });
                            item.isSelected = true;
                            setSelectedProduct(getProduct(+item.id));
                            trigger(field.name);
                          }}
                        />
                      }
                    />
                  )}
                />
              </ResponsiveWrapper>
            </>
          ) : null}
          {selectedProduct &&
            (selectedProduct.requireIdentifier ||
              selectedProduct.requireImei) && (
              <>
                <Spacer size={16} />
                <ResponsiveWrapper className="stretch">
                  {selectedProduct.requireIdentifier && (
                    <Controller
                      name="identifier"
                      control={control}
                      rules={{
                        required: true,
                        validate: validateIdentifier(
                          selectedProduct.productSpecificIdentifierRegex
                        ),
                      }}
                      render={({ field }) => (
                        <TextInput
                          required
                          inputRef={field.ref}
                          id="addobject_identifier"
                          css={{ flex: 1 }}
                          placeholder={
                            selectedProduct.identifierTranslationKey
                              ? t(
                                  `Common:validation_${selectedProduct.identifierTranslationKey}`
                                )
                              : undefined
                          }
                          label={t("Common:telephone_identifier")}
                          value={field.value}
                          onChange={field.onChange}
                          validationError={
                            errors.identifier &&
                            getIdentifierErrors(
                              errors.identifier.type,
                              selectedProduct.identifierTranslationKey
                            )
                          }
                        />
                      )}
                    />
                  )}
                  {selectedProduct.requireIdentifier &&
                    selectedProduct.requireImei && <Spacer size={16} />}
                  {selectedProduct.requireImei && (
                    <Controller
                      name="imei"
                      control={control}
                      rules={{
                        required: true,
                        validate: validateImei,
                      }}
                      render={({ field }) => (
                        <TextInput
                          required
                          inputRef={field.ref}
                          css={{ flex: 1 }}
                          label={t("Objects:Labels_imei")}
                          placeholder={t("Objects:Placeholder_imei")}
                          value={field.value}
                          onChange={field.onChange}
                          validationError={
                            errors.imei &&
                            ((errors.imei.type === "required" &&
                              t("Objects:Validation_imei_required")) ||
                              t("Objects:Validation_imei_format"))
                          }
                        />
                      )}
                    />
                  )}
                </ResponsiveWrapper>
              </>
            )}
          {(selectedProduct?.requireSipPassword ||
            selectedProduct?.requireSipUsername) && (
            <>
              <Spacer size={16} />
              <ResponsiveWrapper className="stretch">
                {selectedProduct?.requireSipUsername && (
                  <>
                    <Controller
                      name="sipUsername"
                      control={control}
                      rules={{ required: true, minLength: 8 }}
                      render={({ field }) => (
                        <TextInput
                          required
                          inputRef={field.ref}
                          placeholder={t(
                            "Administrator:password_constraints_minimum_length"
                          ).replace("{0}", "8")}
                          css={{ flex: 1 }}
                          label={t("Transmitters:labels_sip_username")}
                          value={field.value}
                          onChange={field.onChange}
                          validationError={
                            errors.sipUsername &&
                            (field.value?.length === 0
                              ? t("Errors:input_field_required")
                              : t(
                                  "Administrator:password_constraints_minimum_length"
                                ).replace("{0}", "8"))
                          }
                        />
                      )}
                    />
                    <Spacer size={16} />
                  </>
                )}

                {selectedProduct?.requireSipPassword && (
                  <Controller
                    name="sipPassword"
                    control={control}
                    rules={{ required: true, minLength: 8 }}
                    render={({ field }) => (
                      <TextInput
                        required
                        inputRef={field.ref}
                        placeholder={t(
                          "Administrator:password_constraints_minimum_length"
                        ).replace("{0}", "8")}
                        css={{ flex: 1 }}
                        label={t("Transmitters:labels_sip_password")}
                        value={field.value}
                        onChange={field.onChange}
                        validationError={
                          errors.sipPassword &&
                          (field.value?.length === 0
                            ? t("Errors:input_field_required")
                            : t(
                                "Administrator:password_constraints_minimum_length"
                              ).replace("{0}", "8"))
                        }
                      />
                    )}
                  />
                )}
              </ResponsiveWrapper>
            </>
          )}

          <Spacer size={16} />

          <ResponsiveWrapper className="stretch" colAlign="start">
            {selectedCreationType !== 2 ||
            (selectedCreationType === 2 &&
              selectedExternalIntegration?.requireServiceId) ? (
              <Controller
                name="responseCenterId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    id="addobject_reception"
                    title={t("Objects:reception")}
                    items={receptions}
                    maxVisible={8}
                    disabled={
                      receptions === undefined || receptions.length === 0
                    }
                    selectedItem={receptions.find(
                      (r) => r.isSelected || field.value === r.id
                    )}
                    onSelectItem={async (item) => {
                      setValue(
                        "nextSubscriptionId",
                        item.extraData.nextSubscriptionId
                      );
                      if (item?.id) {
                        setValue(field.name, +item.id);
                      } else {
                        setSubscriptions([]);
                        setValue("responseCenterId", undefined);
                      }
                      receptions.forEach((r) => {
                        r.isSelected = r.id === item.id;
                      });
                      trigger(field.name);
                    }}
                    invalid={errors.responseCenterId !== undefined}
                    validationError={
                      errors.responseCenterId &&
                      t("Objects:Validation_reception")
                    }
                  />
                )}
              />
            ) : null}
            <Spacer size={16} />
            {(selectedCreationType !== 2 ||
              (selectedCreationType === 2 &&
                selectedExternalIntegration?.requireServiceId)) &&
            !!config?.show.includes("ActivateWithoutCode") ? (
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    className="fill-available align-self-end"
                    checked={!!field.value}
                    onChange={field.onChange}
                    style={{ padding: 0 }}
                    label={t("Objects:activate_subscription")}
                    dataCy="subscription_active"
                    labelStyles={{ fontWeight: "bold" }}
                  />
                )}
              />
            ) : null}
          </ResponsiveWrapper>
          {selectedCreationType === 1 ? (
            <>
              <Spacer size={16} />
              <ResponsiveWrapper colAlign="start">
                {!selectedTransmitter && (
                  <Button
                    text={t("Objects:labels_select_transmitter")}
                    variant="border-link"
                    onClick={() => setIsTransmittersModal(true)}
                  />
                )}
              </ResponsiveWrapper>
              {selectedTransmitter ? (
                <>
                  <Spacer size={16} />
                  <Table<Transmitter>
                    columns={[
                      {
                        header: t("Objects:Telephone"),
                        fieldTemplate: (rowData) => (
                          <TableCell value={rowData.identifier} />
                        ),
                      },
                      {
                        header: t("Objects:IMEI"),
                        fieldTemplate: (rowData) => (
                          <TableCell value={rowData.imei} />
                        ),
                      },
                      {
                        header: t("Objects:Product"),
                        fieldTemplate: (rowData) => (
                          <TableCell value={rowData.productName} />
                        ),
                      },
                      {
                        header: t("Objects:Manufacturer"),
                        fieldTemplate: (rowData) => (
                          <TableCell value={rowData.manufacturer} />
                        ),
                      },
                      {
                        header: t("Objects:Model"),
                        fieldTemplate: (rowData) => (
                          <TableCell value={rowData.model} />
                        ),
                      },
                      {
                        header: t("Objects:Firmware"),
                        fieldTemplate: (rowData) => (
                          <TableCell value={rowData.firmware} />
                        ),
                      },
                    ]}
                    rowActions={[
                      {
                        icon: "refresh",
                        text: t("Objects:labels_select_transmitter"),
                        onClick: () => {
                          setIsTransmittersModal(true);
                        },
                      },
                    ]}
                    items={[selectedTransmitter]}
                    hideEmptyMessage
                    showRowHover
                    rowActionsFixed
                  />
                </>
              ) : null}
            </>
          ) : null}
        </Column>
      </form>
      <AddTransmitterToObjectCreation
        isAddTransmitterOpen={isTransmittersModal}
        setIsAddTransmitterOpen={setIsTransmittersModal}
        selectedTransmitterId={selectedTransmitter}
        setSelectedTransmitterId={setSelectedTransmitter}
      />
    </PrimeModal>
  );
});
