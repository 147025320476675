import useTranslations from "../../../../core/i18n/useTranslations";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";
import { ProductForm } from "./General/ProductForm";
import useUser from "../../../../core/user/useUser";
import { getSellers } from "../../../../core/api/sellers/sellers";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { IResellerType } from "../../../../core/api/sellers/types";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { getOrganizationTrees } from "../../../../core/api/organizations/organizations";
import { transformOrganizationsToTreeData } from "../../../../core/utils/organizations";
import { ITreeData } from "../../../../ui-lib/components/Hierarchy/Tree";
import { createProduct } from "../../../../core/api/products/products";
import { IProductDetails } from "../../../../core/api/products/types";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";

export const AddProductModal = ({
  isOpen,
  setIsOpen,
  fetchProducts,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  fetchProducts: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [resellers, setResellers] = useState<IResellerType[]>([]);
  const [organizationTree, setOrganizationTree] = useState<ITreeData[]>();

  const fetchResellersOrganizations = async () => {
    setIsLoading(true);
    try {
      const resellersResult = await getSellers(authenticatedRequest);
      setResellers(resellersResult.data);
      const organizationTreeResult = await getOrganizationTrees(
        { page: 1, pageSize: 2000, includeParent: true },
        authenticatedRequest
      );
      const transformedData = transformOrganizationsToTreeData(
        organizationTreeResult.data
      );
      setOrganizationTree(transformedData);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const createProductCall = async (values: IProductDetails) => {
    setIsFormLoading(true);
    try {
      await createProduct(authenticatedRequest, values);
      setIsOpen(false);
      notify({ message: t("Reseller:product_created") });
      fetchProducts();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsFormLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchResellersOrganizations();
    }
  }, [isOpen]);

  return (
    <PrimeModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      withFooter
      withHeader
      contentStyle={{ width: "848px" }}
      header={t("Reseller:add_product")}
      loading={isLoading}
      submitBtn={{
        text: t("Reseller:add_product"),
        type: "submit",
        form: "product-form",
        disabled: isFormLoading,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: () => setIsOpen(false) }}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ProductForm
          organizations={organizationTree ?? []}
          resellers={resellers}
          onSubmit={createProductCall}
          isNew={true}
        />
      )}
    </PrimeModal>
  );
};
