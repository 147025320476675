import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  getResponseCenterDistributions,
  useResponseCenterDistributions,
} from "../../../../core/api/responsecenters/responsecenters";
import { ResponseCenterDistributionResponse } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import Button from "../../../../ui-lib/components/Button/Button";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import { TableRowSelectionPopupActions } from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import CreateDistributorModal from "./CreateDistributorModal";
import DeleteDistributorModal from "./DeleteDistributorModal";
import { DistributionTable } from "./DistributionTable";
import EditDistributorModal from "./EditDistributorModal";
import TypePicker, { Type } from "./TypePicker";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";

import styles from "../../../PagesLayout.module.css";

const INITIAL_TABLE_SETTINGS = {
  type: Type.All,
};

const Distribution = () => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { id } = useParams<{ id: string }>();

  const [tableSettings, setTableSettings] = useState(INITIAL_TABLE_SETTINGS);
  const [total, setTotal] = useState<ResponseCenterDistributionResponse[]>([]);
  const { data, isLoading } = useResponseCenterDistributions(id);

  const [selected, setSelected] = useState<number[]>([]);
  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<ResponseCenterDistributionResponse>>(
      null
    );
  const editeModalRef = useRef<ModalActions>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setTotal(data);
    }
  }, [data]);

  // This is called when a new alarm reception contact is added
  const refetchData = async () => {
    const { data: updated } = await getResponseCenterDistributions(
      id,
      authenticatedRequest
    );
    setTotal(updated);
  };

  // Client side filtering of data because of API limitations
  const filtered = useMemo(
    () =>
      total.filter((responseCenterContact) => {
        switch (tableSettings.type) {
          case Type.GetUrlTrue:
            return responseCenterContact.contactGetAuthorizedUrl;
          case Type.GetUrlFalse:
            return !responseCenterContact.contactGetAuthorizedUrl;
          case Type.All:
          default:
            return true;
        }
      }),
    [total, tableSettings]
  );

  return (
    <Column type="top" align="start" style={{ width: "100%" }}>
      <Row style={{ flex: 0, width: "100%" }}>
        <Column align="start">
          <h1>{t("AlarmReceptions:distributions_title")}</h1>
          <Spacer size={8} />
          <p style={{ color: "var(--Grey-600)" }}>
            {t("AlarmReceptions:distributions_description")}
          </p>
        </Column>
        <Column style={{ flex: 0, width: "100%" }}>
          <Modal
            trigger={() => (
              <div>
                <Button
                  variant="secondary"
                  text={t("AlarmReceptions:add_distributor")}
                />
              </div>
            )}
            closeOnDocumentClick={false}
          >
            {(close) => (
              <CreateDistributorModal
                responseCenterId={id}
                onClose={close}
                onAdd={() => {
                  close();
                  refetchData();
                }}
              />
            )}
          </Modal>
        </Column>
      </Row>

      <Spacer size={32} />

      <Row style={{ flex: 0, alignSelf: "flex-start" }}>
        <TypePicker
          type={tableSettings.type}
          setType={(type) => {
            setTableSettings((prev) => ({ ...prev, type }));
          }}
        />
      </Row>

      <Spacer size={16} />

      <Column className={styles.tableContainer} type="top">
        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <DistributionTable
            data={filtered}
            selectionPopupRef={selectionPopupRef}
            isLoading={isLoading}
            onStartEdit={(responseCenterContact) => {
              setSelected([responseCenterContact.id as number]);
              editeModalRef?.current?.open();
            }}
            onStartDelete={(responseCenterContacts) => {
              setSelected(responseCenterContacts.map((c) => c.id as number));
              setDeleteOpen(true);
            }}
          />
        )}
      </Column>

      <Modal modalRef={editeModalRef}>
        {(close) => (
          <EditDistributorModal
            responseCenterId={id}
            responseCenterContactId={selected[0]}
            onClose={close}
            onEdit={() => {
              close();
              refetchData();
            }}
          />
        )}
      </Modal>

      <DeleteDistributorModal
        responseCenterId={id}
        responseCenterContactIds={selected}
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
          refetchData();
        }}
      />
    </Column>
  );
};

export default Distribution;
