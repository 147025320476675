import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import { Spacer } from "../../../components/Layout/Layout";
import {
  IObjectAbsence,
  ObjectDetailsModel,
} from "../../../core/api/objects/types";
import { useOrganizationsTree } from "../../../core/api/organizations/organizations";
import { getObjectThumbnail } from "../../../core/api/photo";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { ManualSyncWrapper } from "../../../components/ManualSync/ManualSyncWrapper";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import {
  AlarmHandling,
  getAlarmHandlings,
} from "../../../core/api/alarmhandlings";
import { userDateFormat } from "../../../ui-lib/utils/userDateFormat";
import { FormProvider } from "react-hook-form";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";
import useUser from "../../../core/user/useUser";
import Alert from "../../../ui-lib/components/Alerts/Alert";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import ObjectGeneralBasic from "./ObjectsGeneralBasic";
import ObjectGeneralPersonalDetails from "./ObjectGeneralPersonalDetails";
import useTranslations from "../../../core/i18n/useTranslations";
import MedicalNotes from "./MedicalNotes";
import ObjectGeneralMorePersonalInfo from "./ObjectGeneralMorePersonalInfo";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Tabs from "../../../ui-lib/components/Tabs/Tabs";

import styles from "./ObjectGeneral.module.css";

const AbsenceAlert = ({
  data,
  isOngoing,
  dateFormat,
}: {
  data: IObjectAbsence;
  isOngoing: boolean;
  dateFormat: string;
}) => {
  const t = useTranslations();

  return (
    <>
      <div>
        <span className="fw-600">{`${t(
          `Objects:${isOngoing ? "absent" : "absent_upcoming"}`
        )} -`}</span>
        <span style={{ lineBreak: "anywhere" }}>{` ${data.reason}. `}</span>
        <span className="fw-600">{t("Objects:from")}</span>:{" "}
        <span>{`${moment(data.startDate).format(userDateFormat(dateFormat))} ${
          data.startTime
        } `}</span>
        {data.endDate ? (
          <>
            <span className="fw-600">{` ${t("Objects:to")}: `}</span>
            <span>{`${moment(data.endDate).format(
              userDateFormat(dateFormat)
            )} ${data.endTime}`}</span>
          </>
        ) : null}
      </div>
      {data.comment && (
        <div>
          <span className="fw-600">{t("Common:labels_additional_info")}</span>:{" "}
          <span>{data.comment}</span>
        </div>
      )}
    </>
  );
};

const ObjectGeneralContainer = ({ objectId }: { objectId: string }) => {
  const t = useTranslations();
  const history = useHistory();
  const { config, authenticatedRequest, dateFormat } = useUser();
  const { width } = useWindowDimensions();
  const formRef = useObjectFormRef();
  const { data, absenceData, update, fetchObject, isLoading } =
    useSharedObject();
  const methods = useObjectForm<ObjectDetailsModel>({ defaultValues: data });
  const { handleSubmit, reset } = methods;

  const [thumbnail, setThumbnail] = useState("");
  const [loading, setLoading] = useState(false);
  const [alarmHandlings, setAlarmHandlings] = useState<AlarmHandling[]>([]);
  const [currentTabId, setCurrentTabId] = useState(0);

  const viserPermission = config?.show.includes("SensioHideFields");
  const viserHTJ = config?.show.includes("SensioHideFieldsOBO");
  const syncPermission = config?.show.includes("LastClusterSync");

  const isDesktop = width >= breakpoints.desktop;

  if (viserPermission && !viserHTJ) {
    history.replace(`/adminportal/objects/${objectId}/accessories`);
  }

  const tabsData = [
    {
      isSelected: currentTabId === 0,
      name: t("Objects:general_basic_details_title"),
      id: 0,
    },
    {
      isSelected: currentTabId === 1,
      name: t("Objects:general_personal_details_title"),
      id: 1,
    },
    {
      isSelected: currentTabId === 2,
      name: t("Objects:medical_notes"),
      id: 2,
    },
    ...(syncPermission
      ? [
          {
            isSelected: currentTabId === 3,
            name: t("Objects:sync_info_tab_name"),
            id: 3,
          },
        ]
      : []),
  ];

  const fetchAlarmHandlings = async () => {
    setLoading(true);
    try {
      const res = await getAlarmHandlings(authenticatedRequest, objectId);
      setAlarmHandlings(res.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const fetchThumbnail = async () => {
    try {
      const objectThumbnail = await getObjectThumbnail(
        objectId,
        authenticatedRequest
      );
      setThumbnail(objectThumbnail.data);
    } catch (error: any) {
      if (error.response?.status === 404) {
        return console.log("Object image not found");
      }
      console.log("error fetching thumbnail");
    }
  };

  useEffect(() => {
    fetchThumbnail();
    if (data?.active) {
      fetchAlarmHandlings();
    }
  }, []);

  const { data: organisationTree = [] } = useOrganizationsTree();
  if (!data || !alarmHandlings) {
    return null;
  }
  const onSubmit = async (values: ObjectDetailsModel) => {
    try {
      await update(values);

      notify({
        message: t("Objects:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const renderAbsence = () => {
    const upcomingAbsenceToRender = absenceData?.upcoming[0]
      ? [absenceData.upcoming[0]]
      : [];
    const absences = absenceData?.ongoing.length
      ? absenceData.ongoing
      : upcomingAbsenceToRender;

    const isOngoing = !!absenceData?.ongoing?.length;
    if (!absences?.length) {
      return null;
    }
    return (
      <>
        <Spacer size={20} />
        {absences.map((absence, index) => (
          <>
            {index > 0 && <Spacer size={8} />}
            <Alert
              key={absence?.id}
              style={{ width: "722px" }}
              variant={isOngoing ? "warning" : "info"}
              title={
                <AbsenceAlert
                  data={absence}
                  isOngoing={isOngoing}
                  dateFormat={dateFormat}
                />
              }
              withDefaultIcon
            />
          </>
        ))}
      </>
    );
  };

  return (
    <div className={styles.objectGeneralWrapper}>
      <h1>{t("Objects:general_page_title")}</h1>
      <p>{t("Objects:general_page_description")}</p>

      {isLoading || loading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <>
          {!!data.dateOfDeath && (
            <>
              <Spacer size={isDesktop ? 32 : 20} />
              <Alert
                title={`${t(
                  "Objects:deceased"
                )} - ${DateTimeHelper.formatDateString(
                  data.dateOfDeath.toString()
                )}.`}
                withDefaultIcon
                variant="warning"
              />
            </>
          )}

          {renderAbsence()}

          {width < breakpoints.desktop && (
            <Tabs
              className={styles.ogjectTabsContainer}
              items={tabsData}
              onSelect={setCurrentTabId}
            />
          )}

          <FormProvider {...methods}>
            <form
              ref={formRef}
              onSubmit={handleSubmit(async (values) => {
                await onSubmit(values);
                reset(values);
              })}
            >
              {(currentTabId === 0 || isDesktop) && (
                <ObjectGeneralBasic
                  defaultValues={data}
                  objectId={objectId}
                  objectPhoto={thumbnail}
                  organizations={organisationTree}
                  alarmHandlings={alarmHandlings}
                />
              )}
              {config?.show.includes("User/BaseInformation") && (
                <div className={styles.personalInfoContainer}>
                  {(currentTabId === 1 || isDesktop) && (
                    <div className={styles.personalInfoWrapper}>
                      <ObjectGeneralPersonalDetails />
                      <ObjectGeneralMorePersonalInfo />
                    </div>
                  )}

                  {(currentTabId === 2 || isDesktop) && (
                    <MedicalNotes objectId={objectId} />
                  )}
                </div>
              )}
              {(currentTabId === 3 || isDesktop) && (
                <ManualSyncWrapper
                  clusterData={{
                    lastSync: data.lastClusterSync,
                    latestSyncAttempt: data.lastClusterSyncAttempt,
                  }}
                  externalSettingData={{
                    syncStatus: data.syncStatus,
                    lastSync: data.lastIntegrationSync,
                    latestSyncAttempt: data.latestIntegrationSyncAttempt,
                  }}
                  refetchFunc={fetchObject}
                />
              )}
            </form>
          </FormProvider>
          <Spacer />
        </>
      )}
    </div>
  );
};

export default observer(ObjectGeneralContainer);
