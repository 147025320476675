/* eslint-disable camelcase */
import axios from "axios";
import querystring from "query-string";
import { PasswordConstraints } from "./administrators/types";

interface GetTokenParams {
  username: string;
  password: string;
}

export interface GetTokenResponse {
  access_token: string;
  refresh_token: string;
  twoFactorAuthType?: "none" | "sms" | "sms_seperate" | "google";
  expires_in: string;
  resetPasswordToken?: string;
}

interface RefreshTokenParams {
  token: string;
  refreshToken: string;
}

interface ResetPasswordParams {
  token: string;
  password: string;
  authCode?: string;
}

interface RefreshTokenResponse {
  access_token: string;
  expires_in: string;
  refresh_token: string;
}

interface GetTokenParams2FA {
  token: string;
  code: string;
  rememberMe: boolean;
}

interface GetTokenResponse2FA {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  resetPasswordToken?: string;
}

const api = {
  getToken: async (params: GetTokenParams) => {
    const response = await axios.post<GetTokenResponse>(
      `/api/v2/token`,
      querystring.stringify({
        ...params,
        grant_type: "password",
      }),
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      }
    );

    return response.data;
  },
  refreshToken: async (params: RefreshTokenParams) => {
    const response = await axios.post<RefreshTokenResponse>(
      `/api/v2/token/refresh`,
      {
        refreshToken: params.refreshToken,
      },
      {
        headers: { Authorization: `Bearer ${params.token}` },
        withCredentials: true,
      }
    );

    return response.data;
  },
  resetPassword: async (params: ResetPasswordParams) => {
    const response = await axios.post(`/api/v2/password/reset`, params);

    return response.data;
  },
  getPasswordComplexity: async (param: string) =>
    axios.get<PasswordConstraints>(
      `/api/v2/password/complexity?token=${param}`
    ),

  getToken2FA: async (params: GetTokenParams2FA) => {
    const response = await axios.post<GetTokenResponse2FA>(
      "/api/v2/2fa",
      {
        authCode: params.code,
        rememberMe: params.rememberMe,
      },
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  },
  resetPasswordRequest: async (userName: string) => {
    const response = await axios.post(
      "/api/v2/password/resetrequest",
      JSON.stringify({ username: userName }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  },
};

export default api;
