import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PlanningHistoryTable } from "./PlanningHistoryTable";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { getActivityHistory } from "../../../../core/api/objects/objects";
import { useSearchFilter } from "../../../../core/hooks/filters/useSearchFilter";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import { IItemProp } from "../../../../ui-lib/components/Dropdown/DropdownItem";
import { IPlanningHistory } from "../../../../core/api/objects/types";
import { useQueryState } from "../../../../core/hooks/filters/useQueryState";
import { ClearFilter } from "../../../../ui-lib/components/Tables/CleatFilters";
import { getOrganizationIntegrations } from "../../../../core/api/organizations/organizations";
import { completedStatuses } from "../PlanningOverview/PlanningOverview";
import { IOrgIntegration } from "../../../../core/api/organizations/types";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useTranslations from "../../../../core/i18n/useTranslations";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import useUser from "../../../../core/user/useUser";
import SearchInput from "../../../../ui-lib/components/Inputs/SearchInput";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import useSharedObject from "../../SharedObjectContext/useSharedObject";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";

export type PlanningHistoryFilters = {
  filterType: string;
  filterStatus: string;
};

const initialHistoryFilters = {
  filterStatus: "",
  filterType: "",
};

const INITIAL_PAGE_SETTINGS = {
  page: 1,
  pageSize: 10,
  total: 0,
};

export const PlanningHistory = () => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { id } = useParams<{ id: string }>();

  const { data: objectData } = useSharedObject();

  const { searchFilter, setSearchFilter, resetSearchFilter } =
    useSearchFilter();

  const [pageSettings, setPageSettings] = useState(INITIAL_PAGE_SETTINGS);
  const [planningHistory, setPlanningHistory] = useState<IPlanningHistory[]>(
    []
  );
  const [integrations, setIntegrations] = useState<IOrgIntegration[]>();
  const [activityTypes, setActivityTypes] = useState<IItemProp[]>([]);
  const [activityToken, setActivityToken] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(true);
  const [historyFilters, setHistoryFilters] =
    useQueryState<PlanningHistoryFilters>(initialHistoryFilters);

  const fetchParams = {
    limit: pageSettings.pageSize,
    paginationToken: activityToken,
    search: searchFilter.value,
    ...historyFilters,
  };

  const clearFilters = () => {
    setPlanningHistory([]);
    setActivityToken("");
    setActivityTypes((prev) =>
      prev.map((type) => ({ ...type, isSelected: false }))
    );
    setHistoryFilters(initialHistoryFilters);
    resetSearchFilter();
    setPageSettings(INITIAL_PAGE_SETTINGS);
  };

  const getAllHistory = async () => {
    setLoading(true);
    try {
      const { data } = await getActivityHistory(
        +id,
        authenticatedRequest,
        fetchParams
      );

      setActivityToken(data.paginationToken);
      if (data.paginationToken === "{}") {
        setShowMore(false);
        return notify({
          message: t("Objects:no_new_activities"),
        });
      }
      const tableData = [] as IPlanningHistory[];
      data?.entities?.forEach((item) => {
        const integrationName = activityTypes?.find(
          (integration) => item.integration === integration.id
        )?.name;
        tableData.push({ ...item, integrationName: integrationName ?? "" });
      });
      setShowMore(true);
      setPlanningHistory((prev) => {
        const newData = [...prev, ...tableData];
        setPageSettings({ ...pageSettings, total: newData.length });
        return newData;
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const getAllIntegrations = async () => {
    try {
      if (objectData) {
        const { data } = await getOrganizationIntegrations(
          objectData.organizationId,
          authenticatedRequest
        );

        const options = data?.map((item) => ({
          id: item.integrationId,
          name: item.name,
          isSelected: item.integrationId === historyFilters.filterType,
        }));
        setIntegrations(data);
        setActivityTypes(options ?? []);
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const statusItems: IItemProp[] = completedStatuses.map((i) => ({
    id: i,
    name: t(`Objects:${i}`),
    isSelected: i === historyFilters.filterStatus,
  }));

  useEffect(() => {
    getAllIntegrations();
  }, []);

  useEffect(() => {
    if (activityTypes.length) {
      getAllHistory();
    }
  }, [
    searchFilter.value,
    historyFilters,
    activityTypes.length,
    pageSettings.pageSize,
  ]);

  const isFiltersApplied =
    !!searchFilter.value ||
    !!historyFilters.filterStatus ||
    !!historyFilters.filterType;

  return (
    <>
      <h1>{t("Objects:planning_history")}</h1>
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <SearchInput
        placeholder={t("Objects:planning_history_search_placeholder")}
        value={searchFilter.value}
        onChange={(value) => {
          if (value !== searchFilter.value) {
            setPlanningHistory([]);
            setPageSettings(INITIAL_PAGE_SETTINGS);
          }
          setSearchFilter({ value });
        }}
        limit={1}
      />
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <Row type="left" className="w-100 flex0">
        <Dropdown
          withClearItem
          width={180}
          placeholder={`--${t("Objects:activity_type")}--`}
          items={activityTypes}
          disabled={!activityTypes.length}
          selectedItem={activityTypes.find((item) => item.isSelected)}
          onSelectItem={(e) => {
            if (e.id !== historyFilters.filterType) {
              setPlanningHistory([]);
              setActivityToken("");
              setPageSettings(INITIAL_PAGE_SETTINGS);
            }
            setActivityTypes((prev) =>
              prev.map((type) => ({ ...type, isSelected: e.id === type.id }))
            );
            setHistoryFilters({
              ...historyFilters,
              filterType: e.id === -1 ? "" : (e.id as string),
            });
          }}
        />
        <Spacer size={8} />
        <Dropdown
          withClearItem
          width={180}
          placeholder={`--${t("Common:status")}--`}
          items={statusItems}
          selectedItem={statusItems.find((status) => status.isSelected)}
          onSelectItem={(el) => {
            if (el.id !== historyFilters.filterStatus) {
              setPlanningHistory([]);
              setActivityToken("");
              setPageSettings(INITIAL_PAGE_SETTINGS);
            }
            statusItems.forEach((val) => ({
              ...val,
              isSelected: val.id === historyFilters.filterStatus,
            }));
            setHistoryFilters({
              ...historyFilters,
              filterStatus: el.id === -1 ? "" : (el.id as string),
            });
          }}
        />
        <Spacer size={8} />
        <ClearFilter
          text={t("Common:labels_clear_all_filters")}
          filtersToWatch={[searchFilter.value, historyFilters]}
          onClearClick={clearFilters}
        />
      </Row>
      <Spacer size={16} />
      {loading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <PlanningHistoryTable
          data={planningHistory}
          objectId={id}
          integrations={integrations}
          isFiltersApplied={isFiltersApplied}
          pageSettings={pageSettings}
          showMore={showMore}
          onClear={clearFilters}
          onPageSettingsChange={setPageSettings}
        />
      )}
    </>
  );
};
