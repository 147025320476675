import { useEffect, useRef, useState } from "react";
import he from "he";
import { useHistory } from "react-router-dom";
import {
  ContactResponse,
  IContactsReorder,
} from "../../../../core/api/contacts/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import Table from "../../../../ui-lib/components/Tables/Table";
import {
  ContactCell,
  CustomTableCell,
} from "../CustomTableCell/CustomTableCell";
import DeleteContactModal from "./DeleteContactModal";
import { addPrivateContactToStore } from "../../../../ui-lib/utils/privateContactHelper";
import Icon from "../../../../ui-lib/components/Icon";
import Tooltip from "../../../../ui-lib/components/Tooltip/Tooltip";
import { Spacer } from "../../../../components/Layout/Layout";
import { ContactsRelationModal } from "./ContactsRelationModal";
import { updateAssociationCommonContact } from "../../../../core/api/contacts/contacts";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import useUser from "../../../../core/user/useUser";
import { urlSearchParser } from "../../../../ui-lib/utils/urlSearchParser";
import { useModalIdHook } from "../../../../core/hooks/modalIdsHandlers/useIdModalHook";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";

export const ContactTable = ({
  objectId,
  contacts,
  onContactUpdate,
  onContactsReorder,
  page,
  setPage,
}: {
  objectId: string;
  contacts: ContactResponse[];
  onContactUpdate: (page?: number) => void;
  onContactsReorder: (contactsPriority: IContactsReorder) => void;
  page: number;
  setPage: (page: number) => void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const tableState = history.location.search;
  const searchState = urlSearchParser(tableState);

  const [contactId, setContactId, resetContactId] = useModalIdHook(
    searchState?.contactId || undefined,
    "contactId"
  );

  const { authenticatedRequest } = useUser();

  const deleteModalRef = useRef<ModalActions>(null);
  const [upForDelete, setUpForDelete] = useState<ContactResponse>();
  const [relationToEdit, setRelationToEdit] =
    useState<ContactResponse | null>();
  const [isLoading, setIsLoading] = useState<string | undefined>();

  const onRelationChange = async (values: any) => {
    try {
      setIsLoading("close");
      await updateAssociationCommonContact(
        objectId,
        relationToEdit?.id!,
        values,
        authenticatedRequest
      );
      notify({
        message: t("Objects:edited_success"),
      });
      setRelationToEdit(null);
      onContactUpdate();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setIsLoading(undefined);
    }
  };

  useEffect(() => {
    if (contactId.id) {
      const contact = contacts.find((c) => c.id === +contactId?.id!);

      // if it is a common contact and not a private contact we want to open the relation modal
      if (!contact?.userId) {
        setRelationToEdit(contact);
      }
    }
  }, [contactId]);

  const contactsLengthPefix = contacts.length % 15 === 0 ? 1 : 0;

  return (
    <>
      <Table<ContactResponse>
        columns={[
          {
            fieldTemplate: (rowData) => (
              <>
                <TableCell
                  className="p-0"
                  value={rowData.name}
                  linkTo={`/adminportal/common-contacts/${rowData.id}`}
                />
                <p
                  style={{
                    color: rowData.description
                      ? "var(--Grey-600)"
                      : "var(--Grey-400)",
                  }}
                >
                  {!rowData.userId
                    ? he.decode(rowData.description || "") ||
                      t("Objects:not_specified")
                    : ""}
                </p>
              </>
            ),
          },
          {
            fieldTemplate: (rowData) => (
              <CustomTableCell
                label={t("Objects:for_object")}
                description={
                  he.decode(
                    (rowData.userId
                      ? rowData.description
                      : rowData.userAssociation?.relation) ?? ""
                  ) || t("Objects:not_specified")
                }
                descriptionColor={
                  (rowData.userId && rowData.description) ||
                  rowData.userAssociation?.relation
                    ? "var(--Grey-600)"
                    : "var(--Grey-400)"
                }
              />
            ),
          },
          {
            fieldTemplate: (rowData) => (
              <ContactCell phone={rowData.telephone1} email={rowData.email} />
            ),
          },
          {
            fieldTemplate: (rowData) => (
              <CustomTableCell
                label={
                  rowData.userId
                    ? t("Objects:private_label")
                    : t("Objects:common_label")
                }
                description={
                  rowData.isResource
                    ? t("Contacts:input_resource")
                    : t("Common:contact_person")
                }
              />
            ),
            style: {
              width: "145px",
            },
          },
          {
            fieldTemplate: (rowData) => (
              <div style={{ display: "flex" }}>
                {rowData.userAssociation?.keyHolder && (
                  <Tooltip
                    trigger={() => (
                      <span>
                        <Icon name="key-holder" size={16} />
                      </span>
                    )}
                    variant="light"
                    position="top center"
                    text={t("Contacts:key_holder")}
                  />
                )}
                {rowData.userAssociation?.nextOfKin &&
                  rowData.userAssociation?.keyHolder && <Spacer size={8} />}
                {rowData.userAssociation?.nextOfKin && (
                  <Tooltip
                    trigger={() => (
                      <span>
                        <Icon name="nok" size={16} />
                      </span>
                    )}
                    variant="light"
                    position="top center"
                    text={t("Contacts:next_of_kin")}
                  />
                )}
              </div>
            ),
            style: {
              width: "55px",
            },
          },
        ]}
        items={contacts}
        rowActions={[
          {
            text: t("Objects:edit_details"),
            icon: "pencil-alt",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              if (rowData.userId) {
                addPrivateContactToStore(rowData.id, history.location.pathname);
              }
              history.push(`/adminportal/common-contacts/${rowData.id}`);
            },
          },
          {
            text: t("Objects:edit_relations"),
            icon: "pencil",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              setContactId(rowData.id);
            },
            hideForRow: (rowData) => !!rowData.userId,
          },
          {
            text: t("Objects:remove_from"),
            icon: "x",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              setUpForDelete(rowData);
              deleteModalRef?.current?.open();
            },
          },
        ]}
        hideHeader
        hideEmptyMessage
        noRowsMessage={t("Table:no_records_found")}
        rowActionContextMenu
        showRowHover
        withShowMore
        withLazyLoading
        paginatedItems={{
          items: contacts,
          pagination: {
            offset: 0,
            limit: contacts.length,
            total:
              contacts.length === 0 ? 0 : contacts.length + contactsLengthPefix,
          },
        }}
        onShowMoreClick={() => {
          setPage(page + 1);
          onContactUpdate(page + 1);
        }}
        reorderableRows
        onRowReorder={(e) => {
          setPage(1);
          onContactsReorder(
            e.value.map((contact: any, index: number) => ({
              priority: index,
              contactId: contact.id,
            }))
          );
        }}
      />

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteContactModal
            objectId={objectId}
            contact={upForDelete}
            onClose={close}
            onDelete={() => {
              close();
              setPage(1);
              onContactUpdate();
            }}
          />
        )}
      </Modal>

      <ContactsRelationModal
        isOpen={!!relationToEdit}
        isEdit
        isAdding={isLoading}
        onClose={() => {
          resetContactId();
          setRelationToEdit(null);
        }}
        onSubmit={onRelationChange}
        defaultValues={{
          relation: relationToEdit?.userAssociation?.relation,
          keyHolder: relationToEdit?.userAssociation?.keyHolder,
          nextOfKin: relationToEdit?.userAssociation?.nextOfKin,
        }}
      />
    </>
  );
};
