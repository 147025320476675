import { IObjectAbsence } from "../../../core/api/objects/types";
import useTranslations from "../../../core/i18n/useTranslations";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { Spacer } from "../../../components/Layout/Layout";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import moment from "moment";
import { useState } from "react";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { endObjectAbsence } from "../../../core/api/objects/objects";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import useUser from "../../../core/user/useUser";

export const EndOngoingAbsences = ({
  absences,
  adminId,
  isOpen,
  onClose,
  reloadAbsence,
}: {
  absences: IObjectAbsence[];
  adminId: number | string;
  isOpen: boolean;
  onClose: () => void;
  reloadAbsence: () => Promise<void>;
}) => {
  const { authenticatedRequest } = useUser();
  const t = useTranslations();
  const [selectedAbsences, setSelectedAbsences] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (date: string, time: string) => {
    const dateObj = moment(date);
    dateObj.set({
      hour: +time.split(":")[0],
      minute: +time.split(":")[1],
    });

    return dateObj.format("D MMMM, YYYY HH:mm");
  };

  const cancelAbsences = async () => {
    setIsLoading(true);
    try {
      const promises = selectedAbsences.map((absenceId) =>
        endObjectAbsence(adminId, absenceId, authenticatedRequest)
      );
      await Promise.all(promises);
      await reloadAbsence();
      notify({
        message: t("Objects:ongoing_absences_deleted"),
      });
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      isOpen={isOpen}
      onClose={onClose}
      header={t("Objects:cancel_absence")}
      withHeader
      withFooter
      submitBtn={{
        text: t("Objects:cancel_absence"),
        onClick: cancelAbsences,
        disabled: !selectedAbsences.length,
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: onClose,
      }}
      loading={isLoading}
      fixedWidth="848px"
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:cancel_multiple_ongoing_description")}
      </p>
      <Spacer size={16} />
      <Checkbox
        checked={selectedAbsences.length === absences.length}
        label={t("Common:select_all_below")}
        onChange={() => {
          if (selectedAbsences.length === absences.length) {
            setSelectedAbsences([]);
          } else {
            setSelectedAbsences(absences.map((absence) => absence.id));
          }
        }}
      />
      {absences.map((absence) => (
        <>
          <Spacer size={8} />
          <Checkbox
            checked={selectedAbsences.includes(absence.id)}
            label={`${absence.reason} (${formatDate(
              absence.startDate,
              absence.startTime
            )} ${
              absence.endDate
                ? ` - ${formatDate(absence.endDate, absence.endTime!)}`
                : ""
            })`}
            onChange={() => {
              const newSelectedAbsences = [...selectedAbsences];
              if (newSelectedAbsences.includes(absence.id)) {
                newSelectedAbsences.splice(
                  selectedAbsences.indexOf(absence.id),
                  1
                );
              } else {
                newSelectedAbsences.push(absence.id);
              }
              setSelectedAbsences(newSelectedAbsences);
            }}
          />
        </>
      ))}
    </PrimeModal>
  );
};
