import { useForm, Controller, FieldError } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";

import { useEffect, useState } from "react";
import { AddressDetails } from "../../../../core/api/addresses/types";

import ModalContent from "../../../../ui-lib/components/Popup/ModalContent";
import Button from "../../../../ui-lib/components/Button/Button";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import TextArea from "../../../../ui-lib/components/Inputs/Textarea";

import { Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import MarkerIcon from "../../../../ui-lib/assets/icons/marker.svg";
import {
  validateEmergencyPhoneNumberIfExists,
  validatePhoneNumberIfExists,
} from "../../../../core/helpers/validation";
import usePageState from "../../../../core/pagestate/usePageState";
import useUser from "../../../../core/user/useUser";

const BASE_PLACEMARK_URL = "https://www.google.com/maps/search/?api=1&query=";

export const AddressForm = ({
  labels,
  defaultValues,
  onSubmit,
  onClose,
}: {
  labels: {
    title: string;
    description: string;
    submit: string;
  };
  defaultValues?: AddressDetails;
  onSubmit: (values: AddressDetails) => void;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const pageState = usePageState();
  const { config } = useUser();

  const {
    control,
    formState: { isSubmitting, errors, isDirty },
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<AddressDetails>({
    defaultValues,
  });

  const [inputAddress, setInputAddress] = useState(
    defaultValues?.streetAddress ?? ""
  );
  useEffect(() => {
    setValue("streetAddress", inputAddress, {
      shouldDirty: true,
    });
  }, [inputAddress]);

  const { ref: googleAddress } = usePlacesWidget({
    apiKey: "AIzaSyAOhQYmEXHT3jNB8dOHsKT1W26B5llY_UI",
    onPlaceSelected: (place) => {
      let country;
      let streetAddress;
      let streetNumber;
      let premise;
      let locality;

      if (place?.address_components) {
        streetAddress = place.address_components.find((ac) =>
          ac.types.includes("route")
        );
        streetNumber = place.address_components.find((ac) =>
          ac.types.includes("street_number")
        );

        premise = place.address_components.find((ac) =>
          ac.types.includes("premise")
        );

        locality = place.address_components.find((ac) =>
          ac.types.includes("locality")
        );

        setValue(
          "city",
          place.address_components.find((ac) =>
            ac.types.includes("postal_town")
          )?.long_name ??
            place.address_components.find((ac) => ac.types.includes("locality"))
              ?.long_name ??
            "",
          {
            shouldDirty: true,
          }
        );

        country = place.address_components.find((ac) =>
          ac.types.includes("country")
        );
        setValue("country", country?.long_name ?? "", {
          shouldDirty: true,
        });
        setValue(
          "postalCode",
          place.address_components.find((ac) =>
            ac.types.includes("postal_code")
          )?.long_name ?? "",
          {
            shouldDirty: true,
          }
        );
      }

      if (place?.geometry?.location) {
        const { lat, lng } = place.geometry.location;
        setValue("latitude", lat(), {
          shouldDirty: true,
        });
        setValue("longitude", lng(), {
          shouldDirty: true,
        });
      } else {
        setValue("latitude", undefined, {
          shouldDirty: true,
        });
        setValue("longitude", undefined, {
          shouldDirty: true,
        });
      }

      const uk = country?.short_name === "GB" || country?.short_name === "UK";

      if (uk) {
        const addressParts = [];

        // response from google can contain a lot of special fields, thus we need to build it in a bit of a weird way
        if (streetNumber?.long_name) addressParts.push(streetNumber?.long_name);
        if (premise?.long_name) addressParts.push(premise?.long_name);
        if (streetAddress?.long_name)
          addressParts.push(streetAddress.long_name);
        if (locality?.long_name) addressParts.push(locality?.long_name);

        setInputAddress(addressParts.join(", "));
      } else if (streetAddress) {
        setInputAddress(
          !!streetNumber?.long_name && !!streetAddress.long_name
            ? `${streetAddress.long_name} ${streetNumber.long_name}`
            : streetAddress.long_name
        );
      } else if (place?.name) {
        setInputAddress(place.name);
      }
    },
    options: {
      fields: [
        "address_components",
        "geometry",
        "icon",
        "name",
        "place_id",
        "formatted_address",
      ],
      strictBounds: false,
      types: [],
    },
    language: pageState.rootStore.languageStore.currentLanguage.nativeName,
  });

  const getPlacemarkLink = () =>
    `${BASE_PLACEMARK_URL}${getValues("latitude")},${getValues("longitude")}`;

  const getErrorMessage = (error?: FieldError) => {
    if (error === undefined) return "";

    if (error.type === "required") {
      return t("Objects:address_input_required_fields");
    }

    if (
      error.type === "validateTelephone" ||
      error.type === "validateOptionalTelephone" ||
      error.type === "validate"
    )
      return t("Errors:response_invalid_telephone_number_format");

    return "Undefined Error";
  };

  return (
    <ModalContent
      onClose={onClose}
      width="848px"
      fixedHeader
      headerContent={
        <>
          <h2 className="subTitle">{labels.title}</h2>
          <Spacer size={8} />
        </>
      }
      footerContent={
        <Row type="left">
          <Button
            form="address-modal-form"
            type="submit"
            variant="primary"
            text={labels.submit}
            loading={isSubmitting}
            disabled={isSubmitting || !isDirty}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
          />
        </Row>
      }
    >
      <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>

      <Spacer size={32} />

      <form onSubmit={handleSubmit(onSubmit)} id="address-modal-form">
        <Row align="start">
          <Controller
            name="streetAddress"
            control={control}
            rules={{ required: !watch("telephone1") || !watch("telephone2") }}
            render={({ field }) => (
              <TextInput
                required={!watch("telephone1") || !watch("telephone2")}
                label={t("Objects:address_input_street")}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.currentTarget.value);
                  trigger("telephone1");
                }}
                description={t("Objects:address_input_street_description")}
                validationError={
                  errors.streetAddress &&
                  t("Objects:address_input_required_fields")
                }
                placeholder={t("Common:street_placeholder")}
                inputRef={googleAddress as any}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                  return false;
                }}
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="apartmentNumber"
            control={control}
            rules={{ maxLength: 100 }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:apartment_number")}
                value={field.value}
                onChange={field.onChange}
                placeholder={t("Common:apartment_placeholder")}
                validationError={
                  errors?.apartmentNumber &&
                  t("Errors:max_chars_exceeded").replace("{0}", "100")
                }
              />
            )}
          />
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Common:labels_city")}
                value={field.value}
                onChange={field.onChange}
                placeholder={t("Common:city_placeholder")}
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="postalCode"
            rules={{ maxLength: 10 }}
            control={control}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:labels_postal_code")}
                value={field.value}
                onChange={field.onChange}
                placeholder={t("Common:postal_code_placeholder")}
                validationError={
                  errors?.postalCode &&
                  t("Errors:max_chars_exceeded").replace("{0}", "10")
                }
              />
            )}
          />
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Common:labels_country")}
                value={field.value}
                onChange={field.onChange}
                placeholder={t("Common:country_placeholder")}
              />
            )}
          />
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="customerSpecific1"
            control={control}
            rules={{ maxLength: 200 }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:customer_specific_1")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors?.customerSpecific1 &&
                  t("Errors:max_chars_exceeded").replace("{0}", "200")
                }
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="customerSpecific2"
            control={control}
            rules={{ maxLength: 200 }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:customer_specific_2")}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors?.customerSpecific2 &&
                  t("Errors:max_chars_exceeded").replace("{0}", "200")
                }
              />
            )}
          />
        </Row>

        <Spacer size={16} />

        <Controller
          name="description"
          control={control}
          rules={{ maxLength: 150 }}
          render={({ field }) => (
            <TextArea
              inputRef={field.ref}
              label={t("Objects:address_input_description")}
              placeholder={t("Errors:MaximumLengthExceeded").replace(
                "{0}",
                "150"
              )}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.description &&
                t("Errors:length_to_long").replace("{0}", "150")
              }
            />
          )}
        />

        <Spacer size={8} />

        <Row align="start">
          <Controller
            name="latitude"
            control={control}
            rules={{
              max: 90,
              min: -90,
              pattern: /^(-?\d{1,2}([.]?\d+)?)$/,
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:labels_latitude")}
                value={`${field.value ?? ""}`.substring(0, 10)}
                onChange={field.onChange}
                validationError={
                  errors?.latitude && t("Errors:ValidationHint_Latitude")
                }
              />
            )}
          />
          <Spacer size={16} />
          <Controller
            name="longitude"
            control={control}
            rules={{
              max: 180,
              min: -180,
              pattern: /^(-?\d{1,3}([.]?\d+)?)$/,
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:labels_longitude")}
                value={`${field.value ?? ""}`.substring(0, 10)}
                onChange={field.onChange}
                validationError={
                  errors?.longitude && t("Errors:ValidationHint_Longitude")
                }
              />
            )}
          />
        </Row>
        <Spacer size={8} />
        <p>{t("Objects:custom_address_description")}</p>
        <Spacer size={8} />
        {getValues("latitude") && getValues("longitude") && (
          <span>
            <img src={MarkerIcon} alt="marker" />
            <a
              style={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "14px",
              }}
              target="_blank"
              href={getPlacemarkLink()}
              rel="noreferrer"
            >
              {t("Common:labels_placemark")}
            </a>
          </span>
        )}

        {!config?.show.includes("ShowObjectDetailsVariant1") && (
          <>
            <Spacer size={16} />

            <Row align="start">
              <Controller
                name="doorCode"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:door_code")}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t("Common:door_placeholder")}
                  />
                )}
              />
              <Spacer size={32} />
              <Controller
                name="alarmCode"
                control={control}
                rules={{ maxLength: 60 }}
                render={({ field }) => (
                  <TextInput
                    inputRef={field.ref}
                    label={t("Objects:address_input_alarm_code")}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t(
                      "Transmitters:create_schedule_placeholder_alarmcode"
                    )}
                    validationError={
                      errors?.alarmCode &&
                      t("Errors:max_chars_exceeded").replace("{0}", "60")
                    }
                  />
                )}
              />
            </Row>
          </>
        )}

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="telephone1"
            control={control}
            rules={{
              required: !watch("streetAddress"),
              validate: (value) => {
                if (value?.length <= 5) {
                  return validateEmergencyPhoneNumberIfExists(value);
                }
                return validatePhoneNumberIfExists(value);
              },
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                required={!watch("streetAddress")}
                label={t("Common:address_main_number")}
                value={field.value}
                onChange={field.onChange}
                description={t("Objects:address_input_phone_description")}
                validationError={getErrorMessage(errors.telephone1)}
                placeholder={t("Common:input_phone_description")}
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="telephone2"
            control={control}
            rules={{
              validate: (value) => {
                if (value?.length <= 5) {
                  return validateEmergencyPhoneNumberIfExists(value);
                }
                return validatePhoneNumberIfExists(value);
              },
            }}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:address_secondary_number")}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.currentTarget.value);
                  trigger("streetAddress");
                }}
                placeholder={t("Common:input_phone_description")}
                validationError={getErrorMessage(errors.telephone2)}
              />
            )}
          />
        </Row>
        <Spacer size={32} />
      </form>
    </ModalContent>
  );
};
