import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from "mobx";
import axios from "axios";
import {
  getStoredToken,
  storeToken,
  clearStoredToken,
  needsRefresh,
} from "./token";
import authApi from "../api/auth";
import configApi, { GetConfigResponse } from "../api/config";
import { getIdentity } from "../api/identities/identities";
import { IIdentity } from "../api/identities/types";
import { AdministratorDetails } from "../api/administrators/types";

interface UserData {
  name: string;
  username: string;
  roles: string[];
  seller: string;
  type: string;
  firstLanguage: string;
  secondLanguage?: string;
  isRole: boolean;
}
export interface MenuAlternative {
  id?: number;
  title?: string;
  url?: string;
  order?: number;
  openType?: number;
  requireAction?: string;
}

class User {
  loggedIn = false;

  initialized = false;

  accountId?: number;

  isRole?: boolean;

  data?: UserData;

  config?: GetConfigResponse;

  unImpersonateName?: string;

  dateFormatData: string = "MM/DD/YYYY";

  constructor() {
    const storedToken = getStoredToken();

    if (storedToken) {
      this.loggedIn = true;
      this.accountId = storedToken.accountId;
      this.isRole = false;
      this.config = storedToken.guiConfig;
      this.unImpersonateName = storedToken.unImpersonateName;
      this.dateFormatData = storedToken.dateFormat ?? this.config?.dateFormat;
    }

    makeObservable(this, {
      loggedIn: observable,
      initialized: observable,
      accountId: observable,
      data: observable,
      unImpersonateName: observable,
      logIn: action,
      logOut: action,
      initialize: action,
      hasAccess: action,
      authenticatedRequest: computed,
      impersonatedFrom: computed,
      dateFormat: computed,
    });
  }

  async logIn({
    token,
    expiresIn,
    refreshToken,
  }: {
    token: string;
    expiresIn: string;
    refreshToken: string;
  }) {
    const config = await configApi.getConfig({ token });
    const accountId = config.account.id;

    if (!accountId) {
      return Promise.reject();
    }

    storeToken({
      token,
      refreshToken,
      accountId,
      expiresIn: parseInt(expiresIn, 10) * 1000, // s to ms
      guiConfig: config,
      unImpersonateName: this.unImpersonateName,
      dateFormat: config.dateFormat,
    });

    this.loggedIn = true;
    this.accountId = accountId;
    this.config = config;
    this.dateFormatData = config.dateFormat;

    await this.initialize();

    return Promise.resolve();
  }

  logOut() {
    clearStoredToken();

    // remove possible AD login link
    window.localStorage.removeItem("linkToNavigate");

    this.loggedIn = false;
    this.initialized = false;
    this.accountId = undefined;
    this.config = undefined;
    this.data = undefined;
    this.unImpersonateName = undefined;
    this.dateFormatData = "MM/DD/YYYY";
  }

  hasAccess(accessRight: string): boolean {
    if (this.config && this.config?.show?.indexOf(accessRight) > -1) {
      return true;
    }
    return false;
  }

  needsTokenRefresh() {
    const token = getStoredToken();

    if (!this.loggedIn || !token) {
      return false;
    }

    // Check if current token needs refresh
    return needsRefresh({
      issuedAt: token.issuedAt,
      expiresIn: token.expiresIn,
      now: Date.now(),
    });
  }

  async refreshToken() {
    const token = getStoredToken();

    if (!this.loggedIn || !token) {
      return Promise.reject();
    }

    try {
      const data = await authApi.refreshToken({
        token: token.token,
        refreshToken: token.refreshToken,
      });

      const updatedToken = storeToken({
        token: data.access_token,
        refreshToken: data.refresh_token,
        accountId: token.accountId,
        expiresIn: parseInt(data.expires_in, 10) * 1000, // s to ms
        guiConfig: token.guiConfig,
        unImpersonateName: this.unImpersonateName,
      });

      return Promise.resolve(updatedToken);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  async initialize() {
    if (!this.accountId) {
      return Promise.reject();
    }

    const result = await this.authenticatedRequest.get<AdministratorDetails>(
      `/api/v2/admins/${this.accountId}`
    );
    const adminData = result.data;
    let identityData: IIdentity | null = null;
    if (adminData.isRole && adminData.identityId) {
      const res = await getIdentity(
        adminData.identityId,
        this.authenticatedRequest
      );
      identityData = res.data;
    }

    if (!adminData) {
      return Promise.reject();
    }

    let name: string;
    let username: string;

    if (identityData) {
      name = identityData.firstName
        ? `${identityData.firstName} ${identityData.lastName}`
        : identityData.upn;
      username = identityData.upn;
      this.accountId = adminData.identityId;
      this.isRole = adminData.isRole;
    } else {
      name = adminData.name ?? adminData.username;
      username = adminData.username;
    }

    runInAction(() => {
      this.data = {
        name,
        username,
        roles: adminData.roles ?? [],
        seller: adminData.seller ?? adminData.name ?? adminData.username,
        type: adminData.type,
        firstLanguage: adminData.primaryLanguage ?? "",
        secondLanguage: adminData.secondaryLanguage,
        isRole: adminData.isRole,
      };
      this.initialized = true;
    });

    return this.data;
  }

  get authenticatedRequest() {
    const service = axios.create();

    service.interceptors.request.use(async (request) => {
      const token = getStoredToken();

      // Add auth header to request
      request.headers.Authorization = `Bearer ${token?.token}`;

      return request;
    });

    service.interceptors.response.use(undefined, async (error) => {
      const status = error?.response?.status;

      // Check if request fails because of an auth error
      if (status === 401) {
        // In practice, a 401 response does not always mean that the user is logged out.
        // As a workaround, we try to fetch our own admin. If this also fails, we logout the user.
        try {
          if (
            this.accountId &&
            !(
              error?.config.url.endsWith(`admins/${this.accountId}`) ||
              error?.config.url.endsWith(`identities/${this.accountId}`)
            )
          ) {
            if (this.isRole) {
              await this.authenticatedRequest.get<IIdentity>(
                `/api/v2/identities/${this.accountId}`
              );
            } else {
              await this.authenticatedRequest.get<AdministratorDetails>(
                `/api/v2/admins/${this.accountId}`
              );
            }
          } else {
            this.logOut();
          }
        } catch {
          this.logOut();
        }
      }

      return Promise.reject(error);
    });

    return service;
  }

  get dateFormat() {
    return this.dateFormatData;
  }

  get impersonatedFrom() {
    return this.unImpersonateName;
  }

  set impersonatedFrom(name) {
    this.unImpersonateName = name;
  }
}

export default User;
export type { UserData };
