import { IProductDetails } from "../../../../../core/api/products/types";
import { useEffect, useState } from "react";
import {
  getProductDetails,
  updateProduct,
} from "../../../../../core/api/products/products";
import { notifyApiErrors } from "../../../../../core/helpers/helpers";
import useTranslations from "../../../../../core/i18n/useTranslations";
import useUser from "../../../../../core/user/useUser";
import { Spacer } from "../../../../../components/Layout/Layout";
import { ProductForm } from "./ProductForm";
import LoadingSpinner from "../../../../../ui-lib/components/Loading/LoadingSpinner";
import { useOrganizationsTree } from "../../../../../core/api/organizations/organizations";
import { getSellers } from "../../../../../core/api/sellers/sellers";
import { IResellerType } from "../../../../../core/api/sellers/types";

export const ProductGeneral = ({ id }: { id: string }) => {
  const [productDetails, setProductDetails] = useState<IProductDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [resellers, setResellers] = useState<IResellerType[]>([]);

  const { data: organizationTree = [], isLoading: isOrganizationLoading } =
    useOrganizationsTree();

  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const fetchProductDetails = async () => {
    setIsLoading(true);
    try {
      const result = await getProductDetails(authenticatedRequest, id);
      setProductDetails(result.data);
      const resellersResult = await getSellers(authenticatedRequest);
      setResellers(resellersResult.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values: IProductDetails) => {
    setIsLoading(true);
    try {
      await updateProduct(authenticatedRequest, id, values);
      await fetchProductDetails();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProductDetails();
    }
  }, [id]);

  return (
    <>
      <h1>{t("Reseller:product_details")}</h1>
      <Spacer size={8} />
      <p>{t("Reseller:product_details_description")}</p>

      <Spacer size={32} />

      <h2>{t("Reseller:basic_details_title")}</h2>
      <Spacer size={8} />
      <p>{t("Reseller:basic_details_description")}</p>

      <Spacer size={16} />

      {isLoading || isOrganizationLoading ? (
        <LoadingSpinner />
      ) : (
        <ProductForm
          productDetails={productDetails}
          organizations={organizationTree}
          resellers={resellers}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
