import { AxiosInstance, AxiosRequestConfig } from "axios";
import useAuthenticatedFetch from "./useAuthenticatedFetch";

export interface AlarmRuleResponse {
  classifications: Array<number>;
  currentAlarmCode?: number;
  possibleToHaveRule?: boolean;
}

export const useAlarmRule = (id: number) =>
  useAuthenticatedFetch<AlarmRuleResponse>(
    `/api/v2/alarms/${id}/alarmcoderule`,
    {} as AxiosRequestConfig
  );

export const getAlarmRule = (
  id: number | string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<AlarmRuleResponse>(
    `/api/v2/alarms/${id}/alarmcoderule`
  );

export const saveAlarmCode = async (
  id: string,
  alarmCode: string | number,
  authenticatedRequest: AxiosInstance,
  responseCenterRule = false
) => {
  const urlToUse = `/api/v2/alarms/${id}/alarmcoderule`;
  const dataToUse = {
    alarmCode,
    responseCenterRule,
  };

  const response = authenticatedRequest.put(urlToUse, dataToUse);

  return response;
};

export const deleteAlarmCodeRule = (
  id: number | string,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete(`/api/v2/alarms/${id}/alarmcoderule`);
