import AlarmHistory from "../../AlarmHistory/AlarmHistory";
import { Row, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";

const ObjectAlarmHistory = ({ objectId }: { objectId: string }) => {
  const t = useTranslations();

  const sectionsToHide = [
    "headerExport",
    "contentWrapper",
    "customFilters",
    "tableSearch",
  ];

  return (
    <>
      <Row type="space" className="w-100 flex0">
        <div>
          <h1>{t("Objects:Menu_AlarmHistory")}</h1>
          <Spacer size={8} />
          <p>{t("Objects:alarm_history_description")}</p>
        </div>
      </Row>

      <AlarmHistory objectId={objectId} sectionsToHide={sectionsToHide} />
    </>
  );
};

export default ObjectAlarmHistory;
