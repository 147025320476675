import { AxiosInstance } from "axios";
import querystring from "query-string";
import { ApiResponse, ApiSearch } from "../../interfaces/Api";
import { Camera } from "../cameras/types";
import {
  EditLocationModel,
  NewLocationResponseData,
  ILocations,
} from "./types";

const saveLocation = async (
  locationData: EditLocationModel,
  authenticatedRequest: AxiosInstance,
  id?: number
) => {
  const Location = JSON.stringify(locationData);
  const method = id !== undefined ? "put" : "post";
  const path = id !== undefined ? `/${id}` : "";
  const result = (await authenticatedRequest[method])<NewLocationResponseData>(
    `/api/v2/knownlocations${path}`,
    Location,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
const getLocation = async (
  locationId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<EditLocationModel>(
    `/api/v2/knownlocations/${locationId}`
  );

const deleteLocation = async (
  locationId: string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/knownlocations/${locationId}`
  );
  return result;
};
const getKnownLocations = async (
  query: ApiSearch,
  authenticatedRequest: AxiosInstance
) => {
  if (!query.o?.length) {
    delete query.o;
  }
  const parsedQuery = querystring.stringify({
    ...query,
  });
  const result = await authenticatedRequest.get<ILocations>(
    `/api/v2/knownlocations/table?${parsedQuery}`
  );
  return result.data;
};
const addLocationToObject = async (
  objectId: string,
  locationId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<ApiResponse<number>>(
    `/api/v2/users/${objectId}/knownlocations`,
    { id: locationId }
  );
const removeLocationFromObject = async (
  objectId: string,
  locationId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/users/${objectId}/knownlocations/${locationId}`
  );
const getCameras = async (
  locationId: string,
  authenticatedRequest: AxiosInstance
) => {
  const query = querystring.stringify({
    locationId,
  });
  const result = await authenticatedRequest.get<Camera[]>(
    `/api/v2/cameras?${query}`
  );
  return result;
};
const addCameraToLocation = async (
  locationId: string,
  cameraId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<ApiResponse<any>>(
    `/api/v2/cameras/${cameraId}/knownlocations/${locationId}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
const removeCameraFromLocation = async (
  locationId: string,
  cameraId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<ApiResponse<any>>(
    `/api/v2/cameras/${cameraId}/knownlocations/${locationId}`
  );

export {
  addLocationToObject,
  removeLocationFromObject,
  getKnownLocations,
  deleteLocation,
  saveLocation,
  getLocation,
  getCameras,
  addCameraToLocation,
  removeCameraFromLocation,
};
