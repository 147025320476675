import { useMemo } from "react";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";

export type OrganisationFilter = { id: number; value: string };

export const useOrganisationFilter = (
  customerFilter: { customers?: string },
  data: ITreeData[]
): OrganisationFilter | undefined => {
  const organisationFilter = useMemo(() => {
    if (!customerFilter.customers) {
      return undefined;
    }

    return {
      id: 0,
      value: customerFilter.customers,
    };
  }, [customerFilter, data]);

  return organisationFilter;
};
