import { toSafeInteger } from "lodash";
import { useCallback, useMemo } from "react";
import { useQueryState } from "./useQueryState";

type PageState = { page: string; pageSize: string };
export type PageFilter = { page: number; pageSize: number };

const toPageFilter = (state: PageState): PageFilter => ({
  page: toSafeInteger(state.page),
  pageSize: toSafeInteger(state.pageSize),
});

const toPageState = (filter: PageFilter): PageState => ({
  // default values are needed for search to work properly from the home page
  page: filter.page?.toString() || "1",
  pageSize: filter.pageSize?.toString() || "50",
});

const DEFAULT: PageFilter = {
  page: 1,
  pageSize: 50,
};

export const usePageFilter = (searchObj?: any) => {
  const stateToUse = !searchObj
    ? DEFAULT
    : {
        page: searchObj.page,
        pageSize: searchObj.pageSize,
      };
  const [state, setState] = useQueryState<PageState>(toPageState(stateToUse));

  const pageFilter = useMemo(() => toPageFilter(state), [state]);

  const setPageFilter = useCallback(
    (args: PageFilter) => setState(toPageState(args)),
    [setState]
  );

  const resetPageFilter = () =>
    setState(
      toPageState({ page: DEFAULT.page, pageSize: pageFilter.pageSize })
    );

  return { pageFilter, setPageFilter, resetPageFilter };
};
