import { Dispatch, useEffect, useRef, useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import { Row, Spacer } from "../../../components/Layout/Layout";
import {
  deleteADGroup,
  getGroupMapping,
} from "../../../core/api/adGroup/adGroup";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Table from "../../../ui-lib/components/Tables/Table";
import { IADGroup } from "../../../core/api/adGroup/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import { SubRow } from "../../../ui-lib/components/Tables/SubRow";
import BulkDeleteModal from "../../../components/BulkDeleteModal";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../ui-lib/components/Tables/TableRowSelectionPopup";
import Button from "../../../ui-lib/components/Button/Button";
import { RoleMapping } from "../../../core/api/administrators/types";
import {
  findNodeById,
  findRoot,
  transformOrganizationsToTreeData,
} from "../../../core/utils/organizations";
import { NestedOrganizationProps } from "../../../core/api/organizations/organizations";
import ChildrenTreeModal from "../../../ui-lib/components/ChildrenTreePopup/childrenTreeModal";
import { RoleMappingCell } from "../../../ui-lib/components/Tables/RoleMappingCell";

interface RowData {
  [key: string]: string | RoleMapping[];
}

let openHierarchyTree: Function;

export const ADManagementTable = ({
  organizationId,
  organizations,
  setShowAddGroupModal,
  setGroupToEdit,
  isRolesLoading,
  shouldUpdateTable,
}: {
  organizationId: string;
  organizations?: NestedOrganizationProps[];
  setShowAddGroupModal: Dispatch<boolean>;
  setGroupToEdit: Dispatch<IADGroup>;
  isRolesLoading: boolean;
  shouldUpdateTable: number;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [groupMappingData, setGroupMappingData] = useState<IADGroup[]>([]);
  const selectedItemRows = useRef<RowData[]>([]);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const tableRowSelectionPopupRef =
    useRef<TableRowSelectionPopupActions<Record<string, any>>>(null);
  const organizationsTree = transformOrganizationsToTreeData(organizations);
  const [isChildrenTreeOpen, setIsChildrenTreeOpen] = useState<boolean>(false);

  const fetchGroupData = async () => {
    setIsLoading(true);
    setGroupMappingData([]);
    try {
      const groupData = await getGroupMapping(
        organizationId,
        authenticatedRequest
      );
      setGroupMappingData(groupData.data);
    } catch (errors: any) {
      notifyApiErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const openEditADGroup = (data: IADGroup) => {
    setShowAddGroupModal(true);
    setGroupToEdit(data);
  };

  useEffect(() => {
    fetchGroupData();
  }, [shouldUpdateTable]);

  const childrenTreePopupRef = useRef<RowData>();

  openHierarchyTree = (role: RoleMapping) => {
    const organization = findNodeById(organizationsTree, role.belongsToId);
    childrenTreePopupRef.current = {
      id: organization?.key as string,
      value: organization?.label as string,
      children: organization?.children as any,
      root: organization?.parentId
        ? (findRoot(organization?.parentId!, organizationsTree) as any)
        : null,
    };
    setIsChildrenTreeOpen(true);
  };

  return (
    <>
      <BulkDeleteModal
        isOpen={deletionModalOpen}
        onClose={() => setDeletionModalOpen(false)}
        onDelete={() => {
          setDeletionModalOpen(false);
          tableRowSelectionPopupRef.current?.close(true);
          setIsLoading(true);
          fetchGroupData();
          selectedItemRows.current = [];
        }}
        // @ts-ignore
        ids={selectedItemRows.current?.map((g: IADGroup) => g.adGroupId)}
        labels={{
          single: t("Organizations:ad_type"),
          multi: t("Organizations:ad_type_multi"),
        }}
        apiFunc={async (id, authRequest) =>
          deleteADGroup(organizationId, id, authRequest)
        }
      />
      <TableRowSelectionPopup
        ref={tableRowSelectionPopupRef}
        renderBody={(selectedItems) => (
          <Row type="right" align="center">
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                selectedItemRows.current = selectedItems as RowData[];
                setDeletionModalOpen(true);
              }}
            />
          </Row>
        )}
      />

      <ChildrenTreeModal
        organizations={childrenTreePopupRef.current as any}
        isOpen={isChildrenTreeOpen}
        onClose={() => {
          setIsChildrenTreeOpen(false);
        }}
      />

      {isLoading || isRolesLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <Table<IADGroup>
          paginatedItems={{
            items: groupMappingData || [],
            pagination: {
              offset: 0,
              limit: (groupMappingData || []).length,
              total: (groupMappingData || []).length,
            },
          }}
          withShowMore
          columns={[
            {
              header: t("Common:name"),
              fieldTemplate: (rowData) => (
                <TableCell value={rowData.adGroupName} />
              ),
            },
            {
              header: t("Organizations:group_object_id"),
              fieldTemplate: (rowData) => (
                <TableCell value={rowData.adGroupId} />
              ),
            },
            {
              header: t("Common:role_template"),
              fieldTemplate: ({ roleMappings: Roles }) =>
                Roles.map((role, i) => (
                  <SubRow
                    key={role.roleTemplateId + 1}
                    displayDivider={i + 1 !== Roles.length}
                    value={role.roleTemplateName}
                    leftIconTooltip={
                      role.type === "respondent"
                        ? t(`Common:respondent`)
                        : t("Common:admin")
                    }
                    icon={
                      role.type === "respondent" ? "phone-incoming" : "user"
                    }
                  />
                )),
            },
            {
              header: t("Organizations:applies_to"),
              fieldTemplate: ({ roleMappings: Roles }) =>
                Roles.map((role, i) => (
                  <RoleMappingCell
                    key={`${role.roleTemplateId}-${role.belongsToId}`}
                    role={role}
                    index={i}
                    rolesLength={Roles.length}
                    openHierarchyTree={openHierarchyTree}
                  />
                )),
            },
          ]}
          rowActions={[
            {
              text: t("Common:label_edit"),
              icon: "pencil-alt",
              onClick: openEditADGroup,
            },
            {
              text: t("Common:delete"),
              icon: "trash",
              iconVariant: "secondary",
              onClick: (rowData) => {
                selectedItemRows.current = [
                  {
                    adGroupId: rowData.adGroupId,
                  } as RowData,
                ];
                setDeletionModalOpen(true);
              },
            },
          ]}
          onRowClick={openEditADGroup}
          items={groupMappingData}
          withRowSelection
          hideEmptyMessage
          noRowsMessage={t("Organizations:no_ad_groups")}
          isLoading={isLoading}
          showRowHover
          onRowSelectionChange={(selectedItems) => {
            tableRowSelectionPopupRef.current?.open(selectedItems);
          }}
        />
      )}
    </>
  );
};
