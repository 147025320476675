import { useEffect, useRef, useState } from "react";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";
import useTranslations from "../../../core/i18n/useTranslations";
import Button from "../../../ui-lib/components/Button/Button";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import { EditIPFilter, EditIPFilterDialogActions } from "./EditIPFilter";
import useUser from "../../../core/user/useUser";
import {
  editAlarmReceptionIPWhiteList,
  getAlarmReceptionIPWhiteList,
} from "../../../core/api/responsecenters/responsecenters";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteIPWhiteListModal } from "./DeleteIPWhiteListModal";
import { IPFilter, IpWhiteList } from "../../../core/api/organizations/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";

const IPWhiteList = ({ id }: { id: number }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const isAlive = useRef(false);
  const [ipWhiteList, setIPWhiteList] = useState<IpWhiteList>({
    currentIP: "",
    filters: [],
  });
  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<IPFilter>>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [upForDelete, setUpForDelete] = useState<IPFilter[]>([]);

  const fetchIPWhiteList = async () => {
    try {
      const result = await getAlarmReceptionIPWhiteList(
        id,
        authenticatedRequest
      );

      if (isAlive.current) {
        setIPWhiteList({
          ...result.data,
          filters: (result.data.filters || []).map((filter) => ({
            ...filter,
          })),
        });
      }
    } catch (error) {
      if (isAlive.current) {
        notify({ message: t("Errors:error_fetching_data"), variant: "error" });
      }
    }
  };
  const editIPWhiteList = async (whitelist: IpWhiteList) => {
    try {
      await editAlarmReceptionIPWhiteList(id, whitelist, authenticatedRequest);
    } catch (error: any) {
      if (isAlive.current) {
        notifyApiErrors(error.response?.data?.errors);
      }
    } finally {
      await fetchIPWhiteList();
    }
  };
  useEffect(() => {
    fetchIPWhiteList();
  }, [id]);

  useEffect(() => {
    isAlive.current = true;
    fetchIPWhiteList();
    return () => {
      isAlive.current = false;
    };
  }, []);

  const editIPFiltersRef = useRef<EditIPFilterDialogActions>(null);

  return (
    <>
      <Row type="space" align="center" className="w-75">
        <h2>
          {t("Organizations:current_ip")}{" "}
          <span style={{ color: "var(--Primary-700)" }}>
            {ipWhiteList?.currentIP || ""}
          </span>
        </h2>
        <EditIPFilter
          ref={editIPFiltersRef}
          editIPWhiteList={editIPWhiteList}
          modalTitle={t("AlarmReceptions:edit_whitelist_title")}
          saveButtonTitle={t("AlarmReceptions:save_integration")}
        />
        <Button
          variant="secondary"
          text={t("AlarmReceptions:add_new_ipfilter")}
          onClick={() => {
            editIPFiltersRef.current?.open(-1, ipWhiteList, false);
          }}
        />
      </Row>
      <Spacer size={16} />
      <Column type="top" align="start" className="w-75">
        <Table<IPFilter>
          columns={[
            {
              header: t("AlarmReceptions:advanced_settings_ipfilters_startip"),
              fieldTemplate: (rowData) => <TableCell value={rowData.startIP} />,
            },
            {
              header: t("AlarmReceptions:advanced_settings_ipfilters_endip"),
              fieldTemplate: (rowData) => <TableCell value={rowData.endIP} />,
            },
            {
              header: t("AlarmReceptions:advanced_settings_ipfilters_comment"),
              fieldTemplate: (rowData) => <TableCell value={rowData.comment} />,
            },
            {
              header: t("AlarmReceptions:edit_ipfilter_admin_label"),
              fieldTemplate: (rowData, rowIndex) => (
                <Column align="start">
                  <label
                    htmlFor={`ipfilters_admin_${rowIndex}`}
                    className="visuallyHidden"
                  >
                    {t("AlarmReceptions:edit_ipfilter_admin_label")}
                  </label>
                  <Checkbox
                    checked={rowData.adminApi}
                    disabled
                    id={`ipfilters_admin_${rowIndex}`}
                  />
                </Column>
              ),
            },
            {
              header: t("AlarmReceptions:advanced_settings_ipfilters_central"),
              fieldTemplate: (rowData, rowIndex) => (
                <Column align="start">
                  <label
                    htmlFor={`ipfilters_central_${rowIndex}`}
                    className="visuallyHidden"
                  >
                    {t("AlarmReceptions:advanced_settings_ipfilters_central")}
                  </label>
                  <Checkbox
                    checked={rowData.central}
                    disabled
                    id={`ipfilters_central_${rowIndex}`}
                  />
                </Column>
              ),
            },
          ]}
          // items={ipWhiteList?.Filters?.map((filter,index)=>{})}
          items={ipWhiteList?.filters}
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (rowData, index) => {
                if (index !== undefined && index > -1) {
                  editIPFiltersRef.current?.open(index, ipWhiteList);
                }
              },
            },
            {
              icon: "trash",
              text: t("Common:delete"),
              iconVariant: "secondary",
              onClick: (rowData) => {
                setDeleteOpen(true);
                setUpForDelete([rowData]);
              },
            },
          ]}
          hideEmptyMessage
          noRowsMessage={t("Table:no_results_found")}
          showRowHover
          withShowMore
          withRowSelection
          onRowSelectionChange={(selectedItems) => {
            const filtered = selectedItems.filter(
              (i) => !!ipWhiteList?.filters?.find((row) => row.id === i.id)
            );
            selectionPopupRef.current?.open(filtered);
          }}
        />
      </Column>

      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setUpForDelete(selectedItems);
                setDeleteOpen(true);
              }}
            />
          </Row>
        )}
      />

      <DeleteIPWhiteListModal
        isOpen={deleteOpen}
        itemsToDelete={upForDelete}
        onClose={() => setDeleteOpen(false)}
        onDelete={async () => {
          await fetchIPWhiteList();
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
        }}
        objectId={id}
        ipWhiteList={ipWhiteList}
      />
    </>
  );
};
export default IPWhiteList;
