import { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import BulkDeleteModal from "../../components/BulkDeleteModal";
import {
  CameraEditForm,
  EditCameraActions,
} from "../../components/Cameras/CameraEditForm";
import { Column, Spacer } from "../../components/Layout/Layout";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import { deleteCamera, getCamera } from "../../core/api/cameras/cameras";
import { EditCameraModel } from "../../core/api/cameras/types";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { useStoredTableState } from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { PAGE_ID as parentId } from "./Cameras";

import styles from "../PagesLayout.module.css";
import { OverlayPanel } from "primereact/overlaypanel";

const parentPath = "/adminportal/cameras";

const CameraDetails = () => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const history = useHistory();
  const user = useUser();
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const { data: organisationTree } = useOrganizationsTree();
  const { setSubNav } = useSubNav();
  const editCameraRef = useRef<EditCameraActions>(null);
  const [camera, setCamera] = useState<EditCameraModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const { isDirty } = useSaveObject();
  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  const contextMenuRef = useRef<OverlayPanel>(null);

  const loadData = async () => {
    const trans = await getCamera(id, authenticatedRequest);
    setCamera(trans.data);
  };

  useEffect(() => {
    loadData();
    const nav = {
      menu: [
        {
          key: "Menu:Camera_details",
          to: `${url}`,
        },
      ],
      backUrl,
      backText: t("Menu:Cameras"),
    };
    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  if (!camera) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      <PageHeader title={camera?.title} icon="video-camera">
        <Button
          text={t("Common:save")}
          onClick={async () => {
            setIsLoading(true);
            const result = await editCameraRef.current?.submitForm();
            if (result) {
              await loadData();
            }
            setIsLoading(false);
          }}
          loading={isLoading}
          disabled={!isDirty}
        />

        {user.config?.show.includes("DeleteCameras") && (
          <>
            <Button
              className="ml-8"
              type="button"
              variant="secondary"
              image="dots-horizontal"
              text={t("Common:other")}
              onClick={(e) => contextMenuRef.current?.toggle(e)}
            />
            <OverlayPanel ref={contextMenuRef} className="actions-overlay">
              <PopupContextMenuLinkButton
                icon="trash"
                text={t("Common:delete")}
                onClick={() => {
                  setDeletionModalOpen(true);
                }}
              />

              <BulkDeleteModal
                isOpen={deletionModalOpen}
                onClose={() => {
                  setDeletionModalOpen(false);
                }}
                onDelete={(status: boolean) => {
                  if (status) {
                    history.replace(backUrl);
                  }
                }}
                labels={{
                  single: t("Cameras:type"),
                  multi: t("Cameras:type_multi"),
                }}
                ids={[id]}
                apiFunc={deleteCamera}
              />
            </OverlayPanel>
          </>
        )}
      </PageHeader>
      <Column className={styles.content} type="top" align="start">
        <Column style={{ width: "784px" }} type="top" align="start">
          <h1>{t("Cameras:details_page_title")}</h1>
          <Spacer size={8} />
          <p>{t("Cameras:details_page_description")}</p>
          <Spacer size={32} />
          <h2>{t("Cameras:basic_details_title")}</h2>
          <p>{t("Cameras:basic_details_description")}</p>
          <Spacer size={16} />
          <CameraEditForm
            ref={editCameraRef}
            camera={camera}
            organisationTree={organisationTree}
          />
        </Column>
      </Column>
    </>
  );
};

const CameraDetailsContainer = () => (
  <SaveObjectProvider>
    <CameraDetails />
  </SaveObjectProvider>
);
export default CameraDetailsContainer;
