import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAdminRoles } from "../../core/api/administrators/administrators";
import {
  AdministratorMeta,
  AdministratorView,
} from "../../core/api/administrators/types";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import { AdministratorsTable } from "../Administrators/AdministratorsTable";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { DeleteAdministratorModal } from "../Administrators/AdministratorDetails/DeleteAdministratorModal";
import Modal, { ModalActions } from "../../ui-lib/components/Popup/Modal";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { findNodeById } from "../../core/utils/organizations";
import { AddAdministrator } from "../Administrators/AddAdministrator/AddAdministrator";
import { notifyApiErrors } from "../../core/helpers/helpers";

import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import useTranslations from "../../core/i18n/useTranslations";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import useUser from "../../core/user/useUser";

import styles from "../PagesLayout.module.css";

const OrganizationAdmins = ({ organisationId }: { organisationId: number }) => {
  const t = useTranslations();
  const history = useHistory();
  const { authenticatedRequest } = useUser();
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter();
  const [tableSettings, setTableSettings] = useQueryState<{ q: string }>({
    q: "",
  });
  const { data: organisations, isLoading: orgsLoading } =
    useOrganizationsTree(organisationId);
  const organizationsById = findNodeById(organisations, organisationId);
  const organisationsData = organizationsById?.children
    ? [organizationsById, ...organizationsById.children]
    : [];

  const deleteModalRef = useRef<ModalActions>(null);

  const [selected, setSelected] = useState<AdministratorMeta>();
  const [searchParam, setSearchParam] = useState<string>("");
  const [tableData, setTableData] = useState<AdministratorView>();
  const [isLoading, setIsLoading] = useState(true);

  const getAdmins = async () => {
    setIsLoading(true);
    try {
      const result = await getAdminRoles(
        {
          page: pageFilter.page,
          pageSize: pageFilter.pageSize,
          q: tableSettings.q,
          organization: organisationId,
        },
        authenticatedRequest
      );
      setTableData(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAdmins();
  }, [tableSettings.q, pageFilter.page, pageFilter.pageSize]);

  return (
    <>
      <Row type="space" className="w-100 flex0">
        <div>
          <h1>{t("Menu:administrators")}</h1>
          <Spacer size={8} />
          <p>{t("Organizations:organisation_admin_description")}</p>
        </div>
        <AddAdministrator
          variant="secondary"
          image="user"
          organisations={organisations}
          isLoading={orgsLoading}
          organizationsById={organizationsById}
          organizationId={organisationId}
          onSubmit={(adminId: number) => {
            history.push(`/adminportal/administrators/${adminId}`);
          }}
        />
      </Row>
      <Spacer size={24} />
      <Row className={styles.settingsRow}>
        <SearchInput
          placeholder={t("Administrator:administrators_search_placeholder")}
          value={searchParam ?? ""}
          limit={1}
          onChange={(value) => {
            setSearchParam(value);
            setTableSettings((prev) => ({ ...prev, q: value }));
            resetPageFilter();
          }}
        />
      </Row>
      <Spacer size={16} />
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <Column className={styles.tableContainer} type="top">
          <AdministratorsTable
            data={tableData?.admins || []}
            totalRoleAmount={tableData?.totalRoleAmount}
            pageSettings={pageFilter}
            onPageSettingsChange={setPageFilter}
            organisations={organisationsData}
            onStartDelete={(admin: AdministratorMeta) => {
              setSelected(admin);
              deleteModalRef?.current?.open();
            }}
            subPage={true}
          />
        </Column>
      )}

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteAdministratorModal
            administratorId={
              (!!selected?.identityId && selected?.identityId) ||
              (!!selected?.roles[0].id && selected?.roles[0].id) ||
              ""
            }
            identity={!!selected?.identityId}
            onClose={close}
            onDelete={() => {
              close();
              getAdmins();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default OrganizationAdmins;
