/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  FormProvider,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { Column, Spacer } from "../../../components/Layout/Layout";
import { Role, RoleFeature } from "../../../components/RoleFeature";
import { ResponseCenterDetails } from "../../../core/api/responsecenters/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import AccordionBlock from "../../../ui-lib/components/Accordion/AccordionBlock";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import useSharedAlarmReception from "../AlarmReceptionDetails/SharedAlarmReceptionContext/useSharedAlarmReception";
import { AdvancedSettings } from "./AdvancedSettings";
import { MenuAlternativesController } from "../../../components/MenuAlternatives/MenuAlternativesController";
import IPWhiteList from "./IPWhiteList";

import styles from "../../PagesLayout.module.css";

export interface FormMethods<T extends FieldValues> {
  control: Control<T>;
  reset: UseFormReset<T>;
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  errors: DeepMap<ResponseCenterDetails, FieldError>;
  isDirty?: boolean;
}

const TechnicalSettings = () => {
  const t = useTranslations();
  const formRef = useObjectFormRef();
  const { data, update } = useSharedAlarmReception();
  const defaultValues = { ...data };
  const methods = useObjectForm<ResponseCenterDetails>({
    defaultValues,
    shouldUnregister: false,
  });
  const { handleSubmit, reset } = methods;

  if (!data) {
    notify({
      message: "Settings not found",
      variant: "error",
    });
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        className="w-100"
        onSubmit={handleSubmit(async (values) => {
          if (!values.minutesOfAlarmHistory) {
            values.minutesOfAlarmHistory = undefined;
          }
          if (!values.minutesOfUserAlarmHistory) {
            values.minutesOfUserAlarmHistory = undefined;
          }
          const parsed = _.omit(values, "organizationId");
          try {
            await update(parsed);

            notify({
              message: t("AlarmReceptions:edited_success"),
            });
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          }
          reset(values);
        })}
      >
        <Column className={styles.wrapper} type="top" align="start">
          <h1>{t("AlarmReceptions:technical_settings_page_title")}</h1>
          <Spacer size={8} />
          <p>{t("AlarmReceptions:technical_settings_page_description")}</p>

          <RoleFeature requires={[Role.EditAlarmReceptionAdvancedSettings]}>
            <Spacer size={32} />
            <AccordionBlock
              title={t(
                "AlarmReceptions:technical_settings_advanced_settings_title"
              )}
              description={t(
                "AlarmReceptions:technical_settings_advanced_settings_description"
              )}
            >
              <AdvancedSettings id={data.id} />
            </AccordionBlock>
          </RoleFeature>
        </Column>
      </form>
      <RoleFeature requires={[Role.ApplicationMenuAlternatives]}>
        <Spacer size={32} />
        <MenuAlternativesController
          ownerId={data.id}
          alternativesType="responseCenterId"
          accordionTitle={t(
            "AlarmReceptions:technical_settings_alternatives_title"
          )}
          accordionDescription={t(
            "AlarmReceptions:menu_alternatives_description_arc"
          )}
          renderInAccordion
          isMobile
        />
      </RoleFeature>
      <RoleFeature requires={[Role.EditIPWhiteList]}>
        <Spacer size={32} />
        <AccordionBlock
          title={t("AlarmReceptions:technical_settings_whitelist_title")}
          description={t(
            "AlarmReceptions:technical_settings_whitelist_description"
          )}
        >
          <IPWhiteList id={data.id} />
        </AccordionBlock>
      </RoleFeature>
    </FormProvider>
  );
};

export default TechnicalSettings;
