import { AxiosInstance } from "axios";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import { AddressDetails, AddressResponse } from "./types";

const useAddresses = (objectId: string) =>
  useAuthenticatedFetch<AddressResponse[]>(
    `/api/v2/users/${objectId}/addresses`
  );

const useAddressDetails = (objectId: string, addressId: number) =>
  useAuthenticatedFetch<AddressDetails>(
    `/api/v2/users/${objectId}/addresses/${addressId}`
  );

const getAddresses = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<AddressResponse[]>(
    `/api/v2/users/${objectId}/addresses`
  );

const addAddress = async (
  objectId: string,
  addressDetails: AddressDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<{}>(
    `/api/v2/users/${objectId}/addresses`,
    addressDetails
  );

const editAddress = async (
  objectId: string,
  addressId: number,
  addressDetails: AddressDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<{}>(
    `/api/v2/users/${objectId}/addresses/${addressId}`,
    addressDetails
  );

const deleteAddress = async (
  objectId: string,
  addressId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<{}>(
    `/api/v2/users/${objectId}/addresses/${addressId}`
  );

export {
  useAddresses,
  useAddressDetails,
  getAddresses,
  addAddress,
  editAddress,
  deleteAddress,
};
