import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notifyApiErrors } from "../../core/helpers/helpers";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { IAlternatives } from "../../core/api/alternatives/types";
import { updateAlternative } from "../../core/api/alternatives/alternatives";
import { Controller, useForm } from "react-hook-form";
import { Row, Spacer } from "../Layout/Layout";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import RadioGroup from "../../ui-lib/components/Radio/RadioGroup";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const EditAlternativeModal = ({
  alternativeData,
  alternativesType,
  ownerId,
  onSubmitEnd,
  closeModal,
  organisations,
  isMobile = false,
  isOpen,
  withOrganizationsSelection,
  bothAppLinksRequired,
}: {
  alternativeData?: IAlternatives;
  ownerId: number;
  alternativesType:
    | "adminId"
    | "organizationId"
    | "responseCenterId"
    | "sellerId";
  onSubmitEnd: () => void;
  closeModal: () => void;
  organisations: ITreeData[];
  isMobile?: boolean;
  isOpen: boolean;
  withOrganizationsSelection: boolean;
  bothAppLinksRequired: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const linkFor = isMobile ? "organization" : "admin";

  const {
    control,
    formState: { isSubmitting, errors, isDirty },
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm<IAlternatives>({
    defaultValues: alternativeData || {
      order: 0,
      openType: linkFor === "admin" ? 0 : 1,
    },
  });

  const radioItems =
    linkFor === "admin"
      ? [0, 1, 2].map((i) => ({
          id: i,
          value: `${i}`,
          text: t(`Administrator:link_open_type_${i}`),
        }))
      : [1, 2].map((i) => ({
          id: i,
          value: `${i}`,
          text: t(`Administrator:application_open_type_${i}`),
        }));

  const isIosLinkPresent = !!watch("iosLink");
  const isAndroidLinkPresent = !!watch("androidLink");

  const labels = {
    title: t(`Administrator:alternative_${alternativeData ? "edit" : "add"}`),
    description: t(
      `Administrator:alternative_${
        alternativeData ? "edit" : "add"
      }_description`
    ),
    submit: t(alternativeData ? "Common:save" : "Administrator:create_link"),
  };

  const prepareDataToUpdate = (values: IAlternatives): any => {
    const updatedValues = { ...values };
    if (!updatedValues[alternativesType]) {
      updatedValues[alternativesType] = ownerId;
    }
    if (updatedValues.url) {
      updatedValues.showInAdmin = true;
      updatedValues.showInAlarmApp = false;
      updatedValues.iosLink = "";
      updatedValues.androidLink = "";

      if (updatedValues.url.split("//").length === 1) {
        updatedValues.url = `http://${updatedValues.url}`;
      }
    } else {
      updatedValues.showInAdmin = false;
      if (alternativesType === "responseCenterId") {
        updatedValues.showInResponseApp = true;
      } else {
        updatedValues.showInAlarmApp = true;
      }
      updatedValues.url = "";
    }
    if (alternativesType === "organizationId") {
      updatedValues.requireAction = "Users";
    }

    return updatedValues;
  };

  const onSubmit = async (values: IAlternatives) => {
    try {
      if (alternativeData) {
        await updateAlternative(values, authenticatedRequest);
      } else {
        const updatedValues = prepareDataToUpdate(values);
        await updateAlternative(updatedValues, authenticatedRequest);
      }
      notify({
        message: t(
          `Messages:${alternativeData ? "edit" : "add"}_alternative_success`
        ),
      });
      closeModal();
      onSubmitEnd();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={isOpen}
      onClose={closeModal}
      header={labels.title}
      loading={isSubmitting}
      submitBtn={{
        text: labels.submit,
        onClick: handleSubmit(onSubmit),
        disabled: !isDirty,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
      contentStyle={{ width: "848px" }}
    >
      <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>

      <Spacer size={32} />

      <form id="address-modal-form">
        <Row type="fill" align="fill">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                required
                label={t("Common:labels_title")}
                value={field.value}
                onChange={field.onChange}
                validationError={errors.title && t("Common:validation_title")}
              />
            )}
          />
          {withOrganizationsSelection && (
            <>
              <Spacer size={32} />
              <Controller
                name="organizationId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Tree
                    required
                    label={t("Common:belongs_to")}
                    placeholder={t("Common:select_organisation")}
                    items={organisations}
                    selectedTreeItem={field.value}
                    onSelectItem={(selected?: ITreeData) => {
                      setValue(field.name, selected?.key as number, {
                        shouldDirty: true,
                      });
                      trigger(field.name);
                    }}
                    showClearOption={false}
                    validationError={
                      errors?.organizationId && t("Errors:input_field_required")
                    }
                    style={{ width: "376px" }}
                  />
                )}
              />
            </>
          )}
        </Row>

        <Spacer size={16} />

        {linkFor === "admin" && (
          <Row align="start">
            <Controller
              name="url"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  required
                  label={t("Common:url")}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={errors.url && t("Common:validation_URL")}
                  placeholder={t("Common:url_placeholder")}
                />
              )}
            />
          </Row>
        )}

        {linkFor === "organization" && (
          <>
            <Row align="start">
              <Controller
                name="iosLink"
                control={control}
                rules={{
                  required: bothAppLinksRequired || !isAndroidLinkPresent,
                }}
                render={({ field }) => (
                  <TextInput
                    required={bothAppLinksRequired || !isAndroidLinkPresent}
                    label={t("Common:ios_url")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.iosLink &&
                      (bothAppLinksRequired
                        ? t("Common:validation_URL")
                        : t("Errors:android_or_ios_required"))
                    }
                    placeholder={t("Common:url_placeholder")}
                  />
                )}
              />
            </Row>

            <Spacer size={16} />

            <Row align="start">
              <Controller
                name="androidLink"
                control={control}
                rules={{ required: bothAppLinksRequired || !isIosLinkPresent }}
                render={({ field }) => (
                  <TextInput
                    required={bothAppLinksRequired || !isIosLinkPresent}
                    label={t("Common:android_url")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.androidLink &&
                      (bothAppLinksRequired
                        ? t("Common:validation_URL")
                        : t("Errors:android_or_ios_required"))
                    }
                    placeholder={t("Common:url_placeholder")}
                  />
                )}
              />
            </Row>
          </>
        )}

        <Spacer size={16} />

        <Row align="start">
          <Controller
            name="openType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputContainer
                required
                label={
                  <label htmlFor="open-type">
                    {t("AlarmReceptions:edit_menualternative_openin_label")}
                  </label>
                }
                input={
                  <RadioGroup
                    inputRef={field.ref}
                    name="open-type"
                    items={radioItems}
                    selectedValue={
                      radioItems.find(
                        (item) => item.id === getValues("openType")
                      )?.value
                    }
                    onChange={(value) => {
                      field.onChange(value);
                      setValue("openType", +value);
                      trigger("openType");
                    }}
                    fullWidth
                  />
                }
                validationError={
                  errors.openType && t("Common:validation_OpenIn")
                }
              />
            )}
          />
        </Row>
      </form>
    </PrimeModal>
  );
};
