import moment from "moment";
import { Dictionary } from "../../core/interfaces/Dictionary";
import User from "../../core/user/User";
import { NavigationItem } from "../../ui-lib/components/Menus/SideBar/SideBar";
import ContactInfo from "../Navigation/ContactInfo/ContactInfo";
import useTranslations from "../../core/i18n/useTranslations";
import useLanguage from "../../core/i18n/useLanguage";
import { isOrganizationVisible } from "../../ui-lib/utils/organizationsHelper";
import { setUserLangData } from "../../ui-lib/utils/localeHelper";

const useMenu = (user: User): Dictionary<NavigationItem> => {
  const t = useTranslations();
  const show = user.config?.show ?? [];
  const languageStore = useLanguage();
  const changeSelectedLanguage = (id: string) => {
    moment.locale(id.split("-")[0].toLowerCase());
    languageStore.setCurrentLanguageById(id);
    setUserLangData(user, languageStore.lang);
  };
  const menu: Dictionary<NavigationItem> = {};
  const hasShowObjectDetailsVariant1Permission = show.includes(
    "ShowObjectDetailsVariant1"
  );
  const viserPermission = show.includes("SensioHideFields");

  // Section 1 start
  menu["/adminportal"] = {
    id: 1,
    type: "link",
    showTooltip: true,
    to: "/adminportal",
    text: t("Menu:home"),
    icon: "home",
    menu: "main",
  };
  if (show.includes("Alarms")) {
    menu["/adminportal/alarms"] = {
      id: 2,
      type: "link",
      showTooltip: true,
      to: "/adminportal/alarms",
      text: t("Menu:alarmhistory"),
      icon: "status-online",
      menu: "main",
    };
  }
  if (show.find((key) => key === "M:Users/List")) {
    menu["/adminportal/objects"] = {
      id: 3,
      type: "link",
      showTooltip: true,
      to: "/adminportal/objects",
      matchProps: {
        path: "/adminportal/objects",
        exact: true,
        strict: false,
      },
      text: t("Menu:objects"),
      icon: "cube",
      menu: "main",
    };
  }
  if (show.find((key) => key === "M:Search/Reports") && !viserPermission) {
    menu["/adminportal/reports"] = {
      id: 4,
      type: "link",
      showTooltip: true,
      to: "/adminportal/reports",
      text: t("Menu:reports"),
      subtitle: t("Menu:Reports_subtitle"),
      icon: "clipboard-list",
      menu: "main",
    };
  }
  // Section 1 end

  menu.divider5 = {
    id: 5,
    type: "divider",
    menu: "main",
  };

  // Section 2 start
  let showProductDivider = false;
  if (
    show.find((key) => key === "M:Products/Transmitters") &&
    !viserPermission
  ) {
    menu["/adminportal/transmitters"] = {
      id: 6,
      type: "link",
      showTooltip: true,
      to: "/adminportal/transmitters",
      matchProps: {
        path: "/adminportal/transmitters",
        exact: true,
        strict: false,
      },
      text: t("Menu:transmitter"),
      icon: "rss",
      menu: "main",
    };
    showProductDivider = true;
  }
  if (show.find((key) => key === "M:Products/Cameras") && !viserPermission) {
    menu["/adminportal/cameras"] = {
      id: 7,
      type: "link",
      showTooltip: true,
      to: "/adminportal/cameras",
      matchProps: {
        path: "/adminportal/cameras",
        exact: true,
        strict: false,
      },
      text: t("Menu:cameras"),
      icon: "video-camera",
      menu: "main",
    };
    showProductDivider = true;
  }
  if (show.find((key) => key === "M:Products/KnownLocations")) {
    menu["/adminportal/locations"] = {
      id: 8,
      type: "link",
      showTooltip: true,
      to: "/adminportal/locations",
      matchProps: {
        path: "/adminportal/locations",
        exact: true,
        strict: false,
      },
      text: t("Menu:locations"),
      icon: "location-marker",
      menu: "main",
    };
    showProductDivider = true;
  }
  // Section 2 end

  if (showProductDivider) {
    menu.divider9 = {
      id: 9,
      type: "divider",
      menu: "main",
    };
  }

  // Section 3 start
  if (
    (show.find((key) => key === "SkygdSuperAdmin") ||
      user.data?.type === "seller") &&
    !hasShowObjectDetailsVariant1Permission &&
    !viserPermission
  ) {
    menu["/adminportal/resellers"] = {
      id: 22,
      type: "link",
      showTooltip: true,
      to: "/adminportal/resellers",
      matchProps: {
        path: "/adminportal/resellers",
        exact: true,
        strict: false,
      },
      text: t("Common:sellers"),
      icon: "star",
      menu: "main",
    };
  }
  if (
    isOrganizationVisible(user) &&
    !hasShowObjectDetailsVariant1Permission &&
    !viserPermission
  ) {
    menu["/adminportal/organizations"] = {
      id: 10,
      type: "link",
      showTooltip: true,
      to: "/adminportal/organizations",
      text: t("Menu:organizations"),
      icon: "office-building",
      menu: "main",
    };
  }
  if (
    show.find((key) => key === "M:Configure/Adminstrators") &&
    !hasShowObjectDetailsVariant1Permission
  ) {
    menu["/adminportal/administrators"] = {
      id: 11,
      type: "link",
      showTooltip: true,
      to: "/adminportal/administrators",
      matchProps: {
        path: "/adminportal/administrators",
        exact: true,
        strict: false,
      },
      text: t("Menu:administrators"),
      icon: "user",
      menu: "main",
    };
  }
  if (
    show.find((key) => key === "M:Configure/AlarmReception") &&
    !hasShowObjectDetailsVariant1Permission
  ) {
    menu["/adminportal/alarm-receptions"] = {
      id: 12,
      type: "link",
      showTooltip: true,
      to: "/adminportal/alarm-receptions",
      matchProps: {
        path: "/adminportal/alarm-receptions",
        exact: true,
        strict: false,
      },
      text: t("Menu:alarmreceptions"),
      icon: "alarm-reception",
      menu: "main",
    };
  }
  if (
    show.find((key) => key === "M:Configure/CommonContacts") &&
    !hasShowObjectDetailsVariant1Permission &&
    !viserPermission
  ) {
    menu["/adminportal/common-contacts"] = {
      id: 13,
      type: "link",
      showTooltip: true,
      to: "/adminportal/common-contacts",
      text: t("Menu:commoncontacts"),
      icon: "phone",
      menu: "main",
    };
  }
  // Section 3 end

  // Footer start
  menu.divider14 = {
    id: 14,
    type: "divider",
    menu: "footer",
  };
  if (user?.config && user.config.menuAlternatives?.length > 0) {
    menu.item23 = {
      id: 23,
      type: "context",
      showTooltip: true,
      text: t("Menu:links"),
      icon: "link",
      menu: "footer",
      className: "linksWrapper",
      submenuItems: user.config.menuAlternatives
        .toSorted((l1, l2) => (l1.order ?? -1) - (l2.order ?? -1))
        .map((link) => {
          let iconName: string;
          switch (link.openType) {
            case 0:
              iconName = "external-link";
              break;
            case 1:
              iconName = "new-window";
              break;
            default:
              iconName = "";
              break;
          }
          if (link.url?.startsWith("/System")) {
            return {
              id: 100 + (link.id ?? 0),
              type: "context_action",
              showTooltip: false,
              action: () =>
                window.open(
                  `${process.env.REACT_APP_SYSTEM_URL}${link.url}`,
                  "_blank",
                  "width=600,height=600"
                ),
              text: link.title,
              icon: "external-link",
            } as NavigationItem;
          }
          return {
            id: 100 + (link.id ?? 0),
            type: "context_action",
            showTooltip: false,
            to: link.url,
            text: link.title,
            icon: iconName,
            action: () => {
              let actionToReturn: any;
              switch (link.openType) {
                case 0:
                  actionToReturn = window.open(link.url, "_blank");
                  break;
                case 1:
                  actionToReturn = window.open(
                    link.url,
                    "_blank",
                    "width=600,height=600"
                  );
                  break;
                case 2:
                  actionToReturn = window.open(link.url, "_self");
                  break;
                default:
              }
              return actionToReturn;
            },
          } as NavigationItem;
        }),
    };
  }
  menu.item15 = {
    id: 15,
    type: "context_popup_action",
    modal: true,
    showTooltip: true,
    text: t("Menu:support"),
    icon: "support",
    menu: "footer",
    content: ContactInfo,
    title: t("Home:contact_info"),
    cancelButtonText: t("Common:close"),
  };
  menu.item18 = {
    id: 18,
    type: "context",
    showTooltip: true,
    text: user.impersonatedFrom ?? user.data?.name ?? t("Menu:user"),
    fillColor: " var(--Primary-600)",
    menu: "footer",
    truncateText: true,
    submenuItems: [
      {
        id: 20,
        type: "context_action",
        showTooltip: false,
        action: () => user.logOut(),
        text: t("Menu:logout"),
        icon: "logout",
      },
    ],
  };
  // while impersonated user there should be no link to admin profile
  if (!user.impersonatedFrom) {
    menu.item18.submenuItems?.unshift({
      id: 19,
      type: "context_link",
      showTooltip: false,
      to: `/adminportal/${user.data?.isRole ? "identity" : "administrators"}/${
        user.accountId
      }`,
      text: t("Menu:profile"),
      icon: "user",
    });
  }

  if (languageStore.userLanguages.length > 1) {
    const notSelected = languageStore.userLanguages.find(
      (lng) => lng.id !== languageStore.currentLanguage?.id
    );
    if (notSelected)
      menu.item18.submenuItems?.unshift({
        id: 21,
        type: "context_action",
        showTooltip: false,
        action: () => {
          changeSelectedLanguage(notSelected.id);
        },
        text: t(`Languages:LANGUAGE-${notSelected.nativeName.toUpperCase()}`),
        flag: notSelected.flagIconKey,
      });
  }

  // Footer end

  return menu;
};

export default useMenu;
