import { Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

import useUser from "../../../core/user/useUser";
import useTranslations from "../../../core/i18n/useTranslations";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";

const DateOfDeathModal = ({
  deceasedDate,
  isOpen,
  onClose,
  setDeceasedDate,
}: {
  deceasedDate?: Date;
  isOpen: boolean;
  onClose: () => void;
  setDeceasedDate: Dispatch<SetStateAction<Date | undefined>>;
}) => {
  const t = useTranslations();
  const { data, isLoading, update } = useSharedObject();
  const { dateFormat, config } = useUser();

  const isDeceased = !!data?.dateOfDeath;

  const timezone = config?.timezone.timezoneName!;

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  if (!data) {
    notify({ message: t("Errors:UserNotFound"), variant: "error" });
    onClose();
    return null;
  }

  const onSubmit = async () => {
    try {
      if (deceasedDate) {
        setDeceasedDate(undefined);
      }

      await update({ ...data, dateOfDeath: deceasedDate });
      notify({
        message: t("Objects:edited_success"),
      });
      onClose();
    } catch (error: any) {
      notifyApiErrors(error?.response?.data?.errors);
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      header={
        isDeceased
          ? t("Objects:mark_as_not_deceased")
          : t("Objects:mark_as_deceased")
      }
      isOpen={isOpen}
      onClose={onClose}
      submitBtn={{
        text: !isDeceased
          ? t("Common:save")
          : t("Objects:confirm_not_deceased"),
        onClick: onSubmit,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      {!isDeceased ? (
        <DatePicker
          label={t("Common:labels_dod")}
          value={deceasedDate}
          placeholder={dateFormat?.split("/").join("-")}
          onChange={(dateString) => {
            setDeceasedDate(dateString ?? undefined);
          }}
          maxDate={moment().add("1d").tz(timezone).toDate()}
          dateFormat={dateFormat?.split("/").join("-")}
        />
      ) : (
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Messages:date_of_death_description")}
        </p>
      )}
    </PrimeModal>
  );
};

export default observer(DateOfDeathModal);
