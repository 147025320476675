import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { deleteObject } from "../../../core/api/objects/objects";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";

import useUser from "../../../core/user/useUser";
import useTranslations from "../../../core/i18n/useTranslations";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

const DeleteObjectModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await deleteObject(id, authenticatedRequest);
      notify({
        message: t("Objects:delete_object_success"),
      });
      history.replace("/adminportal/objects");
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={isOpen}
      onClose={onClose}
      fixedWidth="576px"
      header={t("Objects:delete_object")}
      submitBtn={{ text: t("Objects:delete_object"), onClick: onSubmit }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:delete_object_confirm")}
      </p>
    </PrimeModal>
  );
};

export default observer(DeleteObjectModal);
