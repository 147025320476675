import useTranslations from "../../../../core/i18n/useTranslations";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Button from "../../../../ui-lib/components/Button/Button";
import { IContactsRelation } from "../../../../core/api/contacts/types";
import { useEffect } from "react";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const ContactsRelationModal = ({
  isOpen,
  isAdding,
  isEdit = false,
  onClose,
  onSubmit,
  defaultValues,
  setSelectedContactId,
}: {
  isOpen: boolean;
  isAdding?: string | undefined;
  isEdit?: boolean;
  onClose: () => void;
  onSubmit: (values: IContactsRelation, closeList?: boolean) => Promise<void>;
  defaultValues?: any;
  setSelectedContactId?: (contactId: number | undefined) => void;
}) => {
  const t = useTranslations();

  const {
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors },
    reset,
  } = useForm<IContactsRelation>({ defaultValues });

  useEffect(() => {
    if (defaultValues && !isAdding) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const modalFooter = () => {
    return (
      <Row type="left">
        <Button
          disabled={!!errors.relation || !!isAdding}
          loading={isAdding === "close"}
          text={t("Common:save")}
          onClick={async () => {
            // save and close
            await onSubmit(getValues(), true);
            reset();
          }}
        />
        {!isEdit && (
          <>
            <Spacer size={8} />
            <Button
              disabled={!!errors.relation || !!isAdding}
              loading={isAdding === "save"}
              variant="border-link"
              text={t("Common:save_and_add_another")}
              onClick={async () => {
                // just save
                await onSubmit(getValues());
                reset();
              }}
            />
          </>
        )}

        <Spacer size={8} />

        <Button
          disabled={!!isAdding}
          variant="secondary"
          text={t("Common:cancel")}
          onClick={() => {
            if (setSelectedContactId) {
              setSelectedContactId(undefined);
            }
            onClose();
          }}
        />
      </Row>
    );
  };

  return (
    <PrimeModal
      withHeader
      header={t("Objects:relation_modal_title")}
      isOpen={isOpen}
      onClose={onClose}
      customFooter={modalFooter()}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:relation_description")}
      </p>
      <Spacer size={16} />
      <form>
        <Controller
          name="relation"
          control={control}
          rules={{ maxLength: 30 }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              value={field.value}
              onChange={(e) => {
                setValue("relation", e.target.value);
                trigger("relation");
              }}
              label={t("Objects:relation_to_object")}
              placeholder={t("Objects:relation_to_object_placeholder")}
              validationError={
                errors.relation &&
                t("Errors:length_to_long").replace("{0}", "30")
              }
            />
          )}
        />

        <Spacer size={16} />

        <label>{t("Objects:additional_contact_info")}</label>
        <div
          style={{ border: "1px solid var(--Grey-200)", padding: "4px 16px" }}
        >
          <Controller
            name="keyHolder"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={!!field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
                label={t("Contacts:key_holder")}
              />
            )}
          />

          <Controller
            name="nextOfKin"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={!!field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
                label={t("Contacts:next_of_kin")}
              />
            )}
          />
        </div>
      </form>
    </PrimeModal>
  );
};
